import { useActor } from "@xstate/react";
import { BackendResponse } from "libs/ui";
import { xor } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { AppliedFilterStateMap, FilterConfig, TableFilterConfig } from "./model";

export function useFilterComponentConfig<
  T extends BackendResponse,
  A extends AppliedFilterStateMap,
>({ tableService, filterSideModelState }: TableFilterConfig<T, A>): FilterConfig<A> {
  const [state, send] = useActor(tableService);
  const { activeFilters, isFilterSideModalOpen, closeModal } = filterSideModelState;

  const [appliedFilters, setAppliedFilters] = useState<A>({
    ...state.context.filters,
  } as A);

  const init = useCallback(() => {
    setAppliedFilters({ ...state.context.filters } as A);
  }, [state.context.filters]);

  const onSearchChange = (value: string) =>
    setAppliedFilters(prevValue => ({
      ...prevValue,
      contains: value ?? undefined,
    }));

  const onNewFilterApplied = <T>(key: keyof A, value: T) => {
    setAppliedFilters(prevValue => ({
      ...prevValue,
      [key]: xor(prevValue[key] ?? [], [value]),
    }));
  };

  const onNewRadioFilterApplied = <T>(key: keyof A, value: T) => {
    setAppliedFilters(prevValue => ({
      ...prevValue,
      [key]: value,
    }));
  };

  const onSingleFilterClear = useCallback((key: keyof A) => {
    setAppliedFilters(currentFilters => {
      const newFilterList = { ...currentFilters, [key]: undefined as A[keyof A] };

      return newFilterList;
    });
  }, []);

  const onClearAll = useCallback(() => {
    setAppliedFilters({} as A);
  }, []);

  const onUpdateFilters = useCallback(() => {
    send([{ type: "UPDATE_FILTERS", value: { ...appliedFilters } }]);
    closeModal();
  }, [appliedFilters, closeModal, send]);

  const onCloseModal = useCallback(() => {
    setAppliedFilters({ ...state.context.filters } as A);
    closeModal();
  }, [closeModal, state.context.filters]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    isFilterSideModalOpen,
    onSearchChange,
    onClearAll,
    onUpdateFilters,
    onCloseModal,
    activeFilters,
    appliedFilters,
    onNewFilterApplied,
    onNewRadioFilterApplied,
    onSingleFilterClear,
  };
}
