import { CountryType, NationalityType } from "api/query";
import { UserStatusType, UserRoleType, GenderType } from "models/PersonModels";

export interface AvatarPersonData {
  id?: string | null;
  src?: string | null;
  loading?: boolean;
  name?: string;
  organizationId?: string | null;
}

export const CHANGE_STATUS_ALLOWED: UserStatusType[] = ["Active", "Invited", "Deactivated"];

export interface PersonResponseModel {
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  organizationId: string;
  status: UserStatusType;
  role: UserRoleType;
  rowVersion?: string;
}
export interface DetailResponseModel extends PersonResponseModel {
  phone: string;
  street: string;
  state?: number | null;
  city: string;
  country: CountryType;
  zipCode?: string | null;
  organizationName: string;
  isAccountOwner?: boolean;
  nationalityType?: NationalityType | null;
  gender?: GenderType;
}
