import { useActor } from "@xstate/react";
import { useInterpret } from "@xstate/react";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { createSafeContext } from "utils/createSafeContext";
import {
  TestsTableStateContext,
  makeTableFetchStateMachine,
  //   useMakeTestsTableAvatarsState,
} from "../common/TestsTable/model";

const Context = createSafeContext<TestsTableStateContext>();

export const useTestsTableStateContext = Context.hook;

export function TestsTableStateProvider({ children }: PropsWithChildren<{}>) {
  const tableMachine = useMemo(() => makeTableFetchStateMachine(), []);
  const tableService = useInterpret(tableMachine);
  //   const [avatarsState] = useMakeTestsTableAvatarsState(tableService);

  const [, send] = useActor(tableService);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  const value = useMemo(() => {
    return {
      tableService,
      fetch,
      //   avatarsState,
    };
  }, [fetch, tableService]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
