import { useActor, useInterpret } from "@xstate/react";
import { TableContext, tableStateMachine } from "libs/ui";
import { useMemo, useCallback, useEffect } from "react";
import { TestsTable } from "../common/TestsTable";
import { TestListResponseModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { useGlobalConfigContext } from "context/GlobalConfigContext";

async function fetchMeasurements(
  organizationId: string,
  userEntityId: string,
  context?: TableContext<TestListResponseModel, any>,
) {
  const result = CarnaApiQuery.Devices.getMeasurements({
    organizationId,
    userEntityId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

const makeTableFetchStateMachine = (organizationId: string, deviceId: string) =>
  // TODO Add filter !!!
  tableStateMachine((context?: TableContext<TestListResponseModel, any>) =>
    fetchMeasurements(organizationId, deviceId, context),
  );

interface TestsPerDeviceTableProps {
  organizationId: string;
  deviceId: string;
}

export function TestsPerDeviceTable({ organizationId, deviceId }: TestsPerDeviceTableProps) {
  const tableMachine = useMemo(
    () => makeTableFetchStateMachine(organizationId, deviceId),
    [organizationId, deviceId],
  );

  const tableService = useInterpret(tableMachine);
  const [state, send] = useActor(tableService);
  const { appConfig } = useGlobalConfigContext();

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  useEffect(() => {
    if (state.matches("init")) {
      fetch();
    }
  }, [fetch, state]);

  return (
    <TestsTable
      showTrend={appConfig?.components.tables.Laboratory.Devices.showTrending}
      testsTableStateContext={{ tableService, fetch }}
      appliedColumns={[
        "measurementType",
        "date",
        "patient",
        "serumCreatinine",
        "eGFR",
        "testedBy",
        "id",
      ]}
    />
  );
}
