/* eslint-disable no-restricted-globals */
import { useInterpret } from "@xstate/react";
import { DocumentResponseModelArrayApiResponse } from "api";
import { UserDetailsResponseModel } from "api/query";
import { UserModel } from "components/Forms/User/model";

import { CarnaApi } from "config/api";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { NEW_BACKEND_NULL } from "config/NEW_BACKEND";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { useCallback, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteStates } from "router";
import { getRowVersion } from "utils/helpers/getRowVersion";

import {
  ConfirmStatusEvent,
  DetailsPageStateContext,
  makeDetailsPageStateMachine,
  SaveEvent,
} from "utils/machines/pages/details/makeDetailsPageStateMachine";
import { DetailsPageServiceList } from "utils/machines/pages/details/model";
import { assign } from "xstate";
import { firstValueFrom } from "rxjs";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import {
  OnEventStatusSubscribeError,
  waitForQueryService,
} from "utils/hooks/useOnEventStatusSubscribe";
import { isAdmin as isRoleAdmin } from "utils/helpers/roles";
import { stripNetworkBodyWith } from "components/Forms/helper";

import {
  UpdateAdminRequestModelForStripping,
  UpdatePartnerRequestModelForStripping,
} from "config/binding";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";

export interface DetailsFormProps {
  service: ReturnType<typeof useMakeUserPagesDetailState>;
  userId: string;
  organizationId: string;
  onClose?: () => void;
}

function useApis(userId: string, selectedUserOrganizationId: string) {
  const { isAdmin, organizationId } = useCurrentUserData();
  const location = useLocation();

  const navigate = useNavigate();

  const fetchAvatarRef = useRef<(() => Promise<DocumentResponseModelArrayApiResponse>) | null>(
    null,
  );

  const fetchUserData = useCallback(() => {
    return isAdmin
      ? CarnaApiQuery.Users.getById({ id: userId })
      : CarnaApiQuery.Partners.getById({ organizationId, id: userId });
  }, [isAdmin, userId, organizationId]);

  const saveUserStatus = useCallback(
    (
      context: DetailsPageStateContext<UserDetailsResponseModel>,
      event: ConfirmStatusEvent,
    ): Promise<UserDetailsResponseModel> => {
      return NEW_BACKEND_NULL;

      // const { id = "", organizationId = "", role } = context.data ?? {};
      // const userRoleApiUrl =
      //   role === "Admin"
      //     ? CarnaApi.Admin.organizationsOrganizationIdAdministratorsIdPatch
      //     : CarnaApi.Partner.organizationsOrganizationIdPartnersIdPatch;

      // return userRoleApiUrl(id, organizationId, {
      //   isActive: event.value,
      //   rowVersion: context.data?.rowVersion ?? 1,
      // });
    },
    [],
  );

  const saveUser = useCallback(
    async (
      context: DetailsPageStateContext<UserDetailsResponseModel>,
      event: SaveEvent<UserModel>,
    ): Promise<UserDetailsResponseModel> => {
      const { id = "", organizationId = "", role = undefined } = context.data ?? {};

      const putUser =
        isAdmin && isRoleAdmin(role)
          ? CarnaApiEvent.Admin.put(
              {
                organizationId: organizationId,
                userEntityId: id,
                replaceAdminRequestModel: {
                  ...event.value,
                  rowVersion: event.value.rowVersion ?? "none",
                },
              },
              stripNetworkBodyWith(UpdateAdminRequestModelForStripping),
            )
          : CarnaApiEvent.Partner.put(
              {
                organizationId: organizationId,
                userEntityId: id,
                replacePartnerRequestModel: {
                  ...event.value,
                  rowVersion: event.value.rowVersion ?? "none",
                },
              },
              stripNetworkBodyWith(UpdatePartnerRequestModelForStripping),
            );

      await firstValueFrom(waitForQueryService(putUser));

      const result = await putUser;

      return {
        ...NotNullOrUndefined(context.data),
        ...event.value,
        ...getRowVersion(result),
      };
    },
    [isAdmin],
  );

  /**
   * ! Solution if the user opens this page in new tab
   * ! We have to know the role for the entity
   *
   * ! Location obj will change after navigate(...) call
   * ! this async () => ** will still ** use the old location without state
   * ! However if use reloads the tab, this will pull the correct location obj
   * ! We could use global window.history, but react-routers Location has different Interface
   * ! Rewrite in case of need
   */
  if (fetchAvatarRef.current === null) {
    fetchAvatarRef.current = async () => {
      let role = (location.state as RouteStates["userDetail"])?.role;

      if (!!role === false) {
        role = (await fetchUserData()).role;
        navigate(window.location.pathname, {
          replace: true,
          state: {
            roleType: role,
          },
        });
      }

      return CarnaApi.DocumentApi.downloadImagesPost([
        {
          userId,
          organizationId: selectedUserOrganizationId,
          role: role ?? "Partner",
        },
      ]);
    };
  }

  return { fetchUserData, saveUserStatus, saveUser, fetchAvatar: fetchAvatarRef.current };
}

const successToast = () =>
  toastStore.pushToast({
    expire: 5000,
    type: "success",
    msg: i18n.t("UserDetails.successPutToast", { ns: "translation" }),
  });

const errorPutToast = (_context: any, event: any) => {
  const err = event.data as OnEventStatusSubscribeError;

  if (err.type === "OnEventStatusSubscribeError") {
    switch (err.code) {
      case "BE_ERROR":
        showBeFieldErrors(err.err, i18n.t("UserDetails.errorPutToast", { ns: "translation" }));

        break;
      case "ACTION_FAILED":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("UserDetails.errorPutToast", { ns: "translation" }),
        });
        break;
      case "STATUS_QUERY_ERROR":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("UserDetails.queryServiceError", { ns: "translation" }),
        });
    }
    return;
  }

  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("UserDetails.errorPutToast", { ns: "translation" }),
  });
};

const errorFetchToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("UserDetails.errorFetchToast", { ns: "translation" }),
  });
};

export function useMakeUserPagesDetailState(userId: string, organizationId: string) {
  const { fetchUserData, saveUserStatus, saveUser } = useApis(userId, organizationId);

  const userPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        UserDetailsResponseModel,
        UserModel,
        DetailsPageServiceList<typeof fetchUserData, typeof saveUser, typeof saveUserStatus>
      >(),
    [],
  );

  const service = useInterpret(userPageStateMachine, {
    services: {
      saveData: saveUser,
      fetchData: fetchUserData,
      saveStatus: saveUserStatus,
    },
    actions: {
      dataSaved: successToast,
      savingFailed: errorPutToast,
      failedToLoadData: errorFetchToast,
      loadSavedData: assign({
        data: (_context, event) => {
          return event.data;
        },
      }),
      loadEntityData: assign({
        data: (_context, event) => {
          return event.data;
        },
      }),
      updateSaveStatus: assign({
        data: (context, event) => {
          return {
            ...context.data,
            status: event.data?.status ? "Active" : "Deactivated",
          } as UserDetailsResponseModel;
        },
      }),
      refreshTable: () => {},
    },
  });

  return service;
}
