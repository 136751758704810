import { MeasurementPatientWidgetDataModel } from "api/query";
import { TestsSideDetails } from "components/AppTables/common/TestsTable/TestsSideDetails";
import { TestUnitType, mapToSideDetails } from "components/AppTables/common/TestsTable/utils";
import { SideDetails } from "components/MeasurementSideDetails/SideDetails/SideDetails";
import { GeneralSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/GeneralSectionDetails";
import { PatientSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/PatientSectionDetails";
import { useDetailsPageContext } from "components/PageTemplate/DetailsPage/context";
import { CarnaApiQuery } from "config/apiQuery";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { LoadedModel, isDefaultModel, isLoaded, isLoading } from "models/loadable";
import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useApi } from "utils/hooks/useApi";
import { useOverviewAPIContext } from "../OverviewAPIContext";
import "./MeasurementWidgets.scss";
import { SingleWidget } from "./SingleWidget";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";

function getTestUnitType(type: MeasurementWidgetType): TestUnitType {
  switch (type) {
    case "SerumCreatinine":
    case "Egfr":
      return "SerumCreatinine";

    case "Bmi":
    case "Height":
    case "Weight":
      return "BMI";

    case "Uacr":
    case "UrineAlbumin":
    case "UrineCreatinine":
      return "UACR";

    case "SemiQuantitativeUacr":
      return "SemiQuantitativeUACR";

    case "BloodPressure":
    case "Glucose":
      return type;

    default:
      throw new Error(`type ${type} not found in TestUnitType`);
  }
}

interface Props {
  organizationId: string;
  patientId: string;
}
export function MeasurementWidgets({ organizationId, patientId }: Readonly<Props>) {
  const { appConfig } = useGlobalConfigContext();
  const [testUnitType, setTestUnitType] = useState<{
    type: TestUnitType;
    subtype: MeasurementWidgetType;
  }>();
  const { data } = useOverviewAPIContext();

  const { detailsPageSideBarRef } = useDetailsPageContext();

  const [testData, getTestData, resetTestData] = useApi(
    CarnaApiQuery.Test.getByOrganizationPatientId,
  );

  const testSideDetails = useMemo(
    () => (isLoaded(testData) ? mapToSideDetails(testData.value, testUnitType?.type) : undefined),
    [testData, testUnitType],
  );

  const onWidgetClick = (key: MeasurementWidgetType) => {
    if (key === testUnitType?.subtype) {
      return;
    }

    if (isLoaded(data[key])) {
      setTestUnitType({
        type: getTestUnitType(key),
        subtype: key,
      });
      const { value } = data[key] as LoadedModel<MeasurementPatientWidgetDataModel>;
      const { id } = value;
      getTestData({ organizationId, userEntityId: patientId, testEntityId: id ?? "" });
    }
  };

  const onSideDetailsClose = useCallback(() => {
    setTestUnitType(undefined);
    resetTestData();
  }, [resetTestData]);

  return (
    <div className="MeasurementWidgets" data-testid="MeasurementWidgets">
      {(appConfig?.pages.PatientsPage.Details.MeasurementWidgets ?? []).map(key => (
        <SingleWidget onClick={onWidgetClick} key={key} type={key} />
      ))}

      {detailsPageSideBarRef.current
        ? createPortal(
            <SideDetails
              show={!isDefaultModel(testData)}
              title={isLoaded(testData) ? `#${testSideDetails?.test.testCountId}` : ""}
              onClose={onSideDetailsClose}
              data-testid={testUnitType?.subtype}
            >
              <>
                <TestsSideDetails
                  showTrend={appConfig?.components.tables.Laboratory.Patient.showTrending}
                  measurementTabType={testUnitType?.subtype}
                  sideDetails={testSideDetails}
                  loading={isLoading(testData)}
                />

                <GeneralSectionDetails
                  data={testSideDetails}
                  loading={isLoading(testData)}
                  //   hcpAvatarImage={hcpAvatarImage}
                  //   avatarsLoading={avatarsState.matches("loading")}
                />
                <PatientSectionDetails
                  data={testSideDetails}
                  loading={isLoading(testData)}
                  selectedTestUnit={testUnitType?.type}
                />
              </>
            </SideDetails>,
            detailsPageSideBarRef.current,
          )
        : null}
    </div>
  );
}
