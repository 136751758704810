import { CurrentUserNameModel, useCurrentUserNameContext } from "context/CurrentUserNameContext";
import _ from "lodash-es";
import { loaded } from "models/loadable";
import { useCallback, useEffect } from "react";
import { MakeDetailsPageStateMachineService } from "utils/machines/pages/details/model";
import { useCurrentUserData } from "./useCurrentUserData";

export const getCurrentUserNameUpdate = (
  currentUser: CurrentUserNameModel,
  updatedUser: CurrentUserNameModel,
) => {
  const currentUserUpdate = {} as CurrentUserNameModel;
  Object.keys(currentUser).forEach(key => {
    const currentKey = key as keyof CurrentUserNameModel;
    if (!_.isEqual(currentUser[currentKey], updatedUser[currentKey])) {
      currentUserUpdate[currentKey] = updatedUser[currentKey];
    }
  });

  if (_.isEmpty(currentUserUpdate)) {
    return null;
  }

  return {
    ...currentUser,
    ...currentUserUpdate,
  };
};

export function useUpdateCurrentUserNameIfSame<
  T extends MakeDetailsPageStateMachineService<any, any, any>,
>(personId: string, detailsService: T) {
  const { currentUserId } = useCurrentUserData();
  const { currentUserName, setCurrentUserName } = useCurrentUserNameContext();

  const onSubmit: Parameters<typeof detailsService.onTransition>[0] = useCallback(
    state => {
      if (
        state.matches("entity.loaded") &&
        state.history?.event.type === "SAVE_DATA" &&
        personId === currentUserId
      ) {
        const currentUserNameUpdate = getCurrentUserNameUpdate(
          currentUserName.value,
          state.context.data,
        );
        if (currentUserNameUpdate) {
          setCurrentUserName(loaded(currentUserNameUpdate));
        }
      }
    },
    [currentUserId, currentUserName.value, personId, setCurrentUserName],
  );

  useEffect(() => {
    detailsService.onTransition(onSubmit);

    return () => {
      detailsService.off(onSubmit);
    };
  }, [detailsService, onSubmit]);
}
