import { Auth } from "aws-amplify";

export async function fetchWithToken<TInput>(
  url: string,
  options?: {
    method: string;
    headers?: HeadersInit;
    body?: TInput;
  },
) {
  const headers = await getAccessHeaders(options?.headers);

  const fUrl =
    options?.method === "GET" && options.body ? url + `?${new URLSearchParams(options.body)}` : url;

  const response = await fetch(fUrl, {
    ...options,
    headers,
    body: options?.body && options.method !== "GET" ? JSON.stringify(options.body) : undefined,
  });

  return response;
}

export async function getAccessHeaders(options?: HeadersInit) {
  const headers = new Headers(options);

  headers.set("Content-Type", "application/json");
  headers.set("access-token", `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`);
  headers.set("identity-token", `${(await Auth.currentSession()).getIdToken().getJwtToken()}`);

  return headers;
}
