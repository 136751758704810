import {
  GeneralPractitionerResponseModel,
  OrganizationDetailsResponseModel,
  UserRoleType,
} from "api/query";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Input, Selection, SideModalElements } from "libs/ui";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

import { DynamicFormGenerator } from "components/DynamicFormGenerator";
import { AddressSection } from "components/Forms/FormElements/AddressSection";
import { DeviceField, useOnDeviceChange } from "components/Forms/FormElements/DevicesField";
import { GeneralPractitionersField } from "components/Forms/FormElements/GeneralPractitionersField";
import { Group } from "components/Forms/FormElements/Group";
import { HcpsField } from "components/Forms/FormElements/HcpsField";
import { OrganizationField } from "components/Forms/FormElements/OrganizationField";
import { PersonalSection } from "components/Forms/FormElements/PersonalSection";
import { PhysicalSection } from "components/Forms/FormElements/PhysicalSection";
import { FEHcpResponseModel } from "models/PersonModels";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { userRoleTypeToSelectOptions } from "utils/mappers/userRoleTypeToSelectOptions";
import "./../../Form.scss";
import { isReadonly } from "./../../helper";
import { PatientFormFieldsProps } from "./../model";
import { fieldStatusResolve } from "components/Forms/fieldStatusResolve";

export function FormFields({
  onChange,
  onSubmit,
  entityData: patientData, // create and edit
  editableFields,
  loading,
  children,
  formMode,
}: PropsWithChildren<PatientFormFieldsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const { isAdmin } = useCurrentUserData();
  /**
   * TODO grooming
   * This component should be dependant only to itself
   * Thus, add prop, "defaultValues or initValues"
   * It should come from parent...etc
   */
  const { appConfig } = useGlobalConfigContext();

  const can = useHasAccess();
  const [onDeviceChange] = useOnDeviceChange(patientData, onChange);

  const isAdminInCreateMode = isAdmin && !isReadonly("organizationId", editableFields);

  // #region ! TODO optimize this
  const onOrganizationChange = useCallback(
    (value?: OrganizationDetailsResponseModel) => {
      onChange(
        !value
          ? NotNullOrUndefined(appConfig?.forms.defaultValues.patient.organizationId)
          : value.id,
        "organizationId",
      );

      onChange(!value ? "" : value.name, "organizationName");

      onChange(undefined, "hcpId");
      onChange(undefined, "deviceId");
      onChange(undefined, "deviceSerialNumber");
      onChange(undefined, "hcpFirstName");
      onChange(undefined, "hcpLastName");
    },
    [appConfig?.forms.defaultValues.patient.organizationId, onChange],
  );

  const onHcpChange = useCallback(
    (value?: FEHcpResponseModel) => {
      onChange(!value ? undefined : value.id ?? "", "hcpId");
      onChange(!value ? undefined : value.firstName ?? "", "hcpFirstName");
      onChange(!value ? undefined : value.lastName ?? "", "hcpLastName");
    },
    [onChange],
  );

  const onGeneralPractitionerChange = useCallback(
    (value?: GeneralPractitionerResponseModel) => {
      onChange(!value ? undefined : value.entityId ?? "", "generalPractitionerId");
      onChange(
        !value
          ? undefined
          : {
              title: value.title,
              firstName: value.firstName,
              lastName: value.lastName,
              registrationNumber: value.registrationNumber,
            },
        "generalPractitioner",
      );
    },
    [onChange],
  );

  const patientsHcpTypeField = fieldStatusResolve({
    formMode,
    isReadonly: isReadonly("hcpId", editableFields),
    field: appConfig?.entities.patient.hcp,
    value: patientData.hcpId,
  });

  const deviceIdTypeField = fieldStatusResolve({
    formMode,
    isReadonly: isReadonly("deviceId", editableFields),
    field: appConfig?.entities.patient.deviceField,
    value: patientData.deviceId,
  });

  const generalPractitionerIdTypeField = fieldStatusResolve({
    formMode,
    isReadonly: isReadonly("generalPractitionerId", editableFields),
    field: appConfig?.entities.patient.generalPractitioner,
    value: patientData.generalPractitionerId,
  });

  const shouldShowAdditionalGroup = useMemo(() => {
    const isInViewInfoMode = editableFields?.length === 0;

    const configShowAdditionalPropertiesKeys =
      NotNullOrUndefined(appConfig?.entities.patient.additionalProperties).map(
        el => el.propertyName,
      ).length > 0;

    const haveFieldsToShow =
      configShowAdditionalPropertiesKeys || generalPractitionerIdTypeField.show;

    const patientHasPractitioner = !!patientData.generalPractitionerId;
    const patientHasAdditionalProps = !!Object.values(
      patientData["additionalProperties"] ?? {},
    ).some(el => !!el.length);

    if (isInViewInfoMode) {
      return patientHasPractitioner || patientHasAdditionalProps;
    }

    return haveFieldsToShow;
  }, [
    appConfig?.entities.patient.additionalProperties,
    editableFields?.length,
    generalPractitionerIdTypeField.show,
    patientData,
  ]);

  return (
    <form className="Form" onSubmit={onSubmit} data-testid="patient-form">
      <PersonalSection
        personalData={patientData}
        onChange={onChange}
        editableFields={editableFields}
        loading={loading}
        role="Patient"
        formMode={formMode}
      >
        {children}
      </PersonalSection>
      <AddressSection
        addressData={patientData}
        onChange={onChange}
        editableFields={editableFields}
        loading={loading}
        zipCodeFieldStatus={fieldStatusResolve({
          formMode,
          isReadonly: isReadonly("zipCode", editableFields),
          field: appConfig?.entities.patient.zipCode,
          value: patientData.zipCode,
        })}
      />
      <Group>
        <SideModalElements.SectionTitle>
          {t("Subtitle.organizational")}
        </SideModalElements.SectionTitle>
        <div className="Form__field">
          <Selection<UserRoleType>
            options={userRoleTypeToSelectOptions().filter(el => el.value === "Patient")}
            label={t("userRole")}
            value={"Patient"}
            readOnly
            loading={loading}
          />
        </div>
        {patientData.organizationName && isReadonly("organizationName", editableFields) && (
          <div className="Form__field">
            <Input
              data-testid="organization-name"
              label={t("organization")}
              value={patientData.organizationName}
              readOnly
              loading={loading}
            />
          </div>
        )}
        {isAdminInCreateMode && (
          <div className="Form__field">
            <OrganizationField
              onSelect={onOrganizationChange}
              initialValue={patientData.organizationName}
            />
          </div>
        )}
        {patientsHcpTypeField.show ? (
          <div className="Form__field">
            <HcpsField
              readOnly={patientsHcpTypeField.readonly}
              key={`hcp-${patientData.organizationId}`}
              organizationId={patientData.organizationId}
              initialValue={
                patientsHcpTypeField.showNA
                  ? t("na")
                  : patientData.hcpId
                    ? `${patientData.hcpFirstName ?? ""} ${patientData.hcpLastName ?? ""}`
                    : undefined
              }
              onSelect={onHcpChange}
              loading={loading}
              optional={patientsHcpTypeField.optional}
            />
          </div>
        ) : null}
        {deviceIdTypeField.show ? (
          <div className="Form__field">
            <DeviceField
              optional={deviceIdTypeField.optional}
              readOnly={deviceIdTypeField.readonly}
              key={`device-${patientData.organizationId}`}
              mode={can("manage-device") && patientData.id ? "edit" : "create"}
              organizationId={patientData.organizationId}
              // add modal doesn't have patient id
              personId={patientData.id}
              deviceSerialNumber={patientData.deviceSerialNumber}
              deviceName={patientData.deviceType}
              onSelect={onDeviceChange}
              loading={loading}
            />
          </div>
        ) : null}
      </Group>

      {shouldShowAdditionalGroup ? (
        <Group>
          <SideModalElements.SectionTitle>
            {t("Subtitle.additional")}
          </SideModalElements.SectionTitle>

          {generalPractitionerIdTypeField.show ? (
            <div className="Form__field">
              <GeneralPractitionersField
                optional={generalPractitionerIdTypeField.optional}
                readOnly={generalPractitionerIdTypeField.readonly}
                key={`generalPractitioner-${patientData.organizationId}`}
                initialValue={
                  generalPractitionerIdTypeField.showNA
                    ? t("na")
                    : patientData.generalPractitionerId
                      ? `${patientData.generalPractitioner?.title ?? ""} ${
                          patientData.generalPractitioner?.firstName ?? ""
                        } ${patientData.generalPractitioner?.lastName ?? ""},  ${
                          patientData.generalPractitioner?.registrationNumber ?? ""
                        }`
                      : undefined
                }
                onSelect={onGeneralPractitionerChange}
                loading={loading}
              />
            </div>
          ) : null}

          <DynamicFormGenerator
            formState={patientData["additionalProperties"] ?? {}}
            onChange={onChange}
            additionalProps={appConfig?.entities.patient.additionalProperties}
            custom={appConfig?.general.custom}
            formMode={formMode}
            isReadonly={isReadonly("additionalProperties", editableFields)}
          />
        </Group>
      ) : null}

      {(isReadonly("height", editableFields) || isReadonly("weight", editableFields)) &&
      patientData.height &&
      patientData.weight ? (
        <PhysicalSection
          patientData={patientData}
          onChange={onChange}
          editableFields={editableFields}
          loading={loading}
        />
      ) : null}
      <button hidden type="submit" />
    </form>
  );
}
