/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HcpListDetailsResponseModel,
    HcpListDetailsResponseModelFromJSON,
    HcpListDetailsResponseModelFromJSONTyped,
    HcpListDetailsResponseModelToJSON,
} from './HcpListDetailsResponseModel';

/**
 * 
 * @export
 * @interface HcpListResponseModel
 */
export interface HcpListResponseModel {
    /**
     * 
     * @type {string}
     * @memberof HcpListResponseModel
     */
    paginationToken?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HcpListResponseModel
     */
    limit?: number;
    /**
     * 
     * @type {Array<HcpListDetailsResponseModel>}
     * @memberof HcpListResponseModel
     */
    users?: Array<HcpListDetailsResponseModel> | null;
}

export function HcpListResponseModelFromJSON(json: any): HcpListResponseModel {
    return HcpListResponseModelFromJSONTyped(json, false);
}

export function HcpListResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpListResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationToken': !exists(json, 'PaginationToken') ? undefined : json['PaginationToken'],
        'limit': !exists(json, 'Limit') ? undefined : json['Limit'],
        'users': !exists(json, 'Users') ? undefined : (json['Users'] === null ? null : (json['Users'] as Array<any>).map(HcpListDetailsResponseModelFromJSON)),
    };
}

export function HcpListResponseModelToJSON(value?: HcpListResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PaginationToken': value.paginationToken,
        'Limit': value.limit,
        'Users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(HcpListDetailsResponseModelToJSON)),
    };
}

