/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    TrackingDevicesResponseModelApiResponse,
    TrackingDevicesResponseModelApiResponseFromJSON,
    TrackingDevicesResponseModelApiResponseToJSON,
} from '../models';

/**
 * TrackingDevicesApi - interface
 * 
 * @export
 * @interface TrackingDevicesApiInterface
 */
export interface TrackingDevicesApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingDevicesApiInterface
     */
    trackingDevicesGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TrackingDevicesResponseModelApiResponse>>;

    /**
     */
    trackingDevicesGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TrackingDevicesResponseModelApiResponse>;

}

/**
 * 
 */
export class TrackingDevicesApi extends runtime.BaseAPI implements TrackingDevicesApiInterface {

    /**
     */
    async trackingDevicesGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TrackingDevicesResponseModelApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tracking-devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrackingDevicesResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async trackingDevicesGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TrackingDevicesResponseModelApiResponse> {
        const response = await this.trackingDevicesGetRaw(initOverrides);
        return await response.value();
    }

}
