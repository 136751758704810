import "./BasicChart.scss";

import classNames from "classnames";
import { Selection } from "libs/ui";
import {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { CarnaChartMeasurement } from "../CarnaChartMeasurement";

import { MeasurementGraphModel, TestResponseModel, UserGraphModel } from "api/query";
import { TestsSideDetails } from "components/AppTables/common/TestsTable/TestsSideDetails";
import { SideDetails } from "components/MeasurementSideDetails/SideDetails/SideDetails";
import { GeneralSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/GeneralSectionDetails";
import { PatientSectionDetails } from "components/MeasurementSideDetails/SideDetails/components/PatientSectionDetails";
import { MeasurementDetails } from "components/MeasurementSideDetails/SideDetails/components/model";
import * as echarts from "echarts/core";
import { ECharts } from "echarts/core";
import { LoadableModel, isDefaultModel, isLoaded, isLoading } from "models/loadable";
import { GraphMeasurementsKeys } from "pages/PatientsPage/Details/Graph/GraphDataAPIContext";
import { useChartZoomingOptions } from "../hooks/useChartZoomingOptions";
import { useInitChart } from "../hooks/useInitChart";
import { useWireUpCarnaLineChartHandlers } from "../hooks/useWireUpCarnaLineChartHandlers";
import { GetOptions, ZoomLevels } from "../model";
import { mapToSideDetails } from "../utils";
import { TestUnitType } from "components/AppTables/common/TestsTable/utils";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { SkeletonLoader } from "../SkeletonLoader";

export interface CarnaChartProps {
  data: LoadableModel<UserGraphModel>;
  /**
   * !!!! Deprecated dont use
   */
  dataType: GraphMeasurementsKeys;
  getOptions: GetOptions;
  afterInit?: (chartRef: MutableRefObject<ECharts | undefined | null>) => void;
  onZoomRange?: (range: [number, number]) => void;
  unitType: keyof MeasurementGraphModel["units"];
  chartHeaderSlot?: React.ReactElement | null;
  selectedNode: MeasurementDetails | undefined;
  closeDetails: () => void;
  onTimeRangeChangeCb?: (zoomLevel: ZoomLevels | undefined, action: () => void) => void;
  onNodeClick: (val: echarts.ECElementEvent) => void;
  testData: LoadableModel<TestResponseModel>;
  chartRef: MutableRefObject<echarts.ECharts | undefined | null>;
  showSideDetails: boolean;
  redirectCb?: (
    setZoomLevel: (zoomLevel?: ZoomLevels | undefined, startZoomDate?: string | undefined) => void,
  ) => void;
  className?: string;
  showTrend?: boolean;
  measurementUnit?: MEASUREMENT_UNITS;
}

const INIT_GRAPH_MODEL: UserGraphModel = {
  genderType: "Male",
  id: "",
  measurements: [],
  roleType: "Admin",
};

export function CarnaChart({
  data,
  getOptions,
  afterInit,
  onZoomRange,
  unitType,
  dataType,
  chartHeaderSlot = null,
  selectedNode,
  closeDetails,
  onTimeRangeChangeCb,
  onNodeClick,
  testData,
  chartRef,
  showSideDetails,
  redirectCb,
  className,
  showTrend,
  measurementUnit,
}: PropsWithChildren<CarnaChartProps>) {
  const chartDivRef = useRef<HTMLDivElement | null>(null);

  const [currentSelectedZoomLevel, setCurrentSelectedZoomLevel, zoomOptions, onZoomChange] =
    useChartZoomingOptions();

  const graphData = useMemo(() => (isLoaded(data) ? data.value : INIT_GRAPH_MODEL), [data]);

  useInitChart({
    data: graphData,
    chartDivRef,
    chartRef,
    getOptions,
    afterInit,
    setCurrentSelectedZoomLevel,
    onZoomRange,
    currentSelectedZoomLevel,
  });

  //   const [patientAvatarImage, hcpAvatarImage, avatarsLoading] = useGetDetailsAvatars(selectedNode);

  /**
   * ! Order here for this useEffect is important
   * ! it must come after setting chartRef.current
   */
  const [setZoomLevel] = useWireUpCarnaLineChartHandlers(
    chartRef,
    onNodeClick,
    onZoomChange,
    setCurrentSelectedZoomLevel,
    graphData,
  );

  const onTimeRangeChange = useCallback(
    (zoomLevel?: ZoomLevels) => {
      setZoomLevel(zoomLevel, new Date().toString());

      if (onTimeRangeChangeCb) {
        onTimeRangeChangeCb(zoomLevel, closeDetails);
      }
    },
    [closeDetails, onTimeRangeChangeCb, setZoomLevel],
  );

  useEffect(() => {
    if (redirectCb) {
      redirectCb(setZoomLevel);
    }
  }, [redirectCb, setZoomLevel]);

  const graphSideDetails = selectedNode ? mapToSideDetails(selectedNode, dataType) : undefined;

  const showSkeletonLoader = useMemo(() => isLoading(data) || isDefaultModel(data), [data]);

  return (
    <div className="BasicChartWithLoader">
      <div className="BasicChartWithLoader__loader">
        <SkeletonLoader
          style={{
            opacity: showSkeletonLoader ? 1 : 0,
          }}
        />
      </div>
      <div
        style={{
          opacity: showSkeletonLoader ? 0 : 1,
        }}
        className={classNames("BasicChart", className)}
      >
        <Selection
          icon="Calendar"
          disabled={!(isLoaded(data) && data.value.measurements.length > 0)}
          options={zoomOptions}
          value={currentSelectedZoomLevel}
          onSelect={onTimeRangeChange}
          className="BasicChart__selection"
        />
        <div
          className={classNames("BasicChart__body", {
            "BasicChart__body--withDetails": showSideDetails,
          })}
        >
          <div className="BasicChart__content">
            <div className="BasicChart__header">
              <CarnaChartMeasurement measurementUnit={measurementUnit} />
              {chartHeaderSlot}
            </div>
            <div
              className="ChartWrapper"
              ref={ref => {
                chartDivRef.current = ref;
              }}
            />
          </div>
          <SideDetails
            show={showSideDetails}
            title={isLoaded(testData) ? `#${selectedNode?.testCountId}` : ""}
            onClose={closeDetails}
          >
            <TestsSideDetails
              loading={isLoading(testData)}
              measurementTabType={dataType}
              sideDetails={graphSideDetails}
              showTrend={showTrend}
            />

            <GeneralSectionDetails
              data={graphSideDetails}
              loading={isLoading(testData)}
            />
            <PatientSectionDetails
              data={graphSideDetails}
              loading={isLoading(testData)}
              selectedTestUnit={
                selectedNode ? (Object.keys(selectedNode?.units)[0] as TestUnitType) : undefined
              }
            />
          </SideDetails>
        </div>
      </div>
    </div>
  );
}
