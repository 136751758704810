/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';
import {
    UpdateOrganizationResponseModel,
    UpdateOrganizationResponseModelFromJSON,
    UpdateOrganizationResponseModelFromJSONTyped,
    UpdateOrganizationResponseModelToJSON,
} from './UpdateOrganizationResponseModel';

/**
 * 
 * @export
 * @interface UpdateOrganizationResponseModelApiResponse
 */
export interface UpdateOrganizationResponseModelApiResponse {
    /**
     * 
     * @type {UpdateOrganizationResponseModel}
     * @memberof UpdateOrganizationResponseModelApiResponse
     */
    data?: UpdateOrganizationResponseModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof UpdateOrganizationResponseModelApiResponse
     */
    errors?: Array<ApiError> | null;
}

export function UpdateOrganizationResponseModelApiResponseFromJSON(json: any): UpdateOrganizationResponseModelApiResponse {
    return UpdateOrganizationResponseModelApiResponseFromJSONTyped(json, false);
}

export function UpdateOrganizationResponseModelApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrganizationResponseModelApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : UpdateOrganizationResponseModelFromJSON(json['Data']),
        'errors': !exists(json, 'Errors') ? undefined : (json['Errors'] === null ? null : (json['Errors'] as Array<any>).map(ApiErrorFromJSON)),
    };
}

export function UpdateOrganizationResponseModelApiResponseToJSON(value?: UpdateOrganizationResponseModelApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': UpdateOrganizationResponseModelToJSON(value.data),
        'Errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(ApiErrorToJSON)),
    };
}

