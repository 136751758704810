/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    RulesResponseModelApiResponse,
    RulesResponseModelApiResponseFromJSON,
    RulesResponseModelApiResponseToJSON,
    UpdateRulesRequestModel,
    UpdateRulesRequestModelFromJSON,
    UpdateRulesRequestModelToJSON,
} from '../models';

export interface RulesPutRequest {
    UpdateRulesRequestModel?: UpdateRulesRequestModel;
}

/**
 * RulesApi - interface
 * 
 * @export
 * @interface RulesApiInterface
 */
export interface RulesApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RulesApiInterface
     */
    rulesGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RulesResponseModelApiResponse>>;

    /**
     */
    rulesGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RulesResponseModelApiResponse>;

    /**
     * 
     * @param {UpdateRulesRequestModel} [UpdateRulesRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RulesApiInterface
     */
    rulesPutRaw(requestParameters: RulesPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RulesResponseModelApiResponse>>;

    /**
     */
    rulesPut(UpdateRulesRequestModel?: UpdateRulesRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RulesResponseModelApiResponse>;

}

/**
 * 
 */
export class RulesApi extends runtime.BaseAPI implements RulesApiInterface {

    /**
     */
    async rulesGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RulesResponseModelApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RulesResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async rulesGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RulesResponseModelApiResponse> {
        const response = await this.rulesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async rulesPutRaw(requestParameters: RulesPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RulesResponseModelApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rules`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRulesRequestModelToJSON(requestParameters.UpdateRulesRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RulesResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async rulesPut(UpdateRulesRequestModel?: UpdateRulesRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RulesResponseModelApiResponse> {
        const response = await this.rulesPutRaw({ UpdateRulesRequestModel: UpdateRulesRequestModel }, initOverrides);
        return await response.value();
    }

}
