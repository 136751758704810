import { TestModel } from "components/AppTables/common/TestsTable/model";
import { Avatar, Button } from "libs/ui";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { RouteParams, mainRoutePaths } from "router";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import "../GeneralSectionDetails.scss";

interface TestedByFieldProps {
  data?: TestModel;
  hcpAvatarImage?: string | null;
}

export function TestedByField({ data, hcpAvatarImage }: Readonly<TestedByFieldProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.GeneralSectionDetails" });
  const navigate = useNavigate();
  const { hcpId } = useParams<RouteParams["hcpDetails"]>();
  const { isPatient } = useCurrentUserData();

  if (!data?.testedBy) {
    return <>{t("na")}</>;
  }

  if (data?.testedBy.id === data?.patient.id) {
    return <>{t("selfTested")}</>;
  }

  return (
    <Button
      onClick={
        data.testedBy.id === hcpId || isPatient
          ? undefined
          : () =>
              navigate(
                generatePath(mainRoutePaths.hcpDetails, {
                  hcpId: data.testedBy?.id ?? "",
                  organizationId: data.patient.organizationId,
                }),
              )
      }
      buttonType={"link-grey"}
      buttonSize={"small"}
      disabled={data.testedBy.id === hcpId || isPatient}
    >
      <Avatar
        firstName={data.testedBy.firstName ?? ""}
        src={hcpAvatarImage ?? undefined}
        avatarLoading={false}
        avatarType="Hcp"
      />
      {data.testedBy.firstName && data.testedBy.lastName
        ? data.testedBy.firstName.concat(" ").concat(data.testedBy.lastName)
        : t("labelTestDoneByHCP")}
    </Button>
  );
}
