import { PropsWithChildren, useMemo } from "react";
import { useMakeOrganizationPagesDetailState } from "./model";
import { createSafeContext } from "utils/createSafeContext";

interface OrganizationDetailsServiceContext {
  detailsService: ReturnType<typeof useMakeOrganizationPagesDetailState>;
}
const Context = createSafeContext<OrganizationDetailsServiceContext>();

export const useOrganizationDetailsServiceContext = Context.hook;

export const OrganizationDetailsServiceContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const detailsService = useMakeOrganizationPagesDetailState();

  const value = useMemo<OrganizationDetailsServiceContext>(
    () => ({ detailsService }),
    [detailsService],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
