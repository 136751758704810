import { PropsWithChildren, useCallback, useEffect } from "react";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { FormFields } from "./FormFields";
import { UserFormProps, UserModelKey } from "./model";

export function User({
  entityData,
  setEntityData,
  onSubmit,
  allowedUserRoles,
  editableFields,
  loading,
  children,
  formMode,
}: PropsWithChildren<UserFormProps>) {
  const { isPartner, organizationId } = useCurrentUserData();

  const onChange = useCallback(
    (value: string | undefined, inputKey: UserModelKey) => {
      setEntityData(prevValue => ({
        ...prevValue,
        [inputKey]: value,
      }));
    },
    [setEntityData],
  );

  useEffect(() => {
    if (isPartner && !entityData.organizationId) {
      setEntityData(prevValue => ({
        ...prevValue,
        organizationId,
      }));
    }
  }, [isPartner, organizationId, entityData.organizationId, setEntityData]);

  return (
    <FormFields
      key="FormFields"
      onChange={onChange}
      onSubmit={onSubmit}
      entityData={entityData}
      allowedUserRoles={allowedUserRoles}
      editableFields={editableFields}
      loading={loading}
      formMode={formMode}
    >
      {children}
    </FormFields>
  );
}
