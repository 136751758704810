import { useActor } from "@xstate/react";
import { Toolbar, ToolbarButtonProps } from "libs/ui";
import { TabsForRouter } from "libs/ui/TabsForRouter";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router/model";
import { preventClickBubbling } from "utils/preventClickBubbling";
import { useTranslation } from "react-i18next";
import { DetailsForm } from "./DetailsForm";
import { useSetBreadcrumbsToSelectedOrganization } from "./model";
import { DetailsPage } from "components/PageTemplate/DetailsPage";
import { useOrganizationDetailsServiceContext } from "./Context";

export function Details() {
  const { t } = useTranslation("translation", { keyPrefix: "OrganizationDetails" });
  const { t: tToolbar } = useTranslation("translation", { keyPrefix: "Toolbar" });
  const { organizationId } = useParams<RouteParams["organizationDetails"]>();
  const { detailsService } = useOrganizationDetailsServiceContext();
  const [state, send] = useActor(detailsService);

  useEffect(() => {
    if (state.matches("entity.none")) {
      send("LOAD_DATA");
    }
  }, [send, state]);

  useSetBreadcrumbsToSelectedOrganization(detailsService);

  const toggleOrganizationInfo = useMemo(
    () => preventClickBubbling(() => send(state.matches("info.show") ? "HIDE_INFO" : "SHOW_INFO")),
    [send, state],
  );

  const toggleEdit = useMemo(() => preventClickBubbling(() => send("EDIT")), [send]);

  const toolbarButtons: ToolbarButtonProps[] = useMemo(
    () => [
      {
        icon: "Edit",
        variant: "grey",
        onClick: toggleEdit,
        testId: "editEntity",
        tooltip: tToolbar("edit"),
      },
      {
        icon: "Info",
        variant: "primary",
        onClick: toggleOrganizationInfo,
        active: state.matches("info.show"),
        tooltip: tToolbar("info.Organization"),
      },
    ],
    [state, tToolbar, toggleEdit, toggleOrganizationInfo],
  );

  const tabs = useMemo(() => {
    if (state.matches("entity.loading")) {
      return [];
    }

    return state.context.data?.isMain
      ? [{ routePath: "user-management", label: t("tab.labelAdmins") }]
      : [
          { routePath: "partners", label: t("tab.labelPartners") },
          { routePath: "hcps", label: t("tab.labelHCPs") },
          { routePath: "devices", label: t("tab.labelDevices") },
          { routePath: "patients", label: t("tab.labelPatients") },
          { routePath: "tests", to: "tests/all", label: t("tab.labelTests") },
        ];
  }, [state, t]);

  return (
    <DetailsPage
      key={organizationId}
      details={<DetailsForm detailsService={detailsService} />}
      controls={
        <Toolbar
          buttons={toolbarButtons}
          data-testid="controls-toolbar"
          data-loading={state.matches("entity.loading")}
          data-loaded={state.matches("entity.loaded")}
        />
      }
      showInfo={state.matches("info.show")}
      showEdit={state.matches("entity.editing") || state.matches("entity.saving")}
    >
      <TabsForRouter tabs={tabs} />
    </DetailsPage>
  );
}
