import { TestModel } from "components/AppTables/common/TestsTable/model";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { useCurrentUserData } from "./useCurrentUserData";
import { TestResponseModel } from "api/query";
import { useTranslation } from "react-i18next";
import { SubscribeParams, useOnEventStatusSubscribe } from "./useOnEventStatusSubscribe";
import { toastStore } from "config/toast";
import { DeleteTestModal } from "components/AppModals/DeleteTestModal";
import { MeasurementUIType } from "models/TestModels";
import { useConvertTestTimeZone } from "./useConvertTestTimeZone";
import { useMapTestResponseModelToTestFormModel } from "./BEtoFE/useMapTestResponseModelToTestFormModel";

const deleteMeasurementFromTest = (
  test: TestResponseModel,
  measurementType: MeasurementUIType,
): TestResponseModel => ({
  ...test,
  measurements: test.measurements.filter(
    measurement => measurement.measurementType !== measurementType,
  ),
});

export function useDeleteTest(onSuccessCb: () => void) {
  const { t } = useTranslation("modals", { keyPrefix: "DeleteTestModal" });
  const [test, setTest] = useState<TestModel>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteMeasurementType, setDeleteMeasurementType] = useState<MeasurementUIType>("None");
  const { currentUserId } = useCurrentUserData();
  const [onEvent, inLoadingState] = useOnEventStatusSubscribe();
  const { convertTestMeasurementTime } = useConvertTestTimeZone();
  const [convertToTestFormModel] = useMapTestResponseModelToTestFormModel();

  const changeStateClick = useCallback(
    (test: TestModel, measurementType: MeasurementUIType): MouseEventHandler<HTMLButtonElement> =>
      e => {
        e.stopPropagation();
        setTest(test);
        setShowModal(true);
        setDeleteMeasurementType(measurementType);
      },
    [],
  );

  const resetAndClose = useCallback(() => {
    setShowModal(false);
    onSuccessCb();
  }, [onSuccessCb]);

  const params: SubscribeParams = useMemo(
    () => ({
      complete() {
        toastStore.toast.success({ msg: t("successDeleteMeasurementToast"), expire: 5000 });
        resetAndClose();
      },
      error(err) {
        switch (err.code) {
          case "ACTION_FAILED":
            toastStore.toast.error({ msg: t("errorDeleteMeasurementToast") });
            break;
          case "STATUS_QUERY_ERROR":
            resetAndClose();
            toastStore.toast.error({ msg: t("errorFailedToUpdateTestsTable") });
        }
      },
    }),
    [resetAndClose, t],
  );

  const onTestDelete = useCallback(() => {
    CarnaApiQuery.Test.getByOrganizationId({
      organizationId: test?.organizationId ?? "",
      id: test?.id ?? "",
    }).then(res => {
      if (res.measurements.length > 1) {
        onEvent(
          CarnaApiEvent.Test.put({
            organizationId: res.organizationId,
            userEntityId: currentUserId,
            testEntityId: res.id,
            replaceTestByHcpRequestModel: convertTestMeasurementTime(
              convertToTestFormModel(deleteMeasurementFromTest(res, deleteMeasurementType)),
            ),
          }),
          params,
        );
      } else {
        onEvent(
          CarnaApiEvent.Test.delete({
            organizationId: res.organizationId,
            testEntityId: res.id,
            rowVersion: res.rowVersion,
          }),
          params,
        );
      }
    });
  }, [
    convertTestMeasurementTime,
    convertToTestFormModel,
    currentUserId,
    deleteMeasurementType,
    onEvent,
    params,
    test?.id,
    test?.organizationId,
  ]);

  const DeleteTestModalRef = useMemo(
    () => (
      <DeleteTestModal
        show={showModal}
        onCancel={() => setShowModal(false)}
        onSuccess={onTestDelete}
        isInLoadingState={inLoadingState}
      />
    ),
    [showModal, onTestDelete, inLoadingState],
  );

  return { changeStateClick, DeleteTestModalRef } as const;
}
