/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryType,
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import {
    StateType,
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import {
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface HcpDetailsResponseModel
 */
export interface HcpDetailsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof HcpDetailsResponseModel
     */
    country: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof HcpDetailsResponseModel
     */
    state?: StateType;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {UserStatusType}
     * @memberof HcpDetailsResponseModel
     */
    status: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof HcpDetailsResponseModel
     */
    role: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    deviceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    deviceSerialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpDetailsResponseModel
     */
    deviceUniqueId?: string | null;
    /**
     * 
     * @type {DeviceType}
     * @memberof HcpDetailsResponseModel
     */
    deviceType?: DeviceType;
    /**
     * 
     * @type {number}
     * @memberof HcpDetailsResponseModel
     */
    rowVersion?: number | null;
}

export function HcpDetailsResponseModelFromJSON(json: any): HcpDetailsResponseModel {
    return HcpDetailsResponseModelFromJSONTyped(json, false);
}

export function HcpDetailsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpDetailsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': json['Email'],
        'phone': json['Phone'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : StateTypeFromJSON(json['State']),
        'zipCode': json['ZipCode'],
        'organizationId': json['OrganizationId'],
        'organizationName': json['OrganizationName'],
        'status': UserStatusTypeFromJSON(json['Status']),
        'role': UserRoleTypeFromJSON(json['Role']),
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'deviceName': !exists(json, 'DeviceName') ? undefined : json['DeviceName'],
        'deviceSerialNumber': !exists(json, 'DeviceSerialNumber') ? undefined : json['DeviceSerialNumber'],
        'deviceUniqueId': !exists(json, 'DeviceUniqueId') ? undefined : json['DeviceUniqueId'],
        'deviceType': !exists(json, 'DeviceType') ? undefined : DeviceTypeFromJSON(json['DeviceType']),
        'rowVersion': !exists(json, 'RowVersion') ? undefined : json['RowVersion'],
    };
}

export function HcpDetailsResponseModelToJSON(value?: HcpDetailsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Phone': value.phone,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': StateTypeToJSON(value.state),
        'ZipCode': value.zipCode,
        'OrganizationId': value.organizationId,
        'OrganizationName': value.organizationName,
        'Status': UserStatusTypeToJSON(value.status),
        'Role': UserRoleTypeToJSON(value.role),
        'DeviceId': value.deviceId,
        'DeviceName': value.deviceName,
        'DeviceSerialNumber': value.deviceSerialNumber,
        'DeviceUniqueId': value.deviceUniqueId,
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'RowVersion': value.rowVersion,
    };
}

