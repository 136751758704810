import { Placement, ReferenceType } from "@floating-ui/react-dom";
import { ElementProps, FloatingContext } from "@floating-ui/react";

export function usePlacementParams(placement: Placement) {
  const tooltipPosition = placement.split("-")[0];

  const arrowPosition = {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[tooltipPosition];

  const arrowAngle = {
    top: 0,
    right: 90,
    bottom: 180,
    left: 270,
  }[tooltipPosition];

  return { arrowPosition, arrowAngle };
}

export type TooltipType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "grey"
  | "red"
  | "yellow"
  | "green"
  | "white";

export type arrowSizeType = "small" | "big";

export interface TooltipProps {
  /**
   * List of hooks here
   * !!! YOU MUST NOT CHANGE THIS LIST CONDITIONALLY !!!
   * !!! DONT USE SOMETHING LIKE, USEMEMO ...etc !!!
   * Custom props:
   * (context) => useHover(context, customProps)
   */
  interactionProps?: ((context: FloatingContext<ReferenceType>) => ElementProps)[];
  placement?: Placement;
  mainAxis?: number;
  crossAxis?: number;
  arrowSize?: arrowSizeType;
  type?: TooltipType;
  className?: string;
  showArrow?: boolean;
}
