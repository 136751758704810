import { useActor, useInterpret } from "@xstate/react";
import { OrganizationDetailsResponseModel } from "api/query";
import { OrganizationModel } from "components/Forms/Organization/model";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { UpdateOrganizationModelForStripping } from "config/binding";
import { breadcrumbsStore } from "config/breadcrumbs";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { firstValueFrom } from "rxjs";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { getRowVersion } from "utils/helpers/getRowVersion";
import {
  OnEventStatusSubscribeError,
  waitForQueryService,
} from "utils/hooks/useOnEventStatusSubscribe";
import {
  DetailsPageStateContext,
  SaveEvent,
  makeDetailsPageStateMachine,
} from "utils/machines/pages/details/makeDetailsPageStateMachine";
import { DetailsPageServiceList } from "utils/machines/pages/details/model";

import { assign } from "xstate";

function useApis() {
  const { organizationId } = useParams<RouteParams["organizationDetails"]>();

  const fetchOrganization = useCallback(
    () => CarnaApiQuery.Organizations.getById({ organizationId: organizationId ?? "" }),
    [organizationId],
  );

  const saveOrganization = useCallback(
    async (
      _context: DetailsPageStateContext<OrganizationDetailsResponseModel>,
      event: SaveEvent<OrganizationModel>,
    ): Promise<OrganizationDetailsResponseModel> => {
      const putOrganization = CarnaApiEvent.Organization.put(
        {
          organizationEntityId: organizationId ?? "",
          updateOrganizationRequestModel: {
            ...event.value,
            rowVersion: event.value.rowVersion,
          },
        },
        stripNetworkBodyWith(UpdateOrganizationModelForStripping),
      );

      await firstValueFrom(waitForQueryService(putOrganization));

      const result = await putOrganization;

      return {
        ...event.value,
        ...getRowVersion(result),
      } as OrganizationDetailsResponseModel;
    },
    [organizationId],
  );

  return { fetchOrganization, saveOrganization };
}

const successToast = () =>
  toastStore.pushToast({
    expire: 5000,
    type: "success",
    msg: i18n.t("OrganizationDetails.successPutToast", { ns: "translation" }),
  });

const errorPutToast = (_context: any, event: any) => {
  const err = event.data as OnEventStatusSubscribeError;
  if (err.type === "OnEventStatusSubscribeError") {
    switch (err.code) {
      case "BE_ERROR":
        showBeFieldErrors(
          err.err,
          i18n.t("OrganizationDetails.errorPutToast", { ns: "translation" }),
        );

        break;
      case "ACTION_FAILED":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("OrganizationDetails.errorPutToast", { ns: "translation" }),
        });
        break;
      case "STATUS_QUERY_ERROR":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("OrganizationDetails.queryServiceError", { ns: "translation" }),
        });
    }
    return;
  }

  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("OrganizationDetails.errorPutToast", { ns: "translation" }),
  });
};

const errorFetchToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("OrganizationDetails.errorFetchToast", { ns: "translation" }),
  });
};

export function useMakeOrganizationPagesDetailState() {
  const { fetchOrganization, saveOrganization } = useApis();

  const organizationPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        OrganizationDetailsResponseModel,
        OrganizationModel,
        DetailsPageServiceList<typeof fetchOrganization, typeof saveOrganization, never>
      >(),
    [],
  );

  const service = useInterpret(organizationPageStateMachine, {
    services: {
      saveStatus: undefined,
      saveData: saveOrganization,
      fetchData: fetchOrganization,
    },
    actions: {
      updateSaveStatus: undefined,
      dataSaved: successToast,
      savingFailed: errorPutToast,
      failedToLoadData: errorFetchToast,
      loadSavedData: assign({
        data: (_context, event) => {
          return event.data;
        },
      }),
      loadEntityData: assign({
        data: (_context, event) => {
          return event.data;
        },
      }),
      refreshTable: () => {},
    },
  });

  return service;
}

export function useSetBreadcrumbsToSelectedOrganization(
  detailsService: ReturnType<typeof useMakeOrganizationPagesDetailState>,
) {
  const [state] = useActor(detailsService);

  useEffect(() => {
    if (!state.matches("entity.loading")) {
      breadcrumbsStore.setCurrentPageTitle(`${state.context?.data?.name}`);
    }
    return () => breadcrumbsStore.resetCurrentPageTitle();
  }, [state]);
}
