/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import {
    ManufacturerType,
    ManufacturerTypeFromJSON,
    ManufacturerTypeFromJSONTyped,
    ManufacturerTypeToJSON,
} from './ManufacturerType';

/**
 * 
 * @export
 * @interface AddDeviceRequestModel
 */
export interface AddDeviceRequestModel {
    /**
     * 
     * @type {ManufacturerType}
     * @memberof AddDeviceRequestModel
     */
    manufacturerType: ManufacturerType;
    /**
     * 
     * @type {DeviceType}
     * @memberof AddDeviceRequestModel
     */
    deviceType: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof AddDeviceRequestModel
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AddDeviceRequestModel
     */
    uniqueId: string;
}

export function AddDeviceRequestModelFromJSON(json: any): AddDeviceRequestModel {
    return AddDeviceRequestModelFromJSONTyped(json, false);
}

export function AddDeviceRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddDeviceRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'manufacturerType': ManufacturerTypeFromJSON(json['ManufacturerType']),
        'deviceType': DeviceTypeFromJSON(json['DeviceType']),
        'serialNumber': json['SerialNumber'],
        'uniqueId': json['UniqueId'],
    };
}

export function AddDeviceRequestModelToJSON(value?: AddDeviceRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ManufacturerType': ManufacturerTypeToJSON(value.manufacturerType),
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'SerialNumber': value.serialNumber,
        'UniqueId': value.uniqueId,
    };
}

