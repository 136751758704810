/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatinineStatusType,
    CreatinineStatusTypeFromJSON,
    CreatinineStatusTypeFromJSONTyped,
    CreatinineStatusTypeToJSON,
} from './CreatinineStatusType';
import {
    MeasurementTrendType,
    MeasurementTrendTypeFromJSON,
    MeasurementTrendTypeFromJSONTyped,
    MeasurementTrendTypeToJSON,
} from './MeasurementTrendType';
import {
    MeasurementUnitType,
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';

/**
 * 
 * @export
 * @interface CreatinineModel
 */
export interface CreatinineModel {
    /**
     * 
     * @type {number}
     * @memberof CreatinineModel
     */
    value: number;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof CreatinineModel
     */
    unit: MeasurementUnitType;
    /**
     * 
     * @type {MeasurementTrendType}
     * @memberof CreatinineModel
     */
    trendType: MeasurementTrendType;
    /**
     * 
     * @type {number}
     * @memberof CreatinineModel
     */
    percent: number;
    /**
     * 
     * @type {number}
     * @memberof CreatinineModel
     */
    graphValue: number;
    /**
     * 
     * @type {CreatinineStatusType}
     * @memberof CreatinineModel
     */
    status: CreatinineStatusType;
}

export function CreatinineModelFromJSON(json: any): CreatinineModel {
    return CreatinineModelFromJSONTyped(json, false);
}

export function CreatinineModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatinineModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['Value'],
        'unit': MeasurementUnitTypeFromJSON(json['Unit']),
        'trendType': MeasurementTrendTypeFromJSON(json['TrendType']),
        'percent': json['Percent'],
        'graphValue': json['GraphValue'],
        'status': CreatinineStatusTypeFromJSON(json['Status']),
    };
}

export function CreatinineModelToJSON(value?: CreatinineModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Value': value.value,
        'Unit': MeasurementUnitTypeToJSON(value.unit),
        'TrendType': MeasurementTrendTypeToJSON(value.trendType),
        'Percent': value.percent,
        'GraphValue': value.graphValue,
        'Status': CreatinineStatusTypeToJSON(value.status),
    };
}

