import { HcpsFilterSection } from "components/AppTables/common/Filter/HcpsFilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { AssignedDeviceFilter } from "../../Filter/AssignedDeviceFilter";
import { UserStatusFilter } from "../../Filter/UserStatusFilter";
import { PatientsTableFilterProps } from "../model";
import { OrganizationsFilterSection } from "../../Filter/OrganizationsFilterSection";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";

export function PatientsTableFilter({
  tableService,
  filterSideModelState,
  hcpsFilter,
}: PatientsTableFilterProps) {
  const { isAdmin } = useCurrentUserData();

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({ activeFilters, appliedFilters, onNewFilterApplied, onNewRadioFilterApplied }) => (
        <>
          {activeFilters.map(el => {
            switch (el) {
              case "contains":
                return null;
              case "hcpIds":
                return (
                  <HcpsFilterSection
                    key={el}
                    checkedValues={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                    hcpsFilter={hcpsFilter}
                  />
                );
              case "withDevice":
                return (
                  <AssignedDeviceFilter
                    key={el}
                    value={appliedFilters[el]}
                    onSelect={value => onNewRadioFilterApplied(el, value)}
                  />
                );
              case "organizationIds":
                if (isAdmin) {
                  return (
                    <OrganizationsFilterSection
                      key={el}
                      checkedValues={appliedFilters[el] ?? []}
                      onFilterApply={value => onNewFilterApplied(el, value)}
                    />
                  );
                }
                return null;
              case "userStatuses":
                return (
                  <UserStatusFilter
                    key={el}
                    values={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                  />
                );

              default:
                return null;
            }
          })}
        </>
      )}
    </TableFilter>
  );
}
