/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeasurementTrendType,
    MeasurementTrendTypeFromJSON,
    MeasurementTrendTypeFromJSONTyped,
    MeasurementTrendTypeToJSON,
} from './MeasurementTrendType';
import {
    MeasurementUnitType,
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';

/**
 * 
 * @export
 * @interface MeasurementUnitModel
 */
export interface MeasurementUnitModel {
    /**
     * 
     * @type {number}
     * @memberof MeasurementUnitModel
     */
    value: number;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof MeasurementUnitModel
     */
    unit: MeasurementUnitType;
    /**
     * 
     * @type {MeasurementTrendType}
     * @memberof MeasurementUnitModel
     */
    trendType: MeasurementTrendType;
}

export function MeasurementUnitModelFromJSON(json: any): MeasurementUnitModel {
    return MeasurementUnitModelFromJSONTyped(json, false);
}

export function MeasurementUnitModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementUnitModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['Value'],
        'unit': MeasurementUnitTypeFromJSON(json['Unit']),
        'trendType': MeasurementTrendTypeFromJSON(json['TrendType']),
    };
}

export function MeasurementUnitModelToJSON(value?: MeasurementUnitModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Value': value.value,
        'Unit': MeasurementUnitTypeToJSON(value.unit),
        'TrendType': MeasurementTrendTypeToJSON(value.trendType),
    };
}

