import { FieldLoader } from "libs/ui/FieldLoader";
import { useMemo } from "react";
import "./EGFRRangeTable.scss";
import { EGFRRangeTableRow } from "./EGFRRangeTableRow";
import { useGetEGFRRanges } from "utils/hooks/useGetEGFRRanges";
import { useTranslation } from "react-i18next";

interface EGFRRangeTableProps {
  loading?: boolean;
  value?: number;
}

export function EGFRRangeTable({ value, loading }: Readonly<EGFRRangeTableProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "useEGFRStageText" });
  const { orderedEGFRStageRanges } = useGetEGFRRanges();

  const rows = useMemo(
    () =>
      Object.entries(orderedEGFRStageRanges).map(([key, data]) => (
        <EGFRRangeTableRow key={key} label={t(key)} value={value} {...data} />
      )),
    [orderedEGFRStageRanges, t, value],
  );

  if (loading) {
    return (
      <div className="DetailsField">
        <FieldLoader />
      </div>
    );
  }

  return (
    <table className="EGFRRangeTable">
      <tbody className="EGFRRangeTable__body">{rows}</tbody>
    </table>
  );
}
