import * as d3 from "d3";
import { PropsWithoutRef } from "react";
import "./TotalNumberDonut.scss";

interface TotalNumberDonutProps {
  percentage: number;
}

const DONUT_DIAMETER = 144;

export function TotalNumberDonut({ percentage }: PropsWithoutRef<TotalNumberDonutProps>) {
  const width = DONUT_DIAMETER;
  const height = DONUT_DIAMETER;
  const radius = Math.min(width, height) / 2;
  const heightBetweenArcs = 0.05;
  const innerCircleHeight = 0.75;

  const outerArc: any = d3
    .arc()
    .innerRadius(radius * innerCircleHeight)
    .outerRadius(radius)
    .startAngle(0)
    .endAngle(2 * Math.PI);

  const percentageToRadians = (percentage / 100) * 2 * Math.PI;

  const innerArc: any = d3
    .arc()
    .innerRadius(radius * (innerCircleHeight + heightBetweenArcs))
    .outerRadius(radius * (1 - heightBetweenArcs))
    .startAngle(0)
    .endAngle(percentageToRadians);

  const mainRingColor = percentage > 0 ? "var(--primary-500)" : "var(--primary-100)";

  return (
    <div className="TotalNumberDonut">
      <svg viewBox={`0 0 ${width} ${height}`}>
        <path
          fill={mainRingColor}
          stroke="none"
          d={outerArc()}
          transform={`translate(${width / 2}, ${height / 2})`}
        />
        <path
          fill="var(--secondary-500)"
          stroke="none"
          d={innerArc()}
          transform={`translate(${width / 2}, ${height / 2})`}
        />
      </svg>
    </div>
  );
}
