import { useActor } from "@xstate/react";

import { ResendInviteModal } from "components/AppModals/ResendInviteModal";
import { Chip, resolveStatusToVariant, Toolbar, ToolbarButtonProps } from "libs/ui";
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { preventClickBubbling } from "utils/preventClickBubbling";
import { useDetailsSetAvatarBreadcrumbs, useDetailsSetBreadcrumbsToName } from "utils/breadCrumbs";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { useTranslation } from "react-i18next";
import {
  DetailsPageServiceList,
  MakeDetailsPageStateMachineService,
} from "utils/machines/pages/details/model";
import { UserRoleType, UserStatusType } from "models/PersonModels";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { UpdatePersonStatusModal } from "components/AppModals/UpdatePersonStatusModal";
import { DetailsPage } from ".";

interface PersonModel {
  status: UserStatusType;
  firstName: string;
  lastName: string;
  role: UserRoleType;
  id: string;
  organizationId: string;
  email?: string | null;
}

interface Props<
  ResponseModel extends PersonModel,
  EntityModel,
  Events extends DetailsPageServiceList<any, any, any> = any,
> {
  detailsService: MakeDetailsPageStateMachineService<ResponseModel, EntityModel, Events>;
  canEdit?: boolean;
  detailsForm?: React.ReactNode;
  detailsPageControlsSlot?: React.ReactNode;
}

export function DetailsCommonPerson<
  ResponseModel extends PersonModel,
  EntityModel,
  Events extends DetailsPageServiceList<any, any, any> = any,
>({
  detailsService,
  detailsForm,
  canEdit = true,
  detailsPageControlsSlot,
  children,
}: PropsWithChildren<Props<ResponseModel, EntityModel, Events>>) {
  const { t } = useTranslation("translation", { keyPrefix: "Toolbar" });
  const [state, send] = useActor(detailsService);
  useDetailsSetBreadcrumbsToName(state);
  useDetailsSetAvatarBreadcrumbs();
  const { appConfig } = useGlobalConfigContext();
  const can = useHasAccess();

  const canResendInvite = useMemo(() => {
    if (state.context.data?.role === undefined) {
      return false;
    }
    switch (state.context.data?.role) {
      case "Patient":
        return can("manage-patient");
      case "Hcp":
        return can("add-hcp");
      case "Admin":
        return can("manage-users");
      case "Partner":
        return can("manage-users");
      default:
        throw Error(`${state.context.data?.role} not implemented`);
    }
  }, [can, state.context.data?.role]);

  const toggleChangeStatusModal: ToolbarButtonProps["onClick"] = useMemo(
    () =>
      preventClickBubbling(() => {
        send(state.matches("modal.activate.show") ? "HIDE_ACTIVATE_MODAL" : "SHOW_ACTIVATE_MODAL");
      }),
    [send, state],
  );

  const toggleResendModal: ToolbarButtonProps["onClick"] = useMemo(
    () =>
      preventClickBubbling(() => {
        send(state.matches("modal.resend.show") ? "HIDE_RESEND_MODAL" : "SHOW_RESEND_MODAL");
      }),
    [send, state],
  );

  const togglePersonInfo: ToolbarButtonProps["onClick"] = useMemo(
    () =>
      preventClickBubbling(() => {
        if (state.matches("info.hide")) {
          send("LOAD_DATA");
        }
        send(state.matches("info.hide") ? "SHOW_INFO" : "HIDE_INFO");
      }),
    [send, state],
  );

  const isChangeStatusAllowedPerConfigForVisitedRole =
    NotNullOrUndefined(appConfig) &&
    appConfig?.components.disabled.deactivatePersons.every(el => el !== state?.context?.data?.role);

  const isChangeStatusAllowedPerVisitedCurrentStatus = (["Invited", "Deleted"] as const).every(
    el => el !== state?.context?.data?.status,
  );

  const changeStatusButton =
    isChangeStatusAllowedPerConfigForVisitedRole && isChangeStatusAllowedPerVisitedCurrentStatus;

  const editOptions: ToolbarButtonProps[] = useMemo(
    () => [
      ...(state?.context?.data?.status !== "Deleted"
        ? ([
            {
              icon: "Edit",
              variant: "grey",
              onClick: e => {
                e.preventDefault();
                e.stopPropagation();

                send("EDIT");
              },
              testId: "editEntity",
              tooltip: t("edit"),
            },
          ] as ToolbarButtonProps[])
        : []),
      ...(changeStatusButton
        ? ([
            {
              icon: state?.context?.data?.status === "Deactivated" ? "CheckCircle" : "Slash",
              variant: state?.context?.data?.status === "Deactivated" ? "green" : "red",
              onClick: toggleChangeStatusModal,
              tooltip: t(
                state?.context?.data?.status === "Deactivated" ? "activate" : "deactivate",
              ),
            },
          ] as ToolbarButtonProps[])
        : []),
    ],
    [changeStatusButton, send, state?.context?.data?.status, t, toggleChangeStatusModal],
  );

  const resendOptions: ToolbarButtonProps[] = useMemo(
    () => [
      ...(state?.context?.data?.status === "Invited" && canResendInvite
        ? ([
            {
              icon: "Refresh",
              variant: "grey",
              onClick: toggleResendModal,
              tooltip: t("resendInvite"),
            },
          ] as ToolbarButtonProps[])
        : []),
    ],
    [state?.context?.data?.status, canResendInvite, toggleResendModal, t],
  );

  const toolbarButtons: ToolbarButtonProps[] = useMemo(
    () => [
      ...(canEdit ? editOptions : []),
      ...resendOptions,
      {
        icon: "Info",
        variant: "grey",
        onClick: togglePersonInfo,
        active: state.matches("info.show"),
        tooltip: t(`info.${state.context.data?.role}`),
      },
    ],
    [canEdit, editOptions, t, resendOptions, state, togglePersonInfo],
  );

  useEffect(() => {
    if (state.matches("entity.none")) {
      send("LOAD_DATA");
    }
  }, [send, state]);

  const onUpdatePersonStatus = useCallback(() => {
    send({
      type: "LOAD_DATA",
    });
    send("HIDE_ACTIVATE_MODAL");
  }, [send]);

  return (
    <DetailsPage
      detailsPageControlsSlot={detailsPageControlsSlot}
      controls={
        state?.context?.data ? (
          <Toolbar
            buttons={toolbarButtons}
            data-testid="controls-toolbar"
            data-loading={state.matches("entity.loading")}
            data-loaded={state.matches("entity.loaded")}
          />
        ) : undefined
      }
      details={detailsForm}
      showInfo={state.matches("info.show")}
      showEdit={state.matches("entity.editing") || state.matches("entity.saving")}
      customBreadcrumbsElem={
        state?.context?.data?.status === "NoAccess" ? undefined : (
          <Chip
            loading={state.matches("entity.loading")}
            size="small"
            variant={resolveStatusToVariant(state?.context?.data?.status)}
          >
            {state?.context?.data?.status?.toUpperCase()}
          </Chip>
        )
      }
    >
      <UpdatePersonStatusModal
        firstName={state.context?.data?.firstName}
        lastName={state.context?.data?.lastName}
        id={state.context?.data?.id ?? ""}
        organizationId={state.context?.data?.organizationId ?? ""}
        role={state.context?.data?.role ?? "Admin"}
        status={state.context?.data?.status ?? "Active"}
        show={state.matches("modal.activate.show")}
        onCloseModal={() => send("HIDE_ACTIVATE_MODAL")}
        onSuccess={onUpdatePersonStatus}
      />
      <ResendInviteModal
        user={state.context.data}
        onSuccess={() => {
          send("HIDE_RESEND_MODAL");
        }}
        onCancel={() => send("HIDE_RESEND_MODAL")}
        show={state.matches("modal.resend.show")}
      />
      {children}
    </DetailsPage>
  );
}
