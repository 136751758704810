import { TableContext, tableStateMachine } from "libs/ui";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { useInterpret, useActor } from "@xstate/react";
import { useFilterModal } from "../common/hooks";
import { OrganizationDetailsResponseModelListModel, OrganizationsFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { countriesToSelectOptions } from "utils/mappers/countriesToSelectOptions";
import { statesToSelectOptions } from "utils/mappers/statesToSelectOptions";
import { InterpreterFrom } from "xstate";
import { FilterSideModalState } from "../common/Filter/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { getFilterOptionsPerConfiguration } from "../utils";

// TODO add states filter once BE provides us that filter option
export function ORGANIZATIONS_FILTER_OPTIONS() {
  return {
    countries: countriesToSelectOptions(),
    states: statesToSelectOptions([]),
    contains: "",
  } as const;
}

type OrganizationsFilterOptionsKey = keyof ReturnType<typeof ORGANIZATIONS_FILTER_OPTIONS>;

type TableService = InterpreterFrom<ReturnType<typeof makeTableFetchStateMachine>>;

export interface OrganizationsTableFilterProps {
  tableService: TableService;
  filterSideModelState: FilterSideModalState<OrganizationsFilterOptionsKey>;
}
interface OrganizationsTableStateContext extends OrganizationsTableFilterProps {
  fetch: () => void;
}

export type UIOrganizationFilters = Omit<
  OrganizationsFilterModel,
  "excludeMain" | "beginsWith" | "userIds"
>;

export type OrganizationsTableColumns = "name" | "phone" | "address" | "countries" | "actions";
export interface BodyRowsProps {
  tableService: TableService;
  appliedColumns: OrganizationsTableColumns[];
}

async function getOrganizations(
  context?: TableContext<OrganizationDetailsResponseModelListModel, UIOrganizationFilters>,
) {
  const result = await CarnaApiQuery.Organizations.get({
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export const makeTableFetchStateMachine = () => tableStateMachine(getOrganizations);
const Context = createSafeContext<OrganizationsTableStateContext>();

export const useOrganizationsTableStateContext = Context.hook;

export function OrganizationsTableStateProvider({ children }: Readonly<PropsWithChildren<{}>>) {
  const stateMachine = useMemo(() => makeTableFetchStateMachine(), []);
  const tableService = useInterpret(stateMachine);
  const { appConfig } = useGlobalConfigContext();

  const filterSideModelState = useFilterModal<OrganizationsFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      ORGANIZATIONS_FILTER_OPTIONS,
      NotNullOrUndefined(appConfig?.components.tables.Organization.filterOptions),
    ),
  );

  const [, send] = useActor(tableService);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  const value = useMemo(
    () => ({
      tableService,
      fetch,
      filterSideModelState,
    }),
    [fetch, filterSideModelState, tableService],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
