import { Button, Chip } from "libs/ui";
import { FilterType, FilterTypeValues, useDashboardMapContext } from "../../DashboardMapContext";
import { useCallback } from "react";
import { AGE_OPTIONS, ANCESTRY_OPTIONS, GENDER_OPTIONS } from "../helper";
import "./MapFilterChips.scss";
import { useTranslation } from "react-i18next";
import { useDashboardMapReportContext } from "../../DashboardMapReportDataContext";

export function MapFilterChips() {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.DashboardMapFilter" });
  const { filters, dispatchFilters } = useDashboardMapContext();
  const { getReportModelPerFilters } = useDashboardMapReportContext();

  const { onlyGenders, onlyAgeRanges, onlyAncestries } = filters;

  const showClearAll =
    onlyGenders.length > 0 || onlyAgeRanges.length > 0 || onlyAncestries.length > 0;

  const onChipClose = (filterType: FilterType, val: FilterTypeValues) => {
    const currentValues = new Set<FilterTypeValues>(filters[filterType]);
    currentValues.delete(val);

    dispatchFilters({ type: filterType, payload: [...currentValues.values()] });
    getReportModelPerFilters({ [filterType]: [...currentValues.values()] });
  };

  const onClearAll = useCallback(() => {
    Object.keys(FilterType).forEach(type => {
      dispatchFilters({
        type: type as FilterType,
        payload: [],
      });
    });

    getReportModelPerFilters({
      onlyGenders: undefined,
      onlyAgeRanges: undefined,
      onlyAncestries: undefined,
    });
  }, [dispatchFilters, getReportModelPerFilters]);

  return (
    <div className="MapFilterChips">
      {onlyGenders.map(el => (
        <Chip key={el} size="medium" variant="grey" onClick={() => onChipClose("onlyGenders", el)}>
          {GENDER_OPTIONS.find(gender => gender.value === el)?.title}
        </Chip>
      ))}

      {onlyAgeRanges.map(el => (
        <Chip
          key={el}
          size="medium"
          variant="grey"
          onClick={() => onChipClose("onlyAgeRanges", el)}
        >
          {AGE_OPTIONS.find(age => age.value === el)?.title}
        </Chip>
      ))}

      {onlyAncestries.map(el => (
        <Chip
          key={el}
          size="medium"
          variant="grey"
          onClick={() => onChipClose("onlyAncestries", el)}
        >
          {ANCESTRY_OPTIONS.find(ancestry => ancestry.value === el)?.title}
        </Chip>
      ))}

      {showClearAll ? (
        <Button
          className="ClearAllButton"
          onClick={onClearAll}
          buttonSize="small"
          buttonType="transparent"
        >
          {t("buttonClearAll")}
        </Button>
      ) : null}
    </div>
  );
}
