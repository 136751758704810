import { useActor, useInterpret } from "@xstate/react";
import { UserDetailsResponseModelListModel, UsersFilterModel } from "api/query";
import { TabModalControl } from "components/TabModalControl";
import { CarnaApiQuery } from "config/apiQuery";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { TableContext, tableStateMachine } from "libs/ui";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { AdministratorsTable } from "../common/AdministratorsTable";
import {
  ADMINISTRATORS_FILTER_OPTIONS,
  AdministratorsFilterOptionsKey,
  UIUserFilters,
} from "../common/AdministratorsTable/model";
import { useFilterModal } from "../common/hooks";
import { getFilterOptionsPerConfiguration } from "../utils";

function fetchAdministrators(
  organizationId: string,
  context?: TableContext<UserDetailsResponseModelListModel, UsersFilterModel>,
) {
  const result = CarnaApiQuery.Admin.getByOrganization({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

const makeTableFetchStateMachine = (organizationId: string) =>
  tableStateMachine((context?: TableContext<UserDetailsResponseModelListModel, UIUserFilters>) =>
    fetchAdministrators(organizationId, context),
  );

export function AdministratorsPerOrganizationTable() {
  const { organizationId = "" } = useParams<RouteParams["organizationDetails"]>();
  const tableMachine = useMemo(() => makeTableFetchStateMachine(organizationId), [organizationId]);
  const tableService = useInterpret(tableMachine);
  const [state, send] = useActor(tableService);
  const { appConfig } = useGlobalConfigContext();

  const filterSideModelState = useFilterModal<AdministratorsFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      ADMINISTRATORS_FILTER_OPTIONS(),
      NotNullOrUndefined(
        appConfig?.components.tables.Administrator.AdministratorPerOrganizationTable.filterOptions,
      ),
    ),
  );

  const onFilterToggle = useCallback(
    () => filterSideModelState.openFilterModal(),
    [filterSideModelState],
  );

  const onSearchChange = (value: string) =>
    send([{ type: "UPDATE_FILTERS", value: { ...state.context.filters, contains: value } }]);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  useEffect(() => {
    if (state.matches("init")) {
      fetch();
    }
  }, [fetch, state]);

  return (
    <AdministratorsTable
      adminTableStateContext={{ tableService, filterSideModelState, fetch }}
      onFilterToggle={onFilterToggle}
      searchField={{
        onChange: onSearchChange,
        value: state.context.filters?.contains ?? "",
      }}
      appliedColumns={NotNullOrUndefined(
        appConfig?.components.tables.Administrator.AdministratorPerOrganizationTable.columnList,
      )}
      addButton={<TabModalControl variant="User" onSuccessCb={fetch} />}
      adminFilterOptions={
        appConfig?.components.tables.Administrator.AdministratorPerOrganizationTable.filterOptions
      }
    />
  );
}
