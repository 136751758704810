import { useInterpret, useActor } from "@xstate/react";
import { TestListResponseModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { TableContext, tableStateMachine } from "libs/ui";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { createSafeContext } from "utils/createSafeContext";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { InterpreterFrom } from "xstate/lib/types";

export type PatientTestTableService = InterpreterFrom<
  ReturnType<typeof makeTableFetchStateMachine>
>;

interface PatientLaboratoryContext {
  tableService: PatientTestTableService;
  fetch: () => void;
}
const Context = createSafeContext<PatientLaboratoryContext>();

export const usePatientLaboratoryContext = Context.hook;

async function fetchMeasurements(
  organizationId: string,
  patientId: string,
  context?: TableContext<TestListResponseModel, any>,
) {
  const result = CarnaApiQuery.Patients.getMeasurements({
    organizationId,
    userEntityId: patientId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

type GetMeasurementsFilters = NonNullable<
  Parameters<typeof CarnaApiQuery.Patients.getMeasurements>[0]["filters"]
>;

const makeTableFetchStateMachine = (organizationId: string, patientId: string) =>
  tableStateMachine((context?: TableContext<TestListResponseModel, GetMeasurementsFilters>) =>
    fetchMeasurements(organizationId, patientId, context),
  );

export function PatientLaboratoryContextProvider({ children }: PropsWithChildren) {
  const { currentUserId, organizationId: patientOrganization, userRole } = useCurrentUserData();
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientDetails"]>();

  const tableMachine = useMemo(
    () =>
      makeTableFetchStateMachine(
        userRole === "Patient" ? patientOrganization : organizationId,
        userRole === "Patient" ? currentUserId : patientId,
      ),
    [currentUserId, organizationId, patientId, patientOrganization, userRole],
  );

  const tableService = useInterpret(tableMachine);

  const [, send] = useActor(tableService);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  const value = useMemo(() => ({ tableService, fetch }), [fetch, tableService]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
