/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LaboratoryModel,
    LaboratoryModelFromJSON,
    LaboratoryModelFromJSONTyped,
    LaboratoryModelToJSON,
} from './LaboratoryModel';

/**
 * 
 * @export
 * @interface UpdateRulesRequestModel
 */
export interface UpdateRulesRequestModel {
    /**
     * 
     * @type {LaboratoryModel}
     * @memberof UpdateRulesRequestModel
     */
    laboratory: LaboratoryModel;
    /**
     * 
     * @type {number}
     * @memberof UpdateRulesRequestModel
     */
    rowVersion?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRulesRequestModel
     */
    includeAnonymizedUsers: boolean;
}

export function UpdateRulesRequestModelFromJSON(json: any): UpdateRulesRequestModel {
    return UpdateRulesRequestModelFromJSONTyped(json, false);
}

export function UpdateRulesRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRulesRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'laboratory': LaboratoryModelFromJSON(json['Laboratory']),
        'rowVersion': !exists(json, 'RowVersion') ? undefined : json['RowVersion'],
        'includeAnonymizedUsers': json['IncludeAnonymizedUsers'],
    };
}

export function UpdateRulesRequestModelToJSON(value?: UpdateRulesRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Laboratory': LaboratoryModelToJSON(value.laboratory),
        'RowVersion': value.rowVersion,
        'IncludeAnonymizedUsers': value.includeAnonymizedUsers,
    };
}

