import { AncestryFilter } from "api/report_service/models/AncestryFilter";
import { GenderFilter } from "api/report_service/models/GenderFilter";
import { PatientAgeRange } from "api/report_service/models/PatientAgeRange";
import i18n from "i18n";

export const GENDER_OPTIONS = Object.keys(GenderFilter)
  .map(key => ({
    title: i18n.t(`DashboardPage.DashboardMapFilter.gender.${key}`, { ns: "translation" }),
    value: key as GenderFilter,
  }))
  .sort((a, _b) => (a.value === "NotAvailable" ? 1 : -1));

export const AGE_OPTIONS = Object.keys(PatientAgeRange)
  .map(key => ({
    title: i18n.t(`DashboardPage.DashboardMapFilter.age.${key}`, { ns: "translation" }),
    value: key as PatientAgeRange,
  }))
  .sort((a, _b) => (a.value === "NotAvailable" ? 1 : -1));

export const ANCESTRY_OPTIONS = Object.keys(AncestryFilter)
  .map(key => ({
    title: i18n.t(`DashboardPage.DashboardMapFilter.ancestry.${key}`, { ns: "translation" }),
    value: key as AncestryFilter,
  }))
  .sort((a, _b) => (a.value === "NotAvailable" ? 1 : -1));
