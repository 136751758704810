/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    StringApiResponse,
    StringApiResponseFromJSON,
    StringApiResponseToJSON,
} from '../models';

export interface AccountDataPostRequest {
    accountDataRequestModel: object;
}

/**
 * AccountDataApi - interface
 * 
 * @export
 * @interface AccountDataApiInterface
 */
export interface AccountDataApiInterface {
    /**
     * 
     * @param {object} accountDataRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDataApiInterface
     */
    accountDataPostRaw(requestParameters: AccountDataPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    accountDataPost(accountDataRequestModel: object, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

}

/**
 * 
 */
export class AccountDataApi extends runtime.BaseAPI implements AccountDataApiInterface {

    /**
     */
    async accountDataPostRaw(requestParameters: AccountDataPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.accountDataRequestModel === null || requestParameters.accountDataRequestModel === undefined) {
            throw new runtime.RequiredError('accountDataRequestModel','Required parameter requestParameters.accountDataRequestModel was null or undefined when calling accountDataPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.accountDataRequestModel !== undefined) {
            queryParameters['accountDataRequestModel'] = requestParameters.accountDataRequestModel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async accountDataPost(accountDataRequestModel: object, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.accountDataPostRaw({ accountDataRequestModel: accountDataRequestModel }, initOverrides);
        return await response.value();
    }

}
