import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.scss";

import { Login } from "layout/Login";
import "./i18n";

import { GlobalConfigProvider } from "context/GlobalConfigContext";
import { GlobalPreferenceProvider } from "context/GlobalPreferenceContext";
import { CurrentUserNameProvider } from "context/CurrentUserNameContext";
import { Main } from "layout/Main";
import { Toaster } from "libs/ui/Toaster/Toaster";
import { toastStore } from "config/toast";
import TranslationsLoader from "layout/Login/TranslationsLoader";
import "utils/helpers/google";
import "utils/machines/avatars";

function CarnaApp() {
  return (
    <Authenticator.Provider>
      <Login>
        <GlobalConfigProvider>
          <TranslationsLoader>
            <GlobalPreferenceProvider>
              <CurrentUserNameProvider>
                <Main />
              </CurrentUserNameProvider>
            </GlobalPreferenceProvider>
          </TranslationsLoader>
        </GlobalConfigProvider>
        <Toaster store={toastStore} />
      </Login>
    </Authenticator.Provider>
  );
}

export default CarnaApp;
