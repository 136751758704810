/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddNovaMaxMeasurementPatientRequestModel,
    AddNovaMaxMeasurementPatientRequestModelFromJSON,
    AddNovaMaxMeasurementPatientRequestModelFromJSONTyped,
    AddNovaMaxMeasurementPatientRequestModelToJSON,
} from './AddNovaMaxMeasurementPatientRequestModel';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';

/**
 * 
 * @export
 * @interface AddNovaMaxMeasurementPatientModel
 */
export interface AddNovaMaxMeasurementPatientModel {
    /**
     * 
     * @type {DeviceType}
     * @memberof AddNovaMaxMeasurementPatientModel
     */
    deviceType?: DeviceType;
    /**
     * 
     * @type {AddNovaMaxMeasurementPatientRequestModel}
     * @memberof AddNovaMaxMeasurementPatientModel
     */
    measurement?: AddNovaMaxMeasurementPatientRequestModel;
}

export function AddNovaMaxMeasurementPatientModelFromJSON(json: any): AddNovaMaxMeasurementPatientModel {
    return AddNovaMaxMeasurementPatientModelFromJSONTyped(json, false);
}

export function AddNovaMaxMeasurementPatientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNovaMaxMeasurementPatientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceType': !exists(json, 'DeviceType') ? undefined : DeviceTypeFromJSON(json['DeviceType']),
        'measurement': !exists(json, 'Measurement') ? undefined : AddNovaMaxMeasurementPatientRequestModelFromJSON(json['Measurement']),
    };
}

export function AddNovaMaxMeasurementPatientModelToJSON(value?: AddNovaMaxMeasurementPatientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'Measurement': AddNovaMaxMeasurementPatientRequestModelToJSON(value.measurement),
    };
}

