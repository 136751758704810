import classNames from "classnames";
import "./EGFRRangeTableRow.scss";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";

interface EGFRRangeTableRowProps {
  label: string;
  from?: number;
  to?: number;
  value?: number;
}

function getRange(from?: string, to?: string) {
  if (from && to === "NaN") {
    return `≥ ${from}`;
  }

  if (from === "NaN" && to) {
    return `≤ ${to}`;
  }

  return `${from} - ${to}`;
}

function getIsInRange(value?: number, from?: number, to?: number) {
  if (!value) {
    return false;
  }

  if (from && to === undefined) {
    return value >= from;
  }

  if (from === undefined && to) {
    return value < to;
  }

  return (from ?? 0) <= value && (to ?? 0) >= value;
}

export function EGFRRangeTableRow({ label, from, to, value }: Readonly<EGFRRangeTableRowProps>) {
  const { numberFormatter } = useLocaleNumberFormatter();

  if (from === undefined && to === undefined) {
    throw Error("You must provide at least one param");
  }

  return (
    <tr
      className={classNames("EGFRRangeTableRow", {
        "EGFRRangeTableRow--active": getIsInRange(value, from, to),
      })}
    >
      <td className="EGFRRangeTableCell EGFRRangeTableCell--label">{label}</td>
      <td className="EGFRRangeTableCell EGFRRangeTableCell--range">
        {getRange(numberFormatter({ value: from }), numberFormatter({ value: to }))}
      </td>
    </tr>
  );
}
