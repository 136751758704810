/* eslint-disable testing-library/no-await-sync-queries */
import { IdentifiedWithCkdRiskGroupBy } from "api/report_service/models/IdentifiedWithCkdRiskGroupBy";
import { IdentifiedWithCkdRiskReportModel } from "api/report_service/models/IdentifiedWithCkdRiskReportModel";
import { CarnaApiQuery } from "config/apiQuery";
import { CarnaApiReportService } from "config/apiReportService";
import {
  defaultModel,
  failed,
  isDefaultModel,
  LoadableModel,
  loaded,
  loading,
} from "models/loadable";
import { PropsWithChildren, useCallback, useEffect, useMemo, useReducer } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { getPlaceDetails } from "utils/helpers/google";
import { backOff } from "exponential-backoff";
import { cloneDeep } from "lodash-es";
import { IdentifiedWithCkdRiskFilterModel } from "api/report_service/models/IdentifiedWithCkdRiskFilterModel";
import {
  getPlaceSearchInputBasedOnEntityResponse,
  isKeyInIdentifiedWithCkdRiskFilterModel,
  useSetReportServiceSearchParams,
} from "./helper";
import { logger } from "logger";

const backOffConfig: Parameters<typeof backOff>["1"] = {
  jitter: "full",
  delayFirstAttempt: true,
  startingDelay: 1000,
  numOfAttempts: 2,
} as const;

export interface MapData {
  patientCount: number;
  patientCDKCount: number;
  coordinates: [longitude: number, latitude: number];
  name: string;
  entityState?: string | null;
  entityCountryISO?: string | null;
}

export interface ChartData {
  entityName: string;
  entityState?: string | null;
  entityCountryISO?: string | null;
  categories: {
    Patients: number;
    "Patients identified with CKD": number;
  };
}

interface TotalNumbers {
  totalNumberOfPatients: number;
  totalNumberOfPatientsInCkdRisk: number;
}

type DashboardMapReportState = {
  ChartData: LoadableModel<ChartData[] | null>;
  MapData: LoadableModel<MapData[] | null>;
  TotalNumbers: LoadableModel<TotalNumbers | null>;
  appliedFilters: IdentifiedWithCkdRiskFilterModel;
  isLoadingState: boolean;
};

const DATA_INIT: DashboardMapReportState = {
  ChartData: defaultModel(),
  MapData: defaultModel(),
  TotalNumbers: defaultModel(),
  appliedFilters: {},
  isLoadingState: false,
};

type ActionType = "Load" | "Set" | "Failed";

interface Action {
  type: ActionType;
  payload: {
    data: LoadableModel<IdentifiedWithCkdRiskReportModel | null>;
    filters: IdentifiedWithCkdRiskFilterModel;
  };
}

function mapMapData(data: IdentifiedWithCkdRiskReportModel): MapData[] {
  const { groups } = data;

  return (groups ?? []).map(el => ({
    patientCount: el.numberOfPatients ?? 0,
    patientCDKCount: el.numberOfPatientsInCkdRisk ?? 0,
    coordinates: [
      el.groupedBy?.geolocation?.longitude ?? 0,
      el.groupedBy?.geolocation?.latitude ?? 0,
    ],
    name: el.groupedBy?.name ?? "",
    entityState: el.groupedBy?.state,
    entityCountryISO: el.groupedBy?.countryIso,
  }));
}

function mapChartData(data: IdentifiedWithCkdRiskReportModel): ChartData[] {
  const { groups } = data;

  return (groups ?? []).map(el => ({
    entityName: el.groupedBy?.name ?? "",
    entityState: el.groupedBy?.state ?? "",
    entityCountryISO: el.groupedBy?.countryIso ?? "",
    categories: {
      Patients: el.numberOfPatients ?? 0,
      "Patients identified with CKD": el.numberOfPatientsInCkdRisk ?? 0,
    },
  }));
}

function mapTotalNumbers(data: IdentifiedWithCkdRiskReportModel): TotalNumbers {
  const { totalNumberOfPatients = 0, totalNumberOfPatientsInCkdRisk = 0 } = data;

  return {
    totalNumberOfPatients,
    totalNumberOfPatientsInCkdRisk,
  };
}

function reducer(state: DashboardMapReportState, action: Action): DashboardMapReportState {
  switch (action.type) {
    case "Load":
      return {
        ...state,
        isLoadingState: true,
        ChartData: loading(),
        MapData: loading(),
        TotalNumbers: loading(),
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload.filters,
        },
      };

    case "Set":
      return {
        ...state,
        isLoadingState: false,
        ChartData: loaded(mapChartData(loaded(action.payload.data.value).value)),
        MapData: loaded(mapMapData(loaded(action.payload.data.value).value)),
        TotalNumbers: loaded(mapTotalNumbers(loaded(action.payload.data.value).value)),
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload.filters,
        },
      };

    case "Failed":
      return {
        ...state,
        isLoadingState: false,
        ChartData: failed(null),
        MapData: failed(null),
        TotalNumbers: failed(null),
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload.filters,
        },
      };

    default:
      throw new Error(`${action.type} case for DashboardMapReportState not implemented`);
  }
}

interface DashboardMapReportStateContextData {
  reportData: DashboardMapReportState;
  getReportModelPerGroupingOption: (groupBy: IdentifiedWithCkdRiskGroupBy) => Promise<void>;
  getReportModelPerFilters: (filters: IdentifiedWithCkdRiskFilterModel) => Promise<void>;
}

const Context = createSafeContext<DashboardMapReportStateContextData>();

export const useDashboardMapReportContext = Context.hook;

export function DashboardMapReportContextProvider({ children }: Readonly<PropsWithChildren>) {
  const [reportData, dispatch] = useReducer(reducer, DATA_INIT);

  const [searchParams, setSearchParams] = useSetReportServiceSearchParams();

  const getReportModel = useCallback(
    async (filters: IdentifiedWithCkdRiskFilterModel) => {
      async function getReportModel() {
        const results = await CarnaApiReportService.DashboardsMap.get(filters);
        const groups = await Promise.all(
          (results.groups ?? []).map(async entityData => {
            const orgId = entityData.groupedBy?.entityId;

            if (!entityData.groupedBy?.geolocation) {
              const newEntityData = cloneDeep(entityData);

              if (
                !newEntityData?.groupedBy?.geolocation ||
                newEntityData?.groupedBy?.geolocation.latitude === 0 ||
                newEntityData?.groupedBy?.geolocation.longitude === 0
              ) {
                // eslint-disable-next-line testing-library/no-await-sync-queries
                const orgData = newEntityData?.groupedBy?.entityId
                  ? await CarnaApiQuery.Organizations.getById({
                      organizationId: orgId ?? "",
                    })
                  : undefined;

                const placeSearchString = getPlaceSearchInputBasedOnEntityResponse(
                  orgData,
                  newEntityData,
                );
                // In case we don't have the Country in our nationalities
                if (!placeSearchString) {
                  logger.error(
                    newEntityData,
                    "Could not find place search string, most likely country is missing in nationalities translations ",
                  );
                  return newEntityData;
                }

                try {
                  const placeDetails = await backOff(
                    () => getPlaceDetails(placeSearchString),
                    backOffConfig,
                  );

                  const { countryCode, city, coordinates } = placeDetails.at(0) ?? {};

                  const hasOrganizationGeoLocation =
                    newEntityData.groupedBy &&
                    orgData &&
                    countryCode &&
                    countryCode.toLowerCase() === orgData.country?.toLowerCase() &&
                    city &&
                    city.toLowerCase() === orgData.city?.toLowerCase() &&
                    coordinates?.lng();

                  const hasCountryOrCityGeoLocation =
                    newEntityData.groupedBy && orgData === undefined;

                  if ((hasOrganizationGeoLocation || hasCountryOrCityGeoLocation) && coordinates) {
                    newEntityData.groupedBy!.geolocation = {
                      longitude: coordinates?.lng(),
                      latitude: coordinates?.lat(),
                    };
                  } else if (newEntityData.groupedBy) {
                    newEntityData.groupedBy.geolocation = undefined;
                  }
                } catch (error) {}
              }
              return newEntityData;
            }

            return entityData;
          }),
        );

        return {
          ...results,
          groups,
        };
      }

      setSearchParams(filters);
      dispatch({
        type: "Load",
        payload: { data: loading(), filters },
      });

      getReportModel()
        .then(result =>
          dispatch({
            type: "Set",
            payload: {
              data: loaded(result),
              filters,
            },
          }),
        )
        .catch(() =>
          dispatch({
            type: "Failed",
            payload: { data: failed(null), filters },
          }),
        );
    },
    [setSearchParams],
  );

  const getReportModelPerFilters = useCallback(
    async (filters: IdentifiedWithCkdRiskFilterModel) => {
      const totalFilters = {
        ...reportData.appliedFilters,
        ...filters,
      };

      getReportModel(totalFilters);
    },
    [getReportModel, reportData.appliedFilters],
  );

  const getReportModelPerGroupingOption = useCallback(
    async (groupBy: IdentifiedWithCkdRiskGroupBy) => {
      const totalFilters = {
        ...reportData.appliedFilters,
        groupBy,
      };

      getReportModel(totalFilters);
    },
    [getReportModel, reportData.appliedFilters],
  );

  useEffect(() => {
    const isInInitState =
      isDefaultModel(reportData.ChartData) &&
      isDefaultModel(reportData.MapData) &&
      isDefaultModel(reportData.TotalNumbers);

    if (isInInitState) {
      const allParams = {} as any;
      for (const [key, value] of searchParams.entries()) {
        if (!allParams.hasOwnProperty(key) && isKeyInIdentifiedWithCkdRiskFilterModel(key)) {
          allParams[key] = [];
        }
        allParams[key].push(value);
      }

      const totalFilters: IdentifiedWithCkdRiskFilterModel = {
        ...allParams,
        groupBy: allParams.groupBy ? allParams.groupBy[0] : "Organization",
      };

      getReportModel(totalFilters);
    }
  }, [
    getReportModel,
    getReportModelPerGroupingOption,
    reportData.ChartData,
    reportData.MapData,
    reportData.TotalNumbers,
    searchParams,
  ]);

  const value = useMemo(
    () => ({ reportData, getReportModelPerGroupingOption, getReportModelPerFilters }),
    [getReportModelPerFilters, getReportModelPerGroupingOption, reportData],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
