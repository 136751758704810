import { autoPlacement, autoUpdate } from "@floating-ui/react-dom";
import { IdentifiedWithCkdRiskGroupBy } from "api/report_service/models/IdentifiedWithCkdRiskGroupBy";
import { CarnaMap } from "components/Map";
import { Select } from "libs/ui/Select";
import { Option } from "libs/ui/Select/Select.model";
import { isDefaultModel, isLoaded, isLoading as isLoadingModel } from "models/loadable";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../Loader";
import "./DashboardMap.scss";
import { DashboardMapAside } from "./DashboardMapAside";
import {
  DashboardMapReportContextProvider,
  useDashboardMapReportContext,
} from "./DashboardMapReportDataContext";
import { MapFilters } from "./MapFilters";
import { useSearchParams } from "react-router-dom";

export function DashboardMapComponent() {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();

  const initValue = (searchParams.get("groupBy") ?? "Organization") as IdentifiedWithCkdRiskGroupBy;

  const [groupingOption, setGroupingOption] = useState<IdentifiedWithCkdRiskGroupBy>(initValue);
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.groupingOptions" });
  const { reportData, getReportModelPerGroupingOption } = useDashboardMapReportContext();

  const groupingOptions: Option<IdentifiedWithCkdRiskGroupBy>[] = [
    { title: t("organizations"), value: "Organization" },
    { title: t("address-city"), value: "PatientAddressCity" },
    { title: t("address-country"), value: "PatientAddressCountry" },
  ];

  const onGroupingOptionChange = useCallback(
    (val: IdentifiedWithCkdRiskGroupBy = "Organization") => {
      setGroupingOption(val);
      getReportModelPerGroupingOption(val);
    },
    [getReportModelPerGroupingOption, setGroupingOption],
  );

  return (
    <div className="DashboardMap">
      <DashboardMapAside groupingOption={groupingOption} />

      <div className="DashboardMap__main">
        <div className="DashboardMap__container" ref={mapContainerRef}>
          <div className="DashboardMap__tools">
            <MapFilters />

            <Select
              onSelect={onGroupingOptionChange}
              value={groupingOption}
              className="DashboardMap__select"
              options={groupingOptions}
              dropdownFloatingProps={{
                strategy: "absolute",
                placement: "bottom-end",
                middleware: [autoPlacement({ allowedPlacements: ["bottom-end"] })],
                whileElementsMounted: autoUpdate,
              }}
            />
          </div>

          {isLoadingModel(reportData.MapData) || isDefaultModel(reportData.ChartData) ? (
            <div className="LoaderContainer">
              <Loader />
            </div>
          ) : null}
          {isLoaded(reportData.MapData) ? <CarnaMap containerRef={mapContainerRef} /> : null}
        </div>
      </div>
    </div>
  );
}

export function DashboardMap() {
  return (
    <DashboardMapReportContextProvider>
      <DashboardMapComponent />
    </DashboardMapReportContextProvider>
  );
}
