/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddNovaMaxMeasurementsPatientRequestModel,
    AddNovaMaxMeasurementsPatientRequestModelFromJSON,
    AddNovaMaxMeasurementsPatientRequestModelFromJSONTyped,
    AddNovaMaxMeasurementsPatientRequestModelToJSON,
} from './AddNovaMaxMeasurementsPatientRequestModel';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';

/**
 * 
 * @export
 * @interface AddNovaMaxMeasurementsPatientModel
 */
export interface AddNovaMaxMeasurementsPatientModel {
    /**
     * 
     * @type {DeviceType}
     * @memberof AddNovaMaxMeasurementsPatientModel
     */
    deviceType?: DeviceType;
    /**
     * 
     * @type {AddNovaMaxMeasurementsPatientRequestModel}
     * @memberof AddNovaMaxMeasurementsPatientModel
     */
    measurement?: AddNovaMaxMeasurementsPatientRequestModel;
}

export function AddNovaMaxMeasurementsPatientModelFromJSON(json: any): AddNovaMaxMeasurementsPatientModel {
    return AddNovaMaxMeasurementsPatientModelFromJSONTyped(json, false);
}

export function AddNovaMaxMeasurementsPatientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNovaMaxMeasurementsPatientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceType': !exists(json, 'DeviceType') ? undefined : DeviceTypeFromJSON(json['DeviceType']),
        'measurement': !exists(json, 'Measurement') ? undefined : AddNovaMaxMeasurementsPatientRequestModelFromJSON(json['Measurement']),
    };
}

export function AddNovaMaxMeasurementsPatientModelToJSON(value?: AddNovaMaxMeasurementsPatientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'Measurement': AddNovaMaxMeasurementsPatientRequestModelToJSON(value.measurement),
    };
}

