import { DeviceResponseModelListModel, DevicesFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { TableContext, tableStateMachine } from "libs/ui";
import { InitialValuesModel } from "models/PersonModels";
import { DevicesFilterOptions, DevicesTableColumns } from "utils/createGlobalConfigStore";
import { getCurrentUserData } from "utils/getCurrentUserData";
import { deviceStatusesToSelectOptions } from "utils/mappers/deviceStatusesToSelectOptions";
import { InterpreterFrom } from "xstate";
import { FilterSideModalState } from "../Filter/model";
import { BaseTableFilterProps } from "../Table/model";

async function getAdminDevicesList(
  context?: TableContext<DeviceResponseModelListModel, DevicesFilterModel>,
) {
  const result = await CarnaApiQuery.Admin.getDevices({
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getPartnerDevicesList(
  context?: TableContext<DeviceResponseModelListModel, DevicesFilterModel>,
) {
  const { organizationId } = await getCurrentUserData();

  const result = await CarnaApiQuery.Devices.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getPatientDevicesList(
  context?: TableContext<DeviceResponseModelListModel, DevicesFilterModel>,
) {
  const { organizationId, currentUserId } = await getCurrentUserData();

  const result = CarnaApiQuery.Devices.getByUserId({
    organizationId,
    userEntityId: currentUserId,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export type UIDeviceFilters = Omit<
  DevicesFilterModel,
  "beginsWith" | "userIds" | "deviceEntityIds"
>;
async function tableFetch(context?: TableContext<DeviceResponseModelListModel, UIDeviceFilters>) {
  const { isAdmin, isPatient } = await getCurrentUserData();

  if (isAdmin) {
    return getAdminDevicesList(context);
  }

  return isPatient ? getPatientDevicesList(context) : getPartnerDevicesList(context);
}

export const makeTableFetchStateMachine = () => tableStateMachine(tableFetch);

type TableService = InterpreterFrom<ReturnType<typeof makeTableFetchStateMachine>>;

export function DEVICES_FILTER_OPTIONS() {
  return {
    deviceStatuses: deviceStatusesToSelectOptions(),
    organizationIds: [],
    contains: "",
  } as const;
}

export type DevicesFilterOptionsKey = keyof ReturnType<typeof DEVICES_FILTER_OPTIONS>;

export type DevicesTableStateContext = {
  tableService: TableService;
  fetch: () => void;
  filterSideModelState: FilterSideModalState<DevicesFilterOptionsKey>;
};

export interface DevicesTableProps extends BaseTableFilterProps {
  devicesTableStateContext: DevicesTableStateContext;
  appliedColumns: DevicesTableColumns[];
  devicesFilterOptions?: DevicesFilterOptions[];
  initialValuesAsProps?: Partial<InitialValuesModel>;
}

export interface DevicesTableFilterProps {
  tableService: TableService;
  filterSideModelState: FilterSideModalState<DevicesFilterOptionsKey>;
}

export interface BodyRowsProps {
  tableService: TableService;
  appliedColumns: DevicesTableColumns[];
}
