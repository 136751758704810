import { EgfrLimitKey, useGetEGFRRanges } from "utils/hooks/useGetEGFRRanges";
import { HeatRow } from "./HeatRow";
import { HeatMapTableBase } from "./model";
import "./HeatMapTable.scss";

interface HeatMapTableProps extends HeatMapTableBase {}

export function HeatMapTable({ ...props }: HeatMapTableProps) {
  const { orderedEGFRStageRanges } = useGetEGFRRanges();

  const rowKeys = Object.keys(orderedEGFRStageRanges) as EgfrLimitKey[];

  return (
    <table className="HeatMapTable">
      <tbody className="HeatMapTable__body">
        {rowKeys.map(key => (
          <HeatRow key={key} rowKey={key} {...props} />
        ))}
      </tbody>
    </table>
  );
}
