import { Auth } from "aws-amplify";
import { toastStore } from "config/toast";
import { Loader } from "libs/ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActiveQR } from "./ActiveQR";
import { backOff } from "exponential-backoff";
import { InactiveQR } from "./InactiveQR";
import "./TwoFATab.scss";

export type MFAOption = "TOTP" | "SMS" | "NOMFA" | "SMS_MFA" | "SOFTWARE_TOKEN_MFA";

export interface TwoFAData {
  secretCode: string | undefined;
  qrCode: string | undefined;
  preferredMFA: MFAOption | undefined;
  isLoading: boolean;
  isLoadedWithError: boolean;
}

const backOffConfig: Parameters<typeof backOff>["1"] = {
  jitter: "full",
  delayFirstAttempt: true,
  startingDelay: 1000,
  numOfAttempts: 10,
  retry(e, attemptNumber) {
    return e?.code === "TooManyRequestsException";
  },
} as const;
export function TwoFATab() {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.login.Tabs.TwoFA",
  });
  const { t: tAws } = useTranslation("translation", { keyPrefix: "AWS-Cognito" });

  const [twoFAData, setTwoFAData] = useState<TwoFAData>({
    secretCode: undefined,
    qrCode: undefined,
    preferredMFA: undefined,
    isLoading: true,
    isLoadedWithError: false,
  });

  const onOpenQRCode = useCallback(
    async (currentUserParam?: any) => {
      try {
        const currentUser =
          currentUserParam ??
          (await backOff(
            () =>
              Auth.currentAuthenticatedUser({
                bypassCache: true,
              }),
            backOffConfig,
          ));

        const code = await backOff(() => Auth.setupTOTP(currentUser), backOffConfig);

        const qrCode =
          "otpauth://totp/" +
          currentUser.attributes.email +
          "?secret=" +
          code +
          `&issuer=CARNA: ${currentUser.attributes.email ?? currentUser.attributes.phone_number ?? ""}`;

        setTwoFAData(prevValue => ({ ...prevValue, qrCode, isLoading: false, secretCode: code }));
      } catch (error: any) {
        setTwoFAData(prevValue => ({ ...prevValue, isLoadedWithError: true, isLoading: false }));

        if (error?.code !== "ConcurrentModificationException") {
          toastStore.pushToast({ type: "error", msg: tAws(error.code) });
          return;
        }

        if (error.message) {
          toastStore.pushToast({ type: "error", msg: error.message });
          return;
        }
        toastStore.pushToast({ type: "error", msg: JSON.stringify(error) });
      }
    },
    [tAws],
  );

  const onActivateQR = useCallback(async () => {
    setTwoFAData(prevValue => ({ ...prevValue, isLoading: true }));
    await onOpenQRCode();
  }, [onOpenQRCode]);

  useEffect(() => {
    (async () => {
      try {
        const currentUser = await backOff(
          () =>
            Auth.currentAuthenticatedUser({
              bypassCache: true,
            }),
          backOffConfig,
        );

        const data = await backOff(() => Auth.getPreferredMFA(currentUser), backOffConfig);

        if (data === "SOFTWARE_TOKEN_MFA") {
          await onOpenQRCode(currentUser);
        }

        setTwoFAData(prevValue => ({
          ...prevValue,
          ...(data !== "SOFTWARE_TOKEN_MFA" && { isLoading: false }),
          preferredMFA: data as MFAOption,
        }));
      } catch (error: any) {
        setTwoFAData(prevValue => ({ ...prevValue, isLoadedWithError: true, isLoading: false }));

        if (error.message) {
          toastStore.pushToast({ type: "error", msg: error.message });
          return;
        }
        toastStore.pushToast({ type: "error", msg: JSON.stringify(error) });
      }
    })();
  }, [onOpenQRCode]);

  return (
    <div className="TwoFATab">
      <h3 className="TwoFATab__title">{t("sms_title")}</h3>
      <p className="TwoFATab__text">{t("sms_description")}</p>

      <h3 className="TwoFATab__title">{t("authentication_title")}</h3>
      <Loader loading={twoFAData.isLoading}>
        {twoFAData.qrCode ? (
          <ActiveQR
            secretCode={twoFAData.secretCode}
            qrCode={twoFAData.qrCode}
            preferredMFA={twoFAData.preferredMFA}
            setTwoFAData={setTwoFAData}
          />
        ) : (
          <InactiveQR onClick={onActivateQR} />
        )}
      </Loader>
    </div>
  );
}
