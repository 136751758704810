import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
  useSyncExternalStore,
} from "react";
import { Button } from "libs/ui/Button";
import { autoUpdate, useFloating } from "@floating-ui/react";
import { useOnClickOutside } from "libs/ui/hooks/useClickOutside";
import classNames from "classnames";
import { MakeGridContainer, Widget as WidgetStateProps } from "./MakeGridContainer";
import "./Widget.scss";
interface WidgetProps {
  widgetName: string;
  options?: Partial<WidgetStateProps>;
  Header?: React.ReactNode;
  optionsDropDown?: React.ReactNode;
  gridContainer: MakeGridContainer;
  className?: string;
}

export function GridRectangle() {
  return <div className="GridRectangle" />;
}

export function Widget({
  options,
  optionsDropDown,
  Header,
  children,
  className,
  widgetName,
  gridContainer,
}: PropsWithChildren<WidgetProps>) {
  const [showOptions, setShowOptions] = useState(false);
  const refContainer = useRef<HTMLDivElement>(null);
  const refDragContainer = useRef<HTMLButtonElement>(null);
  const refResizeContainer = useRef<HTMLButtonElement>(null);

  const widgetState = useSyncExternalStore(
    gridContainer.subscribe(widgetName),
    gridContainer.getState(widgetName),
  );

  const toggleShowOptions = () => setShowOptions(prev => !prev);

  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    strategy: "absolute",
    whileElementsMounted: autoUpdate,
  });

  const getIgnoredRefs = useCallback(() => {
    return [refs.reference.current as HTMLDivElement] as const;
  }, [refs.reference]);

  useOnClickOutside(refs.floating, showOptions, toggleShowOptions, getIgnoredRefs);

  useEffect(() => {
    gridContainer.addWidget({ widgetName, x: 1, y: 1, height: 2, width: 2, ...options });
    let removeSnapBinding: ReturnType<typeof gridContainer.hookUpWidgetSnapping>;
    let removeResizeBinding: ReturnType<typeof gridContainer.hookUpWidgetResizing>;

    if (refContainer.current) {
      removeSnapBinding = gridContainer.hookUpWidgetSnapping(
        refDragContainer.current!,
        refContainer.current,
        widgetName,
      );
      removeResizeBinding = gridContainer.hookUpWidgetResizing(
        refResizeContainer.current!,
        refContainer.current,
        widgetName,
      );
    }

    return () => {
      gridContainer.removeWidget(widgetName);
      if (removeSnapBinding) {
        removeSnapBinding();
      }
      if (removeResizeBinding) {
        removeResizeBinding();
      }
    };
  }, [gridContainer, options, widgetName]);

  return (
    <div
      className={classNames("Widget", className)}
      data-widget-name={widgetName}
      data-widget-size={`${widgetState?.width}x${widgetState?.height}`}
      ref={refContainer}
      style={{
        gridArea: widgetState
          ? `${widgetState.y} / ${widgetState.x} / span ${widgetState.height} / span ${widgetState.width}`
          : "",
      }}
    >
      <div className="Widget__head">
        <Button
          ref={refDragContainer}
          className="DragButton"
          buttonType={"transparent"}
          buttonSize={"small"}
          buttonIcon={{ icon: "Drag" }}
        />
        {Header}

        <Button
          buttonType={"transparent"}
          buttonSize={"small"}
          buttonIcon={{ icon: "MoreVertical" }}
          onClick={toggleShowOptions}
          ref={refs.setReference}
          active={showOptions}
        />

        {showOptions && optionsDropDown ? (
          <div ref={refs.setFloating} className="Widget__options" style={floatingStyles}>
            {optionsDropDown}
          </div>
        ) : null}
      </div>

      <div className="Widget__body">{children}</div>
      <div className="Widget__foot">
        <Button
          className="ResizeButton"
          buttonType={"transparent"}
          buttonSize={"small"}
          buttonIcon={{ icon: "Resize" }}
          ref={refResizeContainer}
        />
      </div>
    </div>
  );
}
