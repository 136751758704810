import { FloatingPortal, Placement, autoUpdate, useFloating } from "@floating-ui/react";
import classNames from "classnames";
import { CollapseToggler } from "components/CollapseToggler";
import { useAppMenuContext } from "context/AppMenuContext";
import { useOnClickOutside, useResponsive } from "libs/ui/hooks";
import { useCallback, useMemo, useState } from "react";
import { BasicDetails } from "./BasicDetails";
import { Navigation } from "./Navigation";

import { SignOutModal } from "components/AppModals/SignOutModal";
import { Logo } from "libs/ui";
import { BasicDetailsDropDown } from "./BasicDetails/BasicDetailsDropDown";
import "./SideBar.scss";

export function SideBar() {
  const { menuOpen } = useAppMenuContext();
  const { isDesktop, isTablet } = useResponsive();
  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const [userFloatingMenuVisibility, setUserFloatingMenuVisibility] = useState(false);

  const toggleSignOutModal = useCallback(() => setShowSignOutModal(prevValue => !prevValue), []);

  const getDropDownPlacement = useMemo((): Placement => {
    if (isDesktop) {
      return "right-end";
    } else if (isTablet) {
      return "left-end";
    } else {
      return "top-end";
    }
  }, [isDesktop, isTablet]);

  const { refs, update, floatingStyles } = useFloating({
    open: userFloatingMenuVisibility,
    placement: getDropDownPlacement,
    whileElementsMounted: autoUpdate,
  });

  const onToggleSidebarCollapsed = () => {
    setSidebarCollapsed(prev => !prev);
  };

  const toggleUserFloatingMenuVisibility = useCallback(() => {
    setUserFloatingMenuVisibility(prevVal => !prevVal);
  }, []);

  const getAdditionalIgnoredElems = useCallback(() => {
    return [refs.reference.current as HTMLDivElement] as const;
  }, [refs.reference]);

  useOnClickOutside(
    refs.floating,
    userFloatingMenuVisibility,
    toggleUserFloatingMenuVisibility,
    getAdditionalIgnoredElems,
  );

  return (
    <aside
      className={classNames(
        "SideBar",
        menuOpen && !isDesktop && "SideBar--open",
        sidebarCollapsed && "SideBar--collapsed",
      )}
    >
      <Logo short={sidebarCollapsed} />
      {isDesktop && (
        <CollapseToggler
          toggleCollapsed={sidebarCollapsed}
          onToggleCollapse={onToggleSidebarCollapsed}
        />
      )}
      <Navigation {...(isDesktop && { sidebarCollapsed })} />
      <BasicDetails
        mainRef={refs.setReference}
        onClick={() => {
          toggleUserFloatingMenuVisibility();
          update();
        }}
        {...(isDesktop && { sidebarCollapsed })}
      />
      {userFloatingMenuVisibility === true ? (
        <FloatingPortal>
          <BasicDetailsDropDown
            refProp={refs.setFloating}
            style={floatingStyles}
            onRouteClick={toggleUserFloatingMenuVisibility}
            onSignOutClick={toggleSignOutModal}
          />
        </FloatingPortal>
      ) : null}
      <SignOutModal show={showSignOutModal} onCancel={toggleSignOutModal} />
    </aside>
  );
}
