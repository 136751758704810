import { generatePath } from "react-router-dom";
import { dashboardPaths, mainRoutePaths, NavRoute, settingsRoutePaths } from "router";
import { useHasAccess } from "./useHasAccess";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "./useCurrentUserData";
import { useFeatureToggles } from "utils/featureToggles";

export function useGetNavRoutes() {
  const { t } = useTranslation("translation", { keyPrefix: "Navigation" });
  const can = useHasAccess();
  const { isPatient, currentUserId, organizationId } = useCurrentUserData();
  const futureToggles = useFeatureToggles();

  const canUseDashboardRoute =
    (can("manage-dashboard-admin") && futureToggles["dashboard-map"]) ||
    can("manage-dashboard-patient");

  const routesObj = {
    home: {
      name: t("home"),
      to: isPatient
        ? generatePath(mainRoutePaths.patientOverview, { organizationId, patientId: currentUserId })
        : generatePath(dashboardPaths.dashboardMap),
      icon: "Dashboard",
      disabled: !canUseDashboardRoute,
      accessLevel: true,
    } satisfies NavRoute,
    hcp: {
      name: t("hcp"),
      to: generatePath(mainRoutePaths.hcp),
      icon: "Hcp",
      accessLevel: true,
    } satisfies NavRoute,
    patients: {
      name: t("patients"),
      to: generatePath(mainRoutePaths.patients),
      icon: "User",
      accessLevel: can("manage-patient"),
    } satisfies NavRoute,
    devices: {
      name: t("devices"),
      to: generatePath(mainRoutePaths.devices),
      icon: "Smartphone",
      accessLevel: true,
    } satisfies NavRoute,
    tests: {
      name: t("tests"),
      to: generatePath(mainRoutePaths.tests),
      icon: "Laboratory",
      accessLevel: true,
    } satisfies NavRoute,
    settings: {
      name: t("settings"),
      to: generatePath(mainRoutePaths.settings),
      icon: "Settings",
      accessLevel: true,
    } satisfies NavRoute,
  } as const;

  return [Object.values(routesObj) as NavRoute[], routesObj] as const;
}

export function useGetSettingsNavRoutes() {
  const { t } = useTranslation("translation", { keyPrefix: "Navigation.Settings" });
  const can = useHasAccess();

  const routesObj = {
    projectOrganizations: {
      name: t("projectOrganizations"),
      to: generatePath(settingsRoutePaths.projectOrganizations),
      icon: "Organization",
      accessLevel: can("manage-organization"),
    } satisfies NavRoute,
    users: {
      name: t("users-management"),
      to: generatePath(settingsRoutePaths.users),
      icon: "User",
      accessLevel: can("manage-users"),
    } satisfies NavRoute,
  } as const;
  //...

  return [Object.values(routesObj) as NavRoute[], routesObj] as const;
}
