import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { AvatarAction, AvatarState, useAvatarState } from "utils/hooks/useAvatarState";

interface AppMenuContext {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadSideAvatar: () => Promise<void>;
  dispatchSideAvatarAction: React.Dispatch<AvatarAction>;
  sideAvatarState: AvatarState;
}

const MenuContext = createSafeContext<AppMenuContext>();

export const useAppMenuContext = MenuContext.hook;

export const AppMenuContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { currentUserId, organizationId } = useCurrentUserData();
  const [menuOpen, setMenuOpen] = useState(false);
  const { avatarState, dispatchAvatarAction, loadAvatar } = useAvatarState();

  const loadSideAvatar = useCallback(
    () => loadAvatar(organizationId, currentUserId),
    [currentUserId, loadAvatar, organizationId],
  );

  const value = useMemo<AppMenuContext>(
    () => ({
      menuOpen,
      setMenuOpen,
      loadSideAvatar,
      dispatchSideAvatarAction: dispatchAvatarAction,
      sideAvatarState: avatarState,
    }),
    [avatarState, dispatchAvatarAction, loadSideAvatar, menuOpen],
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
