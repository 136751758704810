/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryType,
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import {
    StateType,
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import {
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface UserDetailsResponseModel
 */
export interface UserDetailsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof UserDetailsResponseModel
     */
    country: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof UserDetailsResponseModel
     */
    state: StateType;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsResponseModel
     */
    isMainOrganization?: boolean;
    /**
     * 
     * @type {UserStatusType}
     * @memberof UserDetailsResponseModel
     */
    status: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserDetailsResponseModel
     */
    role: UserRoleType;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsResponseModel
     */
    rowVersion: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsResponseModel
     */
    isAccountOwner?: boolean;
}

export function UserDetailsResponseModelFromJSON(json: any): UserDetailsResponseModel {
    return UserDetailsResponseModelFromJSONTyped(json, false);
}

export function UserDetailsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetailsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': json['Email'],
        'phone': json['Phone'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': StateTypeFromJSON(json['State']),
        'zipCode': json['ZipCode'],
        'organizationId': json['OrganizationId'],
        'organizationName': json['OrganizationName'],
        'isMainOrganization': !exists(json, 'IsMainOrganization') ? undefined : json['IsMainOrganization'],
        'status': UserStatusTypeFromJSON(json['Status']),
        'role': UserRoleTypeFromJSON(json['Role']),
        'rowVersion': json['RowVersion'],
        'isAccountOwner': !exists(json, 'IsAccountOwner') ? undefined : json['IsAccountOwner'],
    };
}

export function UserDetailsResponseModelToJSON(value?: UserDetailsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Phone': value.phone,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': StateTypeToJSON(value.state),
        'ZipCode': value.zipCode,
        'OrganizationId': value.organizationId,
        'OrganizationName': value.organizationName,
        'IsMainOrganization': value.isMainOrganization,
        'Status': UserStatusTypeToJSON(value.status),
        'Role': UserRoleTypeToJSON(value.role),
        'RowVersion': value.rowVersion,
        'IsAccountOwner': value.isAccountOwner,
    };
}

