import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { AllTestsTable } from "../AllTestsTable";
import { TestsTableColumns } from "../common/TestsTable/model";
import { TestsPerPatientTable } from "../TestsPerPatientTable";
import { MeasurementItemUIType, MeasurementTypes } from "models/TestModels";

interface LaboratoryTestsTableProps {
  appliedColumns: TestsTableColumns[];
  patientAppliedColumns: TestsTableColumns[];
  measurementType?: MeasurementTypes;
  measurementTypeItem?: MeasurementItemUIType;
}
export function LaboratoryTestsTable({
  appliedColumns,
  patientAppliedColumns,
  measurementType,
  measurementTypeItem,
}: LaboratoryTestsTableProps) {
  const { isPatient } = useCurrentUserData();

  return isPatient ? (
    <TestsPerPatientTable
      appliedColumns={patientAppliedColumns}
      measurementType={measurementType}
      measurementTypeItem={measurementTypeItem}
    />
  ) : (
    <AllTestsTable
      appliedColumns={appliedColumns}
      measurementType={measurementType}
      measurementTypeItem={measurementTypeItem}
    />
  );
}
