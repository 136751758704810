import { Auth } from "aws-amplify";

/** Exposing for e2e */
window.Auth = Auth;

declare global {
  interface Window {
    Auth: typeof Auth;
  }
}
