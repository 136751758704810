/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddHcpRequestModel,
    AddHcpRequestModelFromJSON,
    AddHcpRequestModelToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    HcpDetailsResponseModelApiResponse,
    HcpDetailsResponseModelApiResponseFromJSON,
    HcpDetailsResponseModelApiResponseToJSON,
    HcpListResponseModelApiResponse,
    HcpListResponseModelApiResponseFromJSON,
    HcpListResponseModelApiResponseToJSON,
    HcpsFilter,
    HcpsFilterFromJSON,
    HcpsFilterToJSON,
    PartnersFilter,
    PartnersFilterFromJSON,
    PartnersFilterToJSON,
    PatientListResponseModelApiResponse,
    PatientListResponseModelApiResponseFromJSON,
    PatientListResponseModelApiResponseToJSON,
    ResendSignUpRequestModel,
    ResendSignUpRequestModelFromJSON,
    ResendSignUpRequestModelToJSON,
    StringApiResponse,
    StringApiResponseFromJSON,
    StringApiResponseToJSON,
    UpdateHcpRequestModel,
    UpdateHcpRequestModelFromJSON,
    UpdateHcpRequestModelToJSON,
    UpdateHcpStatusRequestModel,
    UpdateHcpStatusRequestModelFromJSON,
    UpdateHcpStatusRequestModelToJSON,
    UpdateUserStatusResponseModelApiResponse,
    UpdateUserStatusResponseModelApiResponseFromJSON,
    UpdateUserStatusResponseModelApiResponseToJSON,
} from '../models';

export interface OrganizationsOrganizationIdHcpsGetRequest {
    limit: number;
    organizationId: string;
    paginationToken?: string;
    filters?: HcpsFilter;
}

export interface OrganizationsOrganizationIdHcpsIdDeleteRequest {
    id: string;
    rowVersion: number;
    organizationId: string;
}

export interface OrganizationsOrganizationIdHcpsIdGetRequest {
    id: string;
    organizationId: string;
}

export interface OrganizationsOrganizationIdHcpsIdPatchRequest {
    id: string;
    organizationId: string;
    UpdateHcpStatusRequestModel?: UpdateHcpStatusRequestModel;
}

export interface OrganizationsOrganizationIdHcpsIdPatientsGetRequest {
    limit: number;
    organizationId: string;
    id: string;
    paginationToken?: string;
    filters?: PartnersFilter;
}

export interface OrganizationsOrganizationIdHcpsIdPutRequest {
    id: string;
    organizationId: string;
    UpdateHcpRequestModel?: UpdateHcpRequestModel;
}

export interface OrganizationsOrganizationIdHcpsIdResendSignUpPostRequest {
    organizationId: string;
    id: string;
    ResendSignUpRequestModel: ResendSignUpRequestModel;
}

export interface OrganizationsOrganizationIdHcpsPostRequest {
    organizationId: string;
    AddHcpRequestModel?: AddHcpRequestModel;
}

/**
 * HcpApi - interface
 * 
 * @export
 * @interface HcpApiInterface
 */
export interface HcpApiInterface {
    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} [paginationToken] 
     * @param {HcpsFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpApiInterface
     */
    organizationsOrganizationIdHcpsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsGet(limit: number, organizationId: string, paginationToken?: string, filters?: HcpsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {number} rowVersion 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpApiInterface
     */
    organizationsOrganizationIdHcpsIdDeleteRaw(requestParameters: OrganizationsOrganizationIdHcpsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdDelete(id: string, rowVersion: number, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpApiInterface
     */
    organizationsOrganizationIdHcpsIdGetRaw(requestParameters: OrganizationsOrganizationIdHcpsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdateHcpStatusRequestModel} [UpdateHcpStatusRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpApiInterface
     */
    organizationsOrganizationIdHcpsIdPatchRaw(requestParameters: OrganizationsOrganizationIdHcpsIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateUserStatusResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdPatch(id: string, organizationId: string, UpdateHcpStatusRequestModel?: UpdateHcpStatusRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateUserStatusResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {string} [paginationToken] 
     * @param {PartnersFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpApiInterface
     */
    organizationsOrganizationIdHcpsIdPatientsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdPatientsGet(limit: number, organizationId: string, id: string, paginationToken?: string, filters?: PartnersFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdateHcpRequestModel} [UpdateHcpRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpApiInterface
     */
    organizationsOrganizationIdHcpsIdPutRaw(requestParameters: OrganizationsOrganizationIdHcpsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdPut(id: string, organizationId: string, UpdateHcpRequestModel?: UpdateHcpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {ResendSignUpRequestModel} ResendSignUpRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpApiInterface
     */
    organizationsOrganizationIdHcpsIdResendSignUpPostRaw(requestParameters: OrganizationsOrganizationIdHcpsIdResendSignUpPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdResendSignUpPost(organizationId: string, id: string, ResendSignUpRequestModel: ResendSignUpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {AddHcpRequestModel} [AddHcpRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HcpApiInterface
     */
    organizationsOrganizationIdHcpsPostRaw(requestParameters: OrganizationsOrganizationIdHcpsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsPost(organizationId: string, AddHcpRequestModel?: AddHcpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpDetailsResponseModelApiResponse>;

}

/**
 * 
 */
export class HcpApi extends runtime.BaseAPI implements HcpApiInterface {

    /**
     */
    async organizationsOrganizationIdHcpsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdHcpsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsGet(limit: number, organizationId: string, paginationToken?: string, filters?: HcpsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsGetRaw({ limit: limit, organizationId: organizationId, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdDeleteRaw(requestParameters: OrganizationsOrganizationIdHcpsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdDelete.');
        }

        if (requestParameters.rowVersion === null || requestParameters.rowVersion === undefined) {
            throw new runtime.RequiredError('rowVersion','Required parameter requestParameters.rowVersion was null or undefined when calling organizationsOrganizationIdHcpsIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdDelete(id: string, rowVersion: number, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdDeleteRaw({ id: id, rowVersion: rowVersion, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdGetRaw(requestParameters: OrganizationsOrganizationIdHcpsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdGetRaw({ id: id, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdPatchRaw(requestParameters: OrganizationsOrganizationIdHcpsIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateUserStatusResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdPatch.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHcpStatusRequestModelToJSON(requestParameters.UpdateHcpStatusRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserStatusResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdPatch(id: string, organizationId: string, UpdateHcpStatusRequestModel?: UpdateHcpStatusRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateUserStatusResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdPatchRaw({ id: id, organizationId: organizationId, UpdateHcpStatusRequestModel: UpdateHcpStatusRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdPatientsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdHcpsIdPatientsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdPatientsGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdPatientsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdPatientsGet(limit: number, organizationId: string, id: string, paginationToken?: string, filters?: PartnersFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdPatientsGetRaw({ limit: limit, organizationId: organizationId, id: id, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdPutRaw(requestParameters: OrganizationsOrganizationIdHcpsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdPut.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHcpRequestModelToJSON(requestParameters.UpdateHcpRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdPut(id: string, organizationId: string, UpdateHcpRequestModel?: UpdateHcpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdPutRaw({ id: id, organizationId: organizationId, UpdateHcpRequestModel: UpdateHcpRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdResendSignUpPostRaw(requestParameters: OrganizationsOrganizationIdHcpsIdResendSignUpPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdResendSignUpPost.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdResendSignUpPost.');
        }

        if (requestParameters.ResendSignUpRequestModel === null || requestParameters.ResendSignUpRequestModel === undefined) {
            throw new runtime.RequiredError('ResendSignUpRequestModel','Required parameter requestParameters.ResendSignUpRequestModel was null or undefined when calling organizationsOrganizationIdHcpsIdResendSignUpPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}/resendSignUp`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendSignUpRequestModelToJSON(requestParameters.ResendSignUpRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdResendSignUpPost(organizationId: string, id: string, ResendSignUpRequestModel: ResendSignUpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdResendSignUpPostRaw({ organizationId: organizationId, id: id, ResendSignUpRequestModel: ResendSignUpRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsPostRaw(requestParameters: OrganizationsOrganizationIdHcpsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpDetailsResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddHcpRequestModelToJSON(requestParameters.AddHcpRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsPost(organizationId: string, AddHcpRequestModel?: AddHcpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsPostRaw({ organizationId: organizationId, AddHcpRequestModel: AddHcpRequestModel }, initOverrides);
        return await response.value();
    }

}
