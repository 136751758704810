import "./RegistryNumber.scss";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import classNames from "classnames";
import { useCurrentUserNameContext } from "context/CurrentUserNameContext";
import { isLoaded, isLoading } from "models/loadable";
import { useRegistryNumberLabel } from "utils/hooks/useRegistryNumberLabel";

export function RegistryNumber() {
  const { isPatient, userRegistryNumber } = useCurrentUserData();
  const { currentUserName } = useCurrentUserNameContext();
  const [registryNumberLabel] = useRegistryNumberLabel();

  if (!isPatient || !userRegistryNumber) {
    return null;
  }

  return (
    <p
      className={classNames("RegistryNumber", {
        "RegistryNumber--loading": isLoading(currentUserName),
      })}
    >
      {`${registryNumberLabel}`.concat(": ")}
      {isLoaded(currentUserName) ? userRegistryNumber : null}
    </p>
  );
}
