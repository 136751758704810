import classNames from "classnames";
import { useRef } from "react";
import { FieldValidation } from "../FieldValidation";
import { Icon } from "../Icon";
import { FormFieldBasic } from "../models";
import { isKeyboardClickKey } from "../utils";
import "./Checkbox.scss";

interface CheckboxProps extends FormFieldBasic {}

export const Checkbox: React.FC<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    CheckboxProps
> = ({ checked, label, validation, className, readOnly, disabled, onChange, ...props }) => {
  const { errorText, successText } = validation ?? {};

  const refInput = useRef<HTMLInputElement>(null);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (readOnly || disabled) {
      return;
    }
    if (isKeyboardClickKey(e.key)) {
      e.preventDefault();
      refInput.current?.click();
    }
  };

  return (
    <div className={classNames("UI-Components CheckboxField", className)}>
      <label
        aria-checked={checked ? "true" : "false"}
        tabIndex={0}
        role="checkbox"
        className={classNames("Checkbox__wrapper", readOnly && "Checkbox__wrapper--readOnly")}
        onKeyDown={onKeyDown}
      >
        <span
          className={classNames(
            "Checkbox__icon",
            checked && "Checkbox__icon--active",
            checked && readOnly && "Checkbox__icon--readOnly",
          )}
        >
          <input
            ref={refInput}
            onChange={readOnly || disabled ? undefined : onChange}
            hidden
            disabled={disabled}
            {...props}
            type="checkbox"
          />
          {checked && <Icon icon="Check" />}
        </span>

        {label && (
          <span
            aria-label={label}
            className={classNames(
              "Checkbox__label",
              successText && "Checkbox__label--success",
              errorText && "Checkbox__label--error",
              readOnly && "Checkbox__label--readOnly",
            )}
          >
            {label}
          </span>
        )}
      </label>

      <FieldValidation {...validation} />
    </div>
  );
};
