import { useActor, useInterpret } from "@xstate/react";
import { TableContext, tableStateMachine } from "libs/ui";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { PatientsPerEntity } from "..";
import { PatientResponseModelListModel, PatientsFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { useHcpDetailsServiceContext } from "pages/HCPPage/Details/Context";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useFilterModal } from "components/AppTables/common/hooks";
import {
  PATIENTS_FILTER_OPTIONS,
  PatientsFilterOptionsKey,
} from "components/AppTables/common/PatientsTable/model";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { getFilterOptionsPerConfiguration } from "components/AppTables/utils";

async function fetchPatients(
  organizationId: string,
  hcpId: string,
  context?: TableContext<PatientResponseModelListModel, PatientsFilterModel>,
) {
  const result = await CarnaApiQuery.Hcps.getPatients({
    organizationId,
    hcpEntityId: hcpId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

const makeTableFetchStateMachine = (organizationId: string, hcpId: string) =>
  tableStateMachine((context?: TableContext<PatientResponseModelListModel, PatientsFilterModel>) =>
    fetchPatients(organizationId, hcpId, context),
  );

export function PatientsPerHCPTable() {
  const { organizationId = "", hcpId = "" } = useParams<RouteParams["hcpDetails"]>();
  const { detailsService } = useHcpDetailsServiceContext();
  const [state] = useActor(detailsService);

  const tableMachine = useMemo(
    () => makeTableFetchStateMachine(organizationId, hcpId),
    [organizationId, hcpId],
  );
  const tableService = useInterpret(tableMachine);
  const { appConfig } = useGlobalConfigContext();

  const filterSideModelState = useFilterModal<Partial<PatientsFilterOptionsKey>>(
    getFilterOptionsPerConfiguration(
      PATIENTS_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Patient.PatientPerHcpTable.filterOptions),
    ),
  );

  return (
    <>
      {state.matches("entity.loaded") ? (
        <PatientsPerEntity
          tableService={tableService}
          filterSideModelState={filterSideModelState}
          hcpsFilter={false}
          patientsFilterOptions={
            appConfig?.components.tables.Patient.PatientPerHcpTable.filterOptions
          }
          initialValuesAsProps={state.context.data}
          appliedColumns={NotNullOrUndefined(
            appConfig?.components.tables.Patient.PatientPerHcpTable.columnList,
          )}
        />
      ) : null}
    </>
  );
}
