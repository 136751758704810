import { useActor } from "@xstate/react";
import { Patient } from "components/Forms/Patient";
import { PatientModel } from "components/Forms/Patient/model";
import { patientFormUtils } from "components/Forms/Patient/patientFormUtils";
import { DetailsCommonPersonForm } from "components/PageTemplate/DetailsPage/DetailsCommonPersonForm";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { Dispatch, PropsWithChildren, SetStateAction, useCallback } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { useTranslation } from "react-i18next";
import { useMakePatientDetailState } from "../model";

interface DetailsFormProps {
  service: ReturnType<typeof useMakePatientDetailState>;
  patientId: string;
  organizationId: string;
  onClose?: () => void;
}

function useEditableFields() {
  const { appConfig } = useGlobalConfigContext();
  const isEmailOptions =
    appConfig?.entities.patient.email.contexts.Update.visibility === "Optional";

  const getEditableFields = useCallback(
    (canManagePatient: boolean) =>
      [
        "firstName",
        "lastName",
        "phone",
        "street",
        "city",
        "country",
        "state",
        "zipCode",
        "gender",
        "ancestry",
        "dateOfBirth",
        "height",
        "weight",
        "nationalityType",
        ...(isEmailOptions ? ["email"] : []),
        ...(canManagePatient
          ? ["registryNumber", "hcpId", "deviceId", "generalPractitionerId", "additionalProperties"]
          : []),
      ] as (keyof PatientModel)[],
    [isEmailOptions],
  );

  return [getEditableFields] as const;
}

function FormElem({
  children,
  service,
  entityData,
  setEntityData,
  onSubmit,
}: PropsWithChildren<{
  service: ReturnType<typeof useMakePatientDetailState>;
  entityData: PatientModel;
  setEntityData: Dispatch<SetStateAction<PatientModel>>;
  onSubmit: (e?: React.FormEvent<Element> | undefined) => void;
}>) {
  const [state] = useActor(service);
  const can = useHasAccess();
  const [getEditableFields] = useEditableFields();

  return (
    <Patient
      formMode={state.matches("entity.editing") ? "Edit" : "Readonly"}
      key="FormPatient"
      entityData={
        state.matches("entity.editing") || state.matches("entity.saving")
          ? entityData
          : // TODO FIXME model
            state.context?.data ?? ({} as any)
      }
      setEntityData={setEntityData}
      onSubmit={onSubmit}
      editableFields={
        state.matches("entity.editing") ? getEditableFields(can("manage-patient")) : []
      }
      loading={state.matches("entity.loading")}
    >
      {children}
    </Patient>
  );
}

export function DetailsForm({
  service,
  patientId,
  organizationId,
  onClose,
}: Readonly<DetailsFormProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "PatientDetails" });

  const { appConfig } = useGlobalConfigContext();

  const getAreFieldsReady = useCallback(
    (value: PatientModel) => patientFormUtils.getFieldsReady(value, "Edit", appConfig),
    [appConfig],
  );

  return (
    <DetailsCommonPersonForm
      avatarParams={{
        personId: patientId ?? "",
        organizationId: organizationId ?? "",
        latestRowVersion:
          service.getSnapshot().context.data?.rowVersion ?? "cannot-find-row-version",
      }}
      getFieldsReady={getAreFieldsReady}
      defaultFormValue={NotNullOrUndefined(appConfig?.forms.defaultValues.patient)}
      detailsService={service}
      FormElem={FormElem}
      t={t}
      onClose={onClose}
    />
  );
}
