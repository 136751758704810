/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatinineModel,
    CreatinineModelFromJSON,
    CreatinineModelFromJSONTyped,
    CreatinineModelToJSON,
} from './CreatinineModel';
import {
    EgfrModel,
    EgfrModelFromJSON,
    EgfrModelFromJSONTyped,
    EgfrModelToJSON,
} from './EgfrModel';

/**
 * 
 * @export
 * @interface UnitsModel
 */
export interface UnitsModel {
    /**
     * 
     * @type {CreatinineModel}
     * @memberof UnitsModel
     */
    creatinine: CreatinineModel;
    /**
     * 
     * @type {EgfrModel}
     * @memberof UnitsModel
     */
    egfr: EgfrModel;
}

export function UnitsModelFromJSON(json: any): UnitsModel {
    return UnitsModelFromJSONTyped(json, false);
}

export function UnitsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnitsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creatinine': CreatinineModelFromJSON(json['Creatinine']),
        'egfr': EgfrModelFromJSON(json['Egfr']),
    };
}

export function UnitsModelToJSON(value?: UnitsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Creatinine': CreatinineModelToJSON(value.creatinine),
        'Egfr': EgfrModelToJSON(value.egfr),
    };
}

