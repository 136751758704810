export type LocalStorageKey = "lastUsedAuth";

export const tryGetLocalStorageValue = <T>(key: LocalStorageKey) => {
  try {
    const value = localStorage.getItem(key);

    if (!value) {
      return undefined;
    }

    return JSON.parse(value) as T;
  } catch (err) {
    console.error("Could not retrieve item from localStorage");
    return undefined;
  }
};
