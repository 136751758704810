import { useActor } from "@xstate/react";
import { useCallback } from "react";
import { TestsTable } from "../common/TestsTable";

import { MeasurementItemType } from "api/event";
import { MeasurementTypes } from "models/TestModels";
import { useSettingsOrgLaboratoryContext } from "pages/Settings/Organizations/Details/LaboratoryContext";
import { TestsTableColumns } from "../common/TestsTable/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";

interface TestsPerOrganizationTableProps {
  appliedColumns: TestsTableColumns[];
  measurementType?: MeasurementTypes;
  measurementTypeItem?: MeasurementItemType;
}

export function TestsPerOrganizationTable({
  appliedColumns,
  measurementType,
  measurementTypeItem,
}: TestsPerOrganizationTableProps) {
  const { tableService } = useSettingsOrgLaboratoryContext();
  const [, send] = useActor(tableService);
  const { appConfig } = useGlobalConfigContext();

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  return (
    <TestsTable
      showTrend={appConfig?.components.tables.Laboratory.Organization.showTrending}
      appliedColumns={appliedColumns}
      testsTableStateContext={{ tableService, fetch }}
      measurementType={measurementType}
      measurementTypeItem={measurementTypeItem}
    />
  );
}
