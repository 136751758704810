/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddNovaMaxMeasurementsHcpModel,
    AddNovaMaxMeasurementsHcpModelFromJSON,
    AddNovaMaxMeasurementsHcpModelFromJSONTyped,
    AddNovaMaxMeasurementsHcpModelToJSON,
} from './AddNovaMaxMeasurementsHcpModel';

/**
 * 
 * @export
 * @interface AddNovaMaxMeasurementsListHcpModel
 */
export interface AddNovaMaxMeasurementsListHcpModel {
    /**
     * 
     * @type {Array<AddNovaMaxMeasurementsHcpModel>}
     * @memberof AddNovaMaxMeasurementsListHcpModel
     */
    measuremenList?: Array<AddNovaMaxMeasurementsHcpModel> | null;
}

export function AddNovaMaxMeasurementsListHcpModelFromJSON(json: any): AddNovaMaxMeasurementsListHcpModel {
    return AddNovaMaxMeasurementsListHcpModelFromJSONTyped(json, false);
}

export function AddNovaMaxMeasurementsListHcpModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNovaMaxMeasurementsListHcpModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measuremenList': !exists(json, 'MeasuremenList') ? undefined : (json['MeasuremenList'] === null ? null : (json['MeasuremenList'] as Array<any>).map(AddNovaMaxMeasurementsHcpModelFromJSON)),
    };
}

export function AddNovaMaxMeasurementsListHcpModelToJSON(value?: AddNovaMaxMeasurementsListHcpModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MeasuremenList': value.measuremenList === undefined ? undefined : (value.measuremenList === null ? null : (value.measuremenList as Array<any>).map(AddNovaMaxMeasurementsHcpModelToJSON)),
    };
}

