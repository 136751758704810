import { Icon, IconType } from "libs/ui/Icon";
import { SingleWidgetBaseProps } from "../model";
import "./WidgetHeader.scss";
import { useTranslation } from "react-i18next";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";

function getIcon(type: MeasurementWidgetType): IconType {
  switch (type) {
    case "SerumCreatinine":
    case "Egfr":
      return "CreatinineEgfr";

    case "Glucose":
      return "Glucose";

    case "Bmi":
      return "Bmi";

    case "Height":
      return "Height";

    case "Weight":
      return "Weight";

    case "BloodPressure":
      return "BloodPressure";

    case "Uacr":
    case "UrineAlbumin":
    case "UrineCreatinine":
    case "SemiQuantitativeUacr":
      return "Uacr";

    default:
      throw new Error(`Icon for ${type} not covered`);
  }
}

interface WidgetHeaderProps extends SingleWidgetBaseProps {
  date?: Date | string | null;
}

export function WidgetHeader({ type, date }: Readonly<WidgetHeaderProps>) {
  const { t } = useTranslation("widgets", { keyPrefix: "MeasurementsWidget" });

  const [dateFormatter] = useRegionDateTimeFormatter();

  return (
    <div className="WidgetHeader">
      <Icon icon={getIcon(type)} />
      <h3 className="WidgetHeader__title">{t(type)}</h3>
      {date ? <span className="WidgetHeader__date">{dateFormatter(date)}</span> : null}
    </div>
  );
}
