import { PropsWithChildren, ReactNode } from "react";
import "./PatientOrganizationalField.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { renderValidElement } from "libs/ui";
import i18n from "i18next";

interface ConditionalLinkProps {
  linkPath: string | undefined;
}

function ConditionalLink({ linkPath, children }: PropsWithChildren<ConditionalLinkProps>) {
  return linkPath ? <Link to={linkPath}>{children}</Link> : children;
}

interface PatientInfoFieldProps {
  label: string;
  linkPath: string | undefined;
  value: string | null | undefined;
  leadingElement?: ReactNode;
  additionalValue?: string;
}

export function PatientOrganizationalField({
  label,
  linkPath,
  value,
  leadingElement,
  additionalValue,
}: Readonly<PatientInfoFieldProps>) {
  return (
    <div className="PatientOrganizationalField">
      <label className="PatientOrganizationalField__label">{label}</label>

      <div className="PatientOrganizationalField__element">
        {leadingElement && value
          ? renderValidElement(
              <div className="PatientOrganizationalField__leadingElement">{leadingElement}</div>,
            )
          : null}
        <div>
          <ConditionalLink linkPath={linkPath}>
            <p
              className={classNames("PatientOrganizationalField__value", {
                "PatientOrganizationalField__value--active": value && linkPath,
              })}
            >
              {!value ? i18n.t("PatientInfoWidget.na", { ns: "widgets" }) : value}
            </p>
          </ConditionalLink>
          {additionalValue && value ? (
            <p className="PatientOrganizationalField__additionalValue">{additionalValue}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
}
