/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PatientAgeRange = {
    NotAvailable: 'NotAvailable',
    From18To24: 'From18To24',
    From25To29: 'From25To29',
    From30To39: 'From30To39',
    From40To49: 'From40To49',
    From50To59: 'From50To59',
    From60To69: 'From60To69',
    From70To79: 'From70To79',
    From80To89: 'From80To89',
    From90To99: 'From90To99',
    From100: 'From100'
} as const;
export type PatientAgeRange = typeof PatientAgeRange[keyof typeof PatientAgeRange];


export function PatientAgeRangeFromJSON(json: any): PatientAgeRange {
    return PatientAgeRangeFromJSONTyped(json, false);
}

export function PatientAgeRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientAgeRange {
    return json as PatientAgeRange;
}

export function PatientAgeRangeToJSON(value?: PatientAgeRange | null): any {
    return value as any;
}

