import { Configuration, DashboardsApi } from "api/report_service";
import { querystring } from "./endpointSerialization";
import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from "./const";
import { injectUnauthorized } from "components/Unathorized";
import { Auth } from "aws-amplify";

let checkingForForbiddenUser: Promise<any> | null = null;

export const configurationAuthApi = new Configuration({
  basePath: `${process.env.VITE_APP_REPORT_SERVICE_HOST ?? "VITE_APP_REPORT_SERVICE_HOST-env-variable-missing"}`,
});

export const configuration =
  process.env.MODE === "test"
    ? undefined
    : new Configuration({
        /**
         * check this function in runtime.ts
         */
        queryParamsStringify: querystring,
        basePath: `${
          process.env.VITE_APP_REPORT_SERVICE_HOST ??
          "VITE_APP_REPORT_SERVICE_HOST-env-variable-missing"
        }`,
        // (input: RequestInfo | URL, init?: RequestInit): Promise<Response>
        middleware: [
          {
            async post(param) {
              if (param.response.status === HTTP_UNAUTHORIZED) {
                injectUnauthorized();
                return param.response;
              }

              if (param.response.status === HTTP_FORBIDDEN) {
                // Avoiding concurrency
                if (checkingForForbiddenUser) {
                  await checkingForForbiddenUser;
                } else {
                  try {
                    checkingForForbiddenUser = Auth.currentAuthenticatedUser({
                      bypassCache: true,
                    });
                    await checkingForForbiddenUser;
                  } catch (error) {
                    console.warn("User is possibly logged out");
                  } finally {
                    checkingForForbiddenUser = null;
                  }
                }
              }

              return param.response;
            },
            async pre(context) {
              return {
                url: context.url,
                init: {
                  ...context.init,
                  headers: new Headers({
                    ...context.init.headers,
                    // ...sign.headers,
                    "access-token": `${(await Auth.currentSession())
                      .getAccessToken()
                      .getJwtToken()}`,
                    "identity-token": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                  }),
                },
              };
            },
          },
        ],
      });

const DashboardsApiContext = new DashboardsApi(configuration);

export const CarnaApiReportService = {
  DashboardsMap: {
    get: DashboardsApi.prototype.dashboardsMapsIdentifiedWithCkdRiskGet.bind(DashboardsApiContext),
  } as const,
} as const;

declare global {
  interface Window {
    ApiReportService: any;
  }
}

if (import.meta.env.MODE === "development") {
  window.ApiReportService = CarnaApiReportService;
}
