/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryType,
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import {
    StateType,
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';

/**
 * 
 * @export
 * @interface UpdateOrganizationRequestModel
 */
export interface UpdateOrganizationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequestModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequestModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequestModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof UpdateOrganizationRequestModel
     */
    country: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof UpdateOrganizationRequestModel
     */
    state?: StateType;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequestModel
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequestModel
     */
    phone: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrganizationRequestModel
     */
    rowVersion?: number | null;
}

export function UpdateOrganizationRequestModelFromJSON(json: any): UpdateOrganizationRequestModel {
    return UpdateOrganizationRequestModelFromJSONTyped(json, false);
}

export function UpdateOrganizationRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrganizationRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['Name'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : StateTypeFromJSON(json['State']),
        'zipCode': json['ZipCode'],
        'phone': json['Phone'],
        'rowVersion': !exists(json, 'RowVersion') ? undefined : json['RowVersion'],
    };
}

export function UpdateOrganizationRequestModelToJSON(value?: UpdateOrganizationRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Name': value.name,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': StateTypeToJSON(value.state),
        'ZipCode': value.zipCode,
        'Phone': value.phone,
        'RowVersion': value.rowVersion,
    };
}

