import { useInterpret } from "@xstate/react";
import { PatientResponseModel } from "api/query";
import { NEW_BACKEND_NULL } from "config/NEW_BACKEND";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { useCallback, useMemo } from "react";
import { getRowVersion } from "utils/helpers/getRowVersion";
import {
  ConfirmStatusEvent,
  DetailsPageStateContext,
  makeDetailsPageStateMachine,
  SaveEvent,
} from "utils/machines/pages/details/makeDetailsPageStateMachine";
import { DetailsPageServiceList } from "utils/machines/pages/details/model";
import { assign } from "xstate";
import { firstValueFrom } from "rxjs";
import {
  OnEventStatusSubscribeError,
  waitForQueryService,
} from "utils/hooks/useOnEventStatusSubscribe";
import { PatientModel } from "components/Forms/Patient/model";
import { BEDateOfBirthToDate, getDBDateFormat } from "utils/converters/getDBDateFormat";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { UpdatePatientRequestModelForStripping } from "config/binding";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";

function useApis(patientId: string, selectedUserOrganizationId: string) {
  const getPatient = useCallback(async (): Promise<PatientResponseModelFixed> => {
    const result = CarnaApiQuery.Patients.getById({
      organizationId: selectedUserOrganizationId,
      patientEntityId: patientId,
    });
    const data = await result;
    return { ...data, dateOfBirth: BEDateOfBirthToDate(data.dateOfBirth) } as any;
  }, [patientId, selectedUserOrganizationId]);

  const savePatient = useCallback(
    async (
      context: DetailsPageStateContext<NonNullable<PatientResponseModel>>,
      event: SaveEvent<PatientModel>,
    ) => {
      const putPatient = CarnaApiEvent.Patient.put(
        {
          organizationId: event.value.organizationId,
          userEntityId: context.data?.id ?? "",
          updatePatientRequestModel: {
            ...event.value,
            dateOfBirth: getDBDateFormat(event.value.dateOfBirth),
            rowVersion: event.value.rowVersion,
            ancestry: event.value.ancestry ?? undefined,
            nationalityType: event.value.nationalityType ?? undefined,
          },
        },
        stripNetworkBodyWith(UpdatePatientRequestModelForStripping),
      );

      await firstValueFrom(waitForQueryService(putPatient));

      const result = await putPatient;

      return {
        ...context.data,
        ...event.value,
        dateOfBirth: event.value.dateOfBirth as any,
        ...getRowVersion(result),
      } as PatientResponseModelFixed;
    },
    [],
  );

  const savePatientStatus = useCallback(
    (
      context: DetailsPageStateContext<NonNullable<PatientResponseModel>>,
      event: ConfirmStatusEvent,
    ): Promise<PatientResponseModelFixed> => {
      return NEW_BACKEND_NULL;
    },

    [],
  );

  return {
    fetchPatientData: getPatient,
    savePatient,
    savePatientStatus,
  } as const;
}

// export function useMakePatientDetailAvatarState(patientId: string, organizationId: string) {
//   const { fetchAvatar } = useApis(patientId, organizationId);
//   return useMakePagesDetailAvatarState(fetchAvatar);
// }

const successToast = () =>
  toastStore.pushToast({
    expire: 5000,
    type: "success",
    msg: i18n.t("PatientDetails.successPutToast", { ns: "translation" }),
  });

const errorPutToast = (_context: any, event: any) => {
  const err = event.data as OnEventStatusSubscribeError;

  if (err.type === "OnEventStatusSubscribeError") {
    switch (err.code) {
      case "BE_ERROR":
        showBeFieldErrors(err.err, i18n.t("PatientDetails.errorPutToast", { ns: "translation" }));

        break;
      case "ACTION_FAILED":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("PatientDetails.errorPutToast", { ns: "translation" }),
        });
        break;
      case "STATUS_QUERY_ERROR":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("PatientDetails.queryServiceError", { ns: "translation" }),
        });
    }
    return;
  }

  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("PatientDetails.errorPutToast", { ns: "translation" }),
  });
};

const errorFetchToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("PatientDetails.errorFetchToast", { ns: "translation" }),
  });
};

type PatientResponseModelFixed = PatientResponseModel & {
  dateOfBirth: Date;
};

export function useMakePatientDetailState(patientId: string, organizationId: string) {
  const { fetchPatientData, savePatient, savePatientStatus } = useApis(patientId, organizationId);

  const patientPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        NonNullable<PatientResponseModelFixed>,
        PatientModel,
        DetailsPageServiceList<
          typeof fetchPatientData,
          typeof savePatient,
          typeof savePatientStatus
        >
      >(),
    [],
  );

  const service = useInterpret(patientPageStateMachine, {
    services: {
      saveData: savePatient,
      fetchData: fetchPatientData,
      saveStatus: savePatientStatus,
    },
    actions: {
      dataSaved: successToast,
      savingFailed: errorPutToast,
      failedToLoadData: errorFetchToast,
      loadSavedData: assign({
        data: (_context, event) => event.data,
      }),
      loadEntityData: assign({
        data: (_context, event) => event.data,
      }),
      updateSaveStatus: assign({
        data: (context, event) => {
          return {
            ...context.data,
            // status: event.data?.data?.isActive ? "Active" : "Deactivated",
          } as PatientResponseModelFixed;
        },
      }),
      refreshTable: () => {},
    },
  });

  return service;
}
