/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    UserDetailsResponseModelApiResponse,
    UserDetailsResponseModelApiResponseFromJSON,
    UserDetailsResponseModelApiResponseToJSON,
    UserListResponseModelApiResponse,
    UserListResponseModelApiResponseFromJSON,
    UserListResponseModelApiResponseToJSON,
    UsersFilter,
    UsersFilterFromJSON,
    UsersFilterToJSON,
} from '../models';

export interface UsersGetRequest {
    limit: number;
    paginationToken?: string;
    filters?: UsersFilter;
}

export interface UsersIdGetRequest {
    id: string;
}

/**
 * UsersApi - interface
 * 
 * @export
 * @interface UsersApiInterface
 */
export interface UsersApiInterface {
    /**
     * 
     * @param {number} limit 
     * @param {string} [paginationToken] 
     * @param {UsersFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersGetRaw(requestParameters: UsersGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserListResponseModelApiResponse>>;

    /**
     */
    usersGet(limit: number, paginationToken?: string, filters?: UsersFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersIdGetRaw(requestParameters: UsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>>;

    /**
     */
    usersIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse>;

}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI implements UsersApiInterface {

    /**
     */
    async usersGetRaw(requestParameters: UsersGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling usersGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async usersGet(limit: number, paginationToken?: string, filters?: UsersFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserListResponseModelApiResponse> {
        const response = await this.usersGetRaw({ limit: limit, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersIdGetRaw(requestParameters: UsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async usersIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse> {
        const response = await this.usersIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

}
