import { useEffect, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router/model";
import { gridContainer } from "utils/helpers/gridContainer";
import "./DashboardStats.scss";
import { useDashboardStatsContext } from "./DashboardStatsContext";
import { HeatMapWidget } from "./HeatMapWidget";
import { MeasurementWidgets } from "./MeasurementWidgets";
import { PatientInfoWidget } from "./PatientInfoWidget";

export function DashboardStats() {
  const gridDivRef = useRef<HTMLDivElement | null>(null);
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientDetails"]>();
  const { getWidgetData } = useDashboardStatsContext();

  useEffect(() => {
    getWidgetData(organizationId, patientId, [
      "HeatMap",
      "PatientInfo",
      "SerumCreatinine",
      "Egfr",
      // "Height", // ! Latest requirement don't want height to be in the rendered widget list
      "Weight",
      "Bmi",
      "Glucose",
      "BloodPressure",
      "UrineCreatinine",
      "UrineAlbumin",
      "Uacr",
      "SemiQuantitativeUacr",
    ]);
  }, [getWidgetData, organizationId, patientId]);

  useLayoutEffect(() => {
    gridContainer.initialize(gridDivRef.current!);
  }, []);

  return (
    <div
      className="DashboardStats"
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(50, 120px)`,
        gridTemplateRows: `repeat(50, 120px)`,
        // gridTemplateColumns: `repeat(12, minmax(0, 12))`,
        gap: "16px",
        gridAutoRows: "120px",
        width: "calc((120px + 16px)* 50)",
      }}
      ref={ref => {
        gridDivRef.current = ref;
      }}
    >
      <HeatMapWidget organizationId={organizationId} patientId={patientId} />
      <PatientInfoWidget organizationId={organizationId} patientId={patientId} />
      <MeasurementWidgets organizationId={organizationId} patientId={patientId} />
    </div>
  );
}
