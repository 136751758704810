import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { useClearAndReloadIfSameUser } from "./useClearAndReloadIfSameUser";
import { ResendInviteModal } from "components/AppModals/ResendInviteModal";

import { useActor } from "@xstate/react";
import { InterpreterFrom } from "xstate";
import { tableStateMachine } from "libs/ui";
import { PersonResponseModel } from "components/AppTables/common/model";
import { UpdatePersonStatusModal } from "components/AppModals/UpdatePersonStatusModal";

export function useChangeStatusModal<T extends PersonResponseModel>(
  tableService: InterpreterFrom<ReturnType<typeof tableStateMachine>>,
  handlerCallbacks?: {
    onSuccessCb?: () => void;
    onErrorCb?: () => void;
  },
) {
  const [person, setPerson] = useState<T>();
  const [showModal, setShowModal] = useState(false);

  const [, send] = useActor(tableService);
  const [clearAndReloadIfSameUser] = useClearAndReloadIfSameUser();

  const toggleModal = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    setShowModal(prevValue => !prevValue);
  }, []);

  const changeStateClick = useCallback(
    (user: T): MouseEventHandler<HTMLButtonElement> =>
      e => {
        e.stopPropagation();
        setPerson(user);
        setShowModal(true);
      },
    [],
  );

  const onChangeStatusSuccess = useCallback(() => {
    clearAndReloadIfSameUser(person?.id);
    handlerCallbacks?.onSuccessCb?.();
    toggleModal();
    send("FETCH");
  }, [clearAndReloadIfSameUser, handlerCallbacks, person?.id, send, toggleModal]);

  const ChangeStateModal = useMemo(() => {
    if (person?.status === "Invited" && person) {
      return (
        <ResendInviteModal
          show={showModal}
          onSuccess={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          user={person}
        />
      );
    }

    return (
      <UpdatePersonStatusModal
        firstName={person?.firstName}
        lastName={person?.lastName}
        id={person?.id ?? ""}
        organizationId={person?.organizationId ?? ""}
        role={person?.role ?? "Admin"}
        status={person?.status ?? "Active"}
        show={showModal}
        onCloseModal={toggleModal}
        onSuccess={onChangeStatusSuccess}
      />
    );
  }, [onChangeStatusSuccess, person, showModal, toggleModal]);

  return {
    changeStateClick,
    ChangeStateModal,
  } as const;
}
