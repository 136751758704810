import * as echarts from "echarts/core";
import { PropsWithoutRef, useEffect, useMemo, useRef } from "react";

import { EChartsOption } from "echarts";
import { BarChart } from "echarts/charts";
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from "echarts/components";
import { SVGRenderer } from "echarts/renderers";
import classNames from "classnames";
import { chartPerEntitiesTooltipFormatter } from "./tooltip";

echarts.use([
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  SVGRenderer,
  BarChart,
  DatasetComponent,
  TransformComponent,
]);

interface ChartPerEntitiesProps {
  data: ReadonlyArray<{
    entityName: string;
    categories: {
      [key: string]: number;
    };
  }>;
  className?: string;
}

export function ChartPerEntities({ data, className }: PropsWithoutRef<ChartPerEntitiesProps>) {
  const chartRef = useRef<echarts.ECharts>();
  const chartDivRef = useRef<HTMLDivElement | null>(null);

  const options = useMemo(() => {
    const dataSetDimensions = [
      "entityName",
      ...Object.entries(data[0]?.categories ?? {}).map(([categoryName]) => categoryName),
    ];

    const colorIndex = ["--primary-500", "--secondary-500"];

    const series: EChartsOption["series"] = Array.from(
      { length: dataSetDimensions.length - 1 },
      (_, idx) => ({
        type: "bar",
        barMaxWidth: 16, // how fat one bar will be
        barGap: 0.5,
        barMinHeight: 1,
        borderRadius: [50, 50, 50, 50],
        // ! this datasetIndex is depending on the DATASET
        // ! Example if i would not use transform sort, i would need to use datasetIndex: 0 in this case !
        // ! it is like a pipeline
        datasetIndex: 1,
        emphasis: {
          disabled: true,
        },
        itemStyle: {
          color: `var(${colorIndex[idx] ?? colorIndex[0]})`,
          borderRadius: [0, 3, 3, 0],
        },
      }),
    );

    return {
      // title: {
      //   text: "",
      // },
      tooltip: {
        trigger: "axis",
        confine: true,
        axisPointer: {
          type: "shadow",
        },
        padding: 0,
        shadowColor: "transparent",
        formatter: chartPerEntitiesTooltipFormatter,
      },
      //   legend: {},
      grid: {
        left: "90",
        right: "20",
        bottom: "0",
        top: "20",
      },
      dataset: [
        {
          dimensions: dataSetDimensions,
          source: data.map(entity => [entity.entityName, ...Object.values(entity.categories)]),
        },
        {
          transform: [
            {
              type: "sort",
              config: { dimension: "entityName", order: "desc" },
            },
          ],
        },
      ],
      xAxis: {
        type: "value",
        position: "top",
        axisLabel: {
          color: "var(--grey-600)",
          fontFamily: "Poppins",
          fontWeight: "400" as any,
          fontSize: "12",
        },
        splitLine: {
          show: true, // Make sure split lines are visible
          lineStyle: {
            color: "var(--grey-200)", // Set the color to red
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "var(--grey-200)",
          },
        },
      },
      dataZoom: [
        {
          id: "dataZoomY",
          type: "inside",
          yAxisIndex: [0],
          filterMode: "empty",
          minValueSpan: 5,
          maxValueSpan: 5,
          zoomLock: false,
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true,
        },
        {
          id: "dataZoomY2",
          type: "slider",
          yAxisIndex: [0],
          filterMode: "empty",
          brushSelect: false,
          minValueSpan: 5,
          maxValueSpan: 5,
          width: 5,
          zoomLock: false,
          showDataShadow: false,
        },
      ],
      yAxis: {
        axisLabel: {
          formatter: function (value) {
            const maxLength = 6; // Adjust the maximum length per line as needed
            const words = value.split(" ");
            let newLabel = "";

            for (const word of words) {
              if (newLabel.length + word.length > maxLength) {
                newLabel += "\n" + word;
              } else {
                newLabel += " " + word;
              }
            }

            return newLabel.trim();
          },
          color: "var(--grey-600)",
          fontFamily: "Poppins",
          fontWeight: "400" as any,
          fontSize: "12",
          lineHeight: 18,
        },
        axisLine: { show: false },
        axisTick: { show: false },
        type: "category",
      },
      series,
    } satisfies EChartsOption;
  }, [data]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    function resize() {
      chartRef.current?.resize();
    }

    if (
      chartDivRef.current &&
      // chartDivRef.current.checkVisibility() && // Safari does not support this
      chartDivRef.current.clientHeight &&
      chartDivRef.current.clientWidth
    ) {
      const observer = new ResizeObserver(resize);
      const observedElement = chartDivRef.current;

      observer.observe(observedElement, { box: "border-box" });

      return () => {
        observer.unobserve(observedElement);
        observer.disconnect();
      };
    }

    /**
     * ! We need this to be trigger after first useEffect
     * ! those extra options are necessary
     */
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.setOption(options, { lazyUpdate: true, silent: true });
    }
  }, [options]);

  const onInit = (ref: HTMLDivElement | null) => {
    if (!!ref && !!chartDivRef.current === false && !!chartRef.current === false) {
      const observer = new ResizeObserver(() => {
        // if (ref.checkVisibility() // Safari does not support checkVisibility && ref.clientHeight && ref.clientWidth) {
        if (ref && ref.clientHeight && ref.clientWidth) {
          const chart = echarts.init(ref, null, {
            renderer: "svg",
          });

          chartRef.current = chart;
          chartRef.current.setOption(options, { lazyUpdate: true, silent: true });
          // chart.on("init", () => {
          //   chart.setOption(options, {
          //     lazyUpdate: true,
          //     silent: true,
          //     notMerge: true,
          //     replaceMerge: "dataset",
          //   });
          // });
          observer.unobserve(ref);
          observer.disconnect();
        }
      });
      observer.observe(ref);
      chartDivRef.current = ref;
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }} ref={onInit} className={classNames(className)} />
  );
}
