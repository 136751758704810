import { FilterSection } from "components/AppTables/common/Filter/FilterSection";
import { OrganizationsFilterSection } from "components/AppTables/common/Filter/OrganizationsFilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { DevicesTableFilterProps, DEVICES_FILTER_OPTIONS } from "../model";

export function DevicesTableFilter({
  tableService,
  filterSideModelState,
}: Readonly<DevicesTableFilterProps>) {
  const { t: tColumns } = useTranslation("translation", { keyPrefix: "DevicesTable.columns" });
  const { isAdmin } = useCurrentUserData();

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({ activeFilters, appliedFilters, onNewFilterApplied }) => (
        <>
          {activeFilters.map(el => {
            if (el === "contains") {
              return null;
            }

            if (el === "organizationIds") {
              return isAdmin ? (
                <OrganizationsFilterSection
                  key={el}
                  checkedValues={appliedFilters[el] ?? []}
                  onFilterApply={value => onNewFilterApplied(el, value)}
                />
              ) : null;
            }

            return (
              <FilterSection
                key={el}
                title={tColumns(el)}
                options={DEVICES_FILTER_OPTIONS()[el]}
                checkedValues={appliedFilters[el] ?? []}
                onFilterApply={value => onNewFilterApplied(el, value)}
              />
            );
          })}
        </>
      )}
    </TableFilter>
  );
}
