/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeasurementResponseModel,
    MeasurementResponseModelFromJSON,
    MeasurementResponseModelFromJSONTyped,
    MeasurementResponseModelToJSON,
} from './MeasurementResponseModel';

/**
 * 
 * @export
 * @interface AddMeasurementsResponseModel
 */
export interface AddMeasurementsResponseModel {
    /**
     * 
     * @type {Array<MeasurementResponseModel>}
     * @memberof AddMeasurementsResponseModel
     */
    insertedMeasurements?: Array<MeasurementResponseModel> | null;
    /**
     * 
     * @type {Array<MeasurementResponseModel>}
     * @memberof AddMeasurementsResponseModel
     */
    failedMeasurements?: Array<MeasurementResponseModel> | null;
    /**
     * 
     * @type {Array<MeasurementResponseModel>}
     * @memberof AddMeasurementsResponseModel
     */
    invalidMeasurements?: Array<MeasurementResponseModel> | null;
}

export function AddMeasurementsResponseModelFromJSON(json: any): AddMeasurementsResponseModel {
    return AddMeasurementsResponseModelFromJSONTyped(json, false);
}

export function AddMeasurementsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddMeasurementsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insertedMeasurements': !exists(json, 'InsertedMeasurements') ? undefined : (json['InsertedMeasurements'] === null ? null : (json['InsertedMeasurements'] as Array<any>).map(MeasurementResponseModelFromJSON)),
        'failedMeasurements': !exists(json, 'FailedMeasurements') ? undefined : (json['FailedMeasurements'] === null ? null : (json['FailedMeasurements'] as Array<any>).map(MeasurementResponseModelFromJSON)),
        'invalidMeasurements': !exists(json, 'InvalidMeasurements') ? undefined : (json['InvalidMeasurements'] === null ? null : (json['InvalidMeasurements'] as Array<any>).map(MeasurementResponseModelFromJSON)),
    };
}

export function AddMeasurementsResponseModelToJSON(value?: AddMeasurementsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'InsertedMeasurements': value.insertedMeasurements === undefined ? undefined : (value.insertedMeasurements === null ? null : (value.insertedMeasurements as Array<any>).map(MeasurementResponseModelToJSON)),
        'FailedMeasurements': value.failedMeasurements === undefined ? undefined : (value.failedMeasurements === null ? null : (value.failedMeasurements as Array<any>).map(MeasurementResponseModelToJSON)),
        'InvalidMeasurements': value.invalidMeasurements === undefined ? undefined : (value.invalidMeasurements === null ? null : (value.invalidMeasurements as Array<any>).map(MeasurementResponseModelToJSON)),
    };
}

