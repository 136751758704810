/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddDeviceRequestModel,
    AddDeviceRequestModelFromJSON,
    AddDeviceRequestModelToJSON,
    DeviceDetailsResponseModelApiResponse,
    DeviceDetailsResponseModelApiResponseFromJSON,
    DeviceDetailsResponseModelApiResponseToJSON,
    DeviceListResponseModelApiResponse,
    DeviceListResponseModelApiResponseFromJSON,
    DeviceListResponseModelApiResponseToJSON,
    DevicesFilter,
    DevicesFilterFromJSON,
    DevicesFilterToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
} from '../models';

export interface OrganizationsOrganizationIdDevicesGetRequest {
    limit: number;
    organizationId: string;
    paginationToken?: string;
    filters?: DevicesFilter;
}

export interface OrganizationsOrganizationIdDevicesIdGetRequest {
    id: string;
    organizationId: string;
}

export interface OrganizationsOrganizationIdDevicesPostRequest {
    organizationId: string;
    AddDeviceRequestModel?: AddDeviceRequestModel;
}

export interface OrganizationsOrganizationIdPatientsIdDevicesGetRequest {
    limit: number;
    organizationId: string;
    id: string;
    paginationToken?: string;
    filters?: DevicesFilter;
}

/**
 * DevicesApi - interface
 * 
 * @export
 * @interface DevicesApiInterface
 */
export interface DevicesApiInterface {
    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} [paginationToken] 
     * @param {DevicesFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    organizationsOrganizationIdDevicesGetRaw(requestParameters: OrganizationsOrganizationIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdDevicesGet(limit: number, organizationId: string, paginationToken?: string, filters?: DevicesFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    organizationsOrganizationIdDevicesIdGetRaw(requestParameters: OrganizationsOrganizationIdDevicesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdDevicesIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {AddDeviceRequestModel} [AddDeviceRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    organizationsOrganizationIdDevicesPostRaw(requestParameters: OrganizationsOrganizationIdDevicesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdDevicesPost(organizationId: string, AddDeviceRequestModel?: AddDeviceRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {string} [paginationToken] 
     * @param {DevicesFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    organizationsOrganizationIdPatientsIdDevicesGetRaw(requestParameters: OrganizationsOrganizationIdPatientsIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdDevicesGet(limit: number, organizationId: string, id: string, paginationToken?: string, filters?: DevicesFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceListResponseModelApiResponse>;

}

/**
 * 
 */
export class DevicesApi extends runtime.BaseAPI implements DevicesApiInterface {

    /**
     */
    async organizationsOrganizationIdDevicesGetRaw(requestParameters: OrganizationsOrganizationIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdDevicesGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/devices`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesGet(limit: number, organizationId: string, paginationToken?: string, filters?: DevicesFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdDevicesGetRaw({ limit: limit, organizationId: organizationId, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdDevicesIdGetRaw(requestParameters: OrganizationsOrganizationIdDevicesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdDevicesIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/devices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdDevicesIdGetRaw({ id: id, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdDevicesPostRaw(requestParameters: OrganizationsOrganizationIdDevicesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceDetailsResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/devices`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddDeviceRequestModelToJSON(requestParameters.AddDeviceRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesPost(organizationId: string, AddDeviceRequestModel?: AddDeviceRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdDevicesPostRaw({ organizationId: organizationId, AddDeviceRequestModel: AddDeviceRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdDevicesGetRaw(requestParameters: OrganizationsOrganizationIdPatientsIdDevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdPatientsIdDevicesGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdDevicesGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdDevicesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}/devices`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdDevicesGet(limit: number, organizationId: string, id: string, paginationToken?: string, filters?: DevicesFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdDevicesGetRaw({ limit: limit, organizationId: organizationId, id: id, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

}
