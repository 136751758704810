/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';
import {
    RulesResponseModel,
    RulesResponseModelFromJSON,
    RulesResponseModelFromJSONTyped,
    RulesResponseModelToJSON,
} from './RulesResponseModel';

/**
 * 
 * @export
 * @interface RulesResponseModelApiResponse
 */
export interface RulesResponseModelApiResponse {
    /**
     * 
     * @type {RulesResponseModel}
     * @memberof RulesResponseModelApiResponse
     */
    data?: RulesResponseModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof RulesResponseModelApiResponse
     */
    errors?: Array<ApiError> | null;
}

export function RulesResponseModelApiResponseFromJSON(json: any): RulesResponseModelApiResponse {
    return RulesResponseModelApiResponseFromJSONTyped(json, false);
}

export function RulesResponseModelApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RulesResponseModelApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : RulesResponseModelFromJSON(json['Data']),
        'errors': !exists(json, 'Errors') ? undefined : (json['Errors'] === null ? null : (json['Errors'] as Array<any>).map(ApiErrorFromJSON)),
    };
}

export function RulesResponseModelApiResponseToJSON(value?: RulesResponseModelApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': RulesResponseModelToJSON(value.data),
        'Errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(ApiErrorToJSON)),
    };
}

