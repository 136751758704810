import { useActor } from "@xstate/react";
import classNames from "classnames";
import { FilterSearchTogglerBar } from "components/FilterSearchTogglerBar";
import { BackendResponse, Table } from "libs/ui";
import { FetchingErrorPage } from "pages/ErrorBoundary/FetchingErrorPage";
import { PropsWithChildren } from "react";
import { SyncTableButton } from "../../SyncTableButton";
import { BaseTableProps } from "../model";
import { TheadSlot } from "../TheadSlot";
import "./BaseTable.scss";

export function BaseTable<T extends BackendResponse, Filters extends Record<string, any>>({
  tableService,
  searchField,
  onFilterToggle,
  addButton,
  className,
  children,
  tableContainerAdditionalChildren,
  innerRef,
  viewTableSelector = false,
  showFilterButton = true,
  ...theadProps
}: PropsWithChildren<BaseTableProps<T, Filters>>) {
  const [state, send] = useActor(tableService);
  const isTableLoading = state.matches("loading") || state.matches("init");
  const withFilterBar = !!searchField || !!onFilterToggle || !!addButton;

  if (state.matches("failure")) {
    return <FetchingErrorPage fetchingFunc={() => send("RETRY")} />;
  }

  return (
    <div
      className={classNames(
        "BaseTable",
        { "BaseTable--loading": isTableLoading },
        { "BaseTable--withFilters": withFilterBar && !isTableLoading },
      )}
    >
      {!isTableLoading && (withFilterBar || addButton) ? (
        <div className="BaseTable__filterBar">
          {addButton || null}
          {withFilterBar ? (
            <>
              <FilterSearchTogglerBar
                searchField={searchField}
                onFilterToggle={onFilterToggle}
                filtersCount={state.context.countFilters}
                showFilterButton={showFilterButton}
              />
              <SyncTableButton className="SyncButton" onClick={() => send("FETCH")} />
            </>
          ) : null}
        </div>
      ) : null}

      <Table
        data-state={state.value}
        innerRef={innerRef}
        loading={isTableLoading}
        paginator
        rowsPerPageSelector
        viewTableSelector={viewTableSelector}
        context={state.context}
        send={send}
        className={classNames(className)}
        data-testid={className}
        data-loading={isTableLoading}
        data-loaded={state.matches("loaded")}
        theadSlot={<TheadSlot {...theadProps} />}
        tableContainerAdditionalChildren={tableContainerAdditionalChildren}
      >
        {children}
      </Table>
    </div>
  );
}
