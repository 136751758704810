/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeasurementResponseModel,
    MeasurementResponseModelFromJSON,
    MeasurementResponseModelFromJSONTyped,
    MeasurementResponseModelToJSON,
} from './MeasurementResponseModel';

/**
 * 
 * @export
 * @interface MeasurementListResponseModel
 */
export interface MeasurementListResponseModel {
    /**
     * 
     * @type {string}
     * @memberof MeasurementListResponseModel
     */
    paginationToken?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MeasurementListResponseModel
     */
    limit?: number;
    /**
     * 
     * @type {Array<MeasurementResponseModel>}
     * @memberof MeasurementListResponseModel
     */
    measurements?: Array<MeasurementResponseModel> | null;
}

export function MeasurementListResponseModelFromJSON(json: any): MeasurementListResponseModel {
    return MeasurementListResponseModelFromJSONTyped(json, false);
}

export function MeasurementListResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementListResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationToken': !exists(json, 'PaginationToken') ? undefined : json['PaginationToken'],
        'limit': !exists(json, 'Limit') ? undefined : json['Limit'],
        'measurements': !exists(json, 'Measurements') ? undefined : (json['Measurements'] === null ? null : (json['Measurements'] as Array<any>).map(MeasurementResponseModelFromJSON)),
    };
}

export function MeasurementListResponseModelToJSON(value?: MeasurementListResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PaginationToken': value.paginationToken,
        'Limit': value.limit,
        'Measurements': value.measurements === undefined ? undefined : (value.measurements === null ? null : (value.measurements as Array<any>).map(MeasurementResponseModelToJSON)),
    };
}

