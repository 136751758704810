import { PatientResponseModel } from "api/query";
import { SelectFilterAsyncNoData } from "components/SelectFilterAsyncNoData";
import { SelectFilterAsyncWithCancel } from "components/SelectFilterAsyncWithCancel";
import { CarnaApiQuery } from "config/apiQuery";
import { FieldValidationProps, FormFieldBasic, SelectFilterResult } from "libs/ui";
import { createSelectFilterAsyncOption } from "libs/ui/SelectFilterAsync/SelectFilterAsync.model";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";

interface PatientsFieldProps extends FormFieldBasic, FieldValidationProps {
  initialValue?: string;
  readOnly?: boolean;
  organizationId: string | undefined | null;
  onSelect: (value: PatientResponseModel | undefined) => void;
  loading?: boolean;
}

export function PatientsField({
  initialValue,
  organizationId,
  readOnly,
  onSelect,
  loading,
}: PatientsFieldProps) {
  const { t: tForm } = useTranslation("translation", { keyPrefix: "Form" });
  const { t: tComponents } = useTranslation("translation", { keyPrefix: "components" });
  const { currentUserId } = useCurrentUserData();

  const fetchPatients = useMemo(
    () =>
      createSelectFilterAsyncOption(async (filter?: string) => {
        const data = await CarnaApiQuery.Hcps.getPatients({
          organizationId: organizationId ?? "",
          hcpEntityId: currentUserId ?? "",
          filters: { contains: filter, userStatuses: ["Active", "Invited"] },
          limit: 100,
          page: 1,
        });

        return (
          data.items.map(patient => ({
            title: `${patient.firstName} ${patient.lastName}`,
            value: patient,
          })) ?? []
        );
      }),
    [currentUserId, organizationId],
  );

  const setRenderSelected = useCallback(
    (props?: PatientResponseModel | undefined) => {
      const hasNoPropsAndInitValue = !props && !initialValue;
      const patientsName =
        !!props?.firstName && !!props?.lastName
          ? `${props?.firstName} ${props?.lastName}`
          : initialValue;

      return (
        <SelectFilterResult
          data-testval={JSON.stringify({ patientsName: `${props?.firstName} ${props?.lastName}` })}
          hasValue={!!(props ?? initialValue)}
          label={tForm("Patient")}
        >
          {hasNoPropsAndInitValue ? null : patientsName}
        </SelectFilterResult>
      );
    },
    [initialValue, tForm],
  );

  return (
    <SelectFilterAsyncWithCancel
      data-testid="patients-field"
      className="PatientsField"
      readOnly={readOnly}
      disabled={!organizationId}
      onSelect={onSelect}
      hasInitialValue={!!initialValue}
      validation={{
        infoText: !readOnly ? tComponents("PatientsField.infoText") : undefined,
        errorText: tForm("ValidationMessages.patientNotAvailable") ?? "",
      }}
      getOptions={fetchPatients}
      loading={loading}
      renderSelected={setRenderSelected}
      noDataComponent={<SelectFilterAsyncNoData variant="Patient" scaleTo={0.6} />}
    />
  );
}
