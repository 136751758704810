import { Authenticator } from "@aws-amplify/ui-react";
import LoginPagesHeader, { LoginPagesHeaderProps } from "../LoginPagesHeader";
import { PasswordStrength } from "components/PasswordStrength";

interface ForceNewPasswordFormFieldsProps extends LoginPagesHeaderProps {}

export function ForceNewPasswordFormFields({ title, text }: ForceNewPasswordFormFieldsProps) {
  return (
    <>
      <LoginPagesHeader title={title} text={text} />
      <Authenticator.ForceNewPassword.FormFields />
      <PasswordStrength
        atLeastOneNumber={false}
        atLeastOneSpecial={false}
        atLeastOneUppercase={false}
        minLength={false}
      />
    </>
  );
}
