/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddGlobalPreferenceRequestModel,
    AddGlobalPreferenceRequestModelFromJSON,
    AddGlobalPreferenceRequestModelToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    GlobalPreferenceDetailsResponseModelApiResponse,
    GlobalPreferenceDetailsResponseModelApiResponseFromJSON,
    GlobalPreferenceDetailsResponseModelApiResponseToJSON,
    UpdateGlobalPreferenceRequestModel,
    UpdateGlobalPreferenceRequestModelFromJSON,
    UpdateGlobalPreferenceRequestModelToJSON,
} from '../models';

export interface OrganizationsOrganizationIdGlobalpreferencesIdGetRequest {
    id: string;
    organizationId: string;
}

export interface OrganizationsOrganizationIdGlobalpreferencesIdPutRequest {
    id: string;
    organizationId: string;
    UpdateGlobalPreferenceRequestModel?: UpdateGlobalPreferenceRequestModel;
}

export interface OrganizationsOrganizationIdGlobalpreferencesPostRequest {
    organizationId: string;
    AddGlobalPreferenceRequestModel?: AddGlobalPreferenceRequestModel;
}

/**
 * GlobalPreferenceApi - interface
 * 
 * @export
 * @interface GlobalPreferenceApiInterface
 */
export interface GlobalPreferenceApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPreferenceApiInterface
     */
    organizationsOrganizationIdGlobalpreferencesIdGetRaw(requestParameters: OrganizationsOrganizationIdGlobalpreferencesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GlobalPreferenceDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdGlobalpreferencesIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GlobalPreferenceDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdateGlobalPreferenceRequestModel} [UpdateGlobalPreferenceRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPreferenceApiInterface
     */
    organizationsOrganizationIdGlobalpreferencesIdPutRaw(requestParameters: OrganizationsOrganizationIdGlobalpreferencesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GlobalPreferenceDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdGlobalpreferencesIdPut(id: string, organizationId: string, UpdateGlobalPreferenceRequestModel?: UpdateGlobalPreferenceRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GlobalPreferenceDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {AddGlobalPreferenceRequestModel} [AddGlobalPreferenceRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalPreferenceApiInterface
     */
    organizationsOrganizationIdGlobalpreferencesPostRaw(requestParameters: OrganizationsOrganizationIdGlobalpreferencesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GlobalPreferenceDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdGlobalpreferencesPost(organizationId: string, AddGlobalPreferenceRequestModel?: AddGlobalPreferenceRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GlobalPreferenceDetailsResponseModelApiResponse>;

}

/**
 * 
 */
export class GlobalPreferenceApi extends runtime.BaseAPI implements GlobalPreferenceApiInterface {

    /**
     */
    async organizationsOrganizationIdGlobalpreferencesIdGetRaw(requestParameters: OrganizationsOrganizationIdGlobalpreferencesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GlobalPreferenceDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdGlobalpreferencesIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdGlobalpreferencesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/users/{id}/preferences`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue as GlobalPreferenceDetailsResponseModelApiResponse);
    }

    /**
     */
    async organizationsOrganizationIdGlobalpreferencesIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GlobalPreferenceDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdGlobalpreferencesIdGetRaw({ id: id, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdGlobalpreferencesIdPutRaw(requestParameters: OrganizationsOrganizationIdGlobalpreferencesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GlobalPreferenceDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdGlobalpreferencesIdPut.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdGlobalpreferencesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/users/{id}/preferences`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGlobalPreferenceRequestModelToJSON(requestParameters.UpdateGlobalPreferenceRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalPreferenceDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdGlobalpreferencesIdPut(id: string, organizationId: string, UpdateGlobalPreferenceRequestModel?: UpdateGlobalPreferenceRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GlobalPreferenceDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdGlobalpreferencesIdPutRaw({ id: id, organizationId: organizationId, UpdateGlobalPreferenceRequestModel: UpdateGlobalPreferenceRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdGlobalpreferencesPostRaw(requestParameters: OrganizationsOrganizationIdGlobalpreferencesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GlobalPreferenceDetailsResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdGlobalpreferencesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/globalpreferences`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddGlobalPreferenceRequestModelToJSON(requestParameters.AddGlobalPreferenceRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalPreferenceDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdGlobalpreferencesPost(organizationId: string, AddGlobalPreferenceRequestModel?: AddGlobalPreferenceRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GlobalPreferenceDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdGlobalpreferencesPostRaw({ organizationId: organizationId, AddGlobalPreferenceRequestModel: AddGlobalPreferenceRequestModel }, initOverrides);
        return await response.value();
    }

}
