import { PickingInfo } from "@deck.gl/core";
import { Trans, useTranslation } from "react-i18next";
import "./MapTooltip.scss";
import { ColorDot } from "libs/ui";
import { MapData } from "pages/DashboardPage/DashboardMap/DashboardMapReportDataContext";

interface TooltipProps {
  info: PickingInfo<MapData>;
}

export function MapTooltip({ info }: Readonly<TooltipProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.TotalNumbers" });
  const { object, x, y } = info;

  if (!object) {
    return null;
  }
  return (
    <div
      className="MapTooltip"
      style={{
        left: x + 5,
        top: y + 5,
      }}
    >
      <p className="MapTooltip__title">{object.name}</p>
      <p className="MapTooltip__stat">
        <ColorDot type="primary" />

        <Trans i18nKey={"totalPatients"} t={t} values={{ value: object.patientCount }}>
          <span style={{ whiteSpace: "pre-wrap" }}></span>
          <strong></strong>
        </Trans>
      </p>
      <p className="MapTooltip__stat">
        <ColorDot type="secondary" />

        <Trans i18nKey={"patientsWithCKD"} t={t} values={{ value: object.patientCDKCount }}>
          <span style={{ whiteSpace: "pre-wrap" }}></span>
          <strong></strong>
        </Trans>
      </p>
    </div>
  );
}
