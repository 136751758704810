import { Auth, Amplify, Signer } from "aws-amplify";
import { tryGetLocalStorageValue } from "utils/localStorage";

declare global {
  interface Window {
    LOG_LEVEL: any;
    credsForSwagger: any;
  }
}

if (import.meta.env.MODE === "development") {
  window.credsForSwagger = async function credsForSwagger() {
    console.log("-----");
    console.log({ IdentityToken: (await Auth.currentSession()).getIdToken().getJwtToken() });
    console.log({ AccessToken: (await Auth.currentSession()).getAccessToken().getJwtToken() });
  };
}

interface AmplifyConfigProps {
  readonly identityPoolId?: string;
  readonly region?: string;
  readonly userPoolId?: string;
  readonly userPoolWebClientId?: string;
  readonly mandatorySignIn?: true;
  readonly authenticationFlowType?: string;
}

export function amplifyConfigure(authObj_: AmplifyConfigProps) {
  Amplify.configure({
    // Logger: logger,
    Auth: authObj_,
    API: {
      endpoints: [
        {
          name: "Carna",
          endpoint: "https://34yx6owznl.execute-api.eu-central-1.amazonaws.com",
          region: "eu-central-1",

          custom_header: async () => {
            return {
              identityToken: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
              Authorization: `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  });
}

const authObj = tryGetLocalStorageValue<AmplifyConfigProps>("lastUsedAuth");

amplifyConfigure({
  ...authObj,
  identityPoolId: authObj?.identityPoolId ?? "eu-central-1:123-123-123-123-123",
  userPoolId: authObj?.userPoolId ?? "eu-central-1_XXXX",
  userPoolWebClientId: authObj?.userPoolWebClientId ?? "123123123123",
});

export async function signRequest(
  url: string,
  service: string,
  method?: string,
  region?: string,
  data?: any,
) {
  const essentialCredentials = Auth.essentialCredentials(await Auth.currentCredentials());

  const params = { method, url, data };
  const credentials = {
    secret_key: essentialCredentials.secretAccessKey,
    access_key: essentialCredentials.accessKeyId,
    session_token: essentialCredentials.sessionToken,
  };
  // set your region and service here
  const serviceInfo = { region, service };

  // Signer.sign takes care of all other steps of Signature V4
  return Signer.sign(params, credentials, serviceInfo);
}
