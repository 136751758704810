import { Icon } from "libs/ui";
import { useTranslation } from "react-i18next";
import "./NoRecordedData.scss";

export function NoRecordedData() {
  const { t } = useTranslation("translation", {
    keyPrefix: "DashboardPage.NoRecordedData",
  });

  return (
    <div className="NoRecordedData">
      <Icon icon={"Info"} />
      <p>{t("noRecordedData")}</p>
    </div>
  );
}
