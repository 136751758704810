import { TabsForRouter } from "libs/ui/TabsForRouter";
import { useCallback, useEffect, useMemo } from "react";
import { Outlet, useMatch, useResolvedPath } from "react-router-dom";
import { useActor } from "@xstate/react";
import isEqual from "lodash-es/isEqual";
import { MeasurementUIType } from "models/TestModels";
import { useHcpPatientsMeasurementsLaboratoryContext } from "./LaboratoryContext";
import { getMeasurementTabs } from "layout/Main/utils/getMeasurementTabs";
import { mainRoutePaths } from "router";

export function EventEnrichedTabsForHcpLabList() {
  const tableContext = useHcpPatientsMeasurementsLaboratoryContext();
  const [, send] = useActor(tableContext.tableService);

  const resolved = useResolvedPath(mainRoutePaths.hcpDetails);
  const match = useMatch({ path: `${resolved.pathname}/tests/:selectedSubTabRoute`, end: true });

  const updateFilter = useCallback(
    (newStateType?: MeasurementUIType) => {
      const currentTableFilters = tableContext.tableService.getSnapshot().context.filters;

      const newStateObj = newStateType ? { measurementTypes: [newStateType] } : undefined;

      if (isEqual(currentTableFilters, newStateObj) === false) {
        send([{ type: "UPDATE_FILTERS", value: newStateObj }]);
      }
    },
    [send, tableContext.tableService],
  );

  const onClick = useCallback(
    (routePath: string) => {
      switch (routePath as ReturnType<typeof getMeasurementTabs>[number]["routePath"]) {
        case "urineAlbumin":
        case "urineCreatinine":
        case "uacr":
          updateFilter("UACR");
          break;
        case "glucose":
          updateFilter("Glucose");
          break;
        case "bloodPressure":
          updateFilter("BloodPressure");
          break;
        case "serumCreatinine":
        case "egfr":
          updateFilter("SerumCreatinine");
          break;
        case "all":
          updateFilter();
          break;

        case "bmi":
        case "height":
        case "weight":
          updateFilter("BMI");
          break;
        case "semiQuantitativeUacr":
          updateFilter("SemiQuantitativeUACR");
          break;

        default:
          throw new Error("Not implemented");
      }
    },
    [updateFilter],
  );

  useEffect(() => {
    const trans: Parameters<typeof tableContext.tableService.onTransition>[0] = state => {
      if (state.matches("init")) {
        // Special situation, update filter callback on first init with all won't work since the state is equal with itself
        if (match?.params.selectedSubTabRoute === "all") {
          tableContext.tableService.send(["FETCH"]);
        } else if (match?.params.selectedSubTabRoute !== undefined) {
          onClick(match?.params.selectedSubTabRoute);
        }
      }
    };

    tableContext.tableService.onTransition(trans);

    return () => {
      tableContext.tableService.off(trans);
    };
  }, [match, onClick, resolved, tableContext]);

  const tabs = useMemo(() => {
    const newTabProps = getMeasurementTabs("HCPDetails.LabList").map(tabProp => ({
      ...tabProp,
      onClick,
    }));

    return newTabProps;
  }, [onClick]);

  return (
    <TabsForRouter selectBox subRoute tabs={tabs}>
      <Outlet />
    </TabsForRouter>
  );
}
