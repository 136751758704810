import { IconType } from "libs/ui/Icon";
import { UAParser } from "ua-parser-js";

export function getTrackingDeviceSystemDetails(deviceUserAgent: string) {
  const parsedUA = UAParser(deviceUserAgent);

  if (!deviceUserAgent.startsWith("Mozilla")) {
    if (!parsedUA.device.vendor) {
      if (parsedUA.device.type === "mobile") {
        return `iPhone ${deviceUserAgent} • Mobile App`;
      } else {
        return deviceUserAgent;
      }
    } else {
      return `${parsedUA.device.vendor} ${parsedUA.device.model} • Mobile App`;
    }
  }

  return `${parsedUA.os.name} ${parsedUA.os.version} • ${parsedUA.browser.name}`;
}

export function getTrackingDeviceIcon(deviceUserAgent?: string | null): IconType {
  if (!deviceUserAgent) {
    return "Computer";
  }

  const parsedUA = UAParser(deviceUserAgent);

  if (parsedUA.device.type === "mobile") {
    return "Smartphone";
  }

  switch (parsedUA.os.name) {
    case "Android":
    case "iOS":
      return parsedUA.device.model === "iPad" ? "Tablet" : "Smartphone";

    case "Mac OS":
      if (!parsedUA.browser.name) {
        return "Smartphone";
      }
      return parsedUA.browser.name === "Mobile Safari" ? "Tablet" : "Computer";

    case "Windows":
    case "Linux":
      return "Computer";

    case "iPadOS":
      return "Tablet";

    default:
      return "Smartphone";
  }
}
