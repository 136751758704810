import { useInterpret, useActor } from "@xstate/react";
import { TestListResponseModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { TableContext, tableStateMachine } from "libs/ui";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { createSafeContext } from "utils/createSafeContext";
import { InterpreterFrom } from "xstate/lib/types";

type GetMeasurementsFilters = NonNullable<
  Parameters<typeof CarnaApiQuery.Organizations.getMeasurements>[0]["filters"]
>;

export type OrgMeasurementsTableService = InterpreterFrom<
  ReturnType<typeof makeTableFetchStateMachine>
>;

interface OrgLaboratoryContext {
  tableService: OrgMeasurementsTableService;
  fetch: () => void;
}
const Context = createSafeContext<OrgLaboratoryContext>();

export const useSettingsOrgLaboratoryContext = Context.hook;

async function fetchMeasurements(
  organizationId: string,
  context?: TableContext<TestListResponseModel, GetMeasurementsFilters>,
) {
  const result = CarnaApiQuery.Organizations.getMeasurements({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

const makeTableFetchStateMachine = (organizationId: string) =>
  tableStateMachine((context?: TableContext<TestListResponseModel, GetMeasurementsFilters>) =>
    fetchMeasurements(organizationId, context),
  );

export function SettingsOrgLaboratoryContextProvider({ children }: PropsWithChildren) {
  const { organizationId = "" } = useParams<RouteParams["patientDetails"]>();

  const tableMachine = useMemo(() => makeTableFetchStateMachine(organizationId), [organizationId]);

  const tableService = useInterpret(tableMachine);

  const [, send] = useActor(tableService);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  const value = useMemo(() => ({ tableService, fetch }), [fetch, tableService]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
