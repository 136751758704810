import { cloneDeep } from "lodash-es";
import { logger } from "logger";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

type Listener = () => void;
export interface Widget {
  x: number;
  y: number;
  width: number;
  height: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  widgetName: string;
}

function createRectangleBase64(
  padding: number,
  width: number,
  height: number,
  fillStyle: string,
  cornerRadius: number,
): string {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;

  const totalWidth = width + padding * 2;
  const totalHeight = height + padding * 2;

  canvas.width = totalWidth;
  canvas.height = totalHeight;

  ctx.beginPath();
  ctx.roundRect(padding, padding, width, height, cornerRadius);
  ctx.closePath();

  ctx.fillStyle = fillStyle;
  ctx.fill();

  return canvas.toDataURL();
}

export class MakeGridContainer {
  private widgets: {
    [key: string]: Widget | undefined;
  } = {};

  private dirtyWidgets: {
    [key: string]: Widget | undefined;
  } = {};

  private listeners: [string, Listener][] = [];
  #gridRectangleClassName = "GridRectangle";

  constructor() {
    const style = document.createElement("style");

    style.innerHTML = `
      .dragdrop-grid .GridRectangle {
        display: none;
      }

      .dragdrop-grid.show-grid .GridRectangle {
        display: block;
      }
      `;

    document.body.appendChild(style);
  }

  #removeElemDirtyPositionStyles(elem: HTMLElement) {
    elem.style.removeProperty("left");
    elem.style.removeProperty("top");
    elem.style.removeProperty("width");
    elem.style.removeProperty("height");
    elem.style.removeProperty("position");
    elem.style.removeProperty("z-index");
    elem.style.removeProperty("opacity");
  }

  initialize(gridContainer: HTMLElement) {}

  #getElemSpecs(elem: HTMLElement) {
    const gridContainerComputedStyles = getComputedStyle(elem.parentElement!); // TODO make sure this is our grid element

    // get the avg from here?
    const gridColumnSizes = gridContainerComputedStyles
      .getPropertyValue("grid-template-columns")
      .replaceAll("px", "")
      .split(" ")
      .map(v => +v);

    const gridRowSizes = gridContainerComputedStyles
      .getPropertyValue("grid-template-rows")
      .replaceAll("px", "")
      .split(" ")
      .map(v => +v);

    const gap = +gridContainerComputedStyles.getPropertyValue("gap").replace("px", "");

    const gridRect = elem.parentElement!.getBoundingClientRect();
    const widgetRect = elem.getBoundingClientRect();

    const widgetXIsInGridColumn = (widgetRect.x - gridRect.x) / (gridColumnSizes[0] + gap); // ( we are asuming that the columns are evenly seperated )

    const widgetYIsInGridRow = (widgetRect.y - gridRect.y) / (gridRowSizes[0] + gap); // ( we are asuming that the columns are evenly seperated )

    const widgetWidthIsInGridColumn = widgetRect.width / (gridColumnSizes[0] + gap); //

    const widgetHeightIsInGridRow = widgetRect.height / (gridRowSizes[0] + gap); // ( we are asuming that the columns are evenly seperated )

    return {
      widgetXIsInGridColumn: Math.floor(widgetXIsInGridColumn) + 1,
      widgetYIsInGridRow: Math.floor(widgetYIsInGridRow) + 1,
      widgetWidthIsInGridColumn: Math.floor(widgetWidthIsInGridColumn) + 1,
      widgetHeightIsInGridRow: Math.floor(widgetHeightIsInGridRow) + 1,
      gap,
      gridRect,
      widgetRect,
      gridColumnSizes,
      gridRowSizes,
    } as const;
  }

  #drawGridRectangles = (gridContainer: HTMLElement, visibility: boolean) => {
    gridContainer.style.background = visibility
      ? `url(${createRectangleBase64(8, 120, 120, "hsla(257, 99%, 66%, 0.05)", 16)})`
      : "";
    gridContainer.style.backgroundPosition = "-8px -8px";
  };

  #getGridDimensions(widgetElem: HTMLElement) {
    const gridContainerComputedStyles = getComputedStyle(widgetElem.parentElement!);
    const gridColumns = gridContainerComputedStyles
      .getPropertyValue("grid-template-columns")
      .split(" ")
      .map(column => +column.replace("px", ""));
    const gridRows = gridContainerComputedStyles
      .getPropertyValue("grid-template-rows")
      .split(" ")
      .map(column => +column.replace("px", ""));
    const gridGapSize = +gridContainerComputedStyles.getPropertyValue("gap").replace("px", "");

    return { gridColumns, gridRows, gridGapSize };
  }

  #getWidgetForcedBoundariesInPx = (widgetElem: HTMLElement, widgetName: string) => {
    const getWidgetState = this.getState(widgetName);
    const { gridColumns, gridRows, gridGapSize } = this.#getGridDimensions(widgetElem);

    const maxWidgetGridRectangleWidth = getWidgetState()?.maxWidth;
    const minWidgetGridRectangleWidth = getWidgetState()?.minWidth;

    const maxWidgetGridRectangleHeight = getWidgetState()?.maxHeight;
    const minWidgetGridRectangleHeight = getWidgetState()?.minHeight;

    const maxWidgetWidth = maxWidgetGridRectangleWidth
      ? +maxWidgetGridRectangleWidth * (gridColumns[0] + gridGapSize) - gridGapSize
      : undefined;

    const minWidgetWidth = minWidgetGridRectangleWidth
      ? +minWidgetGridRectangleWidth * (gridColumns[0] + gridGapSize) - gridGapSize
      : undefined;

    const maxWidgetHeight = maxWidgetGridRectangleHeight
      ? +maxWidgetGridRectangleHeight * (gridRows[0] + gridGapSize) - gridGapSize
      : undefined;

    const minWidgetHeight = minWidgetGridRectangleHeight
      ? +minWidgetGridRectangleHeight * (gridRows[0] + gridGapSize) - gridGapSize
      : undefined;

    return { maxWidgetWidth, minWidgetWidth, maxWidgetHeight, minWidgetHeight } as const;
  };

  isInCollision(targetWidget: Widget, widgetList: Widget[]) {
    const listWithoutTarget = widgetList.filter(w => w.widgetName !== targetWidget.widgetName);

    return listWithoutTarget.some(widget => {
      const overlapX =
        widget.x < targetWidget.x + targetWidget.width && widget.x + widget.width > targetWidget.x;
      const overlapY =
        widget.y < targetWidget.y + targetWidget.height &&
        widget.y + widget.height > targetWidget.y;

      return overlapX && overlapY;
    });
  }

  #repositionWidgets = (focusedWidgetElem: HTMLElement, focusedWidgetElemName: string) => {
    const excludedWidgetState = this.dirtyWidgets[focusedWidgetElemName];

    if (!excludedWidgetState) {
      throw new Error(`No widget with name ${focusedWidgetElemName}`);
    }

    const gridContainer = focusedWidgetElem.parentElement!;

    const gridContainerComputedStyles = getComputedStyle(gridContainer); // TODO make sure this is our grid element
    const gridColumns = gridContainerComputedStyles
      .getPropertyValue("grid-template-columns")
      .replaceAll("px", "")
      .split(" ");
    // const gridRows = gridContainerComputedStyles.getPropertyValue("grid-template-rows").replaceAll("px", "").split(" ");

    const {
      widgetXIsInGridColumn,
      widgetYIsInGridRow,
      widgetWidthIsInGridColumn,
      widgetHeightIsInGridRow,
    } = this.#getElemSpecs(focusedWidgetElem); // ( we are asuming that the columns are evenly seperated )

    //! #region switch places
    for (const wName in this.widgets) {
      if (focusedWidgetElemName === wName) {
        continue;
      }

      const iteratedWidgetState = this.dirtyWidgets[wName]!;

      if (
        iteratedWidgetState.x === widgetXIsInGridColumn &&
        iteratedWidgetState.y === widgetYIsInGridRow &&
        iteratedWidgetState.height === widgetHeightIsInGridRow &&
        iteratedWidgetState.width === widgetWidthIsInGridColumn
      ) {
        const overlappedElem = gridContainer.querySelector<HTMLDivElement>(
          `[data-widget-name=${wName}]`,
        );

        if (!overlappedElem) {
          throw new Error("No overlapped elem");
        }

        this.dirtyWidgets[wName] = cloneDeep(excludedWidgetState);
        this.dirtyWidgets[focusedWidgetElemName] = {
          widgetName: focusedWidgetElemName,
          x: widgetXIsInGridColumn,
          y: widgetYIsInGridRow,
          width: widgetWidthIsInGridColumn,
          height: widgetHeightIsInGridRow,
        };

        const newItWidgetState = this.dirtyWidgets[wName]!;

        overlappedElem.style.gridArea = `${newItWidgetState.y} / ${newItWidgetState.x} / span ${newItWidgetState.height} / span ${newItWidgetState.width}`;

        return;
      }

      // #endregion
    }
  };

  hookUpWidgetResizing = (resizeElem: HTMLElement, widgetElem: HTMLElement, widgetName: string) => {
    const getWidgetState = this.getState(widgetName);

    if (!getWidgetState()) {
      throw new Error(`No widget with name ${widgetName}`);
    }

    let targetElemPageXY: readonly [number, number] = [0, 0];

    const handleMouseMove = (event: MouseEvent) => {
      const elem = widgetElem;

      if (!elem) {
        logger.warn("No elem");
        return;
      }

      const widgetRect = elem.getBoundingClientRect();

      const { maxWidgetWidth, maxWidgetHeight, minWidgetHeight, minWidgetWidth } =
        this.#getWidgetForcedBoundariesInPx(elem, widgetName);

      const relativeNewWidth = event.pageX - widgetRect.x + targetElemPageXY[0];
      const relativeNewHeight = event.pageY - widgetRect.y + targetElemPageXY[1];

      let newWidth: number;
      if (maxWidgetWidth && relativeNewWidth > maxWidgetWidth) {
        newWidth = maxWidgetWidth;
      } else if (minWidgetWidth && relativeNewWidth < minWidgetWidth) {
        newWidth = minWidgetWidth;
      } else {
        newWidth = relativeNewWidth;
      }

      let newHeight: number;
      if (maxWidgetHeight && relativeNewHeight > maxWidgetHeight) {
        newHeight = maxWidgetHeight;
      } else if (minWidgetHeight && relativeNewHeight < minWidgetHeight) {
        newHeight = minWidgetHeight;
      } else {
        newHeight = relativeNewHeight;
      }

      elem.style.left = `${widgetRect.left}px`;
      elem.style.top = `${widgetRect.top}px`;
      elem.style.width = `${newWidth}px`;
      elem.style.height = `${newHeight}px`;
      elem.style.opacity = "0.4";

      elem.style.position = "absolute";
      elem.style.zIndex = "999";
    };

    const handleMouseUp = (event: any) => {
      event.preventDefault();

      const elem = widgetElem;
      if (!elem) {
        logger.warn("No elem");
        return;
      }

      const { widgetHeightIsInGridRow, widgetWidthIsInGridColumn } = this.#getElemSpecs(elem); // ( we are asuming that the columns are evenly seperated )

      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      targetElemPageXY = [0, 0];
      this.#drawGridRectangles(elem.parentElement!, false);

      const newState = {
        ...getWidgetState(),
        widgetName: NotNullOrUndefined(getWidgetState()?.widgetName),
        x: getWidgetState()?.x!,
        y: getWidgetState()?.y!,
        height: widgetHeightIsInGridRow,
        width: widgetWidthIsInGridColumn,
      } as const;

      this.#removeElemDirtyPositionStyles(elem);
      if (this.isInCollision(newState, Object.values(this.dirtyWidgets) as Widget[])) {
        this.dirtyWidgets = {};
        return;
      }
      this.widgets = cloneDeep(this.dirtyWidgets);
      this.dirtyWidgets = {};
      this.setWidgetState(widgetName, { ...newState, widgetName });

      this.#notifyListeners();
    };

    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      this.dirtyWidgets = cloneDeep(this.widgets);

      const rect = resizeElem.getBoundingClientRect();
      targetElemPageXY = [event.pageX - rect.x, event.pageY - rect.y];
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);

      this.#drawGridRectangles(widgetElem.parentElement!, true);
    };

    resizeElem.addEventListener("mousedown", handleMouseDown);

    return () => resizeElem.removeEventListener("mousedown", handleMouseDown);
  };

  hookUpWidgetSnapping = (dragElem: HTMLElement, widgetElem: HTMLElement, widgetName: string) => {
    const getWidgetState = this.getState(widgetName);
    let repositionTimer: any = null;

    if (!getWidgetState()) {
      throw new Error(`No widget with name ${widgetName}`);
    }

    let targetElemPageXY: readonly [number, number] = [0, 0];

    const handleMouseMove = (event: MouseEvent) => {
      const elem = widgetElem;

      if (!elem) {
        logger.warn("No elem");
        return;
      }

      const widgetRect = elem.getBoundingClientRect();

      elem.style.left = `${event.pageX - targetElemPageXY[0]}px`;
      elem.style.top = `${event.pageY - targetElemPageXY[1]}px`;
      elem.style.width = `${widgetRect.width}px`;
      elem.style.height = `${widgetRect.height}px`;
      elem.style.opacity = "0.4";

      elem.style.position = "absolute";
      elem.style.zIndex = "999";

      if (repositionTimer) {
        clearTimeout(repositionTimer);
      }

      repositionTimer = setTimeout(() => {
        this.#repositionWidgets(widgetElem, widgetName);
      }, 150);
    };

    const handleMouseUp = (event: any) => {
      if (repositionTimer) {
        clearTimeout(repositionTimer);
      }
      event.preventDefault();

      const elem = widgetElem;
      if (!elem) {
        logger.warn("No elem");
        return;
      }

      const { widgetXIsInGridColumn, widgetYIsInGridRow } = this.#getElemSpecs(elem); // ( we are asuming that the columns are evenly seperated )

      if (widgetXIsInGridColumn) document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      targetElemPageXY = [0, 0];
      this.#drawGridRectangles(widgetElem.parentElement!, false);

      const newState = {
        ...getWidgetState(),
        widgetName: NotNullOrUndefined(getWidgetState()?.widgetName),
        x: widgetXIsInGridColumn,
        y: widgetYIsInGridRow,
        height: getWidgetState()?.height!,
        width: getWidgetState()?.width!,
      } as const;

      this.#removeElemDirtyPositionStyles(elem);

      if (this.isInCollision(newState, Object.values(this.dirtyWidgets) as Widget[])) {
        this.dirtyWidgets = {};
        return;
      }

      this.widgets = cloneDeep(this.dirtyWidgets);
      this.dirtyWidgets = {};
      this.setWidgetState(widgetName, { ...newState, widgetName });

      this.#notifyListeners();
    };

    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      const rect = widgetElem.getBoundingClientRect();
      this.dirtyWidgets = cloneDeep(this.widgets);

      targetElemPageXY = [event.pageX - rect.x, event.pageY - rect.y];
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);

      this.#drawGridRectangles(widgetElem.parentElement!, true);
    };

    dragElem.addEventListener("mousedown", handleMouseDown);

    return () => dragElem.removeEventListener("mousedown", handleMouseDown);
  };

  //#region REACT
  setWidgetState = (widgetName: string, props: Widget) => {
    this.widgets[widgetName] = props;
  };

  #notifyListeners = (widgetName?: string) => {
    if (widgetName) {
      const listenerIndex = this.listeners.findIndex(l => l[0] === widgetName);
      if (listenerIndex !== -1) {
        this.listeners[listenerIndex][1]();
      }
      return;
    }

    this.listeners.forEach(([name, listener]) => {
      listener();
    });
  };

  addWidget = (widget: Widget) => {
    this.widgets[widget.widgetName] = widget;

    this.listeners.forEach(([name, listener]) => {
      if (name === widget.widgetName) {
        listener();
      }
    });
  };

  removeWidget = (widgetName: string) => {
    delete this.widgets[widgetName];
  };

  getState = (widgetName: string) => () => {
    const widgetState = this.widgets[widgetName];

    return widgetState;
  };

  subscribe = (widgetName: string) => (listener: Listener) => {
    const listenerIndex = this.listeners.findIndex(l => l[0] === widgetName);
    if (listenerIndex !== -1) {
      this.listeners[listenerIndex] = [widgetName, listener];
      return () => this.listeners.splice(listenerIndex, 1);
    }
    this.listeners.push([widgetName, listener]);

    return () => (this.listeners = this.listeners.filter(l => l[0] !== widgetName));
  };

  //#endregion
}
