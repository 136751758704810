import { useActor } from "@xstate/react";
import { resolveRenderCell } from "components/AppTables/utils";
import { ActionButton, TableElements } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import { useCallback, useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { TableCard } from "../../TableCard";
import { TableCardDescription } from "../../TableCardDescription";
import { TableCardDevice } from "../../TableCardDevice";
import { BodyRowsProps } from "../model";
import "./BodyRows.scss";
import { FEHcpResponseModel } from "models/PersonModels";
import { statusToIcon } from "libs/ui/ActionButton/utils";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { avatarCache } from "utils/machines/avatars";
import { PictureResolution } from "utils/helpers/pictures.model";
import { FakeData, isRealData } from "utils/guards";

export function BodyRows({
  tableService,
  changeHCPStateClick,
  appliedColumns,
  currentRightClickContext,
  setCurrentRightClickContext,
}: BodyRowsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "HCPTable" });
  const { t: countriesT } = useTranslation("nationalities", { keyPrefix: "countries" });
  const { isAdmin } = useCurrentUserData();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  /**
   * TODO grooming
   * This should not be dependant on external stuff
   * we should have defaultProps or something
   */
  const { appConfig } = useGlobalConfigContext();

  const can = useHasAccess();
  const tableRedirection = TableUtils.useTableElementsRedirect();

  const [state] = useActor(tableService);

  const defaultValues = Array.from({ length: state.context.linesPerPage }).map((_, idx) => {
    const defaultValue: FakeData<FEHcpResponseModel> = {
      ...NotNullOrUndefined(appConfig?.forms.defaultValues.hcp),
      id: idx.toString(),
      _fake: true,
      organizationName: "",
      status: "Invited",
      role: "Hcp",
    };
    return defaultValue;
  });

  const redirectToHcpDetails = useCallback(
    (hcpId: string, organizationId: string) =>
      tableRedirection({
        path: "hcpDetails",
        params: {
          hcpId,
          organizationId,
        },
      }),
    [tableRedirection],
  );

  return (
    <>
      {(state.matches("loading") ? defaultValues : state.context.data.items ?? []).map(user => {
        if (state.context.tableView === "Grid") {
          return (
            <TableCard
              className="HCPTableCard"
              key={user.id}
              loading={state.matches("loading")}
              inactive={user.status === "Deactivated"}
              avatarData={resolveRenderCell(appliedColumns, "userStatuses", () => ({
                src: isRealData(user)
                  ? avatarCache.get(user.organizationId, user.id, PictureResolution.grid)
                  : undefined,
                loading: false,
                avatarType: "Hcp",
              }))}
              name={`${user.firstName} ${user.lastName}`}
              description={
                <TableCardDescription loading={state.matches("loading")} textType="bold">
                  {user.email}
                </TableCardDescription>
              }
              organization={resolveRenderCell(appliedColumns, "organizationIds", () => ({
                id: user.organizationId,
                name: user.organizationName,
              }))}
              onClick={
                can("view-hcp-details")
                  ? () => redirectToHcpDetails(user.id, user.organizationId)
                  : undefined
              }
              actionChildren={
                user.status !== "Deleted" &&
                can("edit-hcp") &&
                resolveRenderCell(appliedColumns, "actions", () => (
                  <ActionButton
                    onClick={changeHCPStateClick(user)}
                    actionType={user.status}
                    iconType={statusToIcon(user.status)}
                  >
                    {t(`actions.${user.status}`)}
                  </ActionButton>
                ))
              }
            >
              <TableCardDevice
                show={can("manage-device") && resolveRenderCell(appliedColumns, "withDevice")}
                deviceData={{
                  id: user.deviceId,
                  organizationId: user.organizationId,
                  serialNumber: user.deviceSerialNumber,
                }}
                loading={state.matches("loading")}
              />
            </TableCard>
          );
        }

        return (
          <TableElements.Row
            key={user.id}
            inactive={user.status === "Deactivated"}
            onContextMenu={event => {
              event.preventDefault();
              setCurrentRightClickContext([event, user]);
            }}
            {...(can("view-hcp-details") &&
              !currentRightClickContext &&
              !state.matches("loading") && {
                onClick: () => {
                  tableRedirection({
                    path: "hcpDetails",
                    params: {
                      hcpId: user.id,
                      organizationId: user.organizationId,
                    },
                  });
                },
              })}
          >
            <TableElements.AvatarCell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "userStatuses")}
              src={
                isRealData(user)
                  ? avatarCache.get(user.organizationId, user.id, PictureResolution.table)
                  : undefined
              }
              avatarLoading={false}
              firstName={user.firstName}
              label={`${user.firstName} ${user.lastName}`}
              status={user.status}
              avatarType={"Hcp"}
            />

            <TableElements.IconCell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "role")}
              className="HCPIcon"
              icon="Hcp"
            >
              {t("HCP")}
            </TableElements.IconCell>

            <TableElements.UserOrganizationCell
              loading={state.matches("loading")}
              {...(isAdmin && {
                onCellClick: e =>
                  tableRedirection({
                    event: e,
                    path: "organizationDetails",
                    params: { organizationId: user.organizationId, firstTab: "partners" },
                  }),
              })}
              show={resolveRenderCell(appliedColumns, "organizationIds")}
              role={user.role}
              label={user.organizationName ?? ""}
            />

            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "email")}
              data-testid="email_cell"
            >
              {user.email}
            </TableElements.Cell>

            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "phone")}
            >
              {user.phone}
            </TableElements.Cell>

            <TableElements.Cell
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "address")}
            >{`${user.street} ${user.zipCode ?? ""}`}</TableElements.Cell>

            <TableElements.FlagCell
              loading={state.matches("loading")}
              countryName={countriesT(user.country)}
              show={resolveRenderCell(appliedColumns, "countries")}
              country={user.country}
            />

            <TableElements.DeviceCell
              loading={state.matches("loading")}
              {...(user.deviceId && {
                onClick: e =>
                  tableRedirection({
                    event: e,
                    path: "deviceDetails",
                    params: {
                      deviceId: user.deviceId as string,
                      organizationId: user.organizationId,
                    },
                  }),
              })}
              show={can("manage-device") && resolveRenderCell(appliedColumns, "withDevice")}
              deviceSerialNumber={user.deviceSerialNumber}
              deviceIcon={!!user.deviceId}
            >
              {t("na")}
            </TableElements.DeviceCell>

            <TableElements.Cell
              show={can("edit-hcp") && resolveRenderCell(appliedColumns, "actions")}
              className="ActionsCell"
            >
              <TableElements.Actions disabled={user.status === "Deleted"}>
                <ActionButton
                  onClick={changeHCPStateClick(user)}
                  actionType={user.status}
                  iconType={statusToIcon(user.status)}
                >
                  {t(`actions.${user.status}`)}
                </ActionButton>
              </TableElements.Actions>
            </TableElements.Cell>
          </TableElements.Row>
        );
      })}
    </>
  );
}
