/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NationalityType = {
    None: 'None',
    Afghan: 'Afghan',
    Albanian: 'Albanian',
    Algerian: 'Algerian',
    American: 'American',
    Andorran: 'Andorran',
    Angolan: 'Angolan',
    Anguillan: 'Anguillan',
    CitizenOfAntiguaAndBarbuda: 'CitizenOfAntiguaAndBarbuda',
    Argentine: 'Argentine',
    Armenian: 'Armenian',
    Australian: 'Australian',
    Austrian: 'Austrian',
    Azerbaijani: 'Azerbaijani',
    Bahamian: 'Bahamian',
    Bahraini: 'Bahraini',
    Bangladeshi: 'Bangladeshi',
    Barbadian: 'Barbadian',
    Belarusian: 'Belarusian',
    Belgian: 'Belgian',
    Belizean: 'Belizean',
    Beninese: 'Beninese',
    Bermudian: 'Bermudian',
    Bhutanese: 'Bhutanese',
    Bolivian: 'Bolivian',
    CitizenOfBosniaAndHerzegovina: 'CitizenOfBosniaAndHerzegovina',
    Botswanan: 'Botswanan',
    Brazilian: 'Brazilian',
    British: 'British',
    BritishVirginIslander: 'BritishVirginIslander',
    Bruneian: 'Bruneian',
    Bulgarian: 'Bulgarian',
    Burkinan: 'Burkinan',
    Burmese: 'Burmese',
    Burundian: 'Burundian',
    Cambodian: 'Cambodian',
    Cameroonian: 'Cameroonian',
    Canadian: 'Canadian',
    CapeVerdean: 'CapeVerdean',
    CaymanIslander: 'CaymanIslander',
    CentralAfrican: 'CentralAfrican',
    Chadian: 'Chadian',
    Chilean: 'Chilean',
    Chinese: 'Chinese',
    Colombian: 'Colombian',
    Comoran: 'Comoran',
    CongoleseCongo: 'CongoleseCongo',
    CongoleseDRC: 'CongoleseDRC',
    CookIslander: 'CookIslander',
    CostaRican: 'CostaRican',
    Croatian: 'Croatian',
    Cuban: 'Cuban',
    Cymraes: 'Cymraes',
    Cymro: 'Cymro',
    Cypriot: 'Cypriot',
    Czech: 'Czech',
    Danish: 'Danish',
    Djiboutian: 'Djiboutian',
    Dominican: 'Dominican',
    CitizenOfTheDominicanRepublic: 'CitizenOfTheDominicanRepublic',
    Dutch: 'Dutch',
    EastTimorese: 'EastTimorese',
    Ecuadorean: 'Ecuadorean',
    Egyptian: 'Egyptian',
    Emirati: 'Emirati',
    English: 'English',
    EquatorialGuinean: 'EquatorialGuinean',
    Eritrean: 'Eritrean',
    Estonian: 'Estonian',
    Ethiopian: 'Ethiopian',
    Faroese: 'Faroese',
    Fijian: 'Fijian',
    Filipino: 'Filipino',
    Finnish: 'Finnish',
    French: 'French',
    Gabonese: 'Gabonese',
    Gambian: 'Gambian',
    Georgian: 'Georgian',
    German: 'German',
    Ghanaian: 'Ghanaian',
    Gibraltarian: 'Gibraltarian',
    Greek: 'Greek',
    Greenlandic: 'Greenlandic',
    Grenadian: 'Grenadian',
    Guamanian: 'Guamanian',
    Guatemalan: 'Guatemalan',
    CitizenOfGuineaBissau: 'CitizenOfGuineaBissau',
    Guinean: 'Guinean',
    Guyanese: 'Guyanese',
    Haitian: 'Haitian',
    Honduran: 'Honduran',
    HongKonger: 'HongKonger',
    Hungarian: 'Hungarian',
    Icelandic: 'Icelandic',
    Indian: 'Indian',
    Indonesian: 'Indonesian',
    Iranian: 'Iranian',
    Iraqi: 'Iraqi',
    Irish: 'Irish',
    Israeli: 'Israeli',
    Italian: 'Italian',
    Ivorian: 'Ivorian',
    Jamaican: 'Jamaican',
    Japanese: 'Japanese',
    Jordanian: 'Jordanian',
    Kazakh: 'Kazakh',
    Kenyan: 'Kenyan',
    Kittitian: 'Kittitian',
    CitizenOfKiribati: 'CitizenOfKiribati',
    Kosovan: 'Kosovan',
    Kuwaiti: 'Kuwaiti',
    Kyrgyz: 'Kyrgyz',
    Lao: 'Lao',
    Latvian: 'Latvian',
    Lebanese: 'Lebanese',
    Liberian: 'Liberian',
    Libyan: 'Libyan',
    LiechtensteinCitizen: 'LiechtensteinCitizen',
    Lithuanian: 'Lithuanian',
    Luxembourger: 'Luxembourger',
    Macanese: 'Macanese',
    Macedonian: 'Macedonian',
    Malagasy: 'Malagasy',
    Malawian: 'Malawian',
    Malaysian: 'Malaysian',
    Maldivian: 'Maldivian',
    Malian: 'Malian',
    Maltese: 'Maltese',
    Marshallese: 'Marshallese',
    Martiniquais: 'Martiniquais',
    Mauritanian: 'Mauritanian',
    Mauritian: 'Mauritian',
    Mexican: 'Mexican',
    Micronesian: 'Micronesian',
    Moldovan: 'Moldovan',
    Monegasque: 'Monegasque',
    Mongolian: 'Mongolian',
    Montenegrin: 'Montenegrin',
    Montserratian: 'Montserratian',
    Moroccan: 'Moroccan',
    Mosotho: 'Mosotho',
    Mozambican: 'Mozambican',
    Namibian: 'Namibian',
    Nauruan: 'Nauruan',
    Nepalese: 'Nepalese',
    NewZealander: 'NewZealander',
    Nicaraguan: 'Nicaraguan',
    Nigerian: 'Nigerian',
    Nigerien: 'Nigerien',
    Niuean: 'Niuean',
    NorthKorean: 'NorthKorean',
    NorthernIrish: 'NorthernIrish',
    Norwegian: 'Norwegian',
    Omani: 'Omani',
    Pakistani: 'Pakistani',
    Palauan: 'Palauan',
    Palestinian: 'Palestinian',
    Panamanian: 'Panamanian',
    PapuaNewGuinean: 'PapuaNewGuinean',
    Paraguayan: 'Paraguayan',
    Peruvian: 'Peruvian',
    PitcairnIslander: 'PitcairnIslander',
    Polish: 'Polish',
    Portuguese: 'Portuguese',
    Prydeinig: 'Prydeinig',
    PuertoRican: 'PuertoRican',
    Qatari: 'Qatari',
    Romanian: 'Romanian',
    Russian: 'Russian',
    Rwandan: 'Rwandan',
    Salvadorean: 'Salvadorean',
    Sammarinese: 'Sammarinese',
    Samoan: 'Samoan',
    SaoTomean: 'SaoTomean',
    SaudiArabian: 'SaudiArabian',
    Scottish: 'Scottish',
    Senegalese: 'Senegalese',
    Serbian: 'Serbian',
    CitizenOfSeychelles: 'CitizenOfSeychelles',
    SierraLeonean: 'SierraLeonean',
    Singaporean: 'Singaporean',
    Slovak: 'Slovak',
    Slovenian: 'Slovenian',
    SolomonIslander: 'SolomonIslander',
    Somali: 'Somali',
    SouthAfrican: 'SouthAfrican',
    SouthKorean: 'SouthKorean',
    SouthSudanese: 'SouthSudanese',
    Spanish: 'Spanish',
    SriLankan: 'SriLankan',
    StHelenian: 'StHelenian',
    StLucian: 'StLucian',
    Stateless: 'Stateless',
    Sudanese: 'Sudanese',
    Surinamese: 'Surinamese',
    Swazi: 'Swazi',
    Swedish: 'Swedish',
    Swiss: 'Swiss',
    Syrian: 'Syrian',
    Taiwanese: 'Taiwanese',
    Tajik: 'Tajik',
    Tanzanian: 'Tanzanian',
    Thai: 'Thai',
    Togolese: 'Togolese',
    Tongan: 'Tongan',
    Trinidadian: 'Trinidadian',
    Tristanian: 'Tristanian',
    Tunisian: 'Tunisian',
    Turkish: 'Turkish',
    Turkmen: 'Turkmen',
    TurksandCaicos: 'TurksandCaicos',
    Islander: 'Islander',
    Tuvaluan: 'Tuvaluan',
    Ugandan: 'Ugandan',
    Ukrainian: 'Ukrainian',
    Uruguayan: 'Uruguayan',
    Uzbek: 'Uzbek',
    VaticanCitizen: 'VaticanCitizen',
    CitizenOfVanuatu: 'CitizenOfVanuatu',
    Venezuelan: 'Venezuelan',
    Vietnamese: 'Vietnamese',
    Vincentian: 'Vincentian'
} as const;
export type NationalityType = typeof NationalityType[keyof typeof NationalityType];


export function NationalityTypeFromJSON(json: any): NationalityType {
    return NationalityTypeFromJSONTyped(json, false);
}

export function NationalityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NationalityType {
    return json as NationalityType;
}

export function NationalityTypeToJSON(value?: NationalityType | null): any {
    return value as any;
}

