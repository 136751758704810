/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AncestryType,
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import {
    CountryType,
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import {
    GenderType,
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import {
    NationalityType,
    NationalityTypeFromJSON,
    NationalityTypeFromJSONTyped,
    NationalityTypeToJSON,
} from './NationalityType';
import {
    StateType,
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import {
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface PatientDetailsResponseModel
 */
export interface PatientDetailsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof PatientDetailsResponseModel
     */
    country: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof PatientDetailsResponseModel
     */
    state: StateType;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {UserStatusType}
     * @memberof PatientDetailsResponseModel
     */
    status: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof PatientDetailsResponseModel
     */
    role: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {DeviceType}
     * @memberof PatientDetailsResponseModel
     */
    deviceType?: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    deviceSerialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    deviceUniqueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    hcpId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    hcpFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    hcpLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {GenderType}
     * @memberof PatientDetailsResponseModel
     */
    gender: GenderType;
    /**
     * 
     * @type {AncestryType}
     * @memberof PatientDetailsResponseModel
     */
    ancestry: AncestryType;
    /**
     * 
     * @type {number}
     * @memberof PatientDetailsResponseModel
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof PatientDetailsResponseModel
     */
    height: number;
    /**
     * 
     * @type {string}
     * @memberof PatientDetailsResponseModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {NationalityType}
     * @memberof PatientDetailsResponseModel
     */
    nationalityType: NationalityType;
    /**
     * 
     * @type {number}
     * @memberof PatientDetailsResponseModel
     */
    rowVersion?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatientDetailsResponseModel
     */
    years?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientDetailsResponseModel
     */
    bmi?: number;
}

export function PatientDetailsResponseModelFromJSON(json: any): PatientDetailsResponseModel {
    return PatientDetailsResponseModelFromJSONTyped(json, false);
}

export function PatientDetailsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientDetailsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': json['Email'],
        'phone': json['Phone'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': StateTypeFromJSON(json['State']),
        'zipCode': json['ZipCode'],
        'organizationId': json['OrganizationId'],
        'organizationName': json['OrganizationName'],
        'status': UserStatusTypeFromJSON(json['Status']),
        'role': UserRoleTypeFromJSON(json['Role']),
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'deviceType': !exists(json, 'DeviceType') ? undefined : DeviceTypeFromJSON(json['DeviceType']),
        'deviceSerialNumber': !exists(json, 'DeviceSerialNumber') ? undefined : json['DeviceSerialNumber'],
        'deviceUniqueId': !exists(json, 'DeviceUniqueId') ? undefined : json['DeviceUniqueId'],
        'hcpId': !exists(json, 'HcpId') ? undefined : json['HcpId'],
        'hcpFirstName': !exists(json, 'HcpFirstName') ? undefined : json['HcpFirstName'],
        'hcpLastName': !exists(json, 'HcpLastName') ? undefined : json['HcpLastName'],
        'dateOfBirth': !exists(json, 'DateOfBirth') ? undefined : json['DateOfBirth'],
        'gender': GenderTypeFromJSON(json['Gender']),
        'ancestry': AncestryTypeFromJSON(json['Ancestry']),
        'weight': json['Weight'],
        'height': json['Height'],
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'nationalityType': NationalityTypeFromJSON(json['NationalityType']),
        'rowVersion': !exists(json, 'RowVersion') ? undefined : json['RowVersion'],
        'years': !exists(json, 'Years') ? undefined : json['Years'],
        'bmi': !exists(json, 'Bmi') ? undefined : json['Bmi'],
    };
}

export function PatientDetailsResponseModelToJSON(value?: PatientDetailsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Phone': value.phone,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': StateTypeToJSON(value.state),
        'ZipCode': value.zipCode,
        'OrganizationId': value.organizationId,
        'OrganizationName': value.organizationName,
        'Status': UserStatusTypeToJSON(value.status),
        'Role': UserRoleTypeToJSON(value.role),
        'DeviceId': value.deviceId,
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'DeviceSerialNumber': value.deviceSerialNumber,
        'DeviceUniqueId': value.deviceUniqueId,
        'HcpId': value.hcpId,
        'HcpFirstName': value.hcpFirstName,
        'HcpLastName': value.hcpLastName,
        'DateOfBirth': value.dateOfBirth,
        'Gender': GenderTypeToJSON(value.gender),
        'Ancestry': AncestryTypeToJSON(value.ancestry),
        'Weight': value.weight,
        'Height': value.height,
        'RegistryNumber': value.registryNumber,
        'NationalityType': NationalityTypeToJSON(value.nationalityType),
        'RowVersion': value.rowVersion,
        'Years': value.years,
        'Bmi': value.bmi,
    };
}

