/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatinineOptionsModel,
    CreatinineOptionsModelFromJSON,
    CreatinineOptionsModelFromJSONTyped,
    CreatinineOptionsModelToJSON,
} from './CreatinineOptionsModel';
import {
    EgfrOptionsModel,
    EgfrOptionsModelFromJSON,
    EgfrOptionsModelFromJSONTyped,
    EgfrOptionsModelToJSON,
} from './EgfrOptionsModel';
import {
    ViewOptionsModel,
    ViewOptionsModelFromJSON,
    ViewOptionsModelFromJSONTyped,
    ViewOptionsModelToJSON,
} from './ViewOptionsModel';

/**
 * 
 * @export
 * @interface MeasurementsOptionsModel
 */
export interface MeasurementsOptionsModel {
    /**
     * 
     * @type {CreatinineOptionsModel}
     * @memberof MeasurementsOptionsModel
     */
    creatinine: CreatinineOptionsModel;
    /**
     * 
     * @type {EgfrOptionsModel}
     * @memberof MeasurementsOptionsModel
     */
    egfr: EgfrOptionsModel;
    /**
     * 
     * @type {ViewOptionsModel}
     * @memberof MeasurementsOptionsModel
     */
    viewOptions: ViewOptionsModel;
}

export function MeasurementsOptionsModelFromJSON(json: any): MeasurementsOptionsModel {
    return MeasurementsOptionsModelFromJSONTyped(json, false);
}

export function MeasurementsOptionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementsOptionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creatinine': CreatinineOptionsModelFromJSON(json['Creatinine']),
        'egfr': EgfrOptionsModelFromJSON(json['Egfr']),
        'viewOptions': ViewOptionsModelFromJSON(json['ViewOptions']),
    };
}

export function MeasurementsOptionsModelToJSON(value?: MeasurementsOptionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Creatinine': CreatinineOptionsModelToJSON(value.creatinine),
        'Egfr': EgfrOptionsModelToJSON(value.egfr),
        'ViewOptions': ViewOptionsModelToJSON(value.viewOptions),
    };
}

