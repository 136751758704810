/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';
import {
    PatientListResponseModel,
    PatientListResponseModelFromJSON,
    PatientListResponseModelFromJSONTyped,
    PatientListResponseModelToJSON,
} from './PatientListResponseModel';

/**
 * 
 * @export
 * @interface PatientListResponseModelApiResponse
 */
export interface PatientListResponseModelApiResponse {
    /**
     * 
     * @type {PatientListResponseModel}
     * @memberof PatientListResponseModelApiResponse
     */
    data?: PatientListResponseModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof PatientListResponseModelApiResponse
     */
    errors?: Array<ApiError> | null;
}

export function PatientListResponseModelApiResponseFromJSON(json: any): PatientListResponseModelApiResponse {
    return PatientListResponseModelApiResponseFromJSONTyped(json, false);
}

export function PatientListResponseModelApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientListResponseModelApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : PatientListResponseModelFromJSON(json['Data']),
        'errors': !exists(json, 'Errors') ? undefined : (json['Errors'] === null ? null : (json['Errors'] as Array<any>).map(ApiErrorFromJSON)),
    };
}

export function PatientListResponseModelApiResponseToJSON(value?: PatientListResponseModelApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': PatientListResponseModelToJSON(value.data),
        'Errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(ApiErrorToJSON)),
    };
}

