import { AppMenuContextProvider } from "context/AppMenuContext";
import { Outlet, useMatch } from "react-router-dom";
import { Landing } from "./Landing";
import "./Layout.scss";
import { SideBar } from "./SideBar";
import { TopBar } from "./TopBar";

export function Layout() {
  const onRoot = useMatch("/");

  if (onRoot) {
    return <Landing />;
  }

  return (
    <div className="Layout">
      <AppMenuContextProvider>
        <TopBar />
        <SideBar />
        <main className="Main">
          <Outlet />
        </main>
      </AppMenuContextProvider>
    </div>
  );
}
