import { Trans, useTranslation } from "react-i18next";
import { TotalNumberDonut } from "./TotalNumberDonut";
import "./TotalNumbers.scss";
import { Icon, useTooltip, ColorDot } from "libs/ui";
import { useHover } from "@floating-ui/react";

interface TotalNumbersProps {
  totalPatients: number;
  patientsWithCKD: number;
}

export function TotalNumbers({ totalPatients, patientsWithCKD }: TotalNumbersProps) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.TotalNumbers" });
  const [ref, getReferenceProps, Tooltip] = useTooltip({
    interactionProps: [useHover],
    placement: "top-start",
  });

  const percentage = () => {
    if (totalPatients === 0) {
      return 0;
    }
    return (patientsWithCKD / totalPatients) * 100;
  };

  const getPatientWithCKDValue = () => {
    if (totalPatients === 0 || Number.isNaN(totalPatients) || Number.isNaN(patientsWithCKD)) {
      return t("NA");
    }

    return patientsWithCKD;
  };

  return (
    <div className="TotalNumbers">
      <p className="TotalNumbers__title">{t("title")}</p>

      <TotalNumberDonut percentage={percentage()} />

      <div className="TotalNumbers__stat">
        <ColorDot type="primary" />

        {/* !! INFO: if 0 or null or undefined, it will be replaced with N/A */}
        <Trans i18nKey={"totalPatients"} t={t} values={{ value: totalPatients || t("NA") }}>
          <span style={{ whiteSpace: "pre-wrap" }}></span>
          <strong></strong>
        </Trans>
      </div>

      <div className="TotalNumbers__stat">
        <ColorDot type="secondary" />

        <Trans i18nKey={"patientsWithCKD"} t={t} values={{ value: getPatientWithCKDValue() }}>
          <span style={{ whiteSpace: "pre-wrap" }}></span>
          <strong></strong>
        </Trans>

        <Icon ref={ref} {...getReferenceProps()} icon={"Info"} scaleTo={14} />
        <Tooltip>
          <div className="TotalNumbers__tooltip">{t("patientsWithCKDTooltip")}</div>
        </Tooltip>
      </div>
    </div>
  );
}
