/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserDetailsResponseModel,
    UserDetailsResponseModelFromJSON,
    UserDetailsResponseModelFromJSONTyped,
    UserDetailsResponseModelToJSON,
} from './UserDetailsResponseModel';

/**
 * 
 * @export
 * @interface UserListResponseModel
 */
export interface UserListResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UserListResponseModel
     */
    paginationToken?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserListResponseModel
     */
    limit?: number;
    /**
     * 
     * @type {Array<UserDetailsResponseModel>}
     * @memberof UserListResponseModel
     */
    users?: Array<UserDetailsResponseModel> | null;
}

export function UserListResponseModelFromJSON(json: any): UserListResponseModel {
    return UserListResponseModelFromJSONTyped(json, false);
}

export function UserListResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationToken': !exists(json, 'PaginationToken') ? undefined : json['PaginationToken'],
        'limit': !exists(json, 'Limit') ? undefined : json['Limit'],
        'users': !exists(json, 'Users') ? undefined : (json['Users'] === null ? null : (json['Users'] as Array<any>).map(UserDetailsResponseModelFromJSON)),
    };
}

export function UserListResponseModelToJSON(value?: UserListResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PaginationToken': value.paginationToken,
        'Limit': value.limit,
        'Users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(UserDetailsResponseModelToJSON)),
    };
}

