/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AncestryType,
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import {
    EgfrFormulaType,
    EgfrFormulaTypeFromJSON,
    EgfrFormulaTypeFromJSONTyped,
    EgfrFormulaTypeToJSON,
} from './EgfrFormulaType';
import {
    GenderType,
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import {
    MeasurementUnitModel,
    MeasurementUnitModelFromJSON,
    MeasurementUnitModelFromJSONTyped,
    MeasurementUnitModelToJSON,
} from './MeasurementUnitModel';
import {
    SampleType,
    SampleTypeFromJSON,
    SampleTypeFromJSONTyped,
    SampleTypeToJSON,
} from './SampleType';
import {
    WeightCategoryType,
    WeightCategoryTypeFromJSON,
    WeightCategoryTypeFromJSONTyped,
    WeightCategoryTypeToJSON,
} from './WeightCategoryType';

/**
 * 
 * @export
 * @interface NovaMaxMeasurementData
 */
export interface NovaMaxMeasurementData {
    /**
     * 
     * @type {MeasurementUnitModel}
     * @memberof NovaMaxMeasurementData
     */
    creatinine: MeasurementUnitModel;
    /**
     * 
     * @type {MeasurementUnitModel}
     * @memberof NovaMaxMeasurementData
     */
    egfr: MeasurementUnitModel;
    /**
     * 
     * @type {string}
     * @memberof NovaMaxMeasurementData
     */
    sequenceNumber: string;
    /**
     * 
     * @type {number}
     * @memberof NovaMaxMeasurementData
     */
    age: number;
    /**
     * 
     * @type {number}
     * @memberof NovaMaxMeasurementData
     */
    weight: number;
    /**
     * 
     * @type {AncestryType}
     * @memberof NovaMaxMeasurementData
     */
    ancestryType: AncestryType;
    /**
     * 
     * @type {GenderType}
     * @memberof NovaMaxMeasurementData
     */
    genderType: GenderType;
    /**
     * 
     * @type {SampleType}
     * @memberof NovaMaxMeasurementData
     */
    sampleType: SampleType;
    /**
     * 
     * @type {EgfrFormulaType}
     * @memberof NovaMaxMeasurementData
     */
    egfrFormulaType: EgfrFormulaType;
    /**
     * 
     * @type {WeightCategoryType}
     * @memberof NovaMaxMeasurementData
     */
    weightCategoryType: WeightCategoryType;
}

export function NovaMaxMeasurementDataFromJSON(json: any): NovaMaxMeasurementData {
    return NovaMaxMeasurementDataFromJSONTyped(json, false);
}

export function NovaMaxMeasurementDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): NovaMaxMeasurementData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creatinine': MeasurementUnitModelFromJSON(json['Creatinine']),
        'egfr': MeasurementUnitModelFromJSON(json['Egfr']),
        'sequenceNumber': json['SequenceNumber'],
        'age': json['Age'],
        'weight': json['Weight'],
        'ancestryType': AncestryTypeFromJSON(json['AncestryType']),
        'genderType': GenderTypeFromJSON(json['GenderType']),
        'sampleType': SampleTypeFromJSON(json['SampleType']),
        'egfrFormulaType': EgfrFormulaTypeFromJSON(json['EgfrFormulaType']),
        'weightCategoryType': WeightCategoryTypeFromJSON(json['WeightCategoryType']),
    };
}

export function NovaMaxMeasurementDataToJSON(value?: NovaMaxMeasurementData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Creatinine': MeasurementUnitModelToJSON(value.creatinine),
        'Egfr': MeasurementUnitModelToJSON(value.egfr),
        'SequenceNumber': value.sequenceNumber,
        'Age': value.age,
        'Weight': value.weight,
        'AncestryType': AncestryTypeToJSON(value.ancestryType),
        'GenderType': GenderTypeToJSON(value.genderType),
        'SampleType': SampleTypeToJSON(value.sampleType),
        'EgfrFormulaType': EgfrFormulaTypeToJSON(value.egfrFormulaType),
        'WeightCategoryType': WeightCategoryTypeToJSON(value.weightCategoryType),
    };
}

