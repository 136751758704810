import { FieldLoader } from "libs/ui/FieldLoader";
import { UACRRangeTableBodyBaseProps } from "./model";
import { UACRRangeTableBody } from "./UACRRangeTableBody";
import "./UACRRangeTable.scss";

interface UACRRangeTableProps extends UACRRangeTableBodyBaseProps {
  loading?: boolean;
}

export function UACRRangeTable({
  loading,
  orientation = "horizontal",
  status,
}: Readonly<UACRRangeTableProps>) {
  if (loading) {
    return (
      <div className="DetailsField">
        <FieldLoader />
      </div>
    );
  }

  return (
    <table className="UACRRangeTable">
      <UACRRangeTableBody orientation={orientation} status={status ?? "None"} />
    </table>
  );
}
