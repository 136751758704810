import { Icon } from "libs/ui";
import "./PatientGeneralInfo.scss";
import { GenderType } from "models/PersonModels";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { getBMICategory } from "utils/getBMICategory";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface PatientGeneralInfoProps {
  gender: GenderType | undefined;
  bmi: number | null | undefined;
  age: number | null | undefined;
}

export function PatientGeneralInfo({ gender, bmi, age }: Readonly<PatientGeneralInfoProps>) {
  const { t } = useTranslation("widgets", { keyPrefix: "PatientInfoWidget.GeneralInfo" });
  const { numberFormatter } = useLocaleNumberFormatter();

  const BMICategory = useMemo(() => (bmi ? getBMICategory(parseFloat(bmi.toString())) : ""), [bmi]);

  return (
    <div className={"PatientGeneralInfo"}>
      <div className="PatientGeneralInfo__item">
        {gender ? (
          <>
            <Icon icon={gender} />
            <h5 className="PatientGeneralInfo__value">{t(`genderType.${gender}`)}</h5>
          </>
        ) : null}
        <p className="PatientGeneralInfo__title">{t("gender")}</p>
      </div>

      <div className="PatientGeneralInfo__item">
        <Icon icon={"Bmi"} />
        <h5 className="PatientGeneralInfo__value">
          {bmi ? numberFormatter({ value: bmi }).concat(` - ${BMICategory}`) : "--"}
        </h5>
        <p className="PatientGeneralInfo__title">{t("bmi")}</p>
      </div>

      <div className="PatientGeneralInfo__item">
        <Icon icon={"Heart"} />
        <h5 className="PatientGeneralInfo__value">{age}</h5>
        <p className="PatientGeneralInfo__title">{t("age")}</p>
      </div>
    </div>
  );
}
