/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LanguageType = {
    aa: 'aa',
    af: 'af',
    agq: 'agq',
    ak: 'ak',
    am: 'am',
    ar: 'ar',
    arn: 'arn',
    as: 'as',
    asa: 'asa',
    ast: 'ast',
    az: 'az',
    ba: 'ba',
    bas: 'bas',
    be: 'be',
    bem: 'bem',
    bez: 'bez',
    bg: 'bg',
    bm: 'bm',
    bn: 'bn',
    bo: 'bo',
    br: 'br',
    brx: 'brx',
    bs: 'bs',
    byn: 'byn',
    ca: 'ca',
    ccp: 'ccp',
    ce: 'ce',
    ceb: 'ceb',
    cgg: 'cgg',
    chr: 'chr',
    ckb: 'ckb',
    co: 'co',
    cs: 'cs',
    cu: 'cu',
    cy: 'cy',
    da: 'da',
    dav: 'dav',
    de: 'de',
    dje: 'dje',
    dsb: 'dsb',
    dua: 'dua',
    dv: 'dv',
    dyo: 'dyo',
    dz: 'dz',
    ebu: 'ebu',
    ee: 'ee',
    el: 'el',
    en: 'en',
    eo: 'eo',
    es: 'es',
    et: 'et',
    eu: 'eu',
    ewo: 'ewo',
    fa: 'fa',
    ff: 'ff',
    fi: 'fi',
    fil: 'fil',
    fo: 'fo',
    fr: 'fr',
    fur: 'fur',
    fy: 'fy',
    ga: 'ga',
    gd: 'gd',
    gl: 'gl',
    gn: 'gn',
    gsw: 'gsw',
    gu: 'gu',
    guz: 'guz',
    gv: 'gv',
    ha: 'ha',
    haw: 'haw',
    he: 'he',
    hi: 'hi',
    hr: 'hr',
    hsb: 'hsb',
    hu: 'hu',
    hy: 'hy',
    ia: 'ia',
    id: 'id',
    ig: 'ig',
    ii: 'ii',
    is: 'is',
    it: 'it',
    iu: 'iu',
    ja: 'ja',
    jgo: 'jgo',
    jmc: 'jmc',
    jv: 'jv',
    ka: 'ka',
    kab: 'kab',
    kam: 'kam',
    kde: 'kde',
    kea: 'kea',
    khq: 'khq',
    ki: 'ki',
    kk: 'kk',
    kkj: 'kkj',
    kl: 'kl',
    kln: 'kln',
    km: 'km',
    kn: 'kn',
    ko: 'ko',
    kok: 'kok',
    ks: 'ks',
    ksb: 'ksb',
    ksf: 'ksf',
    ksh: 'ksh',
    kw: 'kw',
    ky: 'ky',
    lag: 'lag',
    lb: 'lb',
    lg: 'lg',
    lkt: 'lkt',
    ln: 'ln',
    lo: 'lo',
    lrc: 'lrc',
    lt: 'lt',
    lu: 'lu',
    luo: 'luo',
    luy: 'luy',
    lv: 'lv',
    mas: 'mas',
    mer: 'mer',
    mfe: 'mfe',
    mg: 'mg',
    mgh: 'mgh',
    mgo: 'mgo',
    mi: 'mi',
    mk: 'mk',
    ml: 'ml',
    mn: 'mn',
    moh: 'moh',
    mr: 'mr',
    ms: 'ms',
    mt: 'mt',
    mua: 'mua',
    my: 'my',
    mzn: 'mzn',
    naq: 'naq',
    nb: 'nb',
    nd: 'nd',
    nds: 'nds',
    ne: 'ne',
    nl: 'nl',
    nmg: 'nmg',
    nn: 'nn',
    nnh: 'nnh',
    nqo: 'nqo',
    nr: 'nr',
    nso: 'nso',
    nus: 'nus',
    nyn: 'nyn',
    oc: 'oc',
    om: 'om',
    or: 'or',
    os: 'os',
    pa: 'pa',
    pl: 'pl',
    prg: 'prg',
    ps: 'ps',
    pt: 'pt',
    qu: 'qu',
    quc: 'quc',
    rm: 'rm',
    rn: 'rn',
    ro: 'ro',
    rof: 'rof',
    ru: 'ru',
    rw: 'rw',
    rwk: 'rwk',
    sa: 'sa',
    sah: 'sah',
    saq: 'saq',
    sbp: 'sbp',
    sd: 'sd',
    se: 'se',
    seh: 'seh',
    ses: 'ses',
    sg: 'sg',
    shi: 'shi',
    si: 'si',
    sk: 'sk',
    sl: 'sl',
    sma: 'sma',
    smj: 'smj',
    smn: 'smn',
    sms: 'sms',
    sn: 'sn',
    so: 'so',
    sq: 'sq',
    sr: 'sr',
    ss: 'ss',
    ssy: 'ssy',
    st: 'st',
    sv: 'sv',
    sw: 'sw',
    syr: 'syr',
    ta: 'ta',
    te: 'te',
    teo: 'teo',
    tg: 'tg',
    th: 'th',
    ti: 'ti',
    tig: 'tig',
    tk: 'tk',
    tn: 'tn',
    to: 'to',
    tr: 'tr',
    ts: 'ts',
    tt: 'tt',
    twq: 'twq',
    tzm: 'tzm',
    ug: 'ug',
    uk: 'uk',
    ur: 'ur',
    uz: 'uz',
    vai: 'vai',
    ve: 've',
    vi: 'vi',
    vo: 'vo',
    vun: 'vun',
    wae: 'wae',
    wal: 'wal',
    wo: 'wo',
    xh: 'xh',
    xog: 'xog',
    yav: 'yav',
    yi: 'yi',
    yo: 'yo',
    zgh: 'zgh',
    zh: 'zh',
    zu: 'zu'
} as const;
export type LanguageType = typeof LanguageType[keyof typeof LanguageType];


export function LanguageTypeFromJSON(json: any): LanguageType {
    return LanguageTypeFromJSONTyped(json, false);
}

export function LanguageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageType {
    return json as LanguageType;
}

export function LanguageTypeToJSON(value?: LanguageType | null): any {
    return value as any;
}

