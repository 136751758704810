import { isValidPhoneNumber } from "libphonenumber-js";
import { OrganizationModel } from "./model";

import Ajv from "ajv";
import { FormType } from "models/FormModeModels";
import { ConfigFieldModel, ContextsFieldConfig, GlobalConfig } from "utils/createGlobalConfigStore";

function getOptionalFieldConfig(
  field: ConfigFieldModel<any> | undefined,
  key: keyof ContextsFieldConfig,
) {
  const { visibility } = field?.contexts[key] ?? {};

  return {
    type: "string",
    nullable: visibility !== "Required",
    ...(visibility === "Required" ? { isNotEmpty: true, minLength: 1 } : undefined),
  };
}

function getFieldsReady(
  organizationData: OrganizationModel,
  formType: FormType,
  globalConfig?: GlobalConfig,
) {
  const { zipCode } = globalConfig?.entities.organization ?? {};
  const key: keyof ContextsFieldConfig = formType === "Add" ? "Add" : "Update";

  const statesRequired = !!(globalConfig?.general.custom.enums as any)?.[
    `${organizationData.country}_States`
  ];
  const stateMaximum = statesRequired
    ? (globalConfig?.general.custom.enums as any)?.[`${organizationData.country}_States`].slice(
        -1,
      )[0].V
    : null;

  const ajv = new Ajv(); //{ allowUnionTypes: true }

  ajv.addKeyword({
    keyword: "isNotEmpty",
    validate: (schema: any, data: any, _current: any, rest: any) => {
      if (schema) {
        switch (rest.parentDataProperty as keyof OrganizationModel) {
          case "phone":
            return isValidPhoneNumber(data);

          default:
            return !!data && data.toString().trim() !== "";
        }
      }

      return false;
    },
  });

  const schema = {
    type: "object",
    properties: {
      name: {
        type: "string",
        nullable: false,
        isNotEmpty: true,
        minLength: 1,
      },
      street: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      city: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      phone: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      country: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },

      zipCode: getOptionalFieldConfig(zipCode, key),
      state: {
        type: "integer",
        nullable: !statesRequired,
        ...(statesRequired ? { isNotEmpty: true, minimum: 1, maximum: stateMaximum } : undefined),
      },
    },

    required: [
      "name",
      "street",
      "city",
      "phone",
      "country",
      ...(zipCode?.contexts[key].visibility === "Required" ? ["zipCode"] : []),
      ...(statesRequired ? ["state"] : []),
    ],
    additionalProperties: true,
  }; //satisfies JSONSchemaType<OrganizationModel>;

  const validate = ajv.compile(schema);

  const valid = validate(organizationData);

  return valid;
}

export const organizationFormUtils = { getFieldsReady };
