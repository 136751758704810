import { useInterpret, useActor } from "@xstate/react";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { createSafeContext } from "utils/createSafeContext";
import {
  HCPTableStateContext,
  HCPsFilterOptionsKey,
  HCPs_FILTER_OPTIONS,
  makeTableFetchStateMachine,
} from "../common/HCPTable/model";
import { useFilterModal } from "../common/hooks";
import { getFilterOptionsPerConfiguration } from "../utils";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

const Context = createSafeContext<HCPTableStateContext>();

export const useHCPTableStateContext = Context.hook;

export function HCPTableStateProvider({ children }: Readonly<PropsWithChildren<{}>>) {
  const tableMachine = useMemo(() => makeTableFetchStateMachine(), []);
  const tableService = useInterpret(tableMachine);
  const { appConfig } = useGlobalConfigContext();

  const filterSideModelState = useFilterModal<HCPsFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      HCPs_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Hcp.Index.filterOptions),
    ),
  );

  const [, send] = useActor(tableService);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  const value = useMemo(
    () => ({
      tableService,
      fetch,
      filterSideModelState,
    }),
    [fetch, filterSideModelState, tableService],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
