import { useActor, useInterpret } from "@xstate/react";
import { DeviceResponseModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { breadcrumbsStore } from "config/breadcrumbs";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { makeDetailsPageStateMachine } from "utils/machines/pages/details/makeDetailsPageStateMachine";
import { DetailsPageServiceList } from "utils/machines/pages/details/model";
import { assign } from "xstate";

function useApis() {
  const { organizationId = "", deviceId = "" } = useParams<RouteParams["deviceDetails"]>();

  const getDevice = useCallback(
    () => CarnaApiQuery.Devices.getById({ organizationId, deviceEntityId: deviceId }),
    [organizationId, deviceId],
  );

  return { fetchDeviceData: getDevice };
}

const errorToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("DeviceDetails.errorFetchToast", { ns: "translation" }),
  });
};

export function useMakeDevicesPageDetailState() {
  const { fetchDeviceData } = useApis();

  const deviceDetailPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        DeviceResponseModel,
        never,
        DetailsPageServiceList<typeof fetchDeviceData, never, never>
      >(),
    [],
  );

  const service = useInterpret(deviceDetailPageStateMachine, {
    services: {
      saveStatus: undefined,
      saveData: undefined,
      fetchData: fetchDeviceData,
    },
    actions: {
      updateSaveStatus: undefined,
      dataSaved: undefined,
      savingFailed: undefined,
      failedToLoadData: errorToast,
      loadSavedData: undefined,
      loadEntityData: assign({
        data: (_context, event) => {
          return event.data;
        },
      }),
      refreshTable: () => {},
    },
  });

  return service;
}

export function useBreadcrumbsToSelectedDevice(
  detailsService: ReturnType<typeof useMakeDevicesPageDetailState>,
) {
  const [state] = useActor(detailsService);
  const { t } = useTranslation("translation", { keyPrefix: "deviceType" });

  useEffect(() => {
    if (!state.matches("entity.loading")) {
      breadcrumbsStore.setCurrentPageTitle(t(`${state.context?.data?.deviceType}`));
    }

    return () => breadcrumbsStore.resetCurrentPageTitle();
  }, [state, t]);
}
