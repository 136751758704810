import { useActor, useInterpret } from "@xstate/react";
import { DeviceResponseModelListModel, DevicesFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { TableContext, tableStateMachine } from "libs/ui";
import { DevicesFilterOptionsKey, DEVICES_FILTER_OPTIONS } from "../common/DevicesTable/model";
import { useCallback, useEffect, useMemo } from "react";
import { useFilterModal } from "../common/hooks";
import { DevicesTable } from "../common/DevicesTable";
import { TabModalControl } from "components/TabModalControl";
import { useParams } from "react-router";
import { RouteParams } from "router";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { getFilterOptionsPerConfiguration } from "../utils";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useOrganizationDetailsServiceContext } from "pages/Settings/Organizations/Details/Context";

async function fetchDevices(
  organizationId: string,
  context?: TableContext<DeviceResponseModelListModel, DevicesFilterModel>,
) {
  const result = await CarnaApiQuery.Devices.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

const makeTableFetchStateMachine = (organizationId: string) =>
  tableStateMachine((context?: TableContext<DeviceResponseModelListModel, DevicesFilterModel>) =>
    fetchDevices(organizationId, context),
  );

export function DevicesPerOrganizationTable() {
  const { organizationId = "" } = useParams<RouteParams["organizationDetails"]>();
  const tableMachine = useMemo(() => makeTableFetchStateMachine(organizationId), [organizationId]);
  const tableService = useInterpret(tableMachine);
  const [state, send] = useActor(tableService);
  const { appConfig } = useGlobalConfigContext();

  const { detailsService } = useOrganizationDetailsServiceContext();
  const [detailsState] = useActor(detailsService);

  // const [avatarsState] = useMakeDevicesTableAvatarsState(tableService); !!TO-DO NEW_BACKEND

  const filterSideModelState = useFilterModal<DevicesFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      DEVICES_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Devices.DevicesPerOrganization.filterOptions),
    ),
  );

  const onFilterToggle = useCallback(
    () => filterSideModelState.openFilterModal(),
    [filterSideModelState],
  );

  const onSearchChange = (value: string) =>
    send([{ type: "UPDATE_FILTERS", value: { ...state.context.filters, contains: value } }]);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  useEffect(() => {
    if (state.matches("init")) {
      fetch();
    }
  }, [fetch, state]);

  return detailsState.matches("entity.loaded") ? (
    <DevicesTable
      appliedColumns={NotNullOrUndefined(
        appConfig?.components.tables.Devices.DevicesPerOrganization.columnList,
      )}
      devicesTableStateContext={{ tableService, filterSideModelState, fetch }}
      onFilterToggle={onFilterToggle}
      searchField={{
        onChange: onSearchChange,
        value: state.context.filters?.contains ?? "",
      }}
      addButton={
        <TabModalControl
          variant="Device"
          onSuccessCb={fetch}
          initialValuesAsProps={{
            organizationId: detailsState.context.data?.id,
            organizationName: detailsState.context.data?.name,
          }}
        />
      }
      devicesFilterOptions={
        appConfig?.components.tables.Devices.DevicesPerOrganization.filterOptions
      }
      initialValuesAsProps={{
        organizationId: detailsState.context.data?.id,
        organizationName: detailsState.context.data?.name,
      }}
    />
  ) : null;
}
