import { Configuration } from "../api";
import {
  AccountDataApi,
  AdminApi,
  AuthApi,
  DevicesApi,
  DocumentApi,
  GlobalPreferenceApi,
  GraphApi,
  HcpApi,
  MeasurementApi,
  OrganizationApi,
  PartnerApi,
  PatientApi,
  RulesApi,
  SupportFormApi,
  TrackingDevicesApi,
  UsersApi,
} from "../api/apis";

import { Auth } from "aws-amplify";
import { signRequest } from "./awsConfig";
import { HTTP_FORBIDDEN } from "./const";
import { querystring } from "./endpointSerialization";

let checkingForForbiddenUser: Promise<any> | null = null;
export const configurationAuthApi = new Configuration({
  basePath: `${process.env.VITE_APP_QUERY_HOST ?? "VITE_APP_QUERY_HOST-env-variable-missing"}`,
});

export const configuration =
  process.env.MODE === "test"
    ? undefined
    : new Configuration({
        /**
         * check this function in runtime.ts
         */
        queryParamsStringify: querystring,
        basePath: `${
          process.env.VITE_APP_QUERY_HOST ?? "VITE_APP_QUERY_HOST-env-variable-missing"
        }`,
        // (input: RequestInfo | URL, init?: RequestInit): Promise<Response>
        middleware: [
          {
            async post(param) {
              if (param.response.status === HTTP_FORBIDDEN) {
                // Avoiding concurrency
                if (checkingForForbiddenUser) {
                  await checkingForForbiddenUser;
                } else {
                  try {
                    checkingForForbiddenUser = Auth.currentAuthenticatedUser({
                      bypassCache: true,
                    });
                    await checkingForForbiddenUser;
                  } catch (error) {
                    console.warn("User is possibly logged out");
                  } finally {
                    checkingForForbiddenUser = null;
                  }
                }
              }

              return param.response;
            },
            async pre(context) {
              const sign = await signRequest(
                context.url,
                "execute-api",
                context.init?.method,
                "eu-central-1",
                context.init.body,
              );

              return {
                url: context.url,
                init: {
                  ...context.init,
                  headers: new Headers({
                    ...context.init.headers,
                    ...sign.headers,
                    "access-token": `${(await Auth.currentSession())
                      .getAccessToken()
                      .getJwtToken()}`,
                    "identity-token": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                  }),
                },
              };
            },
          },
        ],
      });

const AdminApiContext = new AdminApi(configuration);
const PartnerApiContext = new PartnerApi(configuration);
const UsersApiContext = new UsersApi(configuration);
const OrganizationApiContext = new OrganizationApi(configuration);
const PatientApiContext = new PatientApi(configuration);
const HcpApiContext = new HcpApi(configuration);
const DevicesApiContext = new DevicesApi(configuration);
const MeasurementApiApiContext = new MeasurementApi(configuration);
const DocumentApContext = new DocumentApi(configuration);
const SupportFormApiContext = new SupportFormApi(configuration);
// const ConfigurationApiContext = new ConfigurationApi(configuration);
const RuleApiContext = new RulesApi(configuration);
const GraphApiContext = new GraphApi(configuration);
const TrackingDevicesApiContext = new TrackingDevicesApi(configuration);
const AccountDataApiContext = new AccountDataApi(configuration);

const GlobalPreferenceApiContext = new GlobalPreferenceApi(configuration);

export const CarnaApi = {
  Admin: {
    devicesGet: AdminApi.prototype.devicesGet.bind(AdminApiContext),
    devicesIdGet: AdminApi.prototype.devicesIdGet.bind(AdminApiContext),
    hcpsGet: AdminApi.prototype.hcpsGet.bind(AdminApiContext),
    hcpsIdGet: AdminApi.prototype.hcpsIdGet.bind(AdminApiContext),
    organizationsOrganizationIdAdministratorsGet:
      AdminApi.prototype.organizationsOrganizationIdAdministratorsGet.bind(AdminApiContext),
    organizationsOrganizationIdAdministratorsIdGet:
      AdminApi.prototype.organizationsOrganizationIdAdministratorsIdGet.bind(AdminApiContext),
    organizationsOrganizationIdAdministratorsIdPatch:
      AdminApi.prototype.organizationsOrganizationIdAdministratorsIdPatch.bind(AdminApiContext),
    organizationsOrganizationIdAdministratorsIdPut:
      AdminApi.prototype.organizationsOrganizationIdAdministratorsIdPut.bind(AdminApiContext),
    organizationsOrganizationIdAdministratorsPost:
      AdminApi.prototype.organizationsOrganizationIdAdministratorsPost.bind(AdminApiContext),
    patientsGet: AdminApi.prototype.patientsGet.bind(AdminApiContext),
    patientsIdGet: AdminApi.prototype.patientsIdGet.bind(AdminApiContext),
    organizationsOrganizationIdAdministratorsIdDelete:
      AdminApi.prototype.organizationsOrganizationIdAdministratorsIdDelete.bind(AdminApiContext),
    organizationsOrganizationIdAdministratorsIdResendSignUpPost:
      AdminApi.prototype.organizationsOrganizationIdAdministratorsIdResendSignUpPost.bind(
        AdminApiContext,
      ),
  } as const,

  Partner: {
    organizationsOrganizationIdPartnersGet:
      PartnerApi.prototype.organizationsOrganizationIdPartnersGet.bind(PartnerApiContext),
    organizationsOrganizationIdPartnersIdGet:
      PartnerApi.prototype.organizationsOrganizationIdPartnersIdGet.bind(PartnerApiContext),
    organizationsOrganizationIdPartnersIdPatch:
      PartnerApi.prototype.organizationsOrganizationIdPartnersIdPatch.bind(PartnerApiContext),
    organizationsOrganizationIdPartnersIdPut:
      PartnerApi.prototype.organizationsOrganizationIdPartnersIdPut.bind(PartnerApiContext),
    organizationsOrganizationIdPartnersPost:
      PartnerApi.prototype.organizationsOrganizationIdPartnersPost.bind(PartnerApiContext),
    organizationsOrganizationIdPartnersIdDelete:
      PartnerApi.prototype.organizationsOrganizationIdPartnersIdDelete.bind(PartnerApiContext),
    organizationsOrganizationIdPartnersIdResendSignUpPost:
      PartnerApi.prototype.organizationsOrganizationIdPartnersIdResendSignUpPost.bind(
        PartnerApiContext,
      ),
  } as const,

  Users: {
    usersGet: UsersApi.prototype.usersGet.bind(UsersApiContext),
    usersIdGet: UsersApi.prototype.usersIdGet.bind(UsersApiContext),
  } as const,

  Organization: {
    organizationsGet: OrganizationApi.prototype.organizationsGet.bind(OrganizationApiContext),
    organizationsIdGet: OrganizationApi.prototype.organizationsIdGet.bind(OrganizationApiContext),
    organizationsIdPut: OrganizationApi.prototype.organizationsIdPut.bind(OrganizationApiContext),
    organizationsPost: OrganizationApi.prototype.organizationsPost.bind(OrganizationApiContext),
  } as const,

  Patient: {
    organizationsOrganizationIdPatientsGet:
      PatientApi.prototype.organizationsOrganizationIdPatientsGet.bind(PatientApiContext),
    organizationsOrganizationIdPatientsIdGet:
      PatientApi.prototype.organizationsOrganizationIdPatientsIdGet.bind(PatientApiContext),
    organizationsOrganizationIdPatientsIdPatch:
      PatientApi.prototype.organizationsOrganizationIdPatientsIdPatch.bind(PatientApiContext),
    organizationsOrganizationIdPatientsIdPut:
      PatientApi.prototype.organizationsOrganizationIdPatientsIdPut.bind(PatientApiContext),
    organizationsOrganizationIdPatientsPost:
      PatientApi.prototype.organizationsOrganizationIdPatientsPost.bind(PatientApiContext),
    organizationsOrganizationIdPatientsIdDelete:
      PatientApi.prototype.organizationsOrganizationIdPatientsIdDelete.bind(PatientApiContext),
    organizationsOrganizationIdPatientsIdResendSignUpPost:
      PatientApi.prototype.organizationsOrganizationIdPatientsIdResendSignUpPost.bind(
        PatientApiContext,
      ),
  } as const,

  Hcp: {
    organizationsOrganizationIdHcpsGet:
      HcpApi.prototype.organizationsOrganizationIdHcpsGet.bind(HcpApiContext),
    organizationsOrganizationIdHcpsIdGet:
      HcpApi.prototype.organizationsOrganizationIdHcpsIdGet.bind(HcpApiContext),
    organizationsOrganizationIdHcpsIdPatch:
      HcpApi.prototype.organizationsOrganizationIdHcpsIdPatch.bind(HcpApiContext),
    organizationsOrganizationIdHcpsIdPatientsGet:
      HcpApi.prototype.organizationsOrganizationIdHcpsIdPatientsGet.bind(HcpApiContext),
    organizationsOrganizationIdHcpsIdPut:
      HcpApi.prototype.organizationsOrganizationIdHcpsIdPut.bind(HcpApiContext),
    organizationsOrganizationIdHcpsPost:
      HcpApi.prototype.organizationsOrganizationIdHcpsPost.bind(HcpApiContext),
    organizationsOrganizationIdHcpsIdDelete:
      HcpApi.prototype.organizationsOrganizationIdHcpsIdDelete.bind(HcpApiContext),
    organizationsOrganizationIdHcpsIdResendSignUpPost:
      HcpApi.prototype.organizationsOrganizationIdHcpsIdResendSignUpPost.bind(HcpApiContext),
  } as const,

  Devices: {
    organizationsOrganizationIdDevicesGet:
      DevicesApi.prototype.organizationsOrganizationIdDevicesGet.bind(DevicesApiContext),
    organizationsOrganizationIdDevicesIdGet:
      DevicesApi.prototype.organizationsOrganizationIdDevicesIdGet.bind(DevicesApiContext),
    organizationsOrganizationIdDevicesPost:
      DevicesApi.prototype.organizationsOrganizationIdDevicesPost.bind(DevicesApiContext),
    organizationsOrganizationIdPatientsIdDevicesGet:
      DevicesApi.prototype.organizationsOrganizationIdPatientsIdDevicesGet.bind(DevicesApiContext),
  } as const,

  MeasurementApi: {
    measurementsGet: MeasurementApi.prototype.measurementsGet.bind(MeasurementApiApiContext),
    organizationsOrganizationIdDevicesIdMeasurementsGet:
      MeasurementApi.prototype.organizationsOrganizationIdDevicesIdMeasurementsGet.bind(
        MeasurementApiApiContext,
      ),
    organizationsOrganizationIdHcpsIdMeasurementsBatchPost:
      MeasurementApi.prototype.organizationsOrganizationIdHcpsIdMeasurementsBatchPost.bind(
        MeasurementApiApiContext,
      ),
    organizationsOrganizationIdHcpsIdMeasurementsPost:
      MeasurementApi.prototype.organizationsOrganizationIdHcpsIdMeasurementsPost.bind(
        MeasurementApiApiContext,
      ),
    organizationsOrganizationIdMeasurementsGet:
      MeasurementApi.prototype.organizationsOrganizationIdMeasurementsGet.bind(
        MeasurementApiApiContext,
      ),
    organizationsOrganizationIdPatientsIdMeasurementsBatchPost:
      MeasurementApi.prototype.organizationsOrganizationIdPatientsIdMeasurementsBatchPost.bind(
        MeasurementApiApiContext,
      ),
    getPatientMeasurements:
      MeasurementApi.prototype.organizationsOrganizationIdPatientsIdMeasurementsGet.bind(
        MeasurementApiApiContext,
      ),
    organizationsOrganizationIdPatientsIdMeasurementsPost:
      MeasurementApi.prototype.organizationsOrganizationIdPatientsIdMeasurementsPost.bind(
        MeasurementApiApiContext,
      ),
    organizationsOrganizationIdHcpsIdPatientsMeasurementsGet:
      MeasurementApi.prototype.organizationsOrganizationIdHcpsIdPatientsMeasurementsGet.bind(
        MeasurementApiApiContext,
      ),
  } as const,

  DocumentApi: {
    downloadImagesPost: DocumentApi.prototype.downloadImagesPost.bind(DocumentApContext),
    organizationsOrganizationIdUsersIdDeleteImageDelete:
      DocumentApi.prototype.organizationsOrganizationIdUsersIdDeleteImageDelete.bind(
        DocumentApContext,
      ),
    organizationsOrganizationIdUsersIdUploadImagePut:
      DocumentApi.prototype.organizationsOrganizationIdUsersIdUploadImagePut.bind(
        DocumentApContext,
      ),
  } as const,

  SupportFormApi: {
    supportFormPost: SupportFormApi.prototype.supportFormPost.bind(SupportFormApiContext),
  } as const,

  GlobalPreference: {
    organizationsOrganizationIdGlobalpreferencesIdPut:
      GlobalPreferenceApi.prototype.organizationsOrganizationIdGlobalpreferencesIdPut.bind(
        GlobalPreferenceApiContext,
      ),
    organizationsOrganizationIdGlobalpreferencesPost:
      GlobalPreferenceApi.prototype.organizationsOrganizationIdGlobalpreferencesPost.bind(
        GlobalPreferenceApiContext,
      ),
  } as const,

  RulesApi: {
    rulesPut: RulesApi.prototype.rulesPut.bind(RuleApiContext),
  } as const,

  GraphApi: {
    patientGraphGet:
      GraphApi.prototype.organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGet.bind(
        GraphApiContext,
      ),
  } as const,

  TrackingDevicesApi: {
    trackingDevicesGet:
      TrackingDevicesApi.prototype.trackingDevicesGet.bind(TrackingDevicesApiContext),
  } as const,

  AccountDataApi: {
    accountDataPost: AccountDataApi.prototype.accountDataPost.bind(AccountDataApiContext),
  } as const,

  Auth: new AuthApi(configurationAuthApi),
} as const;

declare global {
  interface Window {
    CarnaApi: any;
  }
}
