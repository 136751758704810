/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentResponseModel
 */
export interface DocumentResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseModel
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseModel
     */
    organizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponseModel
     */
    image?: string | null;
}

export function DocumentResponseModelFromJSON(json: any): DocumentResponseModel {
    return DocumentResponseModelFromJSONTyped(json, false);
}

export function DocumentResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'UserId') ? undefined : json['UserId'],
        'organizationId': !exists(json, 'OrganizationId') ? undefined : json['OrganizationId'],
        'image': !exists(json, 'Image') ? undefined : json['Image'],
    };
}

export function DocumentResponseModelToJSON(value?: DocumentResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'UserId': value.userId,
        'OrganizationId': value.organizationId,
        'Image': value.image,
    };
}

