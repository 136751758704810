import { UACRLimitKey } from "utils/hooks/useGetUACRRanges";
import { HeatMapTableElementsProps } from "../model";
import { HeatCell } from "../HeatCell";

interface HeatRowProps extends HeatMapTableElementsProps {}

export function HeatRow({ ...props }: HeatRowProps) {
  const cellKeys = Object.keys(UACRLimitKey) as UACRLimitKey[];

  return (
    <tr className="HeatRow">
      {cellKeys.map(key => (
        <HeatCell key={key} cellKey={key} {...props} />
      ))}
    </tr>
  );
}
