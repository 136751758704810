import { Loader } from "libs/ui";
import { isDefaultModel, isFailed, isLoading } from "models/loadable";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { useTranslation } from "react-i18next";
import { FilterSection } from "../FilterSection";
import { CarnaApiQuery } from "config/apiQuery";
import { hcpCollectionToSelectOptions } from "utils/mappers/hcpCollectionToSelectOptions";
import { useApi } from "utils/hooks/useApi";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";

interface HcpsFilterSectionProps {
  checkedValues: string[];
  onFilterApply: (value: string) => void;
  hcpsFilter?: boolean;
}

function useHcpData(hcpsFilter?: boolean) {
  const { organizationId, isAdmin } = useCurrentUserData();
  const { organizationId: currentOrganizationId } = useParams<RouteParams["organizationDetails"]>();

  const [hcpsData, getHcps] = useApi(CarnaApiQuery.Hcps.get);

  const [hcpsDataAdmin, getHcpsAdmin] = useApi(CarnaApiQuery.Admin.getHcps);

  const resolveHCPData = () => {
    if (hcpsFilter) {
      return hcpsData;
    }

    return isAdmin ? hcpsDataAdmin : hcpsData;
  };

  const adminCb = () => getHcpsAdmin({ filters: undefined, limit: 100, page: 1 });
  const userCb = () => getHcps({ organizationId, filters: undefined, limit: 100, page: 1 });

  const resolveGetHCPDataCB = () => {
    if (hcpsFilter) {
      const getHcpsForCurrentOrganization = () =>
        getHcps({
          organizationId: currentOrganizationId ?? "",
          filters: undefined,
          limit: 100,
          page: 1,
        });
      return getHcpsForCurrentOrganization;
    }

    return isAdmin ? adminCb : userCb;
  };

  return {
    hcpData: resolveHCPData(),
    getHcpData: resolveGetHCPDataCB(),
  } as const;
}

export function HcpsFilterSection({
  checkedValues,
  onFilterApply,
  hcpsFilter,
}: HcpsFilterSectionProps) {
  const { t } = useTranslation("translation", { keyPrefix: "HcpsFilterSection" });

  const { hcpData, getHcpData } = useHcpData(hcpsFilter);

  useEffect(() => {
    if (isDefaultModel(hcpData) && hcpsFilter !== false) {
      getHcpData();
    }
  }, [hcpData, getHcpData, hcpsFilter]);

  if (hcpsFilter === false) {
    return null;
  }

  if (isFailed(hcpData)) {
    return <>{t("notAvailable")}</>;
  }

  return (
    <Loader loading={isLoading(hcpData)}>
      <FilterSection
        title={t("hcp")}
        options={hcpCollectionToSelectOptions(hcpData)}
        checkedValues={checkedValues}
        onFilterApply={onFilterApply}
        withSearch={true}
      />
    </Loader>
  );
}
