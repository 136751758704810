import { useCallback } from "react";
import { useCurrentUserData } from "../useCurrentUserData";
import { useLocalStorage } from "../useLocalStorage";

export function useClearAndReloadIfSameUser() {
  const { removeLocalStorageItem } = useLocalStorage("lastUsedAuth");
  const { currentUserId } = useCurrentUserData();

  const clearAndReloadIfSameUser = useCallback(
    (userId: string | undefined | null) => {
      if (userId === currentUserId) {
        removeLocalStorageItem();
        window.location.reload();
      }
    },
    [removeLocalStorageItem, currentUserId],
  );

  return [clearAndReloadIfSameUser] as const;
}
