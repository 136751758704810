/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddPatientRequestModel,
    AddPatientRequestModelFromJSON,
    AddPatientRequestModelToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    PatientDetailsResponseModelApiResponse,
    PatientDetailsResponseModelApiResponseFromJSON,
    PatientDetailsResponseModelApiResponseToJSON,
    PatientListResponseModelApiResponse,
    PatientListResponseModelApiResponseFromJSON,
    PatientListResponseModelApiResponseToJSON,
    PatientsFilter,
    PatientsFilterFromJSON,
    PatientsFilterToJSON,
    ResendSignUpRequestModel,
    ResendSignUpRequestModelFromJSON,
    ResendSignUpRequestModelToJSON,
    StringApiResponse,
    StringApiResponseFromJSON,
    StringApiResponseToJSON,
    UpdatePatientRequestModel,
    UpdatePatientRequestModelFromJSON,
    UpdatePatientRequestModelToJSON,
    UpdatePatientStatusRequestModel,
    UpdatePatientStatusRequestModelFromJSON,
    UpdatePatientStatusRequestModelToJSON,
    UpdateUserStatusResponseModelApiResponse,
    UpdateUserStatusResponseModelApiResponseFromJSON,
    UpdateUserStatusResponseModelApiResponseToJSON,
} from '../models';

export interface OrganizationsOrganizationIdPatientsGetRequest {
    limit: number;
    organizationId: string;
    paginationToken?: string;
    filters?: PatientsFilter;
}

export interface OrganizationsOrganizationIdPatientsIdDeleteRequest {
    id: string;
    rowVersion: number;
    organizationId: string;
}

export interface OrganizationsOrganizationIdPatientsIdGetRequest {
    id: string;
    organizationId: string;
}

export interface OrganizationsOrganizationIdPatientsIdPatchRequest {
    id: string;
    organizationId: string;
    UpdatePatientStatusRequestModel?: UpdatePatientStatusRequestModel;
}

export interface OrganizationsOrganizationIdPatientsIdPutRequest {
    id: string;
    organizationId: string;
    UpdatePatientRequestModel?: UpdatePatientRequestModel;
}

export interface OrganizationsOrganizationIdPatientsIdResendSignUpPostRequest {
    organizationId: string;
    id: string;
    ResendSignUpRequestModel: ResendSignUpRequestModel;
}

export interface OrganizationsOrganizationIdPatientsPostRequest {
    organizationId: string;
    AddPatientRequestModel?: AddPatientRequestModel;
}

/**
 * PatientApi - interface
 * 
 * @export
 * @interface PatientApiInterface
 */
export interface PatientApiInterface {
    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} [paginationToken] 
     * @param {PatientsFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    organizationsOrganizationIdPatientsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsGet(limit: number, organizationId: string, paginationToken?: string, filters?: PatientsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {number} rowVersion 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    organizationsOrganizationIdPatientsIdDeleteRaw(requestParameters: OrganizationsOrganizationIdPatientsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdDelete(id: string, rowVersion: number, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    organizationsOrganizationIdPatientsIdGetRaw(requestParameters: OrganizationsOrganizationIdPatientsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdatePatientStatusRequestModel} [UpdatePatientStatusRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    organizationsOrganizationIdPatientsIdPatchRaw(requestParameters: OrganizationsOrganizationIdPatientsIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateUserStatusResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdPatch(id: string, organizationId: string, UpdatePatientStatusRequestModel?: UpdatePatientStatusRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateUserStatusResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdatePatientRequestModel} [UpdatePatientRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    organizationsOrganizationIdPatientsIdPutRaw(requestParameters: OrganizationsOrganizationIdPatientsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdPut(id: string, organizationId: string, UpdatePatientRequestModel?: UpdatePatientRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {ResendSignUpRequestModel} ResendSignUpRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    organizationsOrganizationIdPatientsIdResendSignUpPostRaw(requestParameters: OrganizationsOrganizationIdPatientsIdResendSignUpPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdResendSignUpPost(organizationId: string, id: string, ResendSignUpRequestModel: ResendSignUpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {AddPatientRequestModel} [AddPatientRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    organizationsOrganizationIdPatientsPostRaw(requestParameters: OrganizationsOrganizationIdPatientsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsPost(organizationId: string, AddPatientRequestModel?: AddPatientRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientDetailsResponseModelApiResponse>;

}

/**
 * 
 */
export class PatientApi extends runtime.BaseAPI implements PatientApiInterface {

    /**
     */
    async organizationsOrganizationIdPatientsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdPatientsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsGet(limit: number, organizationId: string, paginationToken?: string, filters?: PatientsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsGetRaw({ limit: limit, organizationId: organizationId, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdDeleteRaw(requestParameters: OrganizationsOrganizationIdPatientsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdDelete.');
        }

        if (requestParameters.rowVersion === null || requestParameters.rowVersion === undefined) {
            throw new runtime.RequiredError('rowVersion','Required parameter requestParameters.rowVersion was null or undefined when calling organizationsOrganizationIdPatientsIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdDelete(id: string, rowVersion: number, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdDeleteRaw({ id: id, rowVersion: rowVersion, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdGetRaw(requestParameters: OrganizationsOrganizationIdPatientsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdGetRaw({ id: id, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdPatchRaw(requestParameters: OrganizationsOrganizationIdPatientsIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateUserStatusResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdPatch.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePatientStatusRequestModelToJSON(requestParameters.UpdatePatientStatusRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserStatusResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdPatch(id: string, organizationId: string, UpdatePatientStatusRequestModel?: UpdatePatientStatusRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateUserStatusResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdPatchRaw({ id: id, organizationId: organizationId, UpdatePatientStatusRequestModel: UpdatePatientStatusRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdPutRaw(requestParameters: OrganizationsOrganizationIdPatientsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdPut.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePatientRequestModelToJSON(requestParameters.UpdatePatientRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdPut(id: string, organizationId: string, UpdatePatientRequestModel?: UpdatePatientRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdPutRaw({ id: id, organizationId: organizationId, UpdatePatientRequestModel: UpdatePatientRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdResendSignUpPostRaw(requestParameters: OrganizationsOrganizationIdPatientsIdResendSignUpPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdResendSignUpPost.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdResendSignUpPost.');
        }

        if (requestParameters.ResendSignUpRequestModel === null || requestParameters.ResendSignUpRequestModel === undefined) {
            throw new runtime.RequiredError('ResendSignUpRequestModel','Required parameter requestParameters.ResendSignUpRequestModel was null or undefined when calling organizationsOrganizationIdPatientsIdResendSignUpPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}/resendSignUp`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendSignUpRequestModelToJSON(requestParameters.ResendSignUpRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdResendSignUpPost(organizationId: string, id: string, ResendSignUpRequestModel: ResendSignUpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdResendSignUpPostRaw({ organizationId: organizationId, id: id, ResendSignUpRequestModel: ResendSignUpRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsPostRaw(requestParameters: OrganizationsOrganizationIdPatientsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientDetailsResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/patients`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPatientRequestModelToJSON(requestParameters.AddPatientRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsPost(organizationId: string, AddPatientRequestModel?: AddPatientRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsPostRaw({ organizationId: organizationId, AddPatientRequestModel: AddPatientRequestModel }, initOverrides);
        return await response.value();
    }

}
