import classNames from "classnames";
import { Card } from "libs/ui/Card";
import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { useCallback, useSyncExternalStore } from "react";
import { avatarCache } from "utils/machines/avatars";
import { useOverviewAPIContext } from "../OverviewAPIContext";
import { RefreshFailedWidget } from "../RefreshFailedWidget";
import { WidgetLoader } from "../WidgetLoader";
import { PatientGeneralInfo } from "./PatientGeneralInfo";
import { PatientInfoHeader } from "./PatientInfoHeader";
import "./PatientInfoWidget.scss";
import { PatientOrganizationalInfo } from "./PatientOrganizationalInfo";
import { PictureResolution } from "utils/helpers/pictures.model";

interface Props {
  organizationId: string;
  patientId: string;
}

export function PatientInfoWidget({ organizationId, patientId }: Readonly<Props>) {
  const {
    data: { PatientInfo },
    getWidgetData,
  } = useOverviewAPIContext();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const patientInfoData = isLoaded(PatientInfo) ? PatientInfo.value : null;

  const refreshWidgetData = useCallback(() => {
    getWidgetData(organizationId, patientId, ["PatientInfo"]);
  }, [getWidgetData, patientId, organizationId]);

  return (
    <div className={classNames("PatientInfoWidget")}>
      {isLoading(PatientInfo) || isDefaultModel(PatientInfo) ? (
        <WidgetLoader />
      ) : (
        <Card size="large" className="PatientInfoWidget__card">
          {isLoaded(PatientInfo) ? (
            <>
              <PatientInfoHeader
                avatar={avatarCache.get(organizationId, patientId, PictureResolution["112x112"])}
                firstName={patientInfoData?.firstName}
                lastName={patientInfoData?.lastName}
                registryNumber={patientInfoData?.registryNumber}
              />

              <PatientGeneralInfo
                gender={patientInfoData?.gender}
                bmi={patientInfoData?.bmi}
                age={patientInfoData?.years}
              />

              <hr className="PatientInfoWidget__divider" />

              <PatientOrganizationalInfo data={patientInfoData} />
            </>
          ) : (
            <RefreshFailedWidget show={isFailed(PatientInfo)} refreshCb={refreshWidgetData} />
          )}
        </Card>
      )}
    </div>
  );
}
