import {
  DeviceModel,
  IValidationError,
  PatientUserModel,
  TestListResponseModel,
  UserModel,
} from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { TableContext, tableStateMachine } from "libs/ui";
import { MeasurementItemUIType, MeasurementTypes, TestMeasurements } from "models/TestModels";
import { getCurrentUserData } from "utils/getCurrentUserData";
import { InterpreterFrom } from "xstate";
import { BaseTableFilterProps } from "../Table/model";

type GetAdminMeasurementsFilters = NonNullable<
  NonNullable<Parameters<typeof CarnaApiQuery.Admin.getMeasurements>[0]>["filters"]
>;
type GetPartnerMeasurementsFilters = NonNullable<
  Parameters<typeof CarnaApiQuery.Partners.getMeasurements>[0]["filters"]
>;
type GetHcpMeasurementsFilters = NonNullable<
  Parameters<typeof CarnaApiQuery.Partners.getMeasurements>[0]["filters"]
>;
type GetPatientMeasurementsFilters = NonNullable<
  Parameters<typeof CarnaApiQuery.Patients.getMeasurements>[0]["filters"]
>;

async function getAdminMeasurementsList(
  context?: TableContext<TestListResponseModel, GetAdminMeasurementsFilters>,
) {
  const result = await CarnaApiQuery.Admin.getMeasurements({
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getHcpMeasurementsList(
  organizationId: string,
  context?: TableContext<TestListResponseModel, GetHcpMeasurementsFilters>,
) {
  const result = await CarnaApiQuery.Hcps.getMeasurements({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getPartnerMeasurementsList(
  context?: TableContext<TestListResponseModel, GetPartnerMeasurementsFilters>,
) {
  const { organizationId } = await getCurrentUserData();

  const result = CarnaApiQuery.Partners.getMeasurements({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getPatientMeasurementsList(
  organizationId: string,
  patientId: string,
  context?: TableContext<TestListResponseModel, GetPatientMeasurementsFilters>,
) {
  const result = CarnaApiQuery.Patients.getMeasurements({
    organizationId,
    userEntityId: patientId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export async function fetchMeasurements(
  context?: TableContext<
    TestListResponseModel,
    | GetAdminMeasurementsFilters
    | GetHcpMeasurementsFilters
    | GetPartnerMeasurementsFilters
    | GetPatientMeasurementsFilters
  >,
) {
  const { userRole, organizationId, currentUserId } = await getCurrentUserData();

  switch (userRole) {
    case "Admin":
      return getAdminMeasurementsList(context);
    case "Partner":
      return getPartnerMeasurementsList(context);
    case "Hcp":
      return getHcpMeasurementsList(organizationId, context);
    case "Patient":
      return getPatientMeasurementsList(organizationId, currentUserId, context);
    default:
      throw new Error(`AllTables get measurements for ${userRole} not implemented`);
  }
}

export const makeTableFetchStateMachine = () => tableStateMachine(fetchMeasurements);

export type TestTableService = InterpreterFrom<ReturnType<typeof makeTableFetchStateMachine>>;

export type TestsTableStateContext = {
  tableService: TestTableService;
  testItems?: TestModel[];
  fetch: () => void;
};

export type TestsTableColumns =
  | "id"
  | "glucose"
  | "date"
  | "patient"
  | "testedBy"
  | "serumCreatinine"
  | "eGFR"
  | "testResult"
  | "bmi"
  | "height"
  | "weight"
  | "bloodPressure"
  | "urineCreatinine"
  | "urineAlbumin"
  | "uacr"
  | "squacr"
  | "measurementType"
  | "actions"
  | "testingMethod";

export interface TestsTableProps extends BaseTableFilterProps {
  testsTableStateContext: TestsTableStateContext;
  appliedColumns: TestsTableColumns[];
  title?: string;
  measurementType?: MeasurementTypes;
  measurementTypeItem?: MeasurementItemUIType;
  showTrend?: boolean;
}

export interface BodyRowsProps {
  tableService: TestTableService;
  appliedColumns: TestsTableColumns[];
  measurementType?: MeasurementTypes;
  measurementTypeItem?: MeasurementItemUIType;
}

export interface TestModel {
  patient: PatientUserModel;
  testedBy?: UserModel | null;
  device?: DeviceModel;
  measurements: Array<TestMeasurements>;
  id: string;
  testCountId: string;
  organizationId: string;
  measurementTime: string;
  sampledByDevice?: number | null;
  validations?: Array<IValidationError> | null;
}
