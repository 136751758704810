/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    userPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    identityPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    authenticationFlowType: string;
}

export function AuthResponseFromJSON(json: any): AuthResponse {
    return AuthResponseFromJSONTyped(json, false);
}

export function AuthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'userPoolId': json['userPoolId'],
        'identityPoolId': json['identityPoolId'],
        'region': json['region'],
        'authenticationFlowType': json['authenticationFlowType'],
    };
}

export function AuthResponseToJSON(value?: AuthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ClientId': value.clientId,
        'UserPoolId': value.userPoolId,
        'IdentityPoolId': value.identityPoolId,
        'Region': value.region,
        'AuthenticationFlowType': value.authenticationFlowType,
    };
}

