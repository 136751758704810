import { useActor } from "@xstate/react";
import { PatientResponseModel } from "api/query";
import { resolveRenderCell } from "components/AppTables/utils";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { ActionButton, TableElements } from "libs/ui";
import { statusToIcon } from "libs/ui/ActionButton/utils";
import { TableUtils } from "libs/ui/Table/utils";
import { TestMeasurements } from "models/TestModels";
import { useCallback, useMemo, useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { PatientsTableColumns } from "utils/createGlobalConfigStore";
import { useStringToHashPromises } from "utils/helpers/crypto";
import { useRegistryNumberLabel } from "utils/hooks/useRegistryNumberLabel";
import { avatarCache } from "utils/machines/avatars";
import { TableCard } from "../../TableCard";
import { TableCardDescription } from "../../TableCardDescription";
import { TableCardDevice } from "../../TableCardDevice";
import { TableCardPersonAvatar } from "../../TableCardPersonAvatar";
import { CHANGE_STATUS_ALLOWED } from "../../model";
import { BodyRowsProps, PatientMeasurementsModel } from "../model";
import "./BodyRows.scss";
import { PictureResolution } from "utils/helpers/pictures.model";
import { FakeData, isRealData } from "utils/guards";
import { cloneDeep } from "lodash-es";

const convertPatientResponseIntoFlatMeasurements = (
  patients?: PatientResponseModel[],
): PatientMeasurementsModel[] | undefined => {
  const flatModel: PatientMeasurementsModel[] = [];

  patients?.forEach(patient => {
    /**
     * We assume that we don't have duplicated measurementType
     */
    const testModelListToObj = patient.latestTestModel?.reduce(
      (obj, model) => {
        obj[model.measurementType ?? "None"] = model.item;
        return obj;
      },
      {} as Record<string, any>,
    );

    const latestTestModel: TestMeasurements = {
      UACR: testModelListToObj?.UACR
        ? {
            UrineAlbumin: testModelListToObj["UACR"]["UrineAlbumin"],
            UrineCreatinine: testModelListToObj["UACR"]["UrineCreatinine"],
            Uacr: testModelListToObj["UACR"]["Uacr"],
          }
        : undefined,
      SerumCreatinine: testModelListToObj?.SerumCreatinine
        ? {
            SerumCreatinine: testModelListToObj["SerumCreatinine"]["SerumCreatinine"],
            Egfr: testModelListToObj["SerumCreatinine"]["Egfr"],
          }
        : undefined,
      BloodPressure: testModelListToObj?.BloodPressure
        ? {
            Systolic: testModelListToObj["BloodPressure"]["Systolic"],
            Diastolic: testModelListToObj["BloodPressure"]["Diastolic"],
          }
        : undefined,
      Glucose: testModelListToObj?.Glucose
        ? { Glucose: testModelListToObj["Glucose"]["Glucose"] }
        : undefined,
      BMI: testModelListToObj?.BMI
        ? {
            Bmi: testModelListToObj["BMI"]["Bmi"],
            Height: testModelListToObj["BMI"]["Height"],
            Weight: testModelListToObj["BMI"]["Weight"],
          }
        : undefined,
      SemiQuantitativeUACR: testModelListToObj?.SemiQuantitativeUACR
        ? {
            SemiQuantitativeUacr:
              testModelListToObj["SemiQuantitativeUACR"]["SemiQuantitativeUacr"],
            SemiQuantitativeUrineAlbumin:
              testModelListToObj["SemiQuantitativeUACR"]["SemiQuantitativeUrineAlbumin"],
            SemiQuantitativeUrineCreatinine:
              testModelListToObj["SemiQuantitativeUACR"]["SemiQuantitativeUrineCreatinine"],
          }
        : undefined,
    };

    flatModel.push({
      ...patient,
      latestTestModel: new Proxy(cloneDeep(latestTestModel), {
        get(target, property, receiver) {
          if (property in target) {
            return Reflect.get(target, property, receiver);
          } else {
            throw new Error(
              `Requested property ${String(
                property,
              )} does not exist on our latestTestModel object, You should add it for mapping`,
            );
          }
        },
      }),
    });
  });

  return flatModel;
};

export function BodyRows({
  tableService,
  changePatientStateClick,
  onDeletePatient,
  appliedColumns,
  currentRightClickContext,
  setCurrentRightClickContext,
}: Readonly<BodyRowsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "PatientsTable" });
  const tableRedirection = TableUtils.useTableElementsRedirect();
  const [state] = useActor(tableService);

  const { appConfig } = useGlobalConfigContext();
  const [registryNumberLabel] = useRegistryNumberLabel();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const defaultValues = Array.from({ length: state.context.linesPerPage }).map((_, idx) => {
    const defaultValue: FakeData<PatientMeasurementsModel> = {
      ...NotNullOrUndefined(appConfig?.forms.defaultValues.patient),
      id: idx.toString(),
      role: "Patient",
      status: "Invited",
      isAccountOwner: false,
      dateOfBirth: "",
      years: 10,
      _fake: true,
    };
    return defaultValue;
  });

  const cardAction = useCallback(
    (patient: PatientMeasurementsModel) =>
      patient.status !== "Deleted" ? (
        <>
          <ActionButton onClick={onDeletePatient(patient)} iconType={"Delete"} actionType="Deleted">
            {t(`actions.Delete`)}
          </ActionButton>
          {patient.status !== "NoAccess" ? (
            <ActionButton
              onClick={changePatientStateClick(patient)}
              actionType={patient.status}
              iconType={statusToIcon(patient.status)}
            >
              {t(`actions.${patient.status}`)}
            </ActionButton>
          ) : null}
        </>
      ) : null,
    [changePatientStateClick, onDeletePatient, t],
  );

  const testItems = useMemo(
    () => convertPatientResponseIntoFlatMeasurements(state.context.data.items),
    [state.context.data.items],
  );

  /** mostly for e2e tests */
  const testHashValues = useStringToHashPromises(
    (testItems ?? []).map(patient => {
      return JSON.stringify([
        patient.registryNumber,
        patient.phone,
        patient.email,
        patient.firstName + patient.lastName,
      ]);
    }),
    [state.context.data.items],
  );
  // With this we can keep the order of Components while mapping the incoming appliedColumn[]
  const GetCellComponent = useCallback(
    (name: PatientsTableColumns, patient: PatientMeasurementsModel) => {
      switch (name) {
        case "userStatuses":
          return (
            <TableElements.AvatarCell
              key="userStatuses"
              show={resolveRenderCell(appliedColumns, "userStatuses")}
              src={
                isRealData(patient)
                  ? avatarCache.get(patient.organizationId, patient.id, PictureResolution.table)
                  : undefined
              }
              avatarLoading={false}
              firstName={patient.firstName}
              label={patient.firstName.concat(` ${patient.lastName}`)}
              status={patient.status}
              {...(!!patient?.registryNumber && {
                tag: `${registryNumberLabel}: ${patient.registryNumber}`,
              })}
              avatarType={"Patient"}
              loading={state.matches("loading")}
              className="PatientCell"
              cellProps={{ "data-testid": "PatientCell" }}
            />
          );
        case "email":
          return (
            <TableElements.Cell
              key={"email"}
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "email")}
              data-testid="email_cell"
            >
              {patient.email}
            </TableElements.Cell>
          );
        case "hcpIds":
          return (
            <TableElements.AvatarCell
              key="hcpIds"
              show={resolveRenderCell(appliedColumns, "hcpIds")}
              src={
                isRealData(patient) && patient.hcpId
                  ? avatarCache.get(patient.organizationId, patient.hcpId, PictureResolution.table)
                  : undefined
              }
              avatarLoading={false}
              firstName={patient.hcpFirstName ?? ""}
              label={`${patient.hcpFirstName ?? ""} ${patient.hcpLastName ?? ""}`}
              {...(!!patient.hcpId && {
                onCellClick: e =>
                  tableRedirection({
                    event: e,
                    path: "hcpDetails",
                    params: {
                      hcpId: patient.hcpId as string,
                      organizationId: patient.organizationId,
                    },
                  }),
              })}
              avatarType={"Hcp"}
              loading={state.matches("loading")}
            >
              {t("na")}
            </TableElements.AvatarCell>
          );
        case "uacr":
          return (
            <TableElements.TestResultCell
              key="uacr"
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "uacr")}
              type={"UACR"}
              measurement={patient.latestTestModel ?? {}}
            />
          );

        case "squacr":
          return (
            <TableElements.TestResultCell
              key="squacr"
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "squacr")}
              type={"SemiQuantitativeUACR"}
              measurement={patient.latestTestModel ?? {}}
            />
          );

        case "creatinineEgfr":
          return (
            <TableElements.TestResultCell
              key="creatinineEgfr"
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "creatinineEgfr")}
              type={"SerumCreatinine"}
              measurement={patient.latestTestModel ?? {}}
            />
          );

        case "bmi":
          return (
            <TableElements.TestResultCell
              key="bmi"
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "bmi")}
              type={"BMI"}
              measurement={patient.latestTestModel ?? {}}
            />
          );

        case "bloodPressure":
          return (
            <TableElements.TestResultCell
              key={"bloodPressure"}
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "bloodPressure")}
              type={"BloodPressure"}
              measurement={patient.latestTestModel ?? {}}
            />
          );

        case "glucose":
          return (
            <TableElements.TestResultCell
              key={"glucose"}
              loading={state.matches("loading")}
              show={resolveRenderCell(appliedColumns, "glucose")}
              type={"Glucose"}
              measurement={patient.latestTestModel ?? {}}
            />
          );
        case "withDevice":
          return (
            <TableElements.DeviceCell
              key={"withDevice"}
              show={resolveRenderCell(appliedColumns, "withDevice")}
              {...(patient.deviceId && {
                onClick: e =>
                  tableRedirection({
                    event: e,
                    path: "deviceDetails",
                    params: {
                      deviceId: patient.deviceId as string,
                      organizationId: patient.organizationId,
                    },
                  }),
                deviceSerialNumber: patient.deviceSerialNumber,
              })}
              deviceIcon={!!patient.deviceId}
              loading={state.matches("loading")}
            >
              {t("na")}
            </TableElements.DeviceCell>
          );
        case "actions":
          return (
            <TableElements.Cell
              key="actions"
              show={resolveRenderCell(appliedColumns, "actions")}
              className="ActionsCell"
            >
              <TableElements.Actions disabled={patient.status === "Deleted"}>
                <ActionButton
                  onClick={onDeletePatient(patient)}
                  iconType={"Delete"}
                  actionType="Deleted"
                >
                  {t(`actions.Delete`)}
                </ActionButton>
                {CHANGE_STATUS_ALLOWED.some(el => el === patient.status) ? (
                  <ActionButton
                    onClick={changePatientStateClick(patient)}
                    actionType={patient.status}
                    iconType={statusToIcon(patient.status)}
                  >
                    {t(`actions.${patient.status}`)}
                  </ActionButton>
                ) : null}
              </TableElements.Actions>
            </TableElements.Cell>
          );
        default:
          console.warn(`Component not implemented: ${name}`);
          return null;
      }
    },
    [
      appliedColumns,
      changePatientStateClick,
      onDeletePatient,
      registryNumberLabel,
      state,
      t,
      tableRedirection,
    ],
  );

  return (
    <>
      {(state.matches("loading") ? defaultValues : testItems ?? []).map(patient => {
        const picResolution =
          state.context.tableView === "Grid" ? PictureResolution.grid : PictureResolution.table;

        const patientAvatar = isRealData(patient)
          ? avatarCache.get(patient.organizationId, patient.id, picResolution, true)
          : undefined;

        const hcpAvatar = isRealData(patient)
          ? avatarCache.get(patient?.organizationId, patient?.hcpId, picResolution, true)
          : undefined;

        if (state.context.tableView === "Grid") {
          return (
            <TableCard
              data-test-content={
                testHashValues[
                  JSON.stringify([
                    patient.registryNumber,
                    patient.phone,
                    patient.email,
                    patient.firstName + patient.lastName,
                  ])
                ] ?? "not-set"
              }
              data-patient-id={patient.id}
              className="PatientTableCard"
              key={patient.id}
              loading={state.matches("loading")}
              inactive={patient.status === "Deactivated"}
              avatarData={resolveRenderCell(appliedColumns, "userStatuses", () => ({
                src: patientAvatar,
                loading: false,
                avatarType: "Patient",
              }))}
              name={patient.firstName.concat(` ${patient.lastName}`)}
              description={
                <TableCardDescription textType="light" loading={state.matches("loading")}>
                  {t("registryNumber", {
                    registryNumberLabel,
                    registryNum: patient.registryNumber,
                  })}
                </TableCardDescription>
              }
              onClick={() =>
                tableRedirection({
                  path: "patientOverview",
                  params: {
                    patientId: patient.id,
                    organizationId: patient.organizationId,
                  },
                })
              }
              actionChildren={resolveRenderCell(appliedColumns, "actions", () =>
                cardAction(patient),
              )}
            >
              <>
                <TableCardPersonAvatar
                  show={resolveRenderCell(appliedColumns, "hcpIds")}
                  userData={{
                    id: patient.hcpId,
                    src: hcpAvatar,
                    loading: false,
                    organizationId: patient.organizationId,
                    name: `${patient.hcpFirstName} ${patient.hcpLastName}`,
                    role: "Hcp",
                  }}
                  loading={state.matches("loading")}
                >
                  <TableCardDescription>{t("hcp-na")}</TableCardDescription>
                </TableCardPersonAvatar>

                <TableCardDevice
                  show={resolveRenderCell(appliedColumns, "withDevice")}
                  deviceData={{
                    id: patient.deviceId,
                    organizationId: patient.organizationId,
                    serialNumber: patient.deviceSerialNumber,
                  }}
                  loading={state.matches("loading")}
                />
              </>
            </TableCard>
          );
        }

        return (
          <TableElements.Row
            data-test-content={
              testHashValues[
                JSON.stringify([
                  patient.registryNumber,
                  patient.phone,
                  patient.email,
                  patient.firstName + patient.lastName,
                ])
              ] ?? "not-set"
            }
            data-patient-id={patient.id}
            key={patient.id}
            inactive={patient.status === "Deactivated"}
            onContextMenu={event => {
              event.preventDefault();
              setCurrentRightClickContext([event, patient]);
            }}
            onClick={() => {
              if (currentRightClickContext || state.matches("loading")) {
                return null;
              }

              tableRedirection({
                path: "patientOverview",
                params: {
                  patientId: patient.id,
                  organizationId: patient.organizationId,
                },
              });
            }}
          >
            {appliedColumns.map(column => GetCellComponent(column, patient))}
          </TableElements.Row>
        );
      })}
    </>
  );
}
