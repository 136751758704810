/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AncestryType,
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import {
    CountryType,
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import {
    GenderType,
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import {
    NationalityType,
    NationalityTypeFromJSON,
    NationalityTypeFromJSONTyped,
    NationalityTypeToJSON,
} from './NationalityType';
import {
    StateType,
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';

/**
 * 
 * @export
 * @interface UpdatePatientRequestModel
 */
export interface UpdatePatientRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof UpdatePatientRequestModel
     */
    country: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof UpdatePatientRequestModel
     */
    state?: StateType;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    hcpId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {GenderType}
     * @memberof UpdatePatientRequestModel
     */
    gender: GenderType;
    /**
     * 
     * @type {AncestryType}
     * @memberof UpdatePatientRequestModel
     */
    ancestry: AncestryType;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    dateOfBirth: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePatientRequestModel
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePatientRequestModel
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequestModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {NationalityType}
     * @memberof UpdatePatientRequestModel
     */
    nationalityType?: NationalityType;
    /**
     * 
     * @type {number}
     * @memberof UpdatePatientRequestModel
     */
    rowVersion: number;
}

export function UpdatePatientRequestModelFromJSON(json: any): UpdatePatientRequestModel {
    return UpdatePatientRequestModelFromJSONTyped(json, false);
}

export function UpdatePatientRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePatientRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': !exists(json, 'State') ? undefined : StateTypeFromJSON(json['State']),
        'zipCode': json['ZipCode'],
        'phone': json['Phone'],
        'hcpId': !exists(json, 'HcpId') ? undefined : json['HcpId'],
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'gender': GenderTypeFromJSON(json['Gender']),
        'ancestry': AncestryTypeFromJSON(json['Ancestry']),
        'dateOfBirth': json['DateOfBirth'],
        'height': json['Height'],
        'weight': json['Weight'],
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'nationalityType': !exists(json, 'NationalityType') ? undefined : NationalityTypeFromJSON(json['NationalityType']),
        'rowVersion': json['RowVersion'],
    };
}

export function UpdatePatientRequestModelToJSON(value?: UpdatePatientRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': StateTypeToJSON(value.state),
        'ZipCode': value.zipCode,
        'Phone': value.phone,
        'HcpId': value.hcpId,
        'DeviceId': value.deviceId,
        'Gender': GenderTypeToJSON(value.gender),
        'Ancestry': AncestryTypeToJSON(value.ancestry),
        'DateOfBirth': value.dateOfBirth,
        'Height': value.height,
        'Weight': value.weight,
        'RegistryNumber': value.registryNumber,
        'NationalityType': NationalityTypeToJSON(value.nationalityType),
        'RowVersion': value.rowVersion,
    };
}

