import { UpdateUserPreferencesRequestModel } from "api/event/models";
import { UserGlobalPreferencesResponseModel } from "api/query/models/UserGlobalPreferencesResponseModel";
import { GlobalPreferenceModal } from "components/AppModals/GlobalPreferenceModal";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { toastStore } from "config/toast";
import { isDefaultModel, isLoaded, isLoading, loaded } from "models/loadable";
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createSafeContext } from "utils/createSafeContext";
import { getCurrentGlobalPreferences } from "utils/getCurrentGlobalPreferences";
import { isBeErrorModel } from "utils/guards";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { useApi } from "utils/hooks/useApi";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";

export interface GlobalPreferenceContext {
  globalPreference: UserGlobalPreferencesResponseModel | undefined;
  postGlobalPreference: (updateData: UpdateUserPreferencesRequestModel) => void;
  postLoading: boolean;
}

export const DEFAULT_GLOBAL_PREFERENCE = {
  ...getCurrentGlobalPreferences(),
  userId: "",
  organizationId: "",
  rowVersion: "",
} satisfies UserGlobalPreferencesResponseModel;

const Context = createSafeContext<GlobalPreferenceContext>({
  globalPreference: DEFAULT_GLOBAL_PREFERENCE,
  postGlobalPreference: () => ({}),
  postLoading: false,
});
export const useGlobalPreferenceContext = Context.hook;

export function GlobalPreferenceProvider({ children }: PropsWithChildren) {
  const { currentUserId, organizationId } = useCurrentUserData();
  const isPosting = useRef(false);

  const { t } = useTranslation("translation", { keyPrefix: "GlobalPreferenceContext" });

  const [getGlobalPreferenceResult, getGlobalPreference, , setGetGlobalPreferenceResult] = useApi(
    CarnaApiQuery.UserPreferences.organizationsOrganizationIdUsersIdPreferencesGet,
  );

  const onError = useCallback(
    async (err: any) => {
      const response = err.response as Response;
      const resolveJson = await response.json();

      if (isBeErrorModel(resolveJson)) {
        showBeFieldErrors(resolveJson, t("errorAddTestToastText"));
        return;
      }

      toastStore.toast.error({ msg: t("errorPutPreferenceToastText") });
    },
    [t],
  );

  const [postGlobalPreferenceResult, postPreference] = useApi(CarnaApiEvent.UserPreferences.post, {
    toastText: {
      successText: t("successPutPreferenceToastText"),
    },
    onResponseCallback: {
      onError,
    },
  });

  useEffect(() => {
    if (isDefaultModel(getGlobalPreferenceResult)) {
      getGlobalPreference({ organizationId, id: currentUserId });
    }
  }, [currentUserId, getGlobalPreferenceResult, getGlobalPreference, organizationId]);

  const latestSavedGlobalPreference = useMemo(() => {
    return isLoaded(getGlobalPreferenceResult) ? getGlobalPreferenceResult.value : undefined;
  }, [getGlobalPreferenceResult]);

  const postGlobalPreference = useCallback(
    async (updateData: Omit<UpdateUserPreferencesRequestModel, "rowVersion">) => {
      /**
       * We have several setState callbacks
       * Also we have several promises working together
       * I think it would be best to chain promises together and not to rely too much on reacts state
       * ! This was the quick fix, making sure that you can't call it when it's in progress
       */
      if (isPosting.current) {
        return;
      }

      isPosting.current = true;

      try {
        const profile = await CarnaApiQuery.ProfileApi.getProfile();

        postPreference({
          organizationId,
          userEntityId: currentUserId,
          acceptVersion: "v1",
          updateUserPreferencesRequestModel: {
            ...updateData,
            rowVersion: profile.rowVersion,
          },
        }).then(() => {
          setGetGlobalPreferenceResult(
            loaded({
              ...updateData,
              organizationId,
              userId: currentUserId,
              rowVersion: profile.rowVersion,
            }),
          );
        });
      } finally {
        isPosting.current = false;
      }
    },
    [currentUserId, organizationId, postPreference, setGetGlobalPreferenceResult],
  );

  const value = useMemo(
    () => ({
      globalPreference: latestSavedGlobalPreference,
      postGlobalPreference,
      postLoading: isLoading(postGlobalPreferenceResult),
    }),
    [latestSavedGlobalPreference, postGlobalPreference, postGlobalPreferenceResult],
  );

  if (!latestSavedGlobalPreference) {
    return (
      <GlobalPreferenceModal
        isLoading={
          isLoading(getGlobalPreferenceResult) || isDefaultModel(getGlobalPreferenceResult)
        }
        onConfirm={postGlobalPreference}
        isSaving={isLoading(postGlobalPreferenceResult)}
      />
    );
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
