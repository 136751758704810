/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrackingDeviceResponse
 */
export interface TrackingDeviceResponse {
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceResponse
     */
    deviceName: string;
    /**
     * 
     * @type {Date}
     * @memberof TrackingDeviceResponse
     */
    deviceLastAuthenticatedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof TrackingDeviceResponse
     */
    deviceLastModifiedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceResponse
     */
    lastIpUsed: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceResponse
     */
    countryName: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingDeviceResponse
     */
    cityName: string;
}

export function TrackingDeviceResponseFromJSON(json: any): TrackingDeviceResponse {
    return TrackingDeviceResponseFromJSONTyped(json, false);
}

export function TrackingDeviceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingDeviceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceName': json['DeviceName'],
        'deviceLastAuthenticatedDate': (new Date(json['DeviceLastAuthenticatedDate'])),
        'deviceLastModifiedDate': (new Date(json['DeviceLastModifiedDate'])),
        'lastIpUsed': json['LastIpUsed'],
        'countryName': json['CountryName'],
        'cityName': json['CityName'],
    };
}

export function TrackingDeviceResponseToJSON(value?: TrackingDeviceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DeviceName': value.deviceName,
        'DeviceLastAuthenticatedDate': (value.deviceLastAuthenticatedDate.toISOString()),
        'DeviceLastModifiedDate': (value.deviceLastModifiedDate.toISOString()),
        'LastIpUsed': value.lastIpUsed,
        'CountryName': value.countryName,
        'CityName': value.cityName,
    };
}

