import { useActor } from "@xstate/react";
import { EditableDetails } from "components/EditableDetails/EditableDetails";
import { useAppMenuContext } from "context/AppMenuContext";
import { TFunction } from "i18next";
import { UserRoleType, UserStatusType } from "models/PersonModels";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { getImageSrc } from "utils/converters/getImageSrc";
import { useFormHandlers } from "utils/helpers/pages/details";
import { useAvatarState } from "utils/hooks/useAvatarState";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { useUpdateCurrentUserNameIfSame } from "utils/hooks/useUpdateCurrentUserNameIfSame";
import {
  DetailsPageServiceList,
  MakeDetailsPageStateMachineService,
} from "utils/machines/pages/details/model";
import AvatarGroup from "./AvatarGroup";
import { onSaveAvatar } from "./helpers";

interface PersonModel {
  status: UserStatusType;
  firstName: string;
  lastName: string;
  role: UserRoleType;
}

interface AvatarParams {
  organizationId: string;
  personId: string;
  latestRowVersion: string;
}

interface Props<
  ResponseModel extends PersonModel,
  EntityModel,
  Events extends DetailsPageServiceList<any, any, any> = any,
> {
  detailsService: MakeDetailsPageStateMachineService<ResponseModel, EntityModel, Events>;
  avatarParams: AvatarParams;
  canEdit?: boolean;
  getFieldsReady: (tempEntityState: EntityModel) => boolean;
  defaultFormValue: EntityModel;
  FormElem: React.ElementType;
  t: TFunction<string, string>;
  onClose?: () => void;
}

export function DetailsCommonPersonForm<
  ResponseModel extends PersonModel,
  EntityModel,
  Events extends DetailsPageServiceList<any, any, any> = any,
>({
  detailsService,
  canEdit = true,
  avatarParams: { organizationId, personId, latestRowVersion },
  getFieldsReady,
  defaultFormValue,
  t,
  FormElem,
  onClose,
}: PropsWithChildren<Props<ResponseModel, EntityModel, Events>>) {
  const { dispatchSideAvatarAction } = useAppMenuContext();
  const { currentUserId } = useCurrentUserData();
  const [localEntityData, setLocalEntityData] = useState<EntityModel>(() => ({
    ...defaultFormValue,
  }));

  const [state] = useActor(detailsService);

  const inSavingOrLoading = state.matches("entity.saving") || state.matches("entity.loading");

  //#region AVATAR
  const { avatarState, dispatchAvatarAction, loadAvatar, deleteAvatar, uploadAvatar } =
    useAvatarState();

  const presentedAvatarImg = useMemo(() => {
    if (avatarState.newImage === "delete") {
      return undefined;
    }

    return getImageSrc(avatarState.newImage) ?? avatarState.latestSavedImage ?? undefined;
  }, [avatarState.latestSavedImage, avatarState.newImage]);

  useEffect(() => {
    if (avatarState._init) {
      loadAvatar(organizationId, personId);
    }
  }, [avatarState._init, loadAvatar, organizationId, personId]);

  //#endregion

  useUpdateCurrentUserNameIfSame(personId, detailsService);

  const handlerCbs = useMemo(
    () => ({
      submitAvatar(newImage: string | undefined) {
        /**
         * Our Navigation menu Avatar picture must be refreshed
         * ! only if the current user is the one who is editing his profile picture
         */
        if (currentUserId === personId) {
          dispatchSideAvatarAction({
            type: "SetLatestSavedImage",
            payload: { latestSavedImage: newImage },
          });
        }
      },
    }),
    [currentUserId, dispatchSideAvatarAction, personId],
  );

  const { onSubmit, onCancel, saveDisabled } = useFormHandlers(
    detailsService,
    {
      avatarState,
      dispatchAvatarAction,
      async uploadAvatar() {
        return uploadAvatar(organizationId, personId);
      },
      async deleteAvatar() {
        return deleteAvatar(organizationId, personId, latestRowVersion);
      },
    },
    [localEntityData, setLocalEntityData],
    getFieldsReady,
    handlerCbs,
  );

  const allowedEdit = canEdit
    ? state.matches("entity.editing") || state.matches("entity.saving")
    : undefined;

  return (
    <EditableDetails
      title={
        state.matches("entity.editing") || state.matches("entity.saving")
          ? t("titleEdit", {
              role: t(`role.${state.context.data?.role}`),
            })
          : t("title")
      }
      {...(allowedEdit && {
        editMode: true,
      })}
      isLoaded={state.matches("entity.loaded")}
      cancel={{
        disabled: inSavingOrLoading,
        onClick: onCancel,
      }}
      save={{
        disabled: saveDisabled,
        onClick: onSubmit,
        isLoading: state.matches("entity.saving"),
      }}
      onClose={state.matches("entity.editing") ? onClose : undefined}
    >
      <FormElem
        onSubmit={onSubmit}
        service={detailsService}
        entityData={localEntityData}
        setEntityData={setLocalEntityData}
        loading={state.matches("entity.loading")}
      >
        <AvatarGroup
          avatarForCreate={state.matches("entity.editing") && !!presentedAvatarImg === false}
          loading={state.matches("entity.loading") || avatarState.loading}
          editing={state.matches("entity.editing")}
          firstName={state.context.data?.firstName ?? ""}
          imageSrc={presentedAvatarImg}
          onSave={onSaveAvatar(avatarState, dispatchAvatarAction)}
        />
      </FormElem>
    </EditableDetails>
  );
}
