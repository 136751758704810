/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceModel
 */
export interface DeviceModel {
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    name: string;
}

export function DeviceModelFromJSON(json: any): DeviceModel {
    return DeviceModelFromJSONTyped(json, false);
}

export function DeviceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'serialNumber': json['SerialNumber'],
        'name': json['Name'],
    };
}

export function DeviceModelToJSON(value?: DeviceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'SerialNumber': value.serialNumber,
        'Name': value.name,
    };
}

