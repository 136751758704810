import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { SingleWidgetBaseProps } from "../../model";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { useTranslation } from "react-i18next";
import "./MeasurementUnit.scss";
import { MeasurementItem } from "../model";
import { getSQUACRFlags } from "utils/getSQUACRFlags";

interface MeasurementUnitProps extends SingleWidgetBaseProps {
  measurements: MeasurementItem | undefined;
}

export function MeasurementUnit({ type, measurements }: Readonly<MeasurementUnitProps>) {
  const { globalPreference } = useGlobalPreferenceContext();
  const units = useGetUserPreferredMeasurementUnits();
  const { t: imperialUnit } = useTranslation("measurements", { keyPrefix: "Measurements" });

  const getUnit = () => {
    switch (type) {
      case "SerumCreatinine":
        return units.serumCreatinineUnit;
      case "Egfr":
        return units.egfrUnit;

      case "Glucose":
        return units.glucoseUnit;

      case "Bmi":
        return "";

      case "Height":
        return globalPreference?.measureUnitType === "Metric"
          ? units.heightUnit
          : imperialUnit("heightImperialUnit");

      case "Weight":
        return units.weightUnit;

      case "BloodPressure":
        return units.bloodPressureUnit;

      case "Uacr":
        return units.uacrUnit;
      case "UrineAlbumin":
        return units.urineAlbuminUnit;
      case "UrineCreatinine":
        return units.urineCreatinineUnit;

      case "SemiQuantitativeUacr":
        return getSQUACRFlags(measurements?.SemiQuantitativeUacr.Value).stadium; // SemiQuantitativeUACR does not have a unit (comes with "None"), instead we are showing the stadium value

      default:
        throw new Error(`Icon for ${type} not covered`);
    }
  };
  return <div className="MeasurementUnit">{getUnit()}</div>;
}
