import { useActor } from "@xstate/react";
// import { EventsTable } from "components/AppTables";
import { DetailsCommonPerson } from "components/PageTemplate/DetailsPage/DetailsCommonPerson";
// import { Tabs } from "libs/ui";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { DetailsForm } from "./DetailsForm";
import { useMakeUserPagesDetailState } from "./model";
import { UserRoleType } from "models/PersonModels/Roles";

export function Details() {
  // const { t } = useTranslation({ keyPrefix: "UserDetails" });
  const { userId = "", organizationId = "", role } = useParams<RouteParams["userDetail"]>();
  const detailsService = useMakeUserPagesDetailState(userId, organizationId);
  const [, /*state*/ send] = useActor(detailsService);
  // AvatarState is in DetailsForm

  const onClose = useCallback(() => send("CANCEL_EDIT"), [send]);

  return (
    <DetailsCommonPerson
      detailsService={detailsService}
      detailsForm={
        <DetailsForm
          service={detailsService}
          userId={userId}
          organizationId={organizationId}
          onClose={onClose}
        />
      }
    >
      {/* <Tabs
        tabs={[
          {
            label: t("tab.labelEvents"),
            Component: <>NEW_BACKEND</>,
            //   (
            //     <EventsTable
            //       userFetchedData={state.context?.data}
            //       loading={state.matches("entity.loading")}
            //     />
            // )
          },
        ]}
      /> */}
    </DetailsCommonPerson>
  );
}
