/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    property?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    errorCode?: string | null;
}

export function ApiErrorFromJSON(json: any): ApiError {
    return ApiErrorFromJSONTyped(json, false);
}

export function ApiErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'property': !exists(json, 'Property') ? undefined : json['Property'],
        'message': !exists(json, 'Message') ? undefined : json['Message'],
        'errorCode': !exists(json, 'ErrorCode') ? undefined : json['ErrorCode'],
    };
}

export function ApiErrorToJSON(value?: ApiError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Property': value.property,
        'Message': value.message,
        'ErrorCode': value.errorCode,
    };
}

