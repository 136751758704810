import classNames from "classnames";
import { useCurrentUserNameContext } from "context/CurrentUserNameContext";
import { isLoaded, isLoading } from "models/loadable";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { resolveUserRole } from "../../utils";
import "./CurrentUserRole.scss";

export function CurrentUserRole() {
  const { userRole, isPatient } = useCurrentUserData();
  const { currentUserName } = useCurrentUserNameContext();

  if (isPatient) {
    return null;
  }
  return (
    <p
      className={classNames("CurrentUserRole", {
        "CurrentUserRole--loading": isLoading(currentUserName),
      })}
    >
      {isLoaded(currentUserName) ? resolveUserRole(userRole) : null}
    </p>
  );
}
