/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationPatientModel,
    OrganizationPatientModelFromJSON,
    OrganizationPatientModelFromJSONTyped,
    OrganizationPatientModelToJSON,
} from './OrganizationPatientModel';

/**
 * 
 * @export
 * @interface GraphPatientResponseModel
 */
export interface GraphPatientResponseModel {
    /**
     * 
     * @type {OrganizationPatientModel}
     * @memberof GraphPatientResponseModel
     */
    organization: OrganizationPatientModel;
}

export function GraphPatientResponseModelFromJSON(json: any): GraphPatientResponseModel {
    return GraphPatientResponseModelFromJSONTyped(json, false);
}

export function GraphPatientResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GraphPatientResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': OrganizationPatientModelFromJSON(json['Organization']),
    };
}

export function GraphPatientResponseModelToJSON(value: GraphPatientResponseModel): any {
    return {
        'Organization': OrganizationPatientModelToJSON(value.organization),
    };
}

