import { Trans, useTranslation } from "react-i18next";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import "./SingleMeasurement.scss";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { Icon, useTooltip } from "libs/ui";
import { useHover } from "@floating-ui/react";
import { MeasurementTrendType } from "models/TestModels";
import { getMeasurementTrendIcon } from "utils/getMeasurementTrendIcon";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { getSQUACRFlags } from "utils/getSQUACRFlags";

const Percent = ({ percent }: { percent?: number }) =>
  percent ? <span>{(percent ?? 0).toString().concat("%")}</span> : null;

interface SingleMeasurementProps {
  measurementUnit: "UACR" | "SQUACR" | "eGFR";
  value?: number;
  date?: Date | string;
  trendType?: MeasurementTrendType;
  percent?: number;
}

export function SingleMeasurement({
  measurementUnit,
  value,
  date,
  trendType,
  percent,
}: SingleMeasurementProps) {
  const { t } = useTranslation("widgets", { keyPrefix: "HeatMapWidget" });
  const { numberFormatterWithDash } = useLocaleNumberFormatter();
  const { uacrUnit, egfrUnit } = useGetUserPreferredMeasurementUnits();
  const [dateFormatter] = useRegionDateTimeFormatter();
  const [ref, getReferenceProps, Tooltip] = useTooltip({
    interactionProps: [useHover],
  });

  const getUnit = () => {
    switch (measurementUnit) {
      case "SQUACR":
        return null; //SQUACR doesn't have a unit so we return null

      case "UACR":
        return uacrUnit;

      case "eGFR":
        return egfrUnit;

      default:
        throw new Error(`${measurementUnit} not supported`);
    }
  };

  const getValue = () => {
    if (measurementUnit === "SQUACR") {
      return getSQUACRFlags(value).level;
    }
    return numberFormatterWithDash({ value });
  };

  return (
    <div className="SingleMeasurement">
      <Trans
        i18nKey="SingleMeasurement"
        t={t}
        values={{
          measurementUnit: t(`measurementType.${measurementUnit}`),
          value: getValue(),
          unit: getUnit(),
        }}
      >
        <span className="SingleMeasurement__bold"></span>
      </Trans>

      {date || trendType || percent ? (
        <>
          <Icon
            ref={ref}
            {...getReferenceProps()}
            icon={"Info"}
            className="SingleMeasurement__info"
          />
          <Tooltip>
            <div className="SingleMeasurement__tooltip-data">
              {date ? dateFormatter(date) : null}
              {trendType ? <Icon icon={getMeasurementTrendIcon(trendType)} scaleTo={16} /> : null}
              {percent ? <Percent percent={percent} /> : null}
            </div>
          </Tooltip>
        </>
      ) : null}
    </div>
  );
}
