import "./PersonalInformation.scss";

import { DetailsForm as HCPDetailsForm } from "pages/HCPPage/Details/DetailsForm";
import { useMakeHcpPagesDetailState } from "pages/HCPPage/Details/model";
import { DetailsForm as PatientDetailsForm } from "pages/PatientsPage/Details/DetailsForm";
import { useMakePatientDetailState } from "pages/PatientsPage/Details/model";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useActor } from "@xstate/react";

import { DetailsForm as AdminDetailsForm } from "../UsersManagement/Details/DetailsForm";
import { useMakeUserPagesDetailState } from "../UsersManagement/Details/model";
import { Toolbar } from "libs/ui";
import { SettingsPage } from "components/PageTemplate/SettingsPage";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";

function AdminDetails() {
  const { currentUserId = "", organizationId = "" } = useCurrentUserData();
  const detailsService = useMakeUserPagesDetailState(currentUserId, organizationId);
  const [, send] = useActor(detailsService);

  useEffect(() => {
    send("LOAD_DATA");
  }, [send]);

  return [
    <AdminDetailsForm
      key={`${currentUserId}${organizationId}`}
      service={detailsService}
      userId={currentUserId}
      organizationId={organizationId}
    />,
    send,
  ] as const;
}

function HCPDetails() {
  const { currentUserId = "", organizationId = "" } = useCurrentUserData();
  const detailsService = useMakeHcpPagesDetailState(currentUserId, organizationId);
  const [, send] = useActor(detailsService);

  useEffect(() => {
    send("LOAD_DATA");
  }, [send]);

  return [
    <HCPDetailsForm
      key={`${currentUserId}${organizationId}`}
      hcpId={currentUserId}
      organizationId={organizationId}
      service={detailsService}
    />,
    send,
  ] as const;
}

function PatientDetails() {
  const { currentUserId = "", organizationId = "" } = useCurrentUserData();
  const detailsService = useMakePatientDetailState(currentUserId, organizationId);
  const [, send] = useActor(detailsService);

  useEffect(() => {
    send("LOAD_DATA");
  }, [send]);

  return [
    <PatientDetailsForm
      key={`${currentUserId}${organizationId}`}
      patientId={currentUserId}
      organizationId={organizationId}
      service={detailsService}
    />,
    send,
  ] as const;
}

export function PersonalInformation() {
  const { userRole } = useCurrentUserData();
  const { t: tToolbar } = useTranslation("translation", { keyPrefix: "Toolbar" });

  if (!userRole) {
    return null;
  }

  const detailsFormMap = {
    Admin: AdminDetails,
    Partner: AdminDetails,
    Hcp: HCPDetails,
    Patient: PatientDetails,
  };

  const [Element, send] = detailsFormMap[userRole]();

  return (
    <SettingsPage
      controls={
        <Toolbar
          data-testid="controls-toolbar"
          buttons={[
            {
              icon: "Edit",
              variant: "grey",
              onClick: () => send("EDIT"),
              testId: "editEntity",
              tooltip: tToolbar("edit"),
            },
          ]}
        />
      }
    >
      <div className="PersonalInformation">{Element}</div>
    </SettingsPage>
  );
}
