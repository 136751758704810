import { useEffect } from "react";
import { HeatMapWidget } from "./HeatMapWidget";
import "./Overview.scss";
import { useOverviewAPIContext } from "./OverviewAPIContext";
import { PatientInfoWidget } from "./PatientInfoWidget";
import { RouteParams } from "router/model";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { mainRoutePaths } from "router";
import { MeasurementWidgets } from "./MeasurementWidgets";

export function Overview() {
  const { appConfig } = useGlobalConfigContext();
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientDetails"]>();
  const navigate = useNavigate();
  const { getWidgetData } = useOverviewAPIContext();

  const shouldShowPage = NotNullOrUndefined(appConfig).pages.PatientsPage.Details.ShowOverviewTab;

  useEffect(() => {
    if (!shouldShowPage) {
      navigate(
        generatePath(mainRoutePaths.patientDetails, {
          organizationId,
          patientId,
        }),
        { replace: true },
      );
    }
  }, [navigate, organizationId, patientId, shouldShowPage]);

  useEffect(() => {
    if (shouldShowPage) {
      getWidgetData(organizationId, patientId, [
        "HeatMap",
        "PatientInfo",
        "SerumCreatinine",
        "Egfr",
        // "Height", // ! Latest requirement don't want height to be in the rendered widget list
        "Weight",
        "Bmi",
        "Glucose",
        "BloodPressure",
        "UrineCreatinine",
        "UrineAlbumin",
        "Uacr",
        "SemiQuantitativeUacr",
      ]);
    }
  }, [getWidgetData, organizationId, patientId, shouldShowPage]);

  return (
    <div className="Overview">
      <PatientInfoWidget organizationId={organizationId} patientId={patientId} />
      <HeatMapWidget organizationId={organizationId} patientId={patientId} />
      <MeasurementWidgets organizationId={organizationId} patientId={patientId} />
    </div>
  );
}
