/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface AssignedToUserModel
 */
export interface AssignedToUserModel {
    /**
     * 
     * @type {string}
     * @memberof AssignedToUserModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AssignedToUserModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AssignedToUserModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AssignedToUserModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof AssignedToUserModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {UserRoleType}
     * @memberof AssignedToUserModel
     */
    userRole: UserRoleType;
    /**
     * 
     * @type {number}
     * @memberof AssignedToUserModel
     */
    rowVersion?: number | null;
}

export function AssignedToUserModelFromJSON(json: any): AssignedToUserModel {
    return AssignedToUserModelFromJSONTyped(json, false);
}

export function AssignedToUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignedToUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'organizationId': json['OrganizationId'],
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'userRole': UserRoleTypeFromJSON(json['UserRole']),
        'rowVersion': !exists(json, 'RowVersion') ? undefined : json['RowVersion'],
    };
}

export function AssignedToUserModelToJSON(value?: AssignedToUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'OrganizationId': value.organizationId,
        'RegistryNumber': value.registryNumber,
        'UserRole': UserRoleTypeToJSON(value.userRole),
        'RowVersion': value.rowVersion,
    };
}

