import "./PatientOrganizationalInfo.scss";
import { PatientInfoWidgetDataModel } from "api/query";
import { PatientOrganizationalField } from "./PatientOrganizationalField";
import { generatePath, useParams } from "react-router";
import { RouteParams, mainRoutePaths, settingsRoutePaths } from "router";
import { Avatar, DeviceImage } from "libs/ui";
import { useTranslation } from "react-i18next";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { useSyncExternalStore } from "react";
import { avatarCache } from "utils/machines/avatars";
import { PictureResolution } from "utils/helpers/pictures.model";

interface PatientOrganizationalInfoProps {
  data: PatientInfoWidgetDataModel | null;
}

export function PatientOrganizationalInfo({ data }: Readonly<PatientOrganizationalInfoProps>) {
  const { organizationId = "" } = useParams<RouteParams["patientDetails"]>();
  const { t } = useTranslation("widgets", {
    keyPrefix: "PatientInfoWidget.OrganizationalInfo",
  });
  const can = useHasAccess();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  return (
    <div className="PatientOrganizationalInfo">
      <PatientOrganizationalField
        label={t("organization")}
        value={data?.organization?.name}
        linkPath={
          data?.organization?.id === organizationId && can("manage-organization")
            ? generatePath(settingsRoutePaths.organizationDetails, {
                organizationId: data?.organization?.id ?? "",
                firstTab: "partners",
              })
            : undefined
        }
      />
      <PatientOrganizationalField
        label={t("hcp")}
        value={data?.hcp?.firstName.concat(` ${data?.hcp.lastName}`)}
        linkPath={
          can("view-hcp-details") && data?.hcp?.id
            ? generatePath(mainRoutePaths.hcpDetails, {
                organizationId: data?.organization?.id ?? "",
                hcpId: data?.hcp?.id,
              })
            : undefined
        }
        leadingElement={
          <Avatar
            src={avatarCache.get(
              data?.organization?.id,
              data?.hcp?.id,
              PictureResolution.details,
              true,
            )}
            firstName={data?.hcp?.firstName}
            avatarLoading={false}
            size="extraSmall"
          />
        }
      />
      <PatientOrganizationalField
        label={t("device")}
        value={data?.device?.name}
        additionalValue={data?.device?.serialNumber}
        linkPath={
          can("manage-device") && data?.device?.id
            ? generatePath(mainRoutePaths.deviceDetails, {
                organizationId: data?.organization?.id ?? "",
                deviceId: data?.device?.id,
              })
            : undefined
        }
        leadingElement={<DeviceImage />}
      />
    </div>
  );
}
