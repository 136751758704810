/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LanguageType,
    LanguageTypeFromJSON,
    LanguageTypeFromJSONTyped,
    LanguageTypeToJSON,
} from './LanguageType';
import {
    MeasureUnitType,
    MeasureUnitTypeFromJSON,
    MeasureUnitTypeFromJSONTyped,
    MeasureUnitTypeToJSON,
} from './MeasureUnitType';
import {
    RegionType,
    RegionTypeFromJSON,
    RegionTypeFromJSONTyped,
    RegionTypeToJSON,
} from './RegionType';
import {
    TimeZoneType,
    TimeZoneTypeFromJSON,
    TimeZoneTypeFromJSONTyped,
    TimeZoneTypeToJSON,
} from './TimeZoneType';

/**
 * 
 * @export
 * @interface UpdateGlobalPreferenceRequestModel
 */
export interface UpdateGlobalPreferenceRequestModel {
    /**
     * 
     * @type {LanguageType}
     * @memberof UpdateGlobalPreferenceRequestModel
     */
    languageType: LanguageType;
    /**
     * 
     * @type {RegionType}
     * @memberof UpdateGlobalPreferenceRequestModel
     */
    regionType: RegionType;
    /**
     * 
     * @type {TimeZoneType}
     * @memberof UpdateGlobalPreferenceRequestModel
     */
    timeZoneType: TimeZoneType;
    /**
     * 
     * @type {MeasureUnitType}
     * @memberof UpdateGlobalPreferenceRequestModel
     */
    measureUnitType: MeasureUnitType;
}

export function UpdateGlobalPreferenceRequestModelFromJSON(json: any): UpdateGlobalPreferenceRequestModel {
    return UpdateGlobalPreferenceRequestModelFromJSONTyped(json, false);
}

export function UpdateGlobalPreferenceRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateGlobalPreferenceRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'languageType': LanguageTypeFromJSON(json['LanguageType']),
        'regionType': RegionTypeFromJSON(json['RegionType']),
        'timeZoneType': TimeZoneTypeFromJSON(json['TimeZoneType']),
        'measureUnitType': MeasureUnitTypeFromJSON(json['MeasureUnitType']),
    };
}

export function UpdateGlobalPreferenceRequestModelToJSON(value?: UpdateGlobalPreferenceRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'LanguageType': LanguageTypeToJSON(value.languageType),
        'RegionType': RegionTypeToJSON(value.regionType),
        'TimeZoneType': TimeZoneTypeToJSON(value.timeZoneType),
        'MeasureUnitType': MeasureUnitTypeToJSON(value.measureUnitType),
    };
}

