import { Button, Icon, Input } from "libs/ui";
import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import "./ActiveQR.scss";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { toastStore } from "config/toast";
import { MFAOption, TwoFAData } from "..";
import { DeactivateQRcodeModal } from "./DeactivateQRcodeModal";
import { SecretCodeInText } from "./SecretCodeInText";
import { WHOLE_NUMBER } from "utils/regex";
import { backOff } from "exponential-backoff";

interface ActiveQRProps {
  secretCode: string | undefined;
  qrCode: string | undefined;
  preferredMFA: MFAOption | undefined;
  setTwoFAData: Dispatch<SetStateAction<TwoFAData>>;
}

const EXPECTED_CODE_LENGTH = 6;

const backOffConfig: Parameters<typeof backOff>["1"] = {
  jitter: "full",
  delayFirstAttempt: true,
  startingDelay: 1000,
  numOfAttempts: 10,
  retry(e, attemptNumber) {
    return e?.code === "TooManyRequestsException";
  },
} as const;

export function ActiveQR({ qrCode, secretCode, preferredMFA, setTwoFAData }: ActiveQRProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "PageTemplate.Settings.login.Tabs.TwoFA",
  });
  const { t: tAws } = useTranslation("translation", { keyPrefix: "AWS-Cognito" });
  const { user } = useAuthenticator();

  const [codeValue, setCodeValue] = useState("");
  const [totpValidation, setTotpValidation] = useState({
    isLoading: false,
    validated: false,
  });
  const [turnOffModalData, setTurnOffModalData] = useState({ show: false, isLoading: false });

  const onTurnOffConfirm = useCallback(async () => {
    setTurnOffModalData(prevValue => ({ ...prevValue, isLoading: true }));

    try {
      await backOff(() => Auth.setPreferredMFA(user, "SMS"), backOffConfig);
      setTwoFAData({
        isLoading: false,
        isLoadedWithError: false,
        preferredMFA: "SMS",
        qrCode: undefined,
        secretCode: undefined,
      });

      setTurnOffModalData(prevValue => ({ ...prevValue, isLoading: false, show: false }));
      setTotpValidation(prevValue => ({ ...prevValue, validated: false }));

      toastStore.pushToast({ type: "success", msg: t("code_remove_success"), expire: 5000 });
    } catch (error: any) {
      setTurnOffModalData({ show: false, isLoading: false });
      toastStore.pushToast({ type: "error", msg: tAws(error.code) });
    }
  }, [setTwoFAData, t, tAws, user]);

  const onVerifyCode = async (e?: React.FormEvent) => {
    e?.preventDefault();
    setTotpValidation(prevValue => ({
      ...prevValue,
      isLoading: true,
    }));

    try {
      await backOff(() => Auth.verifyTotpToken(user, codeValue), { ...backOffConfig });
      await backOff(() => Auth.setPreferredMFA(user, "TOTP"), backOffConfig);
      setTotpValidation({
        validated: true,
        isLoading: false,
      });

      setCodeValue("");

      toastStore.pushToast({ type: "success", msg: t("code_verify_success"), expire: 5000 });
    } catch (err: any) {
      setTotpValidation({
        validated: false,
        isLoading: false,
      });

      setCodeValue("");

      toastStore.pushToast({
        type: "error",
        msg:
          err.message !== undefined && err.message === "Code mismatch"
            ? t("codeMismatch")
            : tAws(err.code),
      });
    }
  };

  const onCodeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (WHOLE_NUMBER.test(value)) {
      setCodeValue(e.target.value);
    }
  };

  return (
    <>
      {preferredMFA === "SOFTWARE_TOKEN_MFA" || totpValidation.validated ? (
        <p className="TwoFATab__text">{t("authentication_TOTP_active")}</p>
      ) : (
        <ul className="ActiveQR__list">
          {[1, 2, 3, 4].map(index => (
            <li key={index} className="ActiveQR__item">
              <span className="ActiveQR__index">{index.toString().concat(".")}</span>
              {t(`authentication_active_description_${index}`)}
            </li>
          ))}
        </ul>
      )}

      <div className="ActiveQR__code">
        <QRCodeSVG width={200} height={200} value={qrCode ?? ""} className="ActiveQR__result" />

        <SecretCodeInText secretCode={secretCode} />

        {totpValidation.validated || preferredMFA === "SOFTWARE_TOKEN_MFA" ? null : (
          <form className="ActiveQR__confirm-form">
            <Input
              value={codeValue}
              label={t("confirm_label")}
              onChange={onCodeInput}
              maxLength={EXPECTED_CODE_LENGTH}
            />

            <Button
              onClick={onVerifyCode}
              buttonType="primary"
              buttonSize="medium"
              disabled={codeValue.length !== EXPECTED_CODE_LENGTH || totpValidation.isLoading}
            >
              {t("button_submit")}
            </Button>
          </form>
        )}
      </div>

      {(preferredMFA === "SOFTWARE_TOKEN_MFA" || totpValidation.validated) && (
        <button
          onClick={() => setTurnOffModalData(prevValue => ({ ...prevValue, show: true }))}
          className="TwoFATab__button TwoFATab__button--red"
        >
          <Icon icon="Quit" />
          {t("authentication_active_button")}
        </button>
      )}

      <DeactivateQRcodeModal
        show={turnOffModalData.show}
        isLoading={turnOffModalData.isLoading}
        onCancel={() => setTurnOffModalData(prevValue => ({ ...prevValue, show: false }))}
        onSubmit={onTurnOffConfirm}
      />
    </>
  );
}
