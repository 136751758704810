/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PatientCreatinineModel,
    PatientCreatinineModelFromJSON,
    PatientCreatinineModelFromJSONTyped,
    PatientCreatinineModelToJSON,
} from './PatientCreatinineModel';
import {
    PatientEgfrModel,
    PatientEgfrModelFromJSON,
    PatientEgfrModelFromJSONTyped,
    PatientEgfrModelToJSON,
} from './PatientEgfrModel';

/**
 * 
 * @export
 * @interface LatestMeasurements
 */
export interface LatestMeasurements {
    /**
     * 
     * @type {PatientCreatinineModel}
     * @memberof LatestMeasurements
     */
    creatinine?: PatientCreatinineModel;
    /**
     * 
     * @type {PatientEgfrModel}
     * @memberof LatestMeasurements
     */
    egfr?: PatientEgfrModel;
}

export function LatestMeasurementsFromJSON(json: any): LatestMeasurements {
    return LatestMeasurementsFromJSONTyped(json, false);
}

export function LatestMeasurementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LatestMeasurements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creatinine': !exists(json, 'Creatinine') ? undefined : PatientCreatinineModelFromJSON(json['Creatinine']),
        'egfr': !exists(json, 'Egfr') ? undefined : PatientEgfrModelFromJSON(json['Egfr']),
    };
}

export function LatestMeasurementsToJSON(value?: LatestMeasurements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Creatinine': PatientCreatinineModelToJSON(value.creatinine),
        'Egfr': PatientEgfrModelToJSON(value.egfr),
    };
}

