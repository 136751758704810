/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryType,
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import {
    GenderType,
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import {
    LatestMeasurements,
    LatestMeasurementsFromJSON,
    LatestMeasurementsFromJSONTyped,
    LatestMeasurementsToJSON,
} from './LatestMeasurements';
import {
    NationalityType,
    NationalityTypeFromJSON,
    NationalityTypeFromJSONTyped,
    NationalityTypeToJSON,
} from './NationalityType';
import {
    StateType,
    StateTypeFromJSON,
    StateTypeFromJSONTyped,
    StateTypeToJSON,
} from './StateType';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import {
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface PatientListDetailsResponseModel
 */
export interface PatientListDetailsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    city: string;
    /**
     * 
     * @type {CountryType}
     * @memberof PatientListDetailsResponseModel
     */
    country: CountryType;
    /**
     * 
     * @type {StateType}
     * @memberof PatientListDetailsResponseModel
     */
    state: StateType;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {UserStatusType}
     * @memberof PatientListDetailsResponseModel
     */
    status: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof PatientListDetailsResponseModel
     */
    role: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    deviceSerialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    deviceUniqueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    hcpId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    hcpFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    hcpLastName?: string | null;
    /**
     * 
     * @type {LatestMeasurements}
     * @memberof PatientListDetailsResponseModel
     */
    latestMeasurements?: LatestMeasurements;
    /**
     * 
     * @type {string}
     * @memberof PatientListDetailsResponseModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {NationalityType}
     * @memberof PatientListDetailsResponseModel
     */
    nationalityType: NationalityType;
    /**
     * 
     * @type {number}
     * @memberof PatientListDetailsResponseModel
     */
    rowVersion?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatientListDetailsResponseModel
     */
    years?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientListDetailsResponseModel
     */
    bmi?: number;
    /**
     * 
     * @type {GenderType}
     * @memberof PatientListDetailsResponseModel
     */
    gender: GenderType;
}

export function PatientListDetailsResponseModelFromJSON(json: any): PatientListDetailsResponseModel {
    return PatientListDetailsResponseModelFromJSONTyped(json, false);
}

export function PatientListDetailsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientListDetailsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'email': json['Email'],
        'phone': json['Phone'],
        'street': json['Street'],
        'city': json['City'],
        'country': CountryTypeFromJSON(json['Country']),
        'state': StateTypeFromJSON(json['State']),
        'zipCode': json['ZipCode'],
        'organizationId': json['OrganizationId'],
        'organizationName': json['OrganizationName'],
        'status': UserStatusTypeFromJSON(json['Status']),
        'role': UserRoleTypeFromJSON(json['Role']),
        'deviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'deviceSerialNumber': !exists(json, 'DeviceSerialNumber') ? undefined : json['DeviceSerialNumber'],
        'deviceUniqueId': !exists(json, 'DeviceUniqueId') ? undefined : json['DeviceUniqueId'],
        'hcpId': !exists(json, 'HcpId') ? undefined : json['HcpId'],
        'hcpFirstName': !exists(json, 'HcpFirstName') ? undefined : json['HcpFirstName'],
        'hcpLastName': !exists(json, 'HcpLastName') ? undefined : json['HcpLastName'],
        'latestMeasurements': !exists(json, 'LatestMeasurements') ? undefined : LatestMeasurementsFromJSON(json['LatestMeasurements']),
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'nationalityType': NationalityTypeFromJSON(json['NationalityType']),
        'rowVersion': !exists(json, 'RowVersion') ? undefined : json['RowVersion'],
        'years': !exists(json, 'Years') ? undefined : json['Years'],
        'bmi': !exists(json, 'Bmi') ? undefined : json['Bmi'],
        'gender': GenderTypeFromJSON(json['Gender']),
    };
}

export function PatientListDetailsResponseModelToJSON(value?: PatientListDetailsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Email': value.email,
        'Phone': value.phone,
        'Street': value.street,
        'City': value.city,
        'Country': CountryTypeToJSON(value.country),
        'State': StateTypeToJSON(value.state),
        'ZipCode': value.zipCode,
        'OrganizationId': value.organizationId,
        'OrganizationName': value.organizationName,
        'Status': UserStatusTypeToJSON(value.status),
        'Role': UserRoleTypeToJSON(value.role),
        'DeviceId': value.deviceId,
        'DeviceSerialNumber': value.deviceSerialNumber,
        'DeviceUniqueId': value.deviceUniqueId,
        'HcpId': value.hcpId,
        'HcpFirstName': value.hcpFirstName,
        'HcpLastName': value.hcpLastName,
        'LatestMeasurements': LatestMeasurementsToJSON(value.latestMeasurements),
        'RegistryNumber': value.registryNumber,
        'NationalityType': NationalityTypeToJSON(value.nationalityType),
        'RowVersion': value.rowVersion,
        'Years': value.years,
        'Bmi': value.bmi,
        'Gender': GenderTypeToJSON(value.gender),
    };
}

