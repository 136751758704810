/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GenderType,
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface PatientUserModel
 */
export interface PatientUserModel {
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    organizationId: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof PatientUserModel
     */
    roleType: UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof PatientUserModel
     */
    registryNumber?: string | null;
    /**
     * 
     * @type {GenderType}
     * @memberof PatientUserModel
     */
    gender: GenderType;
    /**
     * 
     * @type {number}
     * @memberof PatientUserModel
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof PatientUserModel
     */
    bmi?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientUserModel
     */
    weight: number;
}

export function PatientUserModelFromJSON(json: any): PatientUserModel {
    return PatientUserModelFromJSONTyped(json, false);
}

export function PatientUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'id': json['Id'],
        'organizationId': json['OrganizationId'],
        'roleType': UserRoleTypeFromJSON(json['RoleType']),
        'registryNumber': !exists(json, 'RegistryNumber') ? undefined : json['RegistryNumber'],
        'gender': GenderTypeFromJSON(json['Gender']),
        'height': json['Height'],
        'bmi': !exists(json, 'Bmi') ? undefined : json['Bmi'],
        'weight': json['Weight'],
    };
}

export function PatientUserModelToJSON(value?: PatientUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'Id': value.id,
        'OrganizationId': value.organizationId,
        'RoleType': UserRoleTypeToJSON(value.roleType),
        'RegistryNumber': value.registryNumber,
        'Gender': GenderTypeToJSON(value.gender),
        'Height': value.height,
        'Bmi': value.bmi,
        'Weight': value.weight,
    };
}

