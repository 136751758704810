/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceModel,
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
} from './DeviceModel';
import {
    IValidationError,
    IValidationErrorFromJSON,
    IValidationErrorFromJSONTyped,
    IValidationErrorToJSON,
} from './IValidationError';
import {
    MeasurementModel,
    MeasurementModelFromJSON,
    MeasurementModelFromJSONTyped,
    MeasurementModelToJSON,
} from './MeasurementModel';
import {
    PatientUserModel,
    PatientUserModelFromJSON,
    PatientUserModelFromJSONTyped,
    PatientUserModelToJSON,
} from './PatientUserModel';
import {
    UserModel,
    UserModelFromJSON,
    UserModelFromJSONTyped,
    UserModelToJSON,
} from './UserModel';

/**
 * 
 * @export
 * @interface MeasurementResponseModel
 */
export interface MeasurementResponseModel {
    /**
     * 
     * @type {PatientUserModel}
     * @memberof MeasurementResponseModel
     */
    patient: PatientUserModel;
    /**
     * 
     * @type {UserModel}
     * @memberof MeasurementResponseModel
     */
    testedBy: UserModel;
    /**
     * 
     * @type {DeviceModel}
     * @memberof MeasurementResponseModel
     */
    device: DeviceModel;
    /**
     * 
     * @type {MeasurementModel}
     * @memberof MeasurementResponseModel
     */
    measurements: MeasurementModel;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseModel
     */
    testCountId: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseModel
     */
    mesaurementTime: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseModel
     */
    measurementLocalTime: string;
    /**
     * 
     * @type {Array<IValidationError>}
     * @memberof MeasurementResponseModel
     */
    validations?: Array<IValidationError> | null;
}

export function MeasurementResponseModelFromJSON(json: any): MeasurementResponseModel {
    return MeasurementResponseModelFromJSONTyped(json, false);
}

export function MeasurementResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient': PatientUserModelFromJSON(json['Patient']),
        'testedBy': UserModelFromJSON(json['TestedBy']),
        'device': DeviceModelFromJSON(json['Device']),
        'measurements': MeasurementModelFromJSON(json['Measurements']),
        'id': json['Id'],
        'testCountId': json['TestCountId'],
        'organizationId': json['OrganizationId'],
        'mesaurementTime': json['MesaurementTime'],
        'measurementLocalTime': json['MeasurementLocalTime'],
        'validations': !exists(json, 'Validations') ? undefined : (json['Validations'] === null ? null : (json['Validations'] as Array<any>).map(IValidationErrorFromJSON)),
    };
}

export function MeasurementResponseModelToJSON(value?: MeasurementResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Patient': PatientUserModelToJSON(value.patient),
        'TestedBy': UserModelToJSON(value.testedBy),
        'Device': DeviceModelToJSON(value.device),
        'Measurements': MeasurementModelToJSON(value.measurements),
        'Id': value.id,
        'TestCountId': value.testCountId,
        'OrganizationId': value.organizationId,
        'MesaurementTime': value.mesaurementTime,
        'MeasurementLocalTime': value.measurementLocalTime,
        'Validations': value.validations === undefined ? undefined : (value.validations === null ? null : (value.validations as Array<any>).map(IValidationErrorToJSON)),
    };
}

