import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { RedirectProps, ROUTES } from "router";
import { BackendResponse, TableContext } from "./model";

type TableElementsRedirectProps<PATH extends keyof typeof ROUTES> = RedirectProps<PATH> & {
  event?: React.MouseEvent<HTMLElement, MouseEvent>;
};

export namespace TableUtils {
  export function sliceRowsByPage<T>(data: T[], currentPage: number, linesPerPage: number) {
    return data.slice(
      (currentPage - 1) * linesPerPage,
      (currentPage - 1) * linesPerPage + linesPerPage,
    );
  }

  export function resolveValue(value: any) {
    return typeof value === "function" ? value() : value;
  }

  export function useTableElementsRedirect() {
    const navigate = useNavigate();

    return useCallback(
      <T extends keyof typeof ROUTES>({
        event,
        path,
        params,
        options,
      }: TableElementsRedirectProps<T>) => {
        event?.preventDefault();
        event?.stopPropagation();

        // we need string here coz of -> https://reactrouter.com/en/main/utils/generate-path
        navigate(generatePath(ROUTES[path] as string, params), options);
      },
      [navigate],
    );
  }

  export function areFiltersApplied<T extends BackendResponse, B extends Record<string, any>>(
    context: TableContext<T, B>,
  ) {
    return !!context.filters && !!Object.entries(context.filters).length;
  }
}
