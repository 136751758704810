import { UserDetailsResponseModelListModel, UsersFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { TableContext, tableStateMachine } from "libs/ui";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { AdminFilterOptions, AdministratorsTableColumns } from "utils/createGlobalConfigStore";
import { getCurrentUserData } from "utils/getCurrentUserData";
import { userRoleTypeToSelectOptions } from "utils/mappers/userRoleTypeToSelectOptions";
import { InterpreterFrom } from "xstate";
import { BASIC_FILTER_OPTIONS, FilterSideModalState } from "../Filter/model";
import { BaseTableFilterProps } from "../Table/model";
import { DetailResponseModel, PersonResponseModel } from "../model";

async function getAdminUserList(
  context?: TableContext<UserDetailsResponseModelListModel, UsersFilterModel>,
) {
  const result = await CarnaApiQuery.Users.get({
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getPartnerUserList(
  context?: TableContext<UserDetailsResponseModelListModel, UsersFilterModel>,
) {
  const { organizationId } = await getCurrentUserData();

  const result = await CarnaApiQuery.Partners.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export type UIUserFilters = Omit<UsersFilterModel, "beginsWith" | "userIds">;

const fetchAdministrators = async (
  context?: TableContext<UserDetailsResponseModelListModel, UIUserFilters>,
) => {
  const { isPartner } = await getCurrentUserData();
  return isPartner ? getPartnerUserList(context) : getAdminUserList(context);
};

export const makeTableFetchStateMachine = () => tableStateMachine(fetchAdministrators);

type TableService = InterpreterFrom<ReturnType<typeof makeTableFetchStateMachine>>;

export function ADMINISTRATORS_FILTER_OPTIONS() {
  return {
    ...BASIC_FILTER_OPTIONS(),
    userRoles: userRoleTypeToSelectOptions().filter(
      el => el.value === "Admin" || el.value === "Partner",
    ),
    contains: "",
    organizationIds: [],
  } as const;
}

export type AdministratorsFilterOptionsKey = keyof ReturnType<typeof ADMINISTRATORS_FILTER_OPTIONS>;

export type AdministratorsTableStateContext = {
  tableService: TableService;
  fetch: () => void;
  filterSideModelState: FilterSideModalState<AdministratorsFilterOptionsKey>;
};

export interface AdministratorsTableProps extends BaseTableFilterProps {
  adminTableStateContext: AdministratorsTableStateContext;
  appliedColumns: AdministratorsTableColumns[];
  adminFilterOptions?: AdminFilterOptions[];
}

export interface BodyRowsProps {
  tableService: TableService;
  changeUserStateClick: (user: DetailResponseModel) => MouseEventHandler<HTMLButtonElement>;
  appliedColumns: AdministratorsTableColumns[];
  currentRightClickContext:
    | [React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonResponseModel]
    | undefined;
  setCurrentRightClickContext: Dispatch<
    SetStateAction<
      [React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonResponseModel] | undefined
    >
  >;
}

export interface AdministratorsTableFilterProps {
  tableService: TableService;
  filterSideModelState: FilterSideModalState<AdministratorsFilterOptionsKey>;
}
