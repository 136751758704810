import React, { CSSProperties, DetailedHTMLProps, forwardRef, HTMLAttributes } from "react";
import classNames from "classnames";
import * as IconAssets from "./assets";

import "./Icon.scss";

export type IconTypeActiveInactive =
  | "admins"
  | "alertCircle"
  | "alertTriangle"
  | "archive"
  | "arrowDown"
  | "arrowDownCircle"
  | "arrowDownLeft"
  | "arrowDownRight"
  | "arrowLeft"
  | "arrowLeftCircle"
  | "arrowRight"
  | "arrowRightCircle"
  | "arrowUp"
  | "arrowUpCircle"
  | "arrowUpLeft"
  | "arrowUpRight"
  | "atSign"
  | "attach"
  | "bell"
  | "bluetooth"
  | "book"
  | "bookmarkEmpty"
  | "bookmarkFilled"
  | "calendar"
  | "camera"
  | "caretDown"
  | "caretLeft"
  | "caretRight"
  | "caretUp"
  | "cart"
  | "check"
  | "checkCircle"
  | "chevronDown"
  | "chevronLeft"
  | "chevronRight"
  | "chevronUp"
  | "chevronsDown"
  | "chevronsLeft"
  | "chevronsRight"
  | "chevronsUp"
  | "clinicians"
  | "clipboard"
  | "clock"
  | "close"
  | "closeCircle"
  | "closeSquare"
  | "compass"
  | "computer"
  | "copy"
  | "cornerDownLeft"
  | "cornerDownRight"
  | "cornerLeftDown"
  | "cornerLeftUp"
  | "cornerRightDown"
  | "cornerRightUp"
  | "cornerUpLeft"
  | "cornerUpRight"
  | "creditCard"
  | "crop"
  | "cut"
  | "delete"
  | "download"
  | "drag"
  | "edit"
  | "externalLink"
  | "fastForward"
  | "fiGrid"
  | "fiMenu"
  | "fiPrinter"
  | "file"
  | "fileMinus"
  | "filePlus"
  | "fileText"
  | "filter"
  | "flag"
  | "folder"
  | "folderMinus"
  | "folderPlus"
  | "gift"
  | "globe"
  | "gps"
  | "heartEmpty"
  | "heartFilled"
  | "help"
  | "hide"
  | "home"
  | "image"
  | "index.ts info"
  | "laptop"
  | "layers"
  | "layout"
  | "link"
  | "list"
  | "lock"
  | "mail"
  | "map"
  | "mapPin"
  | "maximize"
  | "mic"
  | "micOff"
  | "minimize"
  | "minus"
  | "minusCircle"
  | "minusSquare"
  | "moreHorizontal"
  | "moreVertical"
  | "move"
  | "navigationEmpty"
  | "navigationFilled"
  | "partners"
  | "pause"
  | "pieChart"
  | "play"
  | "plus"
  | "plusCircle"
  | "plusSquare"
  | "quit"
  | "refresh"
  | "repeat"
  | "resize"
  | "results"
  | "rewind"
  | "ruleEngine"
  | "save"
  | "search"
  | "send"
  | "settings"
  | "share"
  | "show"
  | "signIn"
  | "signOut"
  | "skipBack"
  | "skipForward"
  | "slash"
  | "smartphone"
  | "sort"
  | "stale"
  | "starEmpty"
  | "starFilled"
  | "stop"
  | "tablet"
  | "tag"
  | "thumbsDownEmpty"
  | "thumbsDownFilled"
  | "thumbsUpEmpty"
  | "thumbsUpFilled"
  | "trendingDown"
  | "trendingUp"
  | "upload"
  | "user"
  | "userCheck"
  | "userMinus"
  | "userPlus"
  | "userX"
  | "users"
  | "video"
  | "videoOff"
  | "sync"
  | "voicemail";

export type IconType = keyof typeof IconAssets;
export type IconProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
  icon?: IconType | null;
  scaleTo?: number;
  viewBox?: string;
  loading?: boolean;
};

export function isIconType(icon: any): icon is IconType {
  return !!Object.keys(IconAssets).find(el => el === icon);
}

const DEFAULT_ICON_SIZE_IN_PIXELS = 24;

const IconComp = (
  { icon, viewBox, className, scaleTo, loading, onClick, ...props }: IconProps,
  ref: React.ForwardedRef<HTMLSpanElement>,
) => {
  if (!icon) {
    return null;
  }

  if (IconAssets[icon] === undefined) {
    console.error(`No Icon ( ${icon} ) present in Assets folder`);
    return <>NO ICON</>;
  }

  const IconComponent = IconAssets[icon];

  const scaleIcon: CSSProperties | undefined = scaleTo
    ? { transform: `scale(calc(${scaleTo}/${DEFAULT_ICON_SIZE_IN_PIXELS}))` }
    : undefined;

  return (
    <span
      ref={ref}
      style={scaleIcon}
      className={classNames(`UI-Components Icon Icon-${icon}`, className, {
        "Icon--loading": loading,
        "Icon--clickable": onClick,
      })}
      {...props}
      onClick={onClick}
      role={onClick ? "button" : undefined}
    >
      {loading ? null : <IconComponent viewBox={viewBox} />}
    </span>
  );
};

export const Icon = forwardRef(IconComp);
