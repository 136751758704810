import "./Navigation.scss";
import { NavigationRoute } from "components/NavigationRoute";
import { useAppMenuContext } from "context/AppMenuContext";
import { mainRoutePaths } from "router";
import { useGetNavRoutes } from "utils/hooks/useGetNavRoutes";
import { generatePath } from "react-router-dom";
import { NavigationProps } from "../model";

export function Navigation({ sidebarCollapsed }: NavigationProps) {
  const { setMenuOpen } = useAppMenuContext();
  const [routes] = useGetNavRoutes();

  const navBarRoutes = routes.filter(route => route.to !== generatePath(mainRoutePaths.settings));

  const closeSidebar = () => setMenuOpen(false);

  return (
    <nav className="Navigation">
      <ul className="Navigation__list">
        {navBarRoutes.map(route => {
          return route.accessLevel ? (
            <NavigationRoute
              route={route}
              key={route.to}
              handleRouteChange={closeSidebar}
              {...(sidebarCollapsed && { sidebarCollapsed })}
            />
          ) : null;
        })}
      </ul>
    </nav>
  );
}
