/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EgfrFormulaType,
    EgfrFormulaTypeFromJSON,
    EgfrFormulaTypeFromJSONTyped,
    EgfrFormulaTypeToJSON,
} from './EgfrFormulaType';

/**
 * 
 * @export
 * @interface EgfrOptionsModel
 */
export interface EgfrOptionsModel {
    /**
     * 
     * @type {boolean}
     * @memberof EgfrOptionsModel
     */
    enableMeasurements: boolean;
    /**
     * 
     * @type {EgfrFormulaType}
     * @memberof EgfrOptionsModel
     */
    preferredAlgorithm: EgfrFormulaType;
}

export function EgfrOptionsModelFromJSON(json: any): EgfrOptionsModel {
    return EgfrOptionsModelFromJSONTyped(json, false);
}

export function EgfrOptionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EgfrOptionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enableMeasurements': json['EnableMeasurements'],
        'preferredAlgorithm': EgfrFormulaTypeFromJSON(json['PreferredAlgorithm']),
    };
}

export function EgfrOptionsModelToJSON(value?: EgfrOptionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EnableMeasurements': value.enableMeasurements,
        'PreferredAlgorithm': EgfrFormulaTypeToJSON(value.preferredAlgorithm),
    };
}

