/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NovaMaxMeasurementData,
    NovaMaxMeasurementDataFromJSON,
    NovaMaxMeasurementDataFromJSONTyped,
    NovaMaxMeasurementDataToJSON,
} from './NovaMaxMeasurementData';

/**
 * 
 * @export
 * @interface AddNovaMaxMeasurementHcpRequestModel
 */
export interface AddNovaMaxMeasurementHcpRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AddNovaMaxMeasurementHcpRequestModel
     */
    deviceSerialNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof AddNovaMaxMeasurementHcpRequestModel
     */
    measurementTime: Date;
    /**
     * 
     * @type {NovaMaxMeasurementData}
     * @memberof AddNovaMaxMeasurementHcpRequestModel
     */
    measurementData: NovaMaxMeasurementData;
    /**
     * 
     * @type {string}
     * @memberof AddNovaMaxMeasurementHcpRequestModel
     */
    patientId: string;
}

export function AddNovaMaxMeasurementHcpRequestModelFromJSON(json: any): AddNovaMaxMeasurementHcpRequestModel {
    return AddNovaMaxMeasurementHcpRequestModelFromJSONTyped(json, false);
}

export function AddNovaMaxMeasurementHcpRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNovaMaxMeasurementHcpRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceSerialNumber': json['DeviceSerialNumber'],
        'measurementTime': (new Date(json['MeasurementTime'])),
        'measurementData': NovaMaxMeasurementDataFromJSON(json['MeasurementData']),
        'patientId': json['PatientId'],
    };
}

export function AddNovaMaxMeasurementHcpRequestModelToJSON(value?: AddNovaMaxMeasurementHcpRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DeviceSerialNumber': value.deviceSerialNumber,
        'MeasurementTime': (value.measurementTime.toISOString()),
        'MeasurementData': NovaMaxMeasurementDataToJSON(value.measurementData),
        'PatientId': value.patientId,
    };
}

