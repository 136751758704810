import { useActor } from "@xstate/react/lib/useActor";
import { useInterpret } from "@xstate/react/lib/useInterpret";
import { TabModalControl } from "components/TabModalControl";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { PartnersTable } from "../common/PartnersTable";
import {
  PARTNERS_FILTER_OPTIONS,
  PartnersFilterOptionsKey,
  makeTableFetchStateMachine,
} from "../common/PartnersTable/model";
import { useFilterModal } from "../common/hooks/useFilterModal";
import { getFilterOptionsPerConfiguration } from "../utils";

export function PartnersPerOrganizationTable() {
  const { appConfig } = useGlobalConfigContext();
  const { organizationId = "" } = useParams<RouteParams["organizationDetails"]>();

  const tableMachine = useMemo(() => makeTableFetchStateMachine(organizationId), [organizationId]);
  const tableService = useInterpret(tableMachine);

  const [state, send] = useActor(tableService);

  const filterSideModelState = useFilterModal<PartnersFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      PARTNERS_FILTER_OPTIONS(),
      NotNullOrUndefined(
        appConfig?.components.tables.Partner.PartnerPerOrganizationTable.filterOptions,
      ),
    ),
  );

  const onFilterToggle = useCallback(
    () => filterSideModelState.openFilterModal(),
    [filterSideModelState],
  );

  const onSearchChange = (value: string) =>
    send([{ type: "UPDATE_FILTERS", value: { ...state.context.filters, contains: value } }]);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  useEffect(() => {
    if (state.matches("init")) {
      fetch();
    }
  }, [fetch, state]);

  return (
    <PartnersTable
      tableStateContext={{ tableService, filterSideModelState, fetch }}
      onFilterToggle={onFilterToggle}
      searchField={{
        onChange: onSearchChange,
        value: state.context.filters?.contains ?? "",
      }}
      appliedColumns={NotNullOrUndefined(
        appConfig?.components.tables.Partner.PartnerPerOrganizationTable.columnList,
      )}
      addButton={<TabModalControl variant="User" onSuccessCb={fetch} />}
      filterOptions={appConfig?.components.tables.Partner.PartnerPerOrganizationTable.filterOptions}
    />
  );
}
