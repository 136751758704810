import classNames from "classnames";
import { resolveRenderCell } from "components/AppTables/utils";
import { ActionButton, TableElements } from "libs/ui";
import { TestResultType } from "libs/ui/Table/TableElements/model";
import { TableUtils } from "libs/ui/Table/utils";
import { useTranslation } from "react-i18next";
import { getMeasurementTrendIcon } from "utils/getMeasurementTrendIcon";
import { preventClickBubbling } from "utils/preventClickBubbling";

import { useGetHeightNoLabel } from "components/Chart/hooks/useGetHeightNoLabel";
import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { useGlobalPreferenceContext } from "context/GlobalPreferenceContext";
import { Cell } from "libs/ui/Table/TableElements/Cell";
import { TestResultCellContent } from "libs/ui/Table/TableElements/TestResultCell/TestResultCellContent";
import { MeasurementItemUIType, MeasurementUIType, TestMeasurements } from "models/TestModels";
import { Dispatch, MouseEventHandler, SetStateAction, useCallback, useMemo } from "react";
import { getMeasurementIconType } from "utils/getMeasurementIconType";
import { getSQUACRFlags } from "utils/getSQUACRFlags";
import { useBMIColor } from "utils/hooks/useBMIColor";
import { useCreatinineColor } from "utils/hooks/useCreatinineColor";
import { useCreatinineValueConvertor } from "utils/hooks/useCreatinineValueConvertor";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { useEGFRColor } from "utils/hooks/useEGFRColor";
import { useEGFRStageText } from "utils/hooks/useEGFRStageText";
import { useGetUserPreferredMeasurementUnits } from "utils/hooks/useGetUserPreferredMeasurementUnits/useGetUserPreferredMeasurementUnits";
import { useGetWeightConverter } from "utils/hooks/useGetWeightConverter/useGetWeightConverter";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useRegionDateTimeFormatter } from "utils/hooks/useRegionDateTimeFormatter";
import { useRegistryNumberLabel } from "utils/hooks/useRegistryNumberLabel";
import { useUACRColor } from "utils/hooks/useUACRColor";
import { useUACRStageText } from "utils/hooks/useUACRStageText";
import { useUrineAlbuminColor } from "utils/hooks/useUrineAlbuminColor";
import { useUrineAlbuminValueConvertor } from "utils/hooks/useUrineAlbuminValueConvertor";
import { useUrineCreatinineColor } from "utils/hooks/useUrineCreatinineColor";
import { TestModel, TestsTableColumns } from "../../model";
import { TestUnitType } from "../../utils";
import { TestedBy } from "../TestedBy";
import { logger } from "logger";

interface MeasurementRowProps {
  test: TestModel;
  measurement: TestMeasurements;
  measurementTypeItem?: MeasurementItemUIType;
  loading: boolean;
  appliedColumns: TestsTableColumns[];
  onRowClick: {
    getTest: (request: {
      organizationId: string;
      userEntityId: string;
      testEntityId: string;
    }) => Promise<void>;
    setSelectedTestUnit: Dispatch<SetStateAction<TestUnitType | undefined>>;
  };
  activeDetailsId?: string | null;
  className?: string;
  avatars?: {
    patientAvatar?: string;
    hcpAvatar?: string;
  };
  onDeleteClick: (
    test: TestModel,
    measurementType: MeasurementUIType,
  ) => MouseEventHandler<HTMLButtonElement>;
  onEditClick: (organizationId: string, testId: string) => void;
}

export function MeasurementRow({
  test,
  measurement,
  onRowClick,
  loading,
  avatars,
  appliedColumns,
  activeDetailsId,
  className,
  onDeleteClick,
  onEditClick,
}: Readonly<MeasurementRowProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "TestsTable" });
  const { t: imperialUnit } = useTranslation("measurements", { keyPrefix: "Measurements" });
  const [dateFormatter, timeFormatter] = useRegionDateTimeFormatter();
  const [getCreatineColor] = useCreatinineColor();
  const [getEGFRColor] = useEGFRColor();
  const [getEGFRStageText] = useEGFRStageText();
  const creatinineConvertor = useCreatinineValueConvertor();
  const { serumCreatinineUnit, urineCreatinineUnit } = useGetUserPreferredMeasurementUnits();
  const { isHcp } = useCurrentUserData();
  const [getUrineCreatineColor] = useUrineCreatinineColor();
  const [getUrineAlbuminColor] = useUrineAlbuminColor();
  const [getBMIColor] = useBMIColor();
  const { numberFormatterWithDash } = useLocaleNumberFormatter();
  const getConvertedWeight = useGetWeightConverter();
  const [getUrineAlbuminValue] = useUrineAlbuminValueConvertor();
  const [registryNumberLabel] = useRegistryNumberLabel();
  const [getUACRStageText] = useUACRStageText();
  const [getUACRColor] = useUACRColor();

  const {
    egfrUnit,
    weightUnit,
    glucoseUnit,
    urineAlbuminUnit,
    uacrUnit,
    bloodPressureUnit,
    heightUnit,
  } = useGetUserPreferredMeasurementUnits();
  const can = useHasAccess();

  const { globalPreference } = useGlobalPreferenceContext();
  const [getHeightValue] = useGetHeightNoLabel();

  const tableRedirection = TableUtils.useTableElementsRedirect();

  const toggleEdit = useMemo(
    () => preventClickBubbling(() => onEditClick(test.organizationId, test.id)),
    [onEditClick, test.id, test.organizationId],
  );

  const ColumnsMapper = useCallback(
    (column: TestsTableColumns) => {
      switch (column) {
        case "measurementType":
          return getMeasurementIconType(measurement) ? (
            <TableElements.IconCell
              key={"measurementType"}
              loading={loading}
              show={resolveRenderCell(appliedColumns, "measurementType")}
              className="MeasurementType"
              icon={getMeasurementIconType(measurement)}
            />
          ) : (
            <TableElements.Cell
              key={"measurementType"}
              loading={loading}
              show={resolveRenderCell(appliedColumns, "measurementType")}
            />
          );
        case "date":
          return (
            <TableElements.DateCell
              key={"date"}
              loading={loading}
              show={resolveRenderCell(appliedColumns, "date")}
              time={test.measurementTime ? timeFormatter(test.measurementTime) : t("na")}
            >
              {test.measurementTime ? dateFormatter(test.measurementTime) : t("na")}
            </TableElements.DateCell>
          );
        case "testResult":
          return (
            <TableElements.TestResultCell
              key={"testResult"}
              loading={loading}
              show={resolveRenderCell(appliedColumns, "testResult")}
              type={Object.keys(measurement)[0] as TestResultType}
              measurement={measurement}
            />
          );
        case "serumCreatinine":
          return (
            <Cell
              key="serumCreatinine"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "serumCreatinine")}
            >
              <TestResultCellContent
                type={"serumCreatinine"}
                label={t("LabList.labels.serumCreatinine")}
                colorVal={getCreatineColor(measurement.SerumCreatinine?.SerumCreatinine?.Status)}
                unit={serumCreatinineUnit}
                percent={measurement.SerumCreatinine?.SerumCreatinine?.Percent}
                iconVal={getMeasurementTrendIcon(
                  measurement.SerumCreatinine?.SerumCreatinine?.TrendType,
                )}
              >
                {numberFormatterWithDash({
                  value: +creatinineConvertor(
                    measurement.SerumCreatinine?.SerumCreatinine?.Value ?? 0,
                    measurement.SerumCreatinine?.SerumCreatinine?.Unit ?? MEASUREMENT_UNITS.mgdL,
                  ),
                })}
              </TestResultCellContent>
            </Cell>
          );

        case "eGFR":
          return (
            <Cell
              key="egfr"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "eGFR")}
            >
              <TestResultCellContent
                type={"egfr"}
                label={t("LabList.labels.egfr")}
                colorVal={getEGFRColor(measurement.SerumCreatinine?.Egfr?.Status)}
                unit={egfrUnit}
                percent={measurement.SerumCreatinine?.Egfr?.Percent}
                iconVal={getMeasurementTrendIcon(measurement.SerumCreatinine?.Egfr?.TrendType)}
                tooltip={getEGFRStageText(measurement.SerumCreatinine?.Egfr?.Status)}
              >
                {numberFormatterWithDash({
                  value: measurement.SerumCreatinine?.Egfr?.Value ?? 0,
                })}
              </TestResultCellContent>
            </Cell>
          );

        case "bloodPressure":
          return (
            <Cell
              key="bloodPressure"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "bloodPressure")}
            >
              <TestResultCellContent
                type={"bloodPressure"}
                label={t("LabList.labels.systolic")}
                colorVal={"white"}
                unit={bloodPressureUnit}
                percent={measurement.BloodPressure?.Systolic.Percent}
                iconVal={getMeasurementTrendIcon(measurement.BloodPressure?.Systolic.TrendType)}
              >
                {numberFormatterWithDash({
                  value: measurement.BloodPressure?.Systolic.Value,
                })}
              </TestResultCellContent>

              <TestResultCellContent
                type={"bloodPressure"}
                label={t("LabList.labels.diastolic")}
                colorVal={"white"}
                unit={bloodPressureUnit}
                percent={measurement.BloodPressure?.Diastolic.Percent}
                iconVal={getMeasurementTrendIcon(measurement.BloodPressure?.Diastolic.TrendType)}
              >
                {numberFormatterWithDash({
                  value: measurement.BloodPressure?.Diastolic.Value ?? 0,
                })}
              </TestResultCellContent>
            </Cell>
          );
        case "glucose":
          return (
            <Cell
              key="glucose"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "glucose")}
            >
              <TestResultCellContent
                type={"glucose"}
                label={t("LabList.labels.glucose")}
                colorVal={"white"}
                unit={glucoseUnit}
                percent={measurement.Glucose?.Glucose?.Percent}
                iconVal={getMeasurementTrendIcon(measurement.Glucose?.Glucose?.TrendType)}
              >
                {numberFormatterWithDash({
                  value: measurement.Glucose?.Glucose?.Value,
                })}
              </TestResultCellContent>
            </Cell>
          );
        case "urineCreatinine":
          return (
            <Cell
              key="urineCreatinine"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "urineCreatinine")}
            >
              <TestResultCellContent
                type={"urineCreatinine"}
                label={t("LabList.labels.urineCreatinine")}
                colorVal={getUrineCreatineColor(measurement.UACR?.UrineCreatinine?.Status)}
                unit={urineCreatinineUnit}
                percent={measurement.UACR?.UrineCreatinine?.Percent}
                iconVal={getMeasurementTrendIcon(measurement.UACR?.UrineCreatinine?.TrendType)}
              >
                {numberFormatterWithDash({
                  value: measurement.UACR?.UrineCreatinine?.Value,
                })}
              </TestResultCellContent>
            </Cell>
          );
        case "urineAlbumin":
          return (
            <Cell
              key="urineAlbumin"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "urineAlbumin")}
            >
              <TestResultCellContent
                type={"urineAlbumin"}
                label={t("LabList.labels.urineAlbumin")}
                colorVal={getUrineAlbuminColor(measurement.UACR?.UrineAlbumin.Status)}
                unit={urineAlbuminUnit}
                percent={measurement.UACR?.UrineAlbumin.Percent}
                iconVal={getMeasurementTrendIcon(measurement.UACR?.UrineAlbumin.TrendType)}
              >
                {numberFormatterWithDash({
                  value: getUrineAlbuminValue(String(measurement.UACR?.UrineAlbumin.Value ?? 0)),
                })}
              </TestResultCellContent>
            </Cell>
          );
        case "uacr":
          return (
            <Cell
              key="uacr"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "uacr")}
            >
              <TestResultCellContent
                type={"uacr"}
                label={t("LabList.labels.uacr")}
                colorVal={getUACRColor(measurement.UACR?.Uacr.Status)}
                unit={uacrUnit}
                percent={measurement.UACR?.Uacr.Percent}
                iconVal={getMeasurementTrendIcon(measurement.UACR?.Uacr.TrendType)}
                tooltip={getUACRStageText(measurement.UACR?.Uacr.Status)}
              >
                {numberFormatterWithDash({ value: measurement.UACR?.Uacr.Value })}
              </TestResultCellContent>
            </Cell>
          );

        case "squacr":
          return (
            <Cell
              key="squacr"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "squacr")}
            >
              <TestResultCellContent
                type={"squacr"}
                label={t("LabList.labels.squacr")}
                colorVal={getUACRColor(
                  getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value)
                    .status,
                )}
                percent={measurement.UACR?.Uacr.Percent}
                iconVal={getMeasurementTrendIcon(
                  measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.TrendType,
                )}
                tooltip={getUACRStageText(
                  getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value)
                    .status,
                )}
                unit={
                  getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value)
                    .stadium
                }
              >
                {getSQUACRFlags(measurement.SemiQuantitativeUACR?.SemiQuantitativeUacr.Value).level}
              </TestResultCellContent>
            </Cell>
          );
        case "bmi":
          return (
            <Cell
              key="bmi"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "bmi")}
            >
              <TestResultCellContent
                type={"bmi"}
                label={t("LabList.labels.bmi")}
                colorVal={getBMIColor(measurement.BMI?.Bmi?.Status)}
                percent={measurement.BMI?.Bmi.Percent}
                iconVal={getMeasurementTrendIcon(measurement.BMI?.Bmi.TrendType)}
              >
                {numberFormatterWithDash({
                  value: measurement.BMI?.Bmi.Value,
                  additionalOptions: {
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 0,
                    roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
                  },
                })}
              </TestResultCellContent>
            </Cell>
          );

        case "height":
          return (
            <Cell
              key="height"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "height")}
            >
              <TestResultCellContent
                type={"height"}
                label={t("LabList.labels.height")}
                colorVal={"white"}
                percent={measurement.BMI?.Height.Percent}
                iconVal={getMeasurementTrendIcon(measurement.BMI?.Height.TrendType)}
                unit={
                  globalPreference?.measureUnitType === "Metric"
                    ? heightUnit
                    : imperialUnit("heightImperialUnit")
                }
              >
                {getHeightValue(Number(measurement.BMI?.Height.Value ?? 0))}
              </TestResultCellContent>
            </Cell>
          );

        case "weight":
          return (
            <Cell
              key="weight"
              className="TestResultCell"
              loading={loading}
              show={resolveRenderCell(appliedColumns, "weight")}
            >
              <TestResultCellContent
                type={"weight"}
                label={t("LabList.labels.weight")}
                colorVal={"white"}
                percent={measurement.BMI?.Weight.Percent}
                iconVal={getMeasurementTrendIcon(measurement.BMI?.Weight.TrendType)}
                unit={weightUnit}
              >
                {numberFormatterWithDash({
                  value: Number(getConvertedWeight(measurement.BMI?.Weight.Value ?? 0)),
                  additionalOptions: {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                    roundingMode: "trunc", // Key part: Set rounding mode to 'trunc'
                  },
                })}
              </TestResultCellContent>
            </Cell>
          );

        case "patient":
          return (
            <TableElements.AvatarCell
              key={"patient"}
              loading={loading}
              show={resolveRenderCell(appliedColumns, "patient")}
              src={avatars?.patientAvatar}
              avatarLoading={false}
              firstName={test.patient.firstName}
              label={test.patient.firstName.concat(` ${test.patient.lastName}`)}
              {...(!!test.patient.registryNumber && {
                tag: `${registryNumberLabel}: ${test.patient.registryNumber}`,
              })}
              avatarType={"Patient"}
              onCellClick={e => {
                tableRedirection({
                  event: e,
                  path: "patientDetails",
                  params: {
                    patientId: test.patient.id,
                    organizationId: test.patient.organizationId,
                  },
                });
              }}
            >
              {t("na")}
            </TableElements.AvatarCell>
          );
        case "testedBy":
          return (
            <TestedBy
              key={"testedBy"}
              show={resolveRenderCell(appliedColumns, "testedBy")}
              testedBy={test.testedBy}
              avatarImg={avatars?.hcpAvatar}
              avatarLoading={false}
              loading={loading}
            />
          );
        case "id":
          return (
            <TableElements.Cell
              key={"id"}
              loading={loading}
              show={resolveRenderCell(appliedColumns, "id")}
            >
              #{test.testCountId}
            </TableElements.Cell>
          );
        case "testingMethod":
          return (
            <TableElements.DeviceCell
              key={"testingMethod"}
              loading={loading}
              show={resolveRenderCell(appliedColumns, "testingMethod")}
              {...(can("manage-device") &&
                test.device?.id && {
                  onClick: e =>
                    tableRedirection({
                      event: e,
                      path: "deviceDetails",
                      params: {
                        deviceId: test.device?.id ?? "",
                        organizationId: test.organizationId,
                      },
                    }),
                })}
              deviceSerialNumber={test.device?.serialNumber}
              sampledByDevice={test.sampledByDevice}
              logger={logger}
            >
              {t("manualInput")}
            </TableElements.DeviceCell>
          );
        case "actions":
          return (
            <TableElements.Cell
              key="actions"
              show={resolveRenderCell(appliedColumns, "actions")}
              className="ActionsCell"
            >
              <TableElements.Actions disabled={!isHcp}>
                <ActionButton
                  className=" ActionButton--fullWidth"
                  onClick={toggleEdit}
                  iconType={"Edit"}
                  actionType="Invited"
                >
                  {t("actions.Edit")}
                </ActionButton>
                <ActionButton
                  className="ActionButton--Delete"
                  onClick={onDeleteClick(test, Object.keys(measurement)[0] as MeasurementUIType)}
                  iconType={"Delete"}
                  actionType={"Deleted"}
                >
                  {t("actions.Delete")}
                </ActionButton>
              </TableElements.Actions>
            </TableElements.Cell>
          );
        default:
          console.error(`Not found - table cell type = ${column}`);
          return null;
      }
    },
    [
      appliedColumns,
      avatars?.hcpAvatar,
      avatars?.patientAvatar,
      bloodPressureUnit,
      can,
      creatinineConvertor,
      dateFormatter,
      egfrUnit,
      getBMIColor,
      getConvertedWeight,
      getCreatineColor,
      getEGFRColor,
      getEGFRStageText,
      getHeightValue,
      getUACRColor,
      getUACRStageText,
      getUrineAlbuminColor,
      getUrineAlbuminValue,
      getUrineCreatineColor,
      globalPreference?.measureUnitType,
      glucoseUnit,
      heightUnit,
      imperialUnit,
      isHcp,
      loading,
      measurement,
      numberFormatterWithDash,
      onDeleteClick,
      registryNumberLabel,
      serumCreatinineUnit,
      t,
      tableRedirection,
      test,
      timeFormatter,
      toggleEdit,
      uacrUnit,
      urineAlbuminUnit,
      urineCreatinineUnit,
      weightUnit,
    ],
  );

  const mappedColumns = useMemo(
    () => appliedColumns.map(ColumnsMapper),
    [ColumnsMapper, appliedColumns],
  );

  return (
    <TableElements.Row
      onClick={
        loading
          ? undefined
          : () => {
              onRowClick.getTest({
                organizationId: test.organizationId,
                userEntityId: test.patient.id,
                testEntityId: test.id,
              });
              onRowClick.setSelectedTestUnit(Object.keys(measurement)[0] as TestUnitType);
            }
      }
      className={classNames(className, {
        SelectedTest: `${test.id}-${Object.keys(measurement)[0]}` === activeDetailsId,
      })}
    >
      {mappedColumns}
    </TableElements.Row>
  );
}
