import { Widget } from "libs/ui/Widget";
import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { PatientGeneralInfo } from "pages/PatientsPage/Details/Overview/PatientInfoWidget/PatientGeneralInfo";
import { PatientInfoHeader } from "pages/PatientsPage/Details/Overview/PatientInfoWidget/PatientInfoHeader";
import { PatientOrganizationalInfo } from "pages/PatientsPage/Details/Overview/PatientInfoWidget/PatientOrganizationalInfo";
import { RefreshFailedWidget } from "pages/PatientsPage/Details/Overview/RefreshFailedWidget";
import { WidgetLoader } from "pages/PatientsPage/Details/Overview/WidgetLoader";
import { useCallback, useSyncExternalStore } from "react";
import { gridContainer } from "utils/helpers/gridContainer";
import { PictureResolution } from "utils/helpers/pictures.model";
import { avatarCache } from "utils/machines/avatars";
import { useDashboardStatsContext } from "../DashboardStatsContext";
interface Props {
  organizationId: string;
  patientId: string;
}

export function PatientInfoWidget({ organizationId, patientId }: Readonly<Props>) {
  const {
    data: { PatientInfo },
    getWidgetData,
  } = useDashboardStatsContext();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);

  const patientInfoData = isLoaded(PatientInfo) ? PatientInfo.value : null;

  const refreshWidgetData = useCallback(() => {
    getWidgetData(organizationId, patientId, ["PatientInfo"]);
  }, [getWidgetData, patientId, organizationId]);

  return (
    <Widget
      className="PatientInfoWidget"
      widgetName={"PatientInfoWidget"}
      gridContainer={gridContainer}
      options={{
        maxHeight: 6,
        minHeight: 6,
        x: 7,
        y: 1,
        height: 6,
        width: 6,
        maxWidth: 6,
        minWidth: 6,
      }}
    >
      {isLoading(PatientInfo) || isDefaultModel(PatientInfo) ? (
        <WidgetLoader />
      ) : isLoaded(PatientInfo) ? (
        <>
          <PatientInfoHeader
            avatar={avatarCache.get(organizationId, patientId, PictureResolution["112x112"])}
            firstName={patientInfoData?.firstName}
            lastName={patientInfoData?.lastName}
            registryNumber={patientInfoData?.registryNumber}
          />

          <PatientGeneralInfo
            gender={patientInfoData?.gender}
            bmi={patientInfoData?.bmi}
            age={patientInfoData?.years}
          />

          <hr className="PatientInfoWidget__divider" />

          <PatientOrganizationalInfo data={patientInfoData} />
        </>
      ) : (
        <RefreshFailedWidget show={isFailed(PatientInfo)} refreshCb={refreshWidgetData} />
      )}
    </Widget>
  );
}
