/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    StringApiResponse,
    StringApiResponseFromJSON,
    StringApiResponseToJSON,
    SupportFormRequestModel,
    SupportFormRequestModelFromJSON,
    SupportFormRequestModelToJSON,
} from '../models';

export interface SupportFormPostRequest {
    SupportFormRequestModel: SupportFormRequestModel;
}

/**
 * SupportFormApi - interface
 * 
 * @export
 * @interface SupportFormApiInterface
 */
export interface SupportFormApiInterface {
    /**
     * 
     * @param {SupportFormRequestModel} SupportFormRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportFormApiInterface
     */
    supportFormPostRaw(requestParameters: SupportFormPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    supportFormPost(SupportFormRequestModel: SupportFormRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

}

/**
 * 
 */
export class SupportFormApi extends runtime.BaseAPI implements SupportFormApiInterface {

    /**
     */
    async supportFormPostRaw(requestParameters: SupportFormPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.SupportFormRequestModel === null || requestParameters.SupportFormRequestModel === undefined) {
            throw new runtime.RequiredError('SupportFormRequestModel','Required parameter requestParameters.SupportFormRequestModel was null or undefined when calling supportFormPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/support-form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupportFormRequestModelToJSON(requestParameters.SupportFormRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async supportFormPost(SupportFormRequestModel: SupportFormRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.supportFormPostRaw({ SupportFormRequestModel: SupportFormRequestModel }, initOverrides);
        return await response.value();
    }

}
