import { EGFRRangeTable } from "components/MeasurementSideDetails/SideDetails/elements/EGFRRangeTable/EGFRRangeTable";

interface EGFRResultProps {
  value?: number;
}

export function EGFRResult({ value }: EGFRResultProps) {
  return (
    <div className="EGFRResult">
      <EGFRRangeTable value={value} />
    </div>
  );
}
