import { useInterpret, useActor } from "@xstate/react";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { useFilterModal } from "../common/hooks";
import {
  makeTableFetchStateMachine,
  AdministratorsFilterOptionsKey,
  AdministratorsTableStateContext,
  ADMINISTRATORS_FILTER_OPTIONS,
} from "./../common/AdministratorsTable/model";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { getFilterOptionsPerConfiguration } from "../utils";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

const Context = createSafeContext<AdministratorsTableStateContext>();

export const useAdministratorsTableStateContext = Context.hook;

export function AdministratorsTableStateProvider({ children }: Readonly<PropsWithChildren>) {
  const tableMachine = useMemo(() => makeTableFetchStateMachine(), []);
  const tableService = useInterpret(tableMachine);
  const { appConfig } = useGlobalConfigContext();
  const filterSideModelState = useFilterModal<AdministratorsFilterOptionsKey>(
    getFilterOptionsPerConfiguration(
      ADMINISTRATORS_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Administrator.Index.filterOptions),
    ),
  );

  const [, send] = useActor(tableService);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  const value = useMemo(
    () => ({
      tableService,
      fetch,
      filterSideModelState,
    }),
    [tableService, fetch, filterSideModelState],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
