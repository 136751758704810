/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddUserRequestModel,
    AddUserRequestModelFromJSON,
    AddUserRequestModelToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    PartnersFilter,
    PartnersFilterFromJSON,
    PartnersFilterToJSON,
    ResendSignUpRequestModel,
    ResendSignUpRequestModelFromJSON,
    ResendSignUpRequestModelToJSON,
    StringApiResponse,
    StringApiResponseFromJSON,
    StringApiResponseToJSON,
    UpdateUserRequestModel,
    UpdateUserRequestModelFromJSON,
    UpdateUserRequestModelToJSON,
    UpdateUserStatusRequestModel,
    UpdateUserStatusRequestModelFromJSON,
    UpdateUserStatusRequestModelToJSON,
    UpdateUserStatusResponseModelApiResponse,
    UpdateUserStatusResponseModelApiResponseFromJSON,
    UpdateUserStatusResponseModelApiResponseToJSON,
    UserDetailsResponseModelApiResponse,
    UserDetailsResponseModelApiResponseFromJSON,
    UserDetailsResponseModelApiResponseToJSON,
    UserListResponseModelApiResponse,
    UserListResponseModelApiResponseFromJSON,
    UserListResponseModelApiResponseToJSON,
} from '../models';

export interface OrganizationsOrganizationIdPartnersGetRequest {
    limit: number;
    organizationId: string;
    paginationToken?: string;
    filters?: PartnersFilter;
}

export interface OrganizationsOrganizationIdPartnersIdDeleteRequest {
    id: string;
    rowVersion: number;
    organizationId: string;
}

export interface OrganizationsOrganizationIdPartnersIdGetRequest {
    id: string;
    organizationId: string;
}

export interface OrganizationsOrganizationIdPartnersIdPatchRequest {
    id: string;
    organizationId: string;
    UpdateUserStatusRequestModel?: UpdateUserStatusRequestModel;
}

export interface OrganizationsOrganizationIdPartnersIdPutRequest {
    id: string;
    organizationId: string;
    UpdateUserRequestModel?: UpdateUserRequestModel;
}

export interface OrganizationsOrganizationIdPartnersIdResendSignUpPostRequest {
    organizationId: string;
    id: string;
    ResendSignUpRequestModel: ResendSignUpRequestModel;
}

export interface OrganizationsOrganizationIdPartnersPostRequest {
    organizationId: string;
    AddUserRequestModel?: AddUserRequestModel;
}

/**
 * PartnerApi - interface
 * 
 * @export
 * @interface PartnerApiInterface
 */
export interface PartnerApiInterface {
    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} [paginationToken] 
     * @param {PartnersFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApiInterface
     */
    organizationsOrganizationIdPartnersGetRaw(requestParameters: OrganizationsOrganizationIdPartnersGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPartnersGet(limit: number, organizationId: string, paginationToken?: string, filters?: PartnersFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {number} rowVersion 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApiInterface
     */
    organizationsOrganizationIdPartnersIdDeleteRaw(requestParameters: OrganizationsOrganizationIdPartnersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdPartnersIdDelete(id: string, rowVersion: number, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApiInterface
     */
    organizationsOrganizationIdPartnersIdGetRaw(requestParameters: OrganizationsOrganizationIdPartnersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPartnersIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdateUserStatusRequestModel} [UpdateUserStatusRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApiInterface
     */
    organizationsOrganizationIdPartnersIdPatchRaw(requestParameters: OrganizationsOrganizationIdPartnersIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateUserStatusResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPartnersIdPatch(id: string, organizationId: string, UpdateUserStatusRequestModel?: UpdateUserStatusRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateUserStatusResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdateUserRequestModel} [UpdateUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApiInterface
     */
    organizationsOrganizationIdPartnersIdPutRaw(requestParameters: OrganizationsOrganizationIdPartnersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPartnersIdPut(id: string, organizationId: string, UpdateUserRequestModel?: UpdateUserRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {ResendSignUpRequestModel} ResendSignUpRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApiInterface
     */
    organizationsOrganizationIdPartnersIdResendSignUpPostRaw(requestParameters: OrganizationsOrganizationIdPartnersIdResendSignUpPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdPartnersIdResendSignUpPost(organizationId: string, id: string, ResendSignUpRequestModel: ResendSignUpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {AddUserRequestModel} [AddUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApiInterface
     */
    organizationsOrganizationIdPartnersPostRaw(requestParameters: OrganizationsOrganizationIdPartnersPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPartnersPost(organizationId: string, AddUserRequestModel?: AddUserRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse>;

}

/**
 * 
 */
export class PartnerApi extends runtime.BaseAPI implements PartnerApiInterface {

    /**
     */
    async organizationsOrganizationIdPartnersGetRaw(requestParameters: OrganizationsOrganizationIdPartnersGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdPartnersGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/partners`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPartnersGet(limit: number, organizationId: string, paginationToken?: string, filters?: PartnersFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPartnersGetRaw({ limit: limit, organizationId: organizationId, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdDeleteRaw(requestParameters: OrganizationsOrganizationIdPartnersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPartnersIdDelete.');
        }

        if (requestParameters.rowVersion === null || requestParameters.rowVersion === undefined) {
            throw new runtime.RequiredError('rowVersion','Required parameter requestParameters.rowVersion was null or undefined when calling organizationsOrganizationIdPartnersIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdDelete(id: string, rowVersion: number, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdPartnersIdDeleteRaw({ id: id, rowVersion: rowVersion, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdGetRaw(requestParameters: OrganizationsOrganizationIdPartnersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPartnersIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPartnersIdGetRaw({ id: id, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdPatchRaw(requestParameters: OrganizationsOrganizationIdPartnersIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateUserStatusResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPartnersIdPatch.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.UpdateUserStatusRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserStatusResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdPatch(id: string, organizationId: string, UpdateUserStatusRequestModel?: UpdateUserStatusRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateUserStatusResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPartnersIdPatchRaw({ id: id, organizationId: organizationId, UpdateUserStatusRequestModel: UpdateUserStatusRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdPutRaw(requestParameters: OrganizationsOrganizationIdPartnersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPartnersIdPut.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestModelToJSON(requestParameters.UpdateUserRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdPut(id: string, organizationId: string, UpdateUserRequestModel?: UpdateUserRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPartnersIdPutRaw({ id: id, organizationId: organizationId, UpdateUserRequestModel: UpdateUserRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdResendSignUpPostRaw(requestParameters: OrganizationsOrganizationIdPartnersIdResendSignUpPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersIdResendSignUpPost.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPartnersIdResendSignUpPost.');
        }

        if (requestParameters.ResendSignUpRequestModel === null || requestParameters.ResendSignUpRequestModel === undefined) {
            throw new runtime.RequiredError('ResendSignUpRequestModel','Required parameter requestParameters.ResendSignUpRequestModel was null or undefined when calling organizationsOrganizationIdPartnersIdResendSignUpPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/partners/{id}/resendSignUp`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendSignUpRequestModelToJSON(requestParameters.ResendSignUpRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPartnersIdResendSignUpPost(organizationId: string, id: string, ResendSignUpRequestModel: ResendSignUpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdPartnersIdResendSignUpPostRaw({ organizationId: organizationId, id: id, ResendSignUpRequestModel: ResendSignUpRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPartnersPostRaw(requestParameters: OrganizationsOrganizationIdPartnersPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPartnersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/partners`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddUserRequestModelToJSON(requestParameters.AddUserRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPartnersPost(organizationId: string, AddUserRequestModel?: AddUserRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPartnersPostRaw({ organizationId: organizationId, AddUserRequestModel: AddUserRequestModel }, initOverrides);
        return await response.value();
    }

}
