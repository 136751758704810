/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddOrganizationRequestModel,
    AddOrganizationRequestModelFromJSON,
    AddOrganizationRequestModelToJSON,
    AddOrganizationResponseModelApiResponse,
    AddOrganizationResponseModelApiResponseFromJSON,
    AddOrganizationResponseModelApiResponseToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    OrganizationCollectionResponseModelApiResponse,
    OrganizationCollectionResponseModelApiResponseFromJSON,
    OrganizationCollectionResponseModelApiResponseToJSON,
    OrganizationDetailsResponseModelApiResponse,
    OrganizationDetailsResponseModelApiResponseFromJSON,
    OrganizationDetailsResponseModelApiResponseToJSON,
    OrganizationsFilter,
    OrganizationsFilterFromJSON,
    OrganizationsFilterToJSON,
    UpdateOrganizationRequestModel,
    UpdateOrganizationRequestModelFromJSON,
    UpdateOrganizationRequestModelToJSON,
    UpdateOrganizationResponseModelApiResponse,
    UpdateOrganizationResponseModelApiResponseFromJSON,
    UpdateOrganizationResponseModelApiResponseToJSON,
} from '../models';

export interface OrganizationsGetRequest {
    limit: number;
    paginationToken?: string;
    filters?: OrganizationsFilter;
}

export interface OrganizationsIdGetRequest {
    id: string;
}

export interface OrganizationsIdPutRequest {
    id: string;
    UpdateOrganizationRequestModel?: UpdateOrganizationRequestModel;
}

export interface OrganizationsPostRequest {
    AddOrganizationRequestModel?: AddOrganizationRequestModel;
}

/**
 * OrganizationApi - interface
 * 
 * @export
 * @interface OrganizationApiInterface
 */
export interface OrganizationApiInterface {
    /**
     * 
     * @param {number} limit 
     * @param {string} [paginationToken] 
     * @param {OrganizationsFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationsGetRaw(requestParameters: OrganizationsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationCollectionResponseModelApiResponse>>;

    /**
     */
    organizationsGet(limit: number, paginationToken?: string, filters?: OrganizationsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationCollectionResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationsIdGetRaw(requestParameters: OrganizationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateOrganizationRequestModel} [UpdateOrganizationRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationsIdPutRaw(requestParameters: OrganizationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateOrganizationResponseModelApiResponse>>;

    /**
     */
    organizationsIdPut(id: string, UpdateOrganizationRequestModel?: UpdateOrganizationRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateOrganizationResponseModelApiResponse>;

    /**
     * 
     * @param {AddOrganizationRequestModel} [AddOrganizationRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationsPostRaw(requestParameters: OrganizationsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddOrganizationResponseModelApiResponse>>;

    /**
     */
    organizationsPost(AddOrganizationRequestModel?: AddOrganizationRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddOrganizationResponseModelApiResponse>;

}

/**
 * 
 */
export class OrganizationApi extends runtime.BaseAPI implements OrganizationApiInterface {

    /**
     */
    async organizationsGetRaw(requestParameters: OrganizationsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationCollectionResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationCollectionResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsGet(limit: number, paginationToken?: string, filters?: OrganizationsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationCollectionResponseModelApiResponse> {
        const response = await this.organizationsGetRaw({ limit: limit, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsIdGetRaw(requestParameters: OrganizationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDetailsResponseModelApiResponse> {
        const response = await this.organizationsIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsIdPutRaw(requestParameters: OrganizationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateOrganizationResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationRequestModelToJSON(requestParameters.UpdateOrganizationRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateOrganizationResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsIdPut(id: string, UpdateOrganizationRequestModel?: UpdateOrganizationRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateOrganizationResponseModelApiResponse> {
        const response = await this.organizationsIdPutRaw({ id: id, UpdateOrganizationRequestModel: UpdateOrganizationRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsPostRaw(requestParameters: OrganizationsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddOrganizationResponseModelApiResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrganizationRequestModelToJSON(requestParameters.AddOrganizationRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddOrganizationResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsPost(AddOrganizationRequestModel?: AddOrganizationRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddOrganizationResponseModelApiResponse> {
        const response = await this.organizationsPostRaw({ AddOrganizationRequestModel: AddOrganizationRequestModel }, initOverrides);
        return await response.value();
    }

}
