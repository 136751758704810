/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GenderType,
    GenderTypeFromJSON,
    GenderTypeFromJSONTyped,
    GenderTypeToJSON,
} from './GenderType';
import {
    MeasurementGraphModel,
    MeasurementGraphModelFromJSON,
    MeasurementGraphModelFromJSONTyped,
    MeasurementGraphModelToJSON,
} from './MeasurementGraphModel';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface UserGraphModel
 */
export interface UserGraphModel {
    /**
     * 
     * @type {string}
     * @memberof UserGraphModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserGraphModel
     */
    mesaurementFirstTime: string;
    /**
     * 
     * @type {string}
     * @memberof UserGraphModel
     */
    measurementFirstLocalTime: string;
    /**
     * 
     * @type {string}
     * @memberof UserGraphModel
     */
    mesaurementLastTime: string;
    /**
     * 
     * @type {string}
     * @memberof UserGraphModel
     */
    measurementLastLocalTime: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserGraphModel
     */
    roleType: UserRoleType;
    /**
     * 
     * @type {GenderType}
     * @memberof UserGraphModel
     */
    genderType: GenderType;
    /**
     * 
     * @type {Array<MeasurementGraphModel>}
     * @memberof UserGraphModel
     */
    measurements: Array<MeasurementGraphModel>;
}

export function UserGraphModelFromJSON(json: any): UserGraphModel {
    return UserGraphModelFromJSONTyped(json, false);
}

export function UserGraphModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGraphModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'mesaurementFirstTime': json['MesaurementFirstTime'],
        'measurementFirstLocalTime': json['MeasurementFirstLocalTime'],
        'mesaurementLastTime': json['MesaurementLastTime'],
        'measurementLastLocalTime': json['MeasurementLastLocalTime'],
        'roleType': UserRoleTypeFromJSON(json['RoleType']),
        'genderType': GenderTypeFromJSON(json['GenderType']),
        'measurements': ((json['Measurements'] as Array<any>).map(MeasurementGraphModelFromJSON)),
    };
}

export function UserGraphModelToJSON(value?: UserGraphModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'MesaurementFirstTime': value.mesaurementFirstTime,
        'MeasurementFirstLocalTime': value.measurementFirstLocalTime,
        'MesaurementLastTime': value.mesaurementLastTime,
        'MeasurementLastLocalTime': value.measurementLastLocalTime,
        'RoleType': UserRoleTypeToJSON(value.roleType),
        'GenderType': GenderTypeToJSON(value.genderType),
        'Measurements': ((value.measurements as Array<any>).map(MeasurementGraphModelToJSON)),
    };
}

