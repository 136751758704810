import { HcpResponseModelListModel, HcpsFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { TableContext, tableStateMachine } from "libs/ui";
import { FEHcpResponseModel, InitialValuesModel } from "models/PersonModels";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { HCPTableColumns, HcpFilterOptions } from "utils/createGlobalConfigStore";
import { getCurrentUserData } from "utils/getCurrentUserData";
import { InterpreterFrom } from "xstate";
import { BASIC_FILTER_OPTIONS, FilterSideModalState } from "../Filter/model";
import { BaseTableFilterProps } from "../Table/model";
import { PersonResponseModel } from "../model";

async function getAdminHCPList(context?: TableContext<HcpResponseModelListModel, HcpsFilterModel>) {
  const result = await CarnaApiQuery.Admin.getHcps({
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

async function getPartnerHCPList(
  context?: TableContext<HcpResponseModelListModel, HcpsFilterModel>,
) {
  const { organizationId } = await getCurrentUserData();

  const result = await CarnaApiQuery.Hcps.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

export type UIHcpsFilters = Omit<HcpsFilterModel, "beginsWith" | "userIds">;

async function tableFetch(context?: TableContext<HcpResponseModelListModel, UIHcpsFilters>) {
  const { isAdmin } = await getCurrentUserData();

  return isAdmin ? getAdminHCPList(context) : getPartnerHCPList(context);
}

export const makeTableFetchStateMachine = () => tableStateMachine(tableFetch);

export function HCPs_FILTER_OPTIONS() {
  return {
    ...BASIC_FILTER_OPTIONS(),
    organizationIds: [],
    contains: "",
    withDevice: null,
  } as const;
}

export type HCPsFilterOptionsKey = keyof ReturnType<typeof HCPs_FILTER_OPTIONS>;

type TableService = InterpreterFrom<ReturnType<typeof makeTableFetchStateMachine>>;

export type HCPTableStateContext = {
  tableService: TableService;
  fetch: () => void;
  filterSideModelState: FilterSideModalState<HCPsFilterOptionsKey>;
};

export interface HCPTableProps extends BaseTableFilterProps {
  HCPTableStateContext: HCPTableStateContext;
  appliedColumns: HCPTableColumns[];
  hcpFilterOptions?: HcpFilterOptions[];
  initialValuesAsProps?: Partial<InitialValuesModel>;
}

export interface HCPsTableFilterProps {
  tableService: TableService;
  filterSideModelState: FilterSideModalState<HCPsFilterOptionsKey>;
}

export interface BodyRowsProps {
  tableService: TableService;
  changeHCPStateClick: (hcp: FEHcpResponseModel) => MouseEventHandler<HTMLButtonElement>;
  appliedColumns: HCPTableColumns[];
  currentRightClickContext:
    | [React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonResponseModel]
    | undefined;
  setCurrentRightClickContext: Dispatch<
    SetStateAction<
      [React.MouseEvent<HTMLTableRowElement, MouseEvent>, PersonResponseModel] | undefined
    >
  >;
}
