import { Hub } from "aws-amplify";
import i18n from "i18next";
import { fetchTranslationsInBundle } from "i18n.helpers";
import { globalConfigStore } from "config/globalConfig";
import { isLoaded } from "models/loadable";
import {
  ROOT_TRANSLATION_FILES,
  TENANT_SPECIFIC_ROOT_TRANSLATION_FILES,
  TENANT_SPECIFIC_TRANSLATION_FILES,
} from "config/translations";
import { i18nNotification } from "i18nNotification";

// ! ROOT FETCH
let rootFetch = fetchTranslationsInBundle(
  ROOT_TRANSLATION_FILES.map(ns => ({
    language: i18n.language,
    namespace: ns,
  })),
  true,
);

let requestInit: RequestInit;

declare global {
  interface Window {
    i18n: any;
  }
}
window.i18n = i18n;

export let rootLoaded = false;

const tenant_namespaces = TENANT_SPECIFIC_TRANSLATION_FILES;
/**
 * root translations
 * config
 *  -- they are coming in parallel
 */
async function fetchTenantBundle(tenantId?: string) {
  const result1 = await fetchTranslationsInBundle(
    TENANT_SPECIFIC_ROOT_TRANSLATION_FILES.map(ns => ({
      language: i18n.language,
      namespace: ns,
      tenantId,
    })),
    true,
    requestInit,
  );

  const result2 = await fetchTranslationsInBundle(
    tenant_namespaces.map(ns => ({
      language: i18n.language,
      namespace: ns,
      tenantId,
      requestInit,
    })),
  );

  return result1 || result2;
}

function notifyAppLayout(hasError: Error | undefined) {
  if (hasError) {
    i18nNotification.setState(hasError.cause === "json_error" ? "json_error" : "failed");
    // throw hasError;
  } else {
    // we don't want parallel fetch to override if some of root translations failed before
    if (i18nNotification.getState() === "failed" || i18nNotification.getState() === "json_error") {
      return;
    }

    i18nNotification.setState("ready");
  }
}

async function HubListenerGCListener() {
  const globalConfigState = globalConfigStore.getConfig();
  if (
    rootLoaded &&
    isLoaded(globalConfigState) &&
    globalConfigState.value.general.translationTenantId
  ) {
    // console.log("---FETCHING BUNDLE FROM STORE SUBSCRIBE");
    let result: Error | undefined;

    try {
      result = await fetchTenantBundle(globalConfigState.value.general.translationTenantId);
    } catch (error: any) {
      result = error;
    }

    notifyAppLayout(result);
  }
}

function HubListener({ payload }: any) {
  if (payload.event === "signIn" || payload.event === "configured") {
    // if root is LOADED AND config WILL BE loaded
    globalConfigStore.subscribe(HubListenerGCListener);
    // We wait for main files first
    rootFetch
      .then(data => {
        const globalConfigState = globalConfigStore.getConfig();
        // if Config is LOADED and root is LOADED
        // console.log("---- ROOT LOADED", { status: globalConfigState.state });
        if (isLoaded(globalConfigState) && globalConfigState.value.general.translationTenantId) {
          // console.log("---FETCHING BUNDLE FROM ROOT FETCH");

          fetchTenantBundle(globalConfigState.value.general.translationTenantId)
            .then(result => {
              notifyAppLayout(result);
            })
            .catch((error: any) => {
              notifyAppLayout(error);
            });
        }
        rootLoaded = true;
      })
      .catch(error => {
        notifyAppLayout(error);
        // rootFetch can have error, we will ignore further download if it happens
      });
  }
}

Hub.listen("auth", HubListener, "translations");

export async function resetTranslationLoad(withForceCache = true) {
  rootLoaded = false;
  requestInit = { cache: withForceCache ? "force-cache" : "default" };

  rootFetch = fetchTranslationsInBundle(
    ROOT_TRANSLATION_FILES.map(ns => ({
      language: i18n.language,
      namespace: ns,
    })),
    true,
    requestInit,
  );
}
