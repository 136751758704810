/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceDetailsResponseModel,
    DeviceDetailsResponseModelFromJSON,
    DeviceDetailsResponseModelFromJSONTyped,
    DeviceDetailsResponseModelToJSON,
} from './DeviceDetailsResponseModel';

/**
 * 
 * @export
 * @interface DeviceListResponseModel
 */
export interface DeviceListResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DeviceListResponseModel
     */
    paginationToken?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviceListResponseModel
     */
    limit?: number;
    /**
     * 
     * @type {Array<DeviceDetailsResponseModel>}
     * @memberof DeviceListResponseModel
     */
    devices?: Array<DeviceDetailsResponseModel> | null;
}

export function DeviceListResponseModelFromJSON(json: any): DeviceListResponseModel {
    return DeviceListResponseModelFromJSONTyped(json, false);
}

export function DeviceListResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceListResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationToken': !exists(json, 'PaginationToken') ? undefined : json['PaginationToken'],
        'limit': !exists(json, 'Limit') ? undefined : json['Limit'],
        'devices': !exists(json, 'Devices') ? undefined : (json['Devices'] === null ? null : (json['Devices'] as Array<any>).map(DeviceDetailsResponseModelFromJSON)),
    };
}

export function DeviceListResponseModelToJSON(value?: DeviceListResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PaginationToken': value.paginationToken,
        'Limit': value.limit,
        'Devices': value.devices === undefined ? undefined : (value.devices === null ? null : (value.devices as Array<any>).map(DeviceDetailsResponseModelToJSON)),
    };
}

