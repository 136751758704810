import { PropsWithChildren, useCallback, useEffect } from "react";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { FormFields } from "./FormFields";
import { PatientFormProps, PatientModelKey } from "./model";

export function Patient({
  entityData,
  setEntityData,
  onSubmit,
  editableFields,
  loading,
  children,
  formMode,
}: PropsWithChildren<PatientFormProps>) {
  const { organizationId, isAdmin } = useCurrentUserData();

  const onChange = useCallback(
    (value: any, inputKey: PatientModelKey) => {
      setEntityData(prevValue => ({
        ...prevValue,
        [inputKey]: value,
      }));
    },
    [setEntityData],
  );

  /**
   * Auto assign organization if i am not admin
   */
  useEffect(() => {
    if (!isAdmin && !entityData.organizationId) {
      setEntityData(prevValue => ({
        ...prevValue,
        organizationId,
      }));
    }
  }, [entityData.organizationId, organizationId, setEntityData, isAdmin]);

  return (
    <FormFields
      onChange={onChange}
      onSubmit={onSubmit}
      entityData={entityData}
      editableFields={editableFields}
      loading={loading}
      formMode={formMode}
    >
      {children}
    </FormFields>
  );
}
