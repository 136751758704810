import { Route } from "react-router-dom";
import { applyTo } from "ramda";
import { AccessLevel } from "../utils/AccessLevel";
import { DashboardPage } from "pages/DashboardPage";
import { dashboardPaths, mainRoutePaths } from "router";
import { DashboardMap } from "pages/DashboardPage/DashboardMap";
import { DashboardMapContext } from "pages/DashboardPage/DashboardMap/DashboardMapContext";
import { DashboardStats } from "pages/DashboardPage/DashboardStats";
import { DashboardStatsContext } from "pages/DashboardPage/DashboardStats/DashboardStatsContext";
import { DetailsPageContextProvider } from "components/PageTemplate/DetailsPage/context";
import { FeatureToggleRoute } from "utils/featureToggles";
// import { DashboardStats } from "pages/DashboardPage/DashboardStats";

export const DashboardRoutes = (
  <Route
    path={mainRoutePaths.dashboard}
    element={
      <FeatureToggleRoute keys={["dashboard-map"] as const}>
        <AccessLevel check={applyTo("manage-dashboard-admin")}>
          <DashboardMapContext>
            <DashboardPage />
          </DashboardMapContext>
        </AccessLevel>
      </FeatureToggleRoute>
    }
  >
    <Route index path={dashboardPaths.dashboardMap} element={<DashboardMap />} />
    {/* !!! TODO: Uncomment when Stats page is needed. Also check out DashboardPageNavigation */}
    <Route
      path={dashboardPaths.dashboardStats}
      element={
        <DetailsPageContextProvider>
          <DashboardStatsContext>
            <DashboardStats />
          </DashboardStatsContext>
        </DetailsPageContextProvider>
      }
    />
  </Route>
);
