/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';
import {
    UserListResponseModel,
    UserListResponseModelFromJSON,
    UserListResponseModelFromJSONTyped,
    UserListResponseModelToJSON,
} from './UserListResponseModel';

/**
 * 
 * @export
 * @interface UserListResponseModelApiResponse
 */
export interface UserListResponseModelApiResponse {
    /**
     * 
     * @type {UserListResponseModel}
     * @memberof UserListResponseModelApiResponse
     */
    data?: UserListResponseModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof UserListResponseModelApiResponse
     */
    errors?: Array<ApiError> | null;
}

export function UserListResponseModelApiResponseFromJSON(json: any): UserListResponseModelApiResponse {
    return UserListResponseModelApiResponseFromJSONTyped(json, false);
}

export function UserListResponseModelApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListResponseModelApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : UserListResponseModelFromJSON(json['Data']),
        'errors': !exists(json, 'Errors') ? undefined : (json['Errors'] === null ? null : (json['Errors'] as Array<any>).map(ApiErrorFromJSON)),
    };
}

export function UserListResponseModelApiResponseToJSON(value?: UserListResponseModelApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': UserListResponseModelToJSON(value.data),
        'Errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(ApiErrorToJSON)),
    };
}

