import { Input } from "libs/ui";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./../../Form.scss";
import { isReadonly } from "./../../helper";
import { OrganizationModel, OrganizationModelKey } from "./../model";
import { AddressSection } from "components/Forms/FormElements/AddressSection";
import { PhoneNumberField } from "components/Forms/FormElements/PhoneNumberField";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { CurrentFormMode } from "models/FormModeModels";
import { fieldStatusResolve } from "components/Forms/fieldStatusResolve";

interface OrganizationProps {
  organizationData: OrganizationModel;
  onSubmit: (e: React.FormEvent) => void;
  onChange: (value: string | undefined, inputKey: OrganizationModelKey) => void;
  readonly editableFields?: OrganizationModelKey[];
  formMode: CurrentFormMode;
  loading?: boolean;
}

export function FormFields({
  onChange,
  onSubmit,
  organizationData,
  editableFields,
  formMode,
  loading,
}: OrganizationProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const { appConfig } = useGlobalConfigContext();

  const onPhoneChange = useCallback(
    (value: string | undefined) => {
      onChange(value, "phone");
    },
    [onChange],
  );

  return (
    <form className="Form" onSubmit={onSubmit} data-testid="organization-form">
      <div className="Form__field">
        <Input
          data-testid="organization-name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, "name")}
          label={t("name")}
          value={organizationData.name ?? ""}
          readOnly={isReadonly("name", editableFields)}
        />
      </div>

      <AddressSection
        addressData={organizationData}
        onChange={onChange}
        editableFields={editableFields}
        zipCodeFieldStatus={fieldStatusResolve({
          formMode,
          isReadonly: isReadonly("zipCode", editableFields),
          field: appConfig?.entities.organization.zipCode,
          value: organizationData.zipCode,
        })}
        loading={loading}
      />

      <div className="Form__field">
        <PhoneNumberField
          phone={organizationData.phone}
          onChange={onPhoneChange}
          readOnly={isReadonly("phone", editableFields)}
        />
      </div>
      <button hidden type="submit" />
    </form>
  );
}
