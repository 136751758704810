/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthResponseApiResponse,
    AuthResponseApiResponseFromJSON,
    AuthResponseApiResponseToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
} from '../models';

export interface AuthGetRequest {
    userName?: string;
}

/**
 * AuthApi - interface
 * 
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     * 
     * @param {string} [userName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authGetRaw(requestParameters: AuthGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AuthResponseApiResponse>>;

    /**
     */
    authGet(userName?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AuthResponseApiResponse>;

}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {

    /**
     */
    async authGetRaw(requestParameters: AuthGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AuthResponseApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.userName !== undefined) {
            queryParameters['userName'] = requestParameters.userName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResponseApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async authGet(userName?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AuthResponseApiResponse> {
        const response = await this.authGetRaw({ userName: userName }, initOverrides);
        return await response.value();
    }

}
