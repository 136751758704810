/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrackingDeviceResponse,
    TrackingDeviceResponseFromJSON,
    TrackingDeviceResponseFromJSONTyped,
    TrackingDeviceResponseToJSON,
} from './TrackingDeviceResponse';

/**
 * 
 * @export
 * @interface TrackingDevicesResponseModel
 */
export interface TrackingDevicesResponseModel {
    /**
     * 
     * @type {Array<TrackingDeviceResponse>}
     * @memberof TrackingDevicesResponseModel
     */
    devices?: Array<TrackingDeviceResponse> | null;
}

export function TrackingDevicesResponseModelFromJSON(json: any): TrackingDevicesResponseModel {
    return TrackingDevicesResponseModelFromJSONTyped(json, false);
}

export function TrackingDevicesResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingDevicesResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'devices': !exists(json, 'Devices') ? undefined : (json['Devices'] === null ? null : (json['Devices'] as Array<any>).map(TrackingDeviceResponseFromJSON)),
    };
}

export function TrackingDevicesResponseModelToJSON(value?: TrackingDevicesResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Devices': value.devices === undefined ? undefined : (value.devices === null ? null : (value.devices as Array<any>).map(TrackingDeviceResponseToJSON)),
    };
}

