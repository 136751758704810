/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import {
    MeasurementUnitType,
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';
import {
    RangeModel,
    RangeModelFromJSON,
    RangeModelFromJSONTyped,
    RangeModelToJSON,
} from './RangeModel';
import {
    TestMethodType,
    TestMethodTypeFromJSON,
    TestMethodTypeFromJSONTyped,
    TestMethodTypeToJSON,
} from './TestMethodType';

/**
 * 
 * @export
 * @interface CreatinineOptionsModel
 */
export interface CreatinineOptionsModel {
    /**
     * 
     * @type {boolean}
     * @memberof CreatinineOptionsModel
     */
    enableMeasurements: boolean;
    /**
     * 
     * @type {TestMethodType}
     * @memberof CreatinineOptionsModel
     */
    testMethod: TestMethodType;
    /**
     * 
     * @type {DeviceType}
     * @memberof CreatinineOptionsModel
     */
    preferredDevice: DeviceType;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof CreatinineOptionsModel
     */
    measurementUnit: MeasurementUnitType;
    /**
     * 
     * @type {RangeModel}
     * @memberof CreatinineOptionsModel
     */
    maleNormalRange: RangeModel;
    /**
     * 
     * @type {RangeModel}
     * @memberof CreatinineOptionsModel
     */
    femaleNormalRange: RangeModel;
}

export function CreatinineOptionsModelFromJSON(json: any): CreatinineOptionsModel {
    return CreatinineOptionsModelFromJSONTyped(json, false);
}

export function CreatinineOptionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatinineOptionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enableMeasurements': json['EnableMeasurements'],
        'testMethod': TestMethodTypeFromJSON(json['TestMethod']),
        'preferredDevice': DeviceTypeFromJSON(json['PreferredDevice']),
        'measurementUnit': MeasurementUnitTypeFromJSON(json['MeasurementUnit']),
        'maleNormalRange': RangeModelFromJSON(json['MaleNormalRange']),
        'femaleNormalRange': RangeModelFromJSON(json['FemaleNormalRange']),
    };
}

export function CreatinineOptionsModelToJSON(value?: CreatinineOptionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EnableMeasurements': value.enableMeasurements,
        'TestMethod': TestMethodTypeToJSON(value.testMethod),
        'PreferredDevice': DeviceTypeToJSON(value.preferredDevice),
        'MeasurementUnit': MeasurementUnitTypeToJSON(value.measurementUnit),
        'MaleNormalRange': RangeModelToJSON(value.maleNormalRange),
        'FemaleNormalRange': RangeModelToJSON(value.femaleNormalRange),
    };
}

