/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceModel,
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
} from './DeviceModel';
import {
    TestedByModel,
    TestedByModelFromJSON,
    TestedByModelFromJSONTyped,
    TestedByModelToJSON,
} from './TestedByModel';
import {
    UnitsModel,
    UnitsModelFromJSON,
    UnitsModelFromJSONTyped,
    UnitsModelToJSON,
} from './UnitsModel';

/**
 * 
 * @export
 * @interface MeasurementGraphModel
 */
export interface MeasurementGraphModel {
    /**
     * 
     * @type {UnitsModel}
     * @memberof MeasurementGraphModel
     */
    units: UnitsModel;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGraphModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGraphModel
     */
    mesaurementTime: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGraphModel
     */
    measurementLocalTime: string;
    /**
     * 
     * @type {DeviceModel}
     * @memberof MeasurementGraphModel
     */
    device: DeviceModel;
    /**
     * 
     * @type {TestedByModel}
     * @memberof MeasurementGraphModel
     */
    testedBy: TestedByModel;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGraphModel
     */
    testCountId: string;
}

export function MeasurementGraphModelFromJSON(json: any): MeasurementGraphModel {
    return MeasurementGraphModelFromJSONTyped(json, false);
}

export function MeasurementGraphModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementGraphModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'units': UnitsModelFromJSON(json['Units']),
        'id': json['Id'],
        'mesaurementTime': json['MesaurementTime'],
        'measurementLocalTime': json['MeasurementLocalTime'],
        'device': DeviceModelFromJSON(json['Device']),
        'testedBy': TestedByModelFromJSON(json['TestedBy']),
        'testCountId': json['TestCountId'],
    };
}

export function MeasurementGraphModelToJSON(value?: MeasurementGraphModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Units': UnitsModelToJSON(value.units),
        'Id': value.id,
        'MesaurementTime': value.mesaurementTime,
        'MeasurementLocalTime': value.measurementLocalTime,
        'Device': DeviceModelToJSON(value.device),
        'TestedBy': TestedByModelToJSON(value.testedBy),
        'TestCountId': value.testCountId,
    };
}

