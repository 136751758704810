/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GenderFilter = {
    NotAvailable: 'NotAvailable',
    Male: 'Male',
    Female: 'Female'
} as const;
export type GenderFilter = typeof GenderFilter[keyof typeof GenderFilter];


export function GenderFilterFromJSON(json: any): GenderFilter {
    return GenderFilterFromJSONTyped(json, false);
}

export function GenderFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderFilter {
    return json as GenderFilter;
}

export function GenderFilterToJSON(value?: GenderFilter | null): any {
    return value as any;
}

