import { UserRoleType } from "models/PersonModels/Roles";

export function isAdmin(role: UserRoleType | undefined) {
  return role === "Admin";
}
export function isPartner(role: UserRoleType | undefined) {
  return role === "Partner";
}
export function isHCP(role: UserRoleType | undefined) {
  return role === "Hcp";
}
export function isPatient(role: UserRoleType | undefined) {
  return role === "Patient";
}
