import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { useDashboardMapReportContext } from "../DashboardMapReportDataContext";
import { Loader } from "pages/DashboardPage/Loader";
import { ChartPerEntities } from "components/Chart/Dashboard/ChartPerEntities";
import { FailedToRetrieveData } from "../FailedToRetrieveData";
// import { Pagination } from "./Pagination";
import { useMemo } from "react";
import { IdentifiedWithCkdRiskGroupBy } from "api/report_service/models/IdentifiedWithCkdRiskGroupBy";
import { useTranslation } from "react-i18next";
import { NoRecordedData } from "../NoRecordedData";

// TODO: remove pagination Leaving it for now in case we need it later
// const PAGE_SIZE = 3;

interface PaginatedChartProps {
  groupingOption: IdentifiedWithCkdRiskGroupBy;
}

export function PaginatedChart({ groupingOption }: PaginatedChartProps) {
  const { reportData } = useDashboardMapReportContext();
  // TODO: remove pagination Leaving it for now in case we need it later
  // const [currentIndex, setCurrentIndex] = useState(0);
  const { t: tCountries } = useTranslation("nationalities", { keyPrefix: "countries" });

  const totalChartData = useMemo(
    () => (isLoaded(reportData.ChartData) ? reportData.ChartData.value ?? [] : []),
    [reportData.ChartData],
  );

  // TODO: remove pagination Leaving it for now in case we need it later
  // const startingIndex = currentIndex * PAGE_SIZE;

  const dataPerGrouping = useMemo(() => {
    // const dataToShow = totalChartData.slice(startingIndex, startingIndex + PAGE_SIZE);

    switch (groupingOption) {
      case "PatientAddressCity":
        return totalChartData.map(el => ({
          ...el,
          entityName: el.entityCountryISO
            ? `${el.entityName}, ${tCountries(el.entityCountryISO)}`
            : el.entityName,
        }));

      case "PatientAddressCountry":
        return totalChartData.map(el => ({
          ...el,
          entityName: el.entityCountryISO ? tCountries(el.entityCountryISO) : "",
        }));

      default:
        return totalChartData;
    }
  }, [groupingOption, tCountries, totalChartData]);

  // TODO: remove pagination Leaving it for now in case we need it later
  // const isNextDisabled = (currentIndex + 1) * PAGE_SIZE >= totalChartData.length;

  return (
    <>
      {isLoading(reportData.ChartData) || isDefaultModel(reportData.ChartData) ? <Loader /> : null}
      {isLoaded(reportData.ChartData) ? (
        dataPerGrouping.length > 0 ? (
          <>
            <ChartPerEntities data={dataPerGrouping} className="PaginatedChart" />
            {/* // TODO: remove pagination Leaving it for now in case we need it later */}
            {/* <Pagination
              gotoPage={setCurrentIndex}
              currentIndex={currentIndex}
              queryCount={totalChartData.length}
              limit={PAGE_SIZE}
              disabledNext={isNextDisabled}
            /> */}
          </>
        ) : (
          <NoRecordedData />
        )
      ) : null}
      {isFailed(reportData.ChartData) ? <FailedToRetrieveData /> : null}
    </>
  );
}
