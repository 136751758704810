import { DeviceType, ManufacturerType, OrganizationDetailsResponseModel } from "api/query";
import { Input, Selection } from "libs/ui";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { isReadonly } from "../../helper";
import { deviceFormUtils } from "../deviceFormUtils";
import { DeviceFormFieldsProps } from "../model";
import "./../../Form.scss";
import { AssignedTo } from "./AssignedTo";
import { OrganizationField } from "components/Forms/FormElements/OrganizationField";
import { deviceTypeToSelectOptions } from "utils/mappers/deviceTypeToSelectOptions";
import { manufacturerTypeToSelectOptions } from "utils/mappers/manufacturerTypeToSelectOptions";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { fieldStatusResolve } from "components/Forms/fieldStatusResolve";

export function FormFields({
  onChange,
  onSubmit,
  deviceData,
  editableFields,
  formMode,
}: Readonly<DeviceFormFieldsProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "Form" });
  const { isAdmin } = useCurrentUserData();
  const { appConfig } = useGlobalConfigContext();

  const isAdminInCreateMode = isAdmin && !isReadonly("organizationId", editableFields);

  const { firstName, id, lastName, role } = deviceData.assignedTo ?? {};

  const onOrganizationChange = useCallback(
    (value?: OrganizationDetailsResponseModel) => {
      onChange(
        !value ? deviceFormUtils.DEVICE_DATA_DEFAULT.organizationId : value.id,
        "organizationId",
      );
      onChange(!value ? undefined : value.name, "organizationName");
    },
    [onChange],
  );

  const uniqueIdTypeField = fieldStatusResolve({
    formMode,
    isReadonly: isReadonly("uniqueId", editableFields),
    field: appConfig?.entities.device.uniqueId,
    value: deviceData.uniqueId,
  });

  return (
    <form className="Form" onSubmit={onSubmit} data-testid="device-form">
      <div className="Form__field">
        <Selection<ManufacturerType>
          data-testid="manufacturer-type"
          options={manufacturerTypeToSelectOptions()}
          onSelect={(value?: ManufacturerType) => onChange(value, "manufacturerType")}
          label={t("manufacturer")}
          value={deviceData.manufacturerType}
          readOnly={isReadonly("manufacturerType", editableFields)}
        />
      </div>

      <div className="Form__field">
        <Selection<DeviceType>
          data-testid="device-type"
          options={deviceTypeToSelectOptions()}
          onSelect={(value?: DeviceType) => onChange(value, "deviceType")}
          label={t("deviceType")}
          value={deviceData.deviceType}
          readOnly={isReadonly("deviceType", editableFields)}
        />
      </div>
      <div className="Form__field">
        <Input
          data-testid="serial-number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value, "serialNumber")
          }
          label={t("serialNumber")}
          value={deviceData.serialNumber}
          readOnly={isReadonly("serialNumber", editableFields)}
        />
      </div>

      {uniqueIdTypeField.show ? (
        <div className="Form__field">
          <Input
            optional={uniqueIdTypeField.optional}
            data-testid="uniqueId"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.value, "uniqueId")
            }
            label={t("uniqueId")}
            value={uniqueIdTypeField.showNA ? t("na") : deviceData.uniqueId ?? ""}
            readOnly={uniqueIdTypeField.readonly}
          />
        </div>
      ) : null}

      {isAdminInCreateMode && (
        <div className="Form__field">
          <OrganizationField
            onSelect={onOrganizationChange}
            initialValue={deviceData.organizationName}
          />
        </div>
      )}

      {deviceData.organizationName && isReadonly("organizationId", editableFields) && (
        <div className="Form__field">
          <Input
            data-testid="organization-name"
            label={t("organizationName")}
            value={deviceData.organizationName}
            readOnly
          />
        </div>
      )}

      <div className="Form__field">
        <AssignedTo
          data-testid="assigned-to"
          firstName={firstName}
          lastName={lastName}
          userId={id}
          userRole={role}
        />
      </div>

      <button hidden type="submit" />
    </form>
  );
}
