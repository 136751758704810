import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { getCellStatusData, getIsActive } from "./helper";
import { HeatCellProps } from "./model";
import "./HeatCell.scss";

export function HeatCell({ rowKey, cellKey, UACRStatus, eGFRStatus, risk }: HeatCellProps) {
  const { t } = useTranslation("widgets", { keyPrefix: "HeatMapWidget.HeatMapTable" });
  const { color, status } = getCellStatusData(cellKey, rowKey);
  const isActive = getIsActive({ rowKey, cellKey, UACRStatus, eGFRStatus });

  return (
    <td
      className={classNames("HeatCell", `HeatCell--${color}`, {
        "HeatCell--active": isActive,
      })}
    >
      {isActive && risk ? (
        <>
          <p className="HeatCell__symbol">{t(status)}</p>
          <p className="HeatCell__risk">{t(risk)}</p>
        </>
      ) : null}
    </td>
  );
}
