import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createSafeContext } from "utils/createSafeContext";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { defaultModel, failed, isLoaded, LoadableModel, loaded, loading } from "models/loadable";
import { CarnaApiQuery } from "config/apiQuery";

export interface CurrentUserNameModel {
  firstName: string;
  lastName: string;
}

export interface CurrentUserNameContext {
  currentUserName: LoadableModel<CurrentUserNameModel>;
  setCurrentUserName: Dispatch<SetStateAction<LoadableModel<CurrentUserNameModel>>>;
  fullName: string | null;
}

export function useCurrentUserName() {
  const { currentUserId, isHcp, isPatient, isPartner, organizationId } = useCurrentUserData();
  const [currentUserName, setCurrentUserName] =
    useState<LoadableModel<CurrentUserNameModel>>(defaultModel());

  const fetchUserData = useCallback(() => {
    return isPartner
      ? CarnaApiQuery.Partners.getById({ organizationId, id: currentUserId })
      : isHcp
        ? CarnaApiQuery.Hcps.getById({ organizationId, hcpEntityId: currentUserId })
        : isPatient
          ? CarnaApiQuery.Patients.getById({ organizationId, patientEntityId: currentUserId })
          : CarnaApiQuery.Users.getById({ id: currentUserId });
  }, [isPartner, organizationId, currentUserId, isHcp, isPatient]);

  const loadData = useCallback(() => {
    if (!!currentUserId) {
      setCurrentUserName(loading());
      fetchUserData()
        .then(data => {
          setCurrentUserName(
            loaded({
              firstName: data?.firstName ?? "",
              lastName: data?.lastName ?? "",
            }),
          );
        })
        .catch(err => setCurrentUserName(failed(err)));
    }
  }, [currentUserId, fetchUserData]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return useMemo(() => ({ currentUserName, setCurrentUserName }) as const, [currentUserName]);
}

const Context = createSafeContext<CurrentUserNameContext>();

export const useCurrentUserNameContext = Context.hook;

export function CurrentUserNameProvider({ children }: PropsWithChildren) {
  const { currentUserName, setCurrentUserName } = useCurrentUserName();

  const value = useMemo<CurrentUserNameContext>(
    () => ({
      currentUserName,
      setCurrentUserName,
      fullName: isLoaded(currentUserName)
        ? currentUserName.value.firstName.concat(" ").concat(currentUserName.value.lastName)
        : null,
    }),
    [currentUserName, setCurrentUserName],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
