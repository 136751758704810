import "./FilterSearchTogglerBar.scss";

import { useHover } from "@floating-ui/react";
import { SearchField } from "components/SearchField";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useTooltip } from "libs/ui/Tooltip";
import { Button } from "libs/ui/Button";

interface FilterSearchTogglerBarProps {
  onFilterToggle?: () => void;
  searchField?: {
    onChange?: (value: string) => void;
    value?: string;
  };
  filtersCount: number;
  showFilterButton?: boolean;
}

export function FilterSearchTogglerBar({
  searchField,
  onFilterToggle,
  filtersCount,
  showFilterButton = true,
}: FilterSearchTogglerBarProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Tooltip" });
  const [ref, getReferenceProps, Tooltip] = useTooltip({
    placement: "bottom",
    interactionProps: [useHover],
  });

  return (
    <>
      {searchField ? (
        <SearchField initValue={searchField.value} onInputChange={searchField.onChange} />
      ) : null}

      {onFilterToggle ? (
        <div
          className={classNames("FilterSearchTogglerBar", {
            "FilterSearchTogglerBar--withFilterCount": !!filtersCount,
          })}
          ref={ref}
          {...getReferenceProps()}
        >
          {showFilterButton ? (
            <Button
              onClick={onFilterToggle}
              buttonSize="medium"
              buttonType="white"
              buttonIcon={{ icon: "Filter" }}
            />
          ) : null}
          {filtersCount ? (
            <span data-testid="FiltersCount" className="FilterSearchTogglerBar__count">
              {filtersCount}
            </span>
          ) : null}
          <Tooltip>{t("filter")}</Tooltip>
        </div>
      ) : null}
    </>
  );
}
