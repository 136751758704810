import Ajv from "ajv";
import { RegistryNumberType, VisibilityType } from "api/config_service";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  AdditionalPropertyConfigField,
  ConfigFieldModel,
  ContextsFieldConfig,
  GlobalConfig,
} from "utils/createGlobalConfigStore";
import { isValidBirthDate } from "utils/hooks/useValidateDate";
import { UPI_STRING_FORMAT } from "utils/regex";
import { PatientModel } from "./model";
import { validateEmailPerVisibility } from "utils/validators/validateEmailPerVisibility";
import { FormType } from "models/FormModeModels";
import { getOptionalFieldConfigForValidationSchema } from "../validation/getOptionalFieldConfigForValidationSchema";

export function validateRegistryNumber(
  val: string,
  registryNumber: ConfigFieldModel<any> | undefined,
  key: keyof ContextsFieldConfig,
) {
  switch (registryNumber?.label) {
    case RegistryNumberType.None:
    case RegistryNumberType.Nhs:
    case RegistryNumberType.FreeString:
      return true;
    case RegistryNumberType.Upi: {
      const isUPIOptionalAndEmpty =
        registryNumber?.contexts[key].visibility === VisibilityType.Optional && !val;
      return isUPIOptionalAndEmpty || UPI_STRING_FORMAT.test(val);
    }
    default:
      console.warn(`${registryNumber?.label} registry number type is not covered with validation!`);
      return true;
  }
}

function getOptionalFieldConfig(
  field: ConfigFieldModel<any> | undefined,
  key: keyof ContextsFieldConfig,
  fieldType: string = "string",
) {
  const { visibility } = field?.contexts[key] ?? {};

  return {
    type: fieldType,
    nullable: visibility !== "Required",
    ...(fieldType === "string" && visibility === "Required" ? { minLength: 1 } : undefined),
    ...(visibility === "Required" ? { isNotEmpty: true } : undefined),
  };
}

type AddPropName = AdditionalPropertyConfigField["propertyName"];
type AddPropSchema = Record<AddPropName, ReturnType<typeof getOptionalFieldConfig>>;

interface AdditionalFieldsResolved {
  propSchema: AddPropSchema;
  requiredFields: AddPropName[];
}

function resolveFieldType(
  propTypeName: string,
  propertyName: string,
  additionalPropertiesData: Record<string, any>,
) {
  const value = additionalPropertiesData[propertyName];

  if (propTypeName === "string") {
    return Array.isArray(value) ? "array" : "string";
  }

  /**
   * !!! TODO CAS-2722
   * Multiselect (CheckInput) component starts with '1,2,...xyz' which is `string`
   * but if we change the value, we move this to `array` ['1', '2', ... 'xyz'] and
   * this should be reflected in ajv schema
   */
  function multiSelectResolver() {
    return Array.isArray(value) ? "array" : "string";
  }

  /**
   * !!! TODO CAS-2722
   * BE first returns us the value in `string`, but in the Enums in the Entities
   * single select type value is `number` and we change the value of single
   * select field, the value moves to number and
   * this should be reflected in ajv schema
   */
  function singleSelectResolver() {
    return typeof value === "string" ? "string" : "number";
  }

  return propTypeName.includes("[]") ? multiSelectResolver() : singleSelectResolver();
}

function getAdditionalPropSchemas(
  additionalProperties: AdditionalPropertyConfigField[] | undefined,
  key: keyof ContextsFieldConfig,
  additionalPropertiesData?: Record<string, any>,
): AdditionalFieldsResolved {
  let propSchema: AddPropSchema = {};
  let requiredFields: AddPropName[] = [];

  if (
    !additionalProperties ||
    additionalProperties.length === 0 ||
    additionalProperties.every(el => el.contexts[key].visibility === "Disabled")
  ) {
    return { propSchema, requiredFields } as const;
  }

  additionalProperties.forEach(prop => {
    if (prop.contexts[key].visibility === "Disabled") {
      return;
    }
    if (prop.contexts[key].visibility === "Required") {
      requiredFields.push(prop.propertyName);
    }

    propSchema[prop.propertyName] = getOptionalFieldConfig(
      {
        value: "",
        contexts: prop.contexts,
      },
      key,
      resolveFieldType(prop.typeName, prop.propertyName, additionalPropertiesData ?? {}),
    );
  });

  return { propSchema, requiredFields } as const;
}

function getFieldsReady(
  patientData: PatientModel,
  formType: FormType,
  globalConfig?: GlobalConfig,
) {
  const {
    email,
    deviceField,
    generalPractitioner,
    nationalityType,
    zipCode,
    additionalProperties,
    ancestry,
  } = globalConfig?.entities.patient ?? {};

  const key: keyof ContextsFieldConfig = formType === "Add" ? "Add" : "Update";

  const { registryNumber } = globalConfig?.general ?? {};

  const registryField: ConfigFieldModel<string> = {
    label: registryNumber?.registryNumberType,
    contexts: registryNumber?.contexts ?? {
      Add: { visibility: "Optional" },
      Update: { visibility: "Optional" },
    },
    value: "",
  };

  const statesRequired = !!(globalConfig?.general.custom.enums as any)?.[
    `${patientData.country}_States`
  ];

  const stateMaximum = statesRequired
    ? (globalConfig?.general.custom.enums as any)?.[`${patientData.country}_States`].slice(-1)[0].V
    : null;

  const [emailSchema, isEmailMandatory] = getOptionalFieldConfigForValidationSchema(
    email,
    key,
    patientData.email,
  );
  const [registryNumberSchema, isRegistryNumberMandatory] =
    getOptionalFieldConfigForValidationSchema(registryField, key, patientData.registryNumber);

  const ajv = new Ajv(); //{ allowUnionTypes: true }

  const validateEmail = validateEmailPerVisibility(
    email ? email.contexts[key].visibility : "Optional",
  );

  const additionalPropSchemas = getAdditionalPropSchemas(
    additionalProperties,
    key,
    patientData.additionalProperties,
  );

  ajv.addKeyword({
    keyword: "isNotEmpty",
    validate: (schema: any, data: any, _current: any, rest: any) => {
      if (schema) {
        switch (rest.parentDataProperty as keyof PatientModel) {
          case "email":
            return validateEmail(data);

          case "dateOfBirth":
            if (data instanceof Date) {
              return isValidBirthDate(data);
            }
            return false;

          case "registryNumber":
            return validateRegistryNumber(data, registryField, key);

          case "phone":
            return isValidPhoneNumber(data);

          default:
            return !!data && data.toString().trim() !== "";
        }
      }

      return false;
    },
  });

  const schema = {
    type: "object",
    properties: {
      firstName: {
        type: "string",
        nullable: false,
        isNotEmpty: true,
        minLength: 1,
      },
      lastName: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      street: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      city: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      gender: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      phone: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      dateOfBirth: { type: "object", nullable: false, isNotEmpty: true },
      country: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },
      organizationId: { type: "string", nullable: false, isNotEmpty: true, minLength: 1 },

      zipCode: getOptionalFieldConfig(zipCode, key),
      nationalityType: getOptionalFieldConfig(nationalityType, key),
      generalPractitionerId: getOptionalFieldConfig(generalPractitioner, key),
      deviceId: getOptionalFieldConfig(deviceField, key),
      ancestry: getOptionalFieldConfig(ancestry, key),
      email: emailSchema,
      registryNumber: registryNumberSchema,

      state: {
        type: "integer",
        nullable: !statesRequired,
        ...(statesRequired ? { isNotEmpty: true, minimum: 1, maximum: stateMaximum } : undefined),
      },
      hcpId: { type: "string", nullable: true },

      ...(Object.keys(additionalPropSchemas.propSchema).length > 0
        ? {
            additionalProperties: {
              type: "object",
              properties: additionalPropSchemas.propSchema,
              required: [...additionalPropSchemas.requiredFields],
              additionalProperties: true,
            },
          }
        : undefined),
    },

    required: [
      "firstName",
      "lastName",
      "street",
      "city",
      "gender",
      "phone",
      "dateOfBirth",
      "country",
      "organizationId",

      ...(zipCode?.contexts[key].visibility === "Required" ? ["zipCode"] : []),
      ...(ancestry?.contexts[key].visibility === "Required" ? ["ancestry"] : []),
      ...(nationalityType?.contexts[key].visibility === "Required" ? ["nationalityType"] : []),
      ...(generalPractitioner?.contexts[key].visibility === "Required"
        ? ["generalPractitionerId"]
        : []),
      ...(deviceField?.contexts[key].visibility === "Required" ? ["deviceId"] : []),
      ...(isEmailMandatory ? ["email"] : []),
      ...(isRegistryNumberMandatory ? ["registryNumber"] : []),

      ...(statesRequired ? ["state"] : []),

      ...(additionalPropSchemas.requiredFields.length > 0 ? ["additionalProperties"] : []),
    ],
    additionalProperties: true,
  }; //satisfies JSONSchemaType<PatientModel>;

  const validate = ajv.compile(schema);

  const valid = validate(patientData);

  // if (!valid) {
  //   console.log(validate.errors);
  // }

  return valid;
}

export const patientFormUtils = {
  getFieldsReady,
};
