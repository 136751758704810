/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    GraphPatientResponseModelApiResponse,
    GraphPatientResponseModelApiResponseFromJSON,
    GraphPatientResponseModelApiResponseToJSON,
    PatientMeasurementGraphFilter,
    PatientMeasurementGraphFilterFromJSON,
    PatientMeasurementGraphFilterToJSON,
} from '../models';

export interface OrganizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGetRequest {
    filters: PatientMeasurementGraphFilter;
    organizationId: string;
    patientId: string;
}

/**
 * GraphApi - interface
 * 
 * @export
 * @interface GraphApiInterface
 */
export interface GraphApiInterface {
    /**
     * 
     * @param {PatientMeasurementGraphFilter} filters 
     * @param {string} organizationId 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphApiInterface
     */
    organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GraphPatientResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGet(filters: PatientMeasurementGraphFilter, organizationId: string, patientId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GraphPatientResponseModelApiResponse>;

}

/**
 * 
 */
export class GraphApi extends runtime.BaseAPI implements GraphApiInterface {

    /**
     */
    async organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GraphPatientResponseModelApiResponse>> {
        if (requestParameters.filters === null || requestParameters.filters === undefined) {
            throw new runtime.RequiredError('filters','Required parameter requestParameters.filters was null or undefined when calling organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGet.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{patientId}/measurements/graphs`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GraphPatientResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGet(filters: PatientMeasurementGraphFilter, organizationId: string, patientId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GraphPatientResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsPatientIdMeasurementsGraphsGetRaw({ filters: filters, organizationId: organizationId, patientId: patientId }, initOverrides);
        return await response.value();
    }

}
