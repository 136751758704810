/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddNovaMaxMeasurementsPatientModel,
    AddNovaMaxMeasurementsPatientModelFromJSON,
    AddNovaMaxMeasurementsPatientModelFromJSONTyped,
    AddNovaMaxMeasurementsPatientModelToJSON,
} from './AddNovaMaxMeasurementsPatientModel';

/**
 * 
 * @export
 * @interface AddNovaMaxMeasurementsListPatientModel
 */
export interface AddNovaMaxMeasurementsListPatientModel {
    /**
     * 
     * @type {Array<AddNovaMaxMeasurementsPatientModel>}
     * @memberof AddNovaMaxMeasurementsListPatientModel
     */
    measurementList?: Array<AddNovaMaxMeasurementsPatientModel> | null;
}

export function AddNovaMaxMeasurementsListPatientModelFromJSON(json: any): AddNovaMaxMeasurementsListPatientModel {
    return AddNovaMaxMeasurementsListPatientModelFromJSONTyped(json, false);
}

export function AddNovaMaxMeasurementsListPatientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNovaMaxMeasurementsListPatientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurementList': !exists(json, 'MeasurementList') ? undefined : (json['MeasurementList'] === null ? null : (json['MeasurementList'] as Array<any>).map(AddNovaMaxMeasurementsPatientModelFromJSON)),
    };
}

export function AddNovaMaxMeasurementsListPatientModelToJSON(value?: AddNovaMaxMeasurementsListPatientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MeasurementList': value.measurementList === undefined ? undefined : (value.measurementList === null ? null : (value.measurementList as Array<any>).map(AddNovaMaxMeasurementsPatientModelToJSON)),
    };
}

