import { useActor } from "@xstate/react";
import { TabModalControl } from "components/TabModalControl";
import { InitialValuesModel } from "models/PersonModels";
import { useCallback, useEffect } from "react";
import { PatientFilterOptions, PatientsTableColumns } from "utils/createGlobalConfigStore";
import { FilterSideModalState } from "../common/Filter/model";
import { PatientsTable } from "../common/PatientsTable";
import { PatientsFilterOptionsKey, PatientsTableStateContext } from "../common/PatientsTable/model";

interface PatientsPerEntityProps {
  tableService: PatientsTableStateContext["tableService"];
  filterSideModelState: FilterSideModalState<PatientsFilterOptionsKey>;
  hcpsFilter?: boolean;
  appliedColumns?: PatientsTableColumns[];
  patientsFilterOptions?: PatientFilterOptions[];
  initialValuesAsProps?: Partial<InitialValuesModel>;
}

export function PatientsPerEntity({
  tableService,
  filterSideModelState,
  hcpsFilter = true,
  appliedColumns = [
    "userStatuses",
    "creatinineEgfr",
    "uacr",
    "squacr",
    "glucose",
    "bmi",
    "bloodPressure",
    "withDevice",
    "actions",
  ],
  initialValuesAsProps,
  patientsFilterOptions,
}: Readonly<PatientsPerEntityProps>) {
  const [state, send] = useActor(tableService);

  const onFilterToggle = useCallback(
    () => filterSideModelState.openFilterModal(),
    [filterSideModelState],
  );

  const onSearchChange = (value: string) =>
    send([{ type: "UPDATE_FILTERS", value: { ...state.context.filters, contains: value } }]);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  useEffect(() => {
    if (state.matches("init")) {
      fetch();
    }
  }, [fetch, state]);

  const withFilterButton = hcpsFilter || appliedColumns.some(el => el === "withDevice");

  return (
    <PatientsTable
      appliedColumns={appliedColumns}
      patientsTableStateContext={{ tableService, filterSideModelState, fetch }}
      searchField={{
        onChange: onSearchChange,
        value: state.context.filters?.contains ?? "",
      }}
      {...(withFilterButton && { onFilterToggle })}
      hcpsFilter={hcpsFilter}
      addButton={
        <TabModalControl
          variant="Patient"
          onSuccessCb={fetch}
          initialValuesAsProps={initialValuesAsProps}
        />
      }
      patientsFilterOptions={patientsFilterOptions}
      initialValuesAsProps={initialValuesAsProps}
    />
  );
}
