/* tslint:disable */
/* eslint-disable */
/* @ts-nocheck */

/**
 * Laboratory.Query.Reporting.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AncestryFilter = {
    NotAvailable: 'NotAvailable',
    Black: 'Black',
    Other: 'Other'
} as const;
export type AncestryFilter = typeof AncestryFilter[keyof typeof AncestryFilter];


export function AncestryFilterFromJSON(json: any): AncestryFilter {
    return AncestryFilterFromJSONTyped(json, false);
}

export function AncestryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): AncestryFilter {
    return json as AncestryFilter;
}

export function AncestryFilterToJSON(value?: AncestryFilter | null): any {
    return value as any;
}

