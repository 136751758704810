import { TopLevelFormatterParams } from "echarts/types/dist/shared.js";
import { default as i18n } from "i18n";
import "./ChartPerEntitiesTooltip.scss";

export function chartPerEntitiesTooltipFormatter(params: TopLevelFormatterParams) {
  const [patientsData, patientsWithCKD] = params as any;

  const [title, patientsValue, patientsWithCKDValue] = patientsData.value;

  return `
    <div class="ChartPerEntitiesTooltip">
      <div class="ChartPerEntitiesTooltip__title">${title}</div>

      <div class="ChartPerEntitiesTooltip__single">
        <span class="ChartPerEntitiesTooltip__dot" style="background-color: ${patientsData.color}"></span>
        <p>
        ${i18n.t(`DashboardPage.ChartPerEntities.tooltip.${patientsData.seriesName}`, {
          value: patientsValue,
          ns: "translation",
        })}</p>
        
      </div>

      <div class="ChartPerEntitiesTooltip__single">
        <span class="ChartPerEntitiesTooltip__dot" style="background-color: ${patientsWithCKD.color}"></span>
        ${i18n.t(`DashboardPage.ChartPerEntities.tooltip.${patientsWithCKD.seriesName}`, {
          value: patientsWithCKDValue,
          ns: "translation",
        })}
      </div>

    </div>
    `;
}
