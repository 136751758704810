import { Card } from "libs/ui";
import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";
import { useDashboardStatsContext } from "../../DashboardStatsContext";
import { WidgetBody } from "./WidgetBody";
import { WidgetHeader } from "./WidgetHeader";
import { SingleWidgetBaseProps } from "./model";
import { WidgetLoader } from "pages/PatientsPage/Details/Overview/WidgetLoader";
import { RefreshFailedWidget } from "pages/PatientsPage/Details/Overview/RefreshFailedWidget";

interface SingleWidgetProps extends SingleWidgetBaseProps {
  onClick: (key: MeasurementWidgetType) => void;
}

export function SingleWidget({ type, onClick }: Readonly<SingleWidgetProps>) {
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientDetails"]>();
  const { data, getWidgetData } = useDashboardStatsContext();

  const widgetData = data[type];

  const refreshWidgetData = useCallback(() => {
    getWidgetData(organizationId, patientId, [type]);
  }, [getWidgetData, organizationId, patientId, type]);

  if (isDefaultModel(widgetData) || isLoading(widgetData)) {
    return <WidgetLoader />;
  }

  return (
    <>
      {isLoaded(widgetData) ? (
        <Card
          className="SingleWidget"
          size="small"
          onClick={widgetData.value ? () => onClick(type) : undefined}
          data-testid="SingleWidget"
          data-type={type}
        >
          <WidgetHeader type={type} date={widgetData.value?.measurementTime} />
          <WidgetBody data={widgetData.value} type={type} />
        </Card>
      ) : (
        <RefreshFailedWidget show={isFailed(widgetData)} refreshCb={refreshWidgetData} />
      )}
    </>
  );
}
