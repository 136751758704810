import { useActor } from "@xstate/react";
import { OrganizationDetailsResponseModel } from "api/query";
import { EditableDetails } from "components/EditableDetails/EditableDetails";
import { Organization } from "components/Forms/Organization";
import { OrganizationModel } from "components/Forms/Organization/model";
import { organizationFormUtils } from "components/Forms/Organization/organizationFormUtils";
import { OrganizationLogo } from "components/OrganizationLogo";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { cloneDeep, isEqual } from "lodash-es";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import {
  DetailsPageServiceList,
  MakeDetailsPageStateMachineService,
} from "utils/machines/pages/details/model";

interface DetailsFormProps<Events extends DetailsPageServiceList<any, any, any> = any> {
  detailsService: MakeDetailsPageStateMachineService<
    OrganizationDetailsResponseModel,
    OrganizationModel,
    Events
  >;
}

export function DetailsForm({ detailsService }: Readonly<DetailsFormProps>) {
  const [state, send] = useActor(detailsService);

  const { t } = useTranslation("translation", { keyPrefix: "OrganizationDetails" });

  const { appConfig } = useGlobalConfigContext();

  const [organizationData, setOrganizationData] = useState<OrganizationModel>(
    state.context.error === undefined && state.context?.data
      ? cloneDeep(state.context?.data)
      : NotNullOrUndefined(appConfig?.forms.defaultValues.organization),
  );

  const saveDisabled =
    state.matches("entity.saving") ||
    !organizationFormUtils.getFieldsReady(organizationData, "Edit", appConfig) ||
    isEqual(state.context?.data, organizationData);

  const onSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    if (saveDisabled) {
      return;
    }

    send({ type: "SAVE_DATA", value: organizationData });
  };

  const onCancel = () => {
    send("CANCEL_EDIT");
  };

  const showForm =
    state.matches("entity.editing") ||
    state.matches("entity.saving") ||
    state.matches("entity.failed") ||
    // state.matches("entity.loading_for_edit") ||
    state.matches("entity.loaded");

  const orgData = useMemo(() => {
    return state.matches("entity.editing") || state.matches("entity.saving")
      ? organizationData
      : state.context?.data ?? ({} as OrganizationModel);
  }, [organizationData, state]);

  return (
    <EditableDetails
      title={state.matches("entity.editing") ? t("titleEdit") : t("title")}
      {...((state.matches("entity.editing") || state.matches("entity.saving")) && {
        editMode: true,
      })}
      isLoading={state.matches("entity.loading")}
      isLoaded={state.matches("entity.loaded")}
      cancel={{
        disabled: state.matches("entity.saving") || state.matches("entity.loading"),
        onClick: onCancel,
      }}
      save={{
        disabled: saveDisabled || state.matches("entity.loading"),
        onClick: onSubmit,
        isLoading: state.matches("entity.saving"),
      }}
      onClose={state.matches("entity.editing") ? onCancel : undefined}
    >
      {showForm && (
        <>
          <OrganizationLogo isMain={orgData.isMain} />
          <Organization
            formMode={state.matches("entity.editing") ? "Edit" : "Readonly"}
            organizationData={orgData}
            setOrganizationData={setOrganizationData}
            onSubmit={onSubmit}
            editableFields={
              state.matches("entity.editing")
                ? ["name", "city", "country", "phone", "state", "street", "zipCode"]
                : []
            }
          />
        </>
      )}
    </EditableDetails>
  );
}
