/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DocumentRequestModel,
    DocumentRequestModelFromJSON,
    DocumentRequestModelToJSON,
    DocumentResponseModelApiResponse,
    DocumentResponseModelApiResponseFromJSON,
    DocumentResponseModelApiResponseToJSON,
    DocumentResponseModelArrayApiResponse,
    DocumentResponseModelArrayApiResponseFromJSON,
    DocumentResponseModelArrayApiResponseToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    StringApiResponse,
    StringApiResponseFromJSON,
    StringApiResponseToJSON,
    UserDetails,
    UserDetailsFromJSON,
    UserDetailsToJSON,
} from '../models';

export interface DownloadImagesPostRequest {
    UserDetails: Array<UserDetails>;
}

export interface OrganizationsOrganizationIdUsersIdDeleteImageDeleteRequest {
    userRoleType: string;
    organizationId: string;
    id: string;
}

export interface OrganizationsOrganizationIdUsersIdDownloadImageGetRequest {
    userRoleType: string;
    organizationId: string;
    id: string;
}

export interface OrganizationsOrganizationIdUsersIdUploadImagePutRequest {
    organizationId: string;
    id: string;
    DocumentRequestModel?: DocumentRequestModel;
}

/**
 * DocumentApi - interface
 * 
 * @export
 * @interface DocumentApiInterface
 */
export interface DocumentApiInterface {
    /**
     * 
     * @param {Array<UserDetails>} UserDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    downloadImagesPostRaw(requestParameters: DownloadImagesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DocumentResponseModelArrayApiResponse>>;

    /**
     */
    downloadImagesPost(UserDetails: Array<UserDetails>, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DocumentResponseModelArrayApiResponse>;

    /**
     * 
     * @param {string} userRoleType 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    organizationsOrganizationIdUsersIdDeleteImageDeleteRaw(requestParameters: OrganizationsOrganizationIdUsersIdDeleteImageDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdUsersIdDeleteImageDelete(userRoleType: string, organizationId: string, id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} userRoleType 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    organizationsOrganizationIdUsersIdDownloadImageGetRaw(requestParameters: OrganizationsOrganizationIdUsersIdDownloadImageGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DocumentResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdUsersIdDownloadImageGet(userRoleType: string, organizationId: string, id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DocumentResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {DocumentRequestModel} [DocumentRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    organizationsOrganizationIdUsersIdUploadImagePutRaw(requestParameters: OrganizationsOrganizationIdUsersIdUploadImagePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdUsersIdUploadImagePut(organizationId: string, id: string, DocumentRequestModel?: DocumentRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI implements DocumentApiInterface {

    /**
     */
    async downloadImagesPostRaw(requestParameters: DownloadImagesPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DocumentResponseModelArrayApiResponse>> {
        if (requestParameters.UserDetails === null || requestParameters.UserDetails === undefined) {
            throw new runtime.RequiredError('UserDetails','Required parameter requestParameters.UserDetails was null or undefined when calling downloadImagesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/download-images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.UserDetails.map(UserDetailsToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentResponseModelArrayApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async downloadImagesPost(UserDetails: Array<UserDetails>, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DocumentResponseModelArrayApiResponse> {
        const response = await this.downloadImagesPostRaw({ UserDetails: UserDetails }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdUsersIdDeleteImageDeleteRaw(requestParameters: OrganizationsOrganizationIdUsersIdDeleteImageDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.userRoleType === null || requestParameters.userRoleType === undefined) {
            throw new runtime.RequiredError('userRoleType','Required parameter requestParameters.userRoleType was null or undefined when calling organizationsOrganizationIdUsersIdDeleteImageDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdUsersIdDeleteImageDelete.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdUsersIdDeleteImageDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.userRoleType !== undefined) {
            queryParameters['userRoleType'] = requestParameters.userRoleType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/users/{id}/delete-image`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdUsersIdDeleteImageDelete(userRoleType: string, organizationId: string, id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdUsersIdDeleteImageDeleteRaw({ userRoleType: userRoleType, organizationId: organizationId, id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdUsersIdDownloadImageGetRaw(requestParameters: OrganizationsOrganizationIdUsersIdDownloadImageGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DocumentResponseModelApiResponse>> {
        if (requestParameters.userRoleType === null || requestParameters.userRoleType === undefined) {
            throw new runtime.RequiredError('userRoleType','Required parameter requestParameters.userRoleType was null or undefined when calling organizationsOrganizationIdUsersIdDownloadImageGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdUsersIdDownloadImageGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdUsersIdDownloadImageGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userRoleType !== undefined) {
            queryParameters['userRoleType'] = requestParameters.userRoleType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/users/{id}/download-image`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdUsersIdDownloadImageGet(userRoleType: string, organizationId: string, id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DocumentResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdUsersIdDownloadImageGetRaw({ userRoleType: userRoleType, organizationId: organizationId, id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdUsersIdUploadImagePutRaw(requestParameters: OrganizationsOrganizationIdUsersIdUploadImagePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdUsersIdUploadImagePut.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdUsersIdUploadImagePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/users/{id}/upload-image`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentRequestModelToJSON(requestParameters.DocumentRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdUsersIdUploadImagePut(organizationId: string, id: string, DocumentRequestModel?: DocumentRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdUsersIdUploadImagePutRaw({ organizationId: organizationId, id: id, DocumentRequestModel: DocumentRequestModel }, initOverrides);
        return await response.value();
    }

}
