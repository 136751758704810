import { useActor } from "@xstate/react";
import { DetailsCommonPerson } from "components/PageTemplate/DetailsPage/DetailsCommonPerson";
import { TabsForRouter } from "libs/ui/TabsForRouter";
import { useParams } from "react-router-dom";
import { RouteParams } from "router/model";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { useTranslation } from "react-i18next";
import { useHcpDetailsServiceContext } from "./Context";
import { DetailsForm } from "./DetailsForm";

export function Details() {
  const { t } = useTranslation("translation", { keyPrefix: "HCPDetails" });
  const can = useHasAccess();
  const { organizationId = "", hcpId = "" } = useParams<RouteParams["hcpDetails"]>();
  const { detailsService } = useHcpDetailsServiceContext();
  const [state] = useActor(detailsService);

  // AvatarState is in DetailsForm
  return (
    <DetailsCommonPerson
      detailsService={detailsService}
      detailsForm={
        <DetailsForm service={detailsService} hcpId={hcpId} organizationId={organizationId} />
      }
      canEdit={can("edit-hcp", {
        organizationId: state.context.data?.organizationId,
        id: state.context.data?.id,
        roleType: state.context.data?.role,
      })}
    >
      <TabsForRouter
        tabs={[
          {
            routePath: "patients",
            label: t("tab.labelPatients"),
          },
          {
            routePath: "tests",
            to: "tests/all",
            label: t("tab.labelTests"),
          },
        ]}
      />
    </DetailsCommonPerson>
  );
}
