import { MEASUREMENT_UNITS } from "config/measurementSystemsConst";
import { SideModalElements } from "libs/ui";

import { SectionDetailsProps } from "../model";
import { useEGFRColor } from "utils/hooks/useEGFRColor";
// import { useEGFRStageText } from "utils/hooks/useEGFRStageText";
import { ColorValue } from "../../elements/ColorValue";
import { DetailsField } from "../../elements/DetailsField";
import { TrendDetailsField } from "../../elements/TrendDetailsField";
import { useTranslation } from "react-i18next";
import { EGFRRangeTable } from "../../elements/EGFRRangeTable/EGFRRangeTable";

interface EGFRSectionDetailsProps extends SectionDetailsProps {}

export function EGFRSectionDetails({ data, loading, showTrend }: EGFRSectionDetailsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "Graph.Details.EGFRSectionDetails" });
  const [getEGFRColor] = useEGFRColor();
  // const [getEGFRStageText] = useEGFRStageText();

  return (
    <div className="EGFRSectionDetails">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>

      <DetailsField loading={loading} label={t("labelResult")}>
        <ColorValue colorType={getEGFRColor(data?.measurement?.SerumCreatinine?.Egfr?.Status)}>
          {data?.measurement?.SerumCreatinine?.Egfr?.Value.toString().concat(
            ` ${MEASUREMENT_UNITS.mLmin173m2}`,
          )}
        </ColorValue>
      </DetailsField>

      {/* !!! TODO: leaving it in case we needed, but it is redundant since we got the `EGFRRangeTable` */}
      {/* <DetailsField loading={loading} label={t("labelStageOfCKD")}>
        {getEGFRStageText(data?.measurement?.SerumCreatinine?.Egfr?.Status)}
      </DetailsField> */}

      <TrendDetailsField
        show={showTrend}
        loading={loading}
        trendType={data?.measurement?.SerumCreatinine?.Egfr?.TrendType}
        percent={data?.measurement?.SerumCreatinine?.Egfr?.Percent}
      />

      <DetailsField loading={loading} label={t("labelEquation")}>
        {data?.measurement?.SerumCreatinine?.Egfr?.FormulaType}
      </DetailsField>

      <EGFRRangeTable
        loading={loading}
        value={Number(data?.measurement?.SerumCreatinine?.Egfr?.Value)}
      />
    </div>
  );
}
