/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AncestryType,
    AncestryTypeFromJSON,
    AncestryTypeFromJSONTyped,
    AncestryTypeToJSON,
} from './AncestryType';
import {
    EgfrFormulaType,
    EgfrFormulaTypeFromJSON,
    EgfrFormulaTypeFromJSONTyped,
    EgfrFormulaTypeToJSON,
} from './EgfrFormulaType';
import {
    EgfrStatusType,
    EgfrStatusTypeFromJSON,
    EgfrStatusTypeFromJSONTyped,
    EgfrStatusTypeToJSON,
} from './EgfrStatusType';
import {
    MeasurementTrendType,
    MeasurementTrendTypeFromJSON,
    MeasurementTrendTypeFromJSONTyped,
    MeasurementTrendTypeToJSON,
} from './MeasurementTrendType';
import {
    MeasurementUnitType,
    MeasurementUnitTypeFromJSON,
    MeasurementUnitTypeFromJSONTyped,
    MeasurementUnitTypeToJSON,
} from './MeasurementUnitType';

/**
 * 
 * @export
 * @interface EgfrModel
 */
export interface EgfrModel {
    /**
     * 
     * @type {number}
     * @memberof EgfrModel
     */
    value: number;
    /**
     * 
     * @type {MeasurementUnitType}
     * @memberof EgfrModel
     */
    unit: MeasurementUnitType;
    /**
     * 
     * @type {MeasurementTrendType}
     * @memberof EgfrModel
     */
    trendType: MeasurementTrendType;
    /**
     * 
     * @type {number}
     * @memberof EgfrModel
     */
    percent: number;
    /**
     * 
     * @type {EgfrFormulaType}
     * @memberof EgfrModel
     */
    formulaType: EgfrFormulaType;
    /**
     * 
     * @type {EgfrStatusType}
     * @memberof EgfrModel
     */
    status: EgfrStatusType;
    /**
     * 
     * @type {number}
     * @memberof EgfrModel
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof EgfrModel
     */
    age: number;
    /**
     * 
     * @type {AncestryType}
     * @memberof EgfrModel
     */
    ancestry: AncestryType;
    /**
     * 
     * @type {number}
     * @memberof EgfrModel
     */
    graphValue: number;
}

export function EgfrModelFromJSON(json: any): EgfrModel {
    return EgfrModelFromJSONTyped(json, false);
}

export function EgfrModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EgfrModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['Value'],
        'unit': MeasurementUnitTypeFromJSON(json['Unit']),
        'trendType': MeasurementTrendTypeFromJSON(json['TrendType']),
        'percent': json['Percent'],
        'formulaType': EgfrFormulaTypeFromJSON(json['FormulaType']),
        'status': EgfrStatusTypeFromJSON(json['Status']),
        'weight': json['Weight'],
        'age': json['Age'],
        'ancestry': AncestryTypeFromJSON(json['Ancestry']),
        'graphValue': json['GraphValue'],
    };
}

export function EgfrModelToJSON(value?: EgfrModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Value': value.value,
        'Unit': MeasurementUnitTypeToJSON(value.unit),
        'TrendType': MeasurementTrendTypeToJSON(value.trendType),
        'Percent': value.percent,
        'FormulaType': EgfrFormulaTypeToJSON(value.formulaType),
        'Status': EgfrStatusTypeToJSON(value.status),
        'Weight': value.weight,
        'Age': value.age,
        'Ancestry': AncestryTypeToJSON(value.ancestry),
        'GraphValue': value.graphValue,
    };
}

