/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserStatusResponseModel
 */
export interface UpdateUserStatusResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserStatusResponseModel
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserStatusResponseModel
     */
    isActive: boolean;
}

export function UpdateUserStatusResponseModelFromJSON(json: any): UpdateUserStatusResponseModel {
    return UpdateUserStatusResponseModelFromJSONTyped(json, false);
}

export function UpdateUserStatusResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserStatusResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'isActive': json['IsActive'],
    };
}

export function UpdateUserStatusResponseModelToJSON(value?: UpdateUserStatusResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'IsActive': value.isActive,
    };
}

