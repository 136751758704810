import { useActor } from "@xstate/react";
import { Auth } from "aws-amplify";
import * as AddModal from "components/AddModal/Patient";
import { AllPatientsTable } from "components/AppTables/AllPatientsTable";
import { usePatientsTableStateContext } from "components/AppTables/AllPatientsTable/context";
import { ParentPage } from "components/PageTemplate/ParentPage";
import { toastStore } from "config/toast";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePagesParentTableConfig } from "utils/hooks/Pages/usePagesParentTableConfig";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";

export function Parent() {
  const { organizationId, isAdmin, isPatient } = useCurrentUserData();
  const tableContext = usePatientsTableStateContext();
  const [state, send] = useActor(tableContext.tableService);
  const { t } = useTranslation("translation", { keyPrefix: "CSV" });
  const { appConfig } = useGlobalConfigContext();

  /**
   * ! If you supply {children} for ParentPage
   * ! It can mean this state init here is not the right place
   * ! check TestsRoutes.tsx, there the EventRichTable must fire the init event
   */
  useEffect(() => {
    if (state.matches("init")) {
      send("FETCH");
    }
  }, [send, state]);

  const { isTableEmpty, onFilterToggle, onSearchChange } = usePagesParentTableConfig(
    tableContext,
    "items",
  );

  const showDownloadReportButton =
    appConfig?.pages.PatientsPage.Parent.ShowDownloadReportButton && !isPatient;

  const onDownloadReport = useCallback(async () => {
    const headers = new Headers();
    headers.append(
      "access-token",
      `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    );
    headers.append("identity-token", `${(await Auth.currentSession()).getIdToken().getJwtToken()}`);

    const url = isAdmin
      ? "patients/reports/csv"
      : `organizations/${organizationId}/patients/reports/csv`;

    fetch(`${process.env.VITE_APP_QUERY_HOST}/${url}`, { headers })
      .then(response => response.blob())
      .then(blob => {
        if (blob.size === 0) {
          throw new Error("CSV is empty");
        }
        const blobUrl = window.URL.createObjectURL(blob);

        const aElem = document.createElement("a");
        aElem.href = blobUrl;
        aElem.download = `report.csv`;
        aElem.style.display = "none";

        document.body.appendChild(aElem);
        aElem.click();

        document.body.removeChild(aElem);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(() => {
        toastStore.toast.error({ msg: t("failedToDownloadToast") });
      });
  }, [isAdmin, organizationId, t]);

  return (
    <ParentPage
      variant="Patients"
      AddModal={<AddModal.Patient onSuccessCb={tableContext.fetch} />}
      isEmptyState={isTableEmpty}
      isTableFailed={state.matches("failure")}
      isTableLoading={state.matches("loading")}
      onFilterToggle={onFilterToggle}
      searchField={{
        onChange: onSearchChange,
        value: state.context.filters?.contains ?? "",
      }}
      filtersCount={state.context.countFilters}
      onRefreshTable={tableContext.fetch}
      onDownloadReport={showDownloadReportButton ? onDownloadReport : undefined}
    >
      <AllPatientsTable />
    </ParentPage>
  );
}
