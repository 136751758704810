import "./Login.scss";

import { Amplify, Auth, I18n as AmplifyI18n } from "aws-amplify";
import { PropsWithChildren, useCallback, useState } from "react";
import { useLocalStorage } from "utils/hooks/useLocalStorage";
import { useTranslation } from "react-i18next";

import { Authenticator, AuthenticatorProps, useAuthenticator } from "@aws-amplify/ui-react";

import { CarnaApiQuery } from "config/apiQuery";
import { globalConfigStore } from "config/globalConfig";
import { toastStore } from "config/toast";
import i18n from "i18next";
import ConfirmResetPassword from "layout/Login/ConfirmResetPassword/index";
import LoginPagesHeader from "layout/Login/LoginPagesHeader";
import { Logo } from "libs/ui";
import { FetchingErrorPage } from "pages/ErrorBoundary/FetchingErrorPage";
import SuccessfulPasswordChange from "pages/SuccessfulPasswordChange";
import isEmail from "validator/lib/isEmail";
import AuthenticatorWrapper from "./AuthenticatorWrapper";
import { ForceNewPasswordFormFields } from "./ForceNewPasswordFormFields";
import { Copyright } from "components/Copyright";

export const Login = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation("translation", { keyPrefix: "Login" });
  const { t: tErrorPages } = useTranslation("translation", {
    keyPrefix: "ErrorPages.NoNetworkErrorPage",
  });
  const { setLocalStorageItem } = useLocalStorage("lastUsedAuth");
  const { route, toSignIn } = useAuthenticator(context => [context.route]);

  const [displaySuccessfulPasswordChange, setDisplaySuccessfulPasswordChange] = useState(false);
  const [displayNetworkError, setDisplayNetworkError] = useState(false);

  const [forgotPasswordUsername, setForgotPasswordUsername] = useState("");
  const [confirmResetPasswordErrorCode, setConfirmResetPasswordErrorCode] = useState("");

  AmplifyI18n.putVocabulariesForLanguage("en", {
    "Send code": "Submit",
  });

  const formFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: t("SignIn.email"),
      },
      password: {
        labelHidden: true,
        placeholder: t("SignIn.password"),
      },
    },
    confirmSignIn: {
      confirmation_code: {
        labelHidden: true,
        placeholder: "Enter your Confirmation Code:",
      },
    },
    resetPassword: {
      username: {
        labelHidden: true,
        placeholder: t("ResetPassword.email"),
      },
    },
    forceNewPassword: {
      password: {
        labelHidden: true,
        placeholder: t("ForceNewPassword.newPassword"),
      },
      confirm_password: {
        labelHidden: true,
        placeholder: t("ForceNewPassword.confirmNewPassword"),
      },
    },
  };

  const components: any = {
    SignIn: {
      Header() {
        return <LoginPagesHeader title={t("SignIn.title")} text={[t("SignIn.text")]} />;
      },
    },
    SetupTOTP: {
      Header() {
        return (
          <LoginPagesHeader
            title={t("SetupTOTP.title")}
            text={[
              t("SignIn.text"),
              t("SetupTOTP.title"),
              t("SetupTOTP.text1"),
              t("SetupTOTP.text2"),
              t("SetupTOTP.text3"),
            ]}
          />
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        return (
          <LoginPagesHeader title={t("ConfirmSignIn.title")} text={[t("ConfirmSignIn.text")]} />
        );
      },
    },
    ResetPassword: {
      Header() {
        return (
          <LoginPagesHeader title={t("ResetPassword.title")} text={[t("ResetPassword.text")]} />
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <LoginPagesHeader
            title={t("ConfirmResetPassword.title")}
            text={[t("ConfirmResetPassword.text")]}
          />
        );
      },
    },
    ForceNewPassword: {
      FormFields() {
        return (
          <ForceNewPasswordFormFields
            title={t("ForceNewPassword.title")}
            text={[t("ForceNewPassword.text")]}
          />
        );
      },
    },
  };

  const forgotPasswordSubmit = useCallback(
    (formData: { code: string; password: string }) => {
      const { code, password } = formData;

      setConfirmResetPasswordErrorCode("");

      Auth.forgotPasswordSubmit(forgotPasswordUsername, code, password)
        .then(() => {
          setDisplaySuccessfulPasswordChange(true);
        })
        .catch((err: { code: string; message: string }) => {
          if (err.code === "NetworkError") {
            setDisplayNetworkError(true);
          } else {
            setConfirmResetPasswordErrorCode(err.code);
          }
        });
    },
    [forgotPasswordUsername],
  );

  const setupAuthObj = useCallback(
    async (userName: string) => {
      const amplifyConfig = await CarnaApiQuery.Auth.authGet(
        { userName },
        {
          headers: new Headers({
            "access-token": "fake",
            "identity-token": "fake",
          }),
        },
      );

      const authObj = {
        identityPoolId: amplifyConfig.identityPoolId,
        region: amplifyConfig.region,
        userPoolId: amplifyConfig.userPoolId,
        userPoolWebClientId: amplifyConfig.clientId,
        mandatorySignIn: true,
        authenticationFlowType: amplifyConfig.authenticationFlowType,
      } as const;

      Amplify.configure({
        Auth: authObj,
      });

      setLocalStorageItem(authObj);

      toastStore.clear();
      globalConfigStore?.clear();
    },
    [setLocalStorageItem],
  );

  const services: AuthenticatorProps["services"] = {
    async handleForgotPassword(username: string) {
      if (!isEmail(username)) {
        return Promise.reject(i18n.t("Amplify.ForgotPassword.wrong_email", { ns: "translation" }));
      }

      await setupAuthObj(username);

      setForgotPasswordUsername(username);
      return Auth.forgotPassword(username);
    },
    async handleSignIn(formData: { username: string; password: string }) {
      const { password } = formData;
      const username = formData.username.trim();

      if (!isEmail(username)) {
        throw new Error(i18n.t("Amplify.SignIn.wrong_email", { ns: "translation" }));
      }

      if (password === "") {
        throw new Error(i18n.t("Amplify.SignIn.empty_password", { ns: "translation" }));
      }

      await setupAuthObj(username);

      return Auth.signIn({
        username,
        password,
      })
        .then(user => {
          return user;
        })
        .catch(error => {
          if (
            error instanceof Error &&
            (error.name === "InvalidParameterException" || error.name === "NotAuthorizedException")
          ) {
            throw new Error(
              i18n.t("Amplify.SignIn.incorrect_email_or_password", { ns: "translation" }),
            );
          }

          throw error;
        });
    },
  };

  if (displayNetworkError) {
    return (
      <div className="LogoContentCopyrightLayout">
        <Logo className="LoginLogo" />
        <FetchingErrorPage
          fetchingFunc={() => {
            setDisplayNetworkError(false);
          }}
          title={tErrorPages("title")}
          message={tErrorPages("message")}
          titleIcon="⚠️"
        />
        <Copyright />
      </div>
    );
  }

  if (displaySuccessfulPasswordChange) {
    return (
      <SuccessfulPasswordChange
        buttonAction={() => {
          setDisplaySuccessfulPasswordChange(false);
          toSignIn();
        }}
      />
    );
  }

  return (
    <AuthenticatorWrapper>
      {route === "confirmResetPassword" ? (
        <ConfirmResetPassword
          submit={forgotPasswordSubmit}
          errorCode={confirmResetPasswordErrorCode}
        />
      ) : (
        <Authenticator
          services={services}
          className="Login"
          hideSignUp
          components={components}
          formFields={formFields}
        >
          {children}
        </Authenticator>
      )}
    </AuthenticatorWrapper>
  );
};
