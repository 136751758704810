import classNames from "classnames";
import { useCurrentUserNameContext } from "context/CurrentUserNameContext";
import { Avatar, AvatarSize } from "libs/ui/Avatar";
import { isLoaded, isLoading } from "models/loadable";
import { CurrentUserRole } from "./CurrentUserRole";
import { RegistryNumber } from "./RegistryNumber";

import "./BasicDetails.scss";
import { useAppMenuContext } from "context/AppMenuContext";
import { useEffect } from "react";

interface BasicDetailsProps<T extends HTMLDivElement> {
  sidebarCollapsed?: boolean;
  mainRef?: (node: T | null) => void;
  onClick?: () => void;
  avatarSize?: AvatarSize;
}

export function BasicDetails<T extends HTMLDivElement>({
  sidebarCollapsed,
  mainRef,
  onClick,
  avatarSize,
}: Readonly<BasicDetailsProps<T>>) {
  const { loadSideAvatar, sideAvatarState } = useAppMenuContext();
  const { currentUserName, fullName } = useCurrentUserNameContext();
  const currentUserFullName = isLoaded(currentUserName) ? fullName : null;

  useEffect(() => {
    if (sideAvatarState._init) {
      loadSideAvatar();
    }
  }, [loadSideAvatar, sideAvatarState._init]);

  return (
    <div
      ref={mainRef}
      onClick={onClick}
      className={classNames("BasicDetails", { "BasicDetails--clickable": !!onClick })}
    >
      <Avatar
        avatarLoading={sideAvatarState.loading}
        src={sideAvatarState.latestSavedImage}
        firstName={currentUserFullName ?? ""}
        size={avatarSize}
      />
      <div
        className={classNames(
          "BasicDetails__content",
          sidebarCollapsed && "BasicDetails__content--collapsed",
        )}
      >
        <h4
          className={classNames("BasicDetails__name", {
            "BasicDetails__name--loading": isLoading(currentUserName),
          })}
        >
          {currentUserFullName}
        </h4>

        <CurrentUserRole />
        <RegistryNumber />
      </div>
    </div>
  );
}
