import { PatientInfoWidgetDataModel } from "api/query";
import { MeasurementPatientWidgetDataModel } from "api/query";
import { HeatMapPatientWidgetResponseModel } from "api/query/models/HeatMapPatientWidgetResponseModel";
import { PatientWidgetResponseModelData } from "api/query/models/PatientWidgetResponseModelData";
import { CarnaApiQuery } from "config/apiQuery";
import { LoadableModel, defaultModel, failed, isLoaded, loaded, loading } from "models/loadable";
import { PropsWithChildren, useCallback, useMemo, useReducer } from "react";
import { MeasurementWidgetType } from "utils/createGlobalConfigStore";
import { createSafeContext } from "utils/createSafeContext";

// function isPatientWidgetResponseModelSubtype(
//   data: PatientWidgetResponseModelData,
//   param: "HeatMapPatientWidgetResponseModel",
// ): data is HeatMapPatientWidgetResponseModel;

// function isPatientWidgetResponseModelSubtype(
//   data: PatientWidgetResponseModelData,
//   param: "MeasurementPatientWidgetDataModel",
// ): data is MeasurementPatientWidgetDataModel;

// function isPatientWidgetResponseModelSubtype(
//   data: PatientWidgetResponseModelData,
//   param: "PatientInfoWidgetDataModel",
// ): data is PatientInfoWidgetDataModel;

// function isPatientWidgetResponseModelSubtype(
//   data: PatientWidgetResponseModelData,
//   param: string,
// ): boolean {
//   return data.$type === param;
// }

type OverviewWidgetState = {
  HeatMap: LoadableModel<HeatMapPatientWidgetResponseModel | null>;
  PatientInfo: LoadableModel<PatientInfoWidgetDataModel | null>;
  SerumCreatinine: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  Egfr: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  Height: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  Weight: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  Bmi: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  Glucose: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  BloodPressure: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  UrineCreatinine: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  UrineAlbumin: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  Uacr: LoadableModel<MeasurementPatientWidgetDataModel | null>;
  SemiQuantitativeUacr: LoadableModel<MeasurementPatientWidgetDataModel | null>;
};

const DATA_INIT: OverviewWidgetState = {
  HeatMap: defaultModel(),
  PatientInfo: defaultModel(),
  SerumCreatinine: defaultModel(),
  Egfr: defaultModel(),
  Height: defaultModel(),
  Weight: defaultModel(),
  Bmi: defaultModel(),
  Glucose: defaultModel(),
  BloodPressure: defaultModel(),
  UrineCreatinine: defaultModel(),
  UrineAlbumin: defaultModel(),
  Uacr: defaultModel(),
  SemiQuantitativeUacr: defaultModel(),
};

export const WidgetType = {
  ...MeasurementWidgetType,
  PatientInfo: "PatientInfo",
  HeatMap: "HeatMap",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WidgetType = (typeof WidgetType)[keyof typeof WidgetType];

interface Action {
  type: WidgetType;
  payload: LoadableModel<PatientWidgetResponseModelData | null>;
}

function reducer(state: OverviewWidgetState, action: Action): OverviewWidgetState {
  return {
    ...state,
    [action.type]: action.payload,
  };
}

type HeatMapHistory = "prev" | "next";

interface OverviewAPIContextData {
  data: OverviewWidgetState;
  getWidgetData: (organizationId: string, patientId: string, widgets: WidgetType[]) => void;
  getHeatMapHistory: (organizationId: string, patientId: string, option: HeatMapHistory) => void;
}

const Context = createSafeContext<OverviewAPIContextData>();

export const useOverviewAPIContext = Context.hook;

export function OverviewAPIContext({ children }: Readonly<PropsWithChildren>) {
  const [data, dispatch] = useReducer(reducer, DATA_INIT);

  const getHeatMapHistory = useCallback(
    (organizationId: string, patientId: string, option: HeatMapHistory) => {
      async function getHeatMapAsync() {
        if (isLoaded(data.HeatMap)) {
          dispatch({ type: "HeatMap", payload: loading() });

          try {
            const response = await CarnaApiQuery.Patients.getWidget({
              widgetId: "HeatMap",
              organizationId,
              patientEntityId: patientId,
              filter: {
                heatMap: {
                  ...(option === "prev"
                    ? { previous: data.HeatMap.value?.previous ?? null }
                    : undefined),
                  ...(option === "next" ? { next: data.HeatMap.value?.next ?? null } : undefined),
                },
              },
            });
            const widgetData = response.data ?? null;

            return { type: "HeatMap", payload: loaded(widgetData) } as const;
          } catch (error) {
            return { type: "HeatMap", payload: failed(error) } as const;
          }
        }
      }

      getHeatMapAsync().then(result => {
        if (result) {
          dispatch({
            payload: result.payload,
            type: result.type,
          });
        }
      });
    },
    [data.HeatMap],
  );

  const getWidgetData = useCallback(
    async (organizationId: string, patientId: string, types: WidgetType[]) => {
      types.forEach(type => {
        dispatch({ type, payload: loading() });
      });

      try {
        const response = await CarnaApiQuery.Patients.getWidgets({
          organizationId,
          patientEntityId: patientId,
          filter: {
            ids: types,
          },
        });

        types.forEach(type => {
          dispatch({ type, payload: loaded(null) });
        });

        response.widgets?.forEach(widgetResponse => {
          dispatch({
            type: widgetResponse.id as WidgetType,
            payload: loaded(widgetResponse.data ?? null),
          });
        });
      } catch (error) {
        types.forEach(type => {
          dispatch({ type, payload: failed(error) });
        });
      }
    },
    [],
  );
  const value = useMemo(
    () => ({ data, getWidgetData, getHeatMapHistory }),
    [data, getHeatMapHistory, getWidgetData],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
