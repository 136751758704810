import { useActor, useInterpret } from "@xstate/react";
import { TableContext, tableStateMachine } from "libs/ui";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "router";
import { PatientsPerEntity } from "..";
import { PatientResponseModelListModel, PatientsFilterModel } from "api/query";
import { CarnaApiQuery } from "config/apiQuery";
import { useFilterModal } from "components/AppTables/common/hooks";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import {
  PATIENTS_FILTER_OPTIONS,
  PatientsFilterOptionsKey,
} from "components/AppTables/common/PatientsTable/model";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { getFilterOptionsPerConfiguration } from "components/AppTables/utils";
import { useOrganizationDetailsServiceContext } from "pages/Settings/Organizations/Details/Context";

async function fetchPatients(
  organizationId: string,
  context?: TableContext<PatientResponseModelListModel, PatientsFilterModel>,
) {
  const result = CarnaApiQuery.Patients.get({
    organizationId,
    filters: context?.filters,
    limit: context?.linesPerPage,
    page: context?.currentPage,
  });

  return result;
}

const makeTableFetchStateMachine = (organizationId: string) =>
  tableStateMachine((context?: TableContext<PatientResponseModelListModel, PatientsFilterModel>) =>
    fetchPatients(organizationId, context),
  );

export function PatientsPerOrganizationTable() {
  const { organizationId = "" } = useParams<RouteParams["organizationDetails"]>();
  const tableMachine = useMemo(() => makeTableFetchStateMachine(organizationId), [organizationId]);
  const tableService = useInterpret(tableMachine);

  const { detailsService } = useOrganizationDetailsServiceContext();
  const [detailsState] = useActor(detailsService);

  const { appConfig } = useGlobalConfigContext();

  const filterSideModelState = useFilterModal<Partial<PatientsFilterOptionsKey>>(
    getFilterOptionsPerConfiguration(
      PATIENTS_FILTER_OPTIONS(),
      NotNullOrUndefined(
        appConfig?.components.tables.Patient.PatientPerOrganizationTable.filterOptions,
      ),
    ),
  );

  return detailsState.matches("entity.loaded") ? (
    <PatientsPerEntity
      tableService={tableService}
      filterSideModelState={filterSideModelState}
      appliedColumns={NotNullOrUndefined(
        appConfig?.components.tables.Patient.PatientPerOrganizationTable.columnList,
      )}
      patientsFilterOptions={
        appConfig?.components.tables.Patient.PatientPerOrganizationTable.filterOptions
      }
      initialValuesAsProps={{
        organizationId: detailsState.context.data?.id,
        organizationName: detailsState.context.data?.name,
      }}
    />
  ) : null;
}
