import "./SettingsCards.scss";

import { CardLink, IconType } from "libs/ui";
import { useTranslation } from "react-i18next";
import { NavRoute, settingsRoutePaths } from "router";
import { v4 } from "uuid";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";

export function SettingsCards() {
  const { t } = useTranslation("translation", { keyPrefix: "PageTemplate.Settings" });

  const { appConfig } = useGlobalConfigContext();
  const { userRole } = useCurrentUserData();

  const can = useHasAccess();

  const cardLinks: NavRoute[] = [
    {
      to: settingsRoutePaths.personalInformation,
      name: t("personal-information.title"),
      icon: "User",
      accessLevel: true,
    },
    {
      to: settingsRoutePaths.login,
      name: t("login.title"),
      icon: "SignIn",
      accessLevel: true,
      disabled: false,
    },
    {
      to: settingsRoutePaths.securityAndPrivacy,
      name: t("security-and-privacy.title"),
      icon: "Lock",
      accessLevel: true,
      disabled: false,
    },
    {
      to: settingsRoutePaths.globalPreferences,
      name: t("global-preferences.title"),
      icon: "Settings",
      accessLevel: true,
    },
    ...(appConfig?.pages.Settings.RuleEngine.Access.includes(userRole) === true
      ? [
          {
            to: settingsRoutePaths.ruleEngine,
            name: t("rule-engine.title"),
            icon: "RuleEngine" as IconType,
            accessLevel: can("manage-organization"),
          },
        ]
      : []),
    {
      to: settingsRoutePaths.users,
      name: t("users-management.title"),
      icon: "Users",
      accessLevel: can("manage-users"),
    },
    {
      to: settingsRoutePaths.projectOrganizations,
      name: t("project-organizations.title"),
      icon: "Organization",
      accessLevel: can("manage-organization"),
    },
    {
      to: settingsRoutePaths.support,
      name: t("support.title"),
      icon: "Help",
      accessLevel: true,
      disabled: false,
    },
  ];

  return (
    <div className="SettingsCards">
      {cardLinks.map(card =>
        card.accessLevel ? (
          <CardLink
            key={v4()}
            path={card.to}
            title={card.name}
            icon={card.icon}
            disabled={card.disabled}
          />
        ) : null,
      )}
    </div>
  );
}
