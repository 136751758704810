import { Widget } from "libs/ui/Widget";
import { gridContainer } from "utils/helpers/gridContainer";
import { useDashboardStatsContext } from "../DashboardStatsContext";
import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { useCallback } from "react";
import { EGFRResult } from "pages/PatientsPage/Details/Overview/HeatMapWidget/EGFRResult";
import { HeatMapHeader } from "pages/PatientsPage/Details/Overview/HeatMapWidget/HeatMapHeader";
import { HeatMapTable } from "pages/PatientsPage/Details/Overview/HeatMapWidget/HeatMapTable";
import { Recommendation } from "pages/PatientsPage/Details/Overview/HeatMapWidget/Recommendation";
import { SingleMeasurement } from "pages/PatientsPage/Details/Overview/HeatMapWidget/SingleMeasurement";
import { UacrResult } from "pages/PatientsPage/Details/Overview/HeatMapWidget/UacrResult";
import { RefreshFailedWidget } from "pages/PatientsPage/Details/Overview/RefreshFailedWidget";
import { WidgetLoader } from "pages/PatientsPage/Details/Overview/WidgetLoader";
interface Props {
  organizationId: string;
  patientId: string;
}

export function HeatMapWidget({ organizationId, patientId }: Readonly<Props>) {
  const {
    data: { HeatMap },
    getWidgetData,
    getHeatMapHistory,
  } = useDashboardStatsContext();

  const refreshWidgetData = useCallback(() => {
    getWidgetData(organizationId, patientId, ["HeatMap"]);
  }, [getWidgetData, organizationId, patientId]);

  const eGFRData = isLoaded(HeatMap) ? HeatMap.value?.egfrTest : null;
  const eGFRTest = isLoaded(HeatMap)
    ? eGFRData?.measurements.find(el => el.measurementType === "SerumCreatinine")?.item["Egfr"]
    : null;

  const UACRData = isLoaded(HeatMap)
    ? HeatMap.value?.semiQuantitativeUacrTest ?? HeatMap.value?.uacrTest
    : null;
  const UACRTest = isLoaded(HeatMap)
    ? HeatMap.value?.semiQuantitativeUacrTest
      ? UACRData?.measurements.find(el => el.measurementType === "SemiQuantitativeUACR")?.item[
          "SemiQuantitativeUacr"
        ]
      : UACRData?.measurements.find(el => el.measurementType === "UACR")?.item["Uacr"]
    : null;
  const UACRType = isLoaded(HeatMap)
    ? HeatMap.value?.semiQuantitativeUacrTest
      ? "SQUACR"
      : "UACR"
    : null;

  return (
    <Widget
      className="HeatMapWidget"
      widgetName={"HeatMapWidget"}
      gridContainer={gridContainer}
      options={{
        x: 1,
        y: 1,
        maxHeight: 6,
        minHeight: 6,
        height: 6,
        width: 6,
        maxWidth: 6,
        minWidth: 6,
      }}
      Header={
        <HeatMapHeader
          heatMapData={HeatMap}
          getHeatMapHistory={getHeatMapHistory}
          eGFRDate={eGFRData?.measurementTime}
          UACRDate={UACRData?.measurementTime}
          organizationId={organizationId}
          patientId={patientId}
        />
      }
    >
      {isLoading(HeatMap) || isDefaultModel(HeatMap) ? (
        <WidgetLoader />
      ) : (
        <>
          {isLoaded(HeatMap) ? (
            <div className="HeatMapWidget__body">
              <div className="HeatMapWidget__diagnosis">
                <Recommendation risk={HeatMap.value?.risk} />

                <SingleMeasurement
                  measurementUnit="eGFR"
                  value={eGFRTest?.Value}
                  date={eGFRData?.measurementTime}
                  trendType={eGFRTest?.TrendType}
                  percent={eGFRTest?.Percent}
                />
              </div>

              <UacrResult
                value={UACRTest?.Value}
                date={UACRData?.measurementTime}
                trendType={UACRTest?.TrendType}
                percent={UACRTest?.Percent}
                status={UACRTest?.Status}
                uacrType={UACRType ?? "UACR"}
              />

              <EGFRResult value={eGFRTest?.Value} />

              <HeatMapTable
                eGFRStatus={eGFRTest?.Status}
                UACRStatus={UACRTest?.Status}
                risk={HeatMap.value?.risk}
              />
            </div>
          ) : (
            <RefreshFailedWidget show={isFailed(HeatMap)} refreshCb={refreshWidgetData} />
          )}
        </>
      )}
    </Widget>
  );
}
