/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';

/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    organizationId: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserDetails
     */
    role: UserRoleType;
}

export function UserDetailsFromJSON(json: any): UserDetails {
    return UserDetailsFromJSONTyped(json, false);
}

export function UserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['UserId'],
        'organizationId': json['OrganizationId'],
        'role': UserRoleTypeFromJSON(json['Role']),
    };
}

export function UserDetailsToJSON(value?: UserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'UserId': value.userId,
        'OrganizationId': value.organizationId,
        'Role': UserRoleTypeToJSON(value.role),
    };
}

