import { useInterpret, useActor } from "@xstate/react";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { createSafeContext } from "utils/createSafeContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useFilterModal } from "../common/hooks";
import {
  makeTableFetchStateMachine,
  PatientsFilterOptionsKey,
  PatientsTableStateContext,
  PATIENTS_FILTER_OPTIONS,
} from "../common/PatientsTable/model";
import { getFilterOptionsPerConfiguration } from "../utils";

const Context = createSafeContext<PatientsTableStateContext>();

export const usePatientsTableStateContext = Context.hook;

export function PatientsTableStateProvider({ children }: PropsWithChildren<{}>) {
  const { appConfig } = useGlobalConfigContext();
  const tableMachine = useMemo(() => makeTableFetchStateMachine(), []);
  const tableService = useInterpret(tableMachine);

  const filterSideModelState = useFilterModal<Partial<PatientsFilterOptionsKey>>(
    getFilterOptionsPerConfiguration(
      PATIENTS_FILTER_OPTIONS(),
      NotNullOrUndefined(appConfig?.components.tables.Patient.Index.filterOptions),
    ),
  );

  const [, send] = useActor(tableService);

  const fetch = useCallback(() => {
    send("FETCH");
  }, [send]);

  const value = useMemo(
    () => ({
      tableService,
      fetch,
      filterSideModelState,
    }),
    [fetch, filterSideModelState, tableService],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
