import { OrganizationDetailsResponseModel } from "api/query/models";
import { IdentifiedWithCkdRiskGroupModel } from "api/report_service/models";
import { IdentifiedWithCkdRiskFilterModel } from "api/report_service/models/IdentifiedWithCkdRiskFilterModel";
import { useCallback } from "react";
import i18n from "i18next";

import { useSearchParams } from "react-router-dom";

export function getPlaceSearchInputBasedOnEntityResponse(
  orgData?: OrganizationDetailsResponseModel,
  entityData?: IdentifiedWithCkdRiskGroupModel,
) {
  i18n.t(entityData?.groupedBy?.countryIso ?? "", { keyPrefix: "countries", ns: "nationalities" });

  if (!orgData && !entityData) {
    throw new Error("Either orgData or entityData must be provided");
  }

  // CASE Teamspaces are selected in filters
  if (orgData) {
    return `${orgData.street}, ${orgData.zipCode}, ${orgData.city}, ${orgData.country}`;
  }

  const isoCodeToCountryName = i18n.t(`countries.${entityData?.groupedBy?.countryIso}`, {
    defaultValue: "not-found",
    ns: "nationalities",
  });

  if (isoCodeToCountryName === "not-found") {
    return undefined;
  }

  // CASE Country is selected in filtering
  if (entityData?.groupedBy?.name === entityData?.groupedBy?.countryIso) {
    return isoCodeToCountryName;
  }

  // CASE City is selected in filtering
  return `${entityData?.groupedBy?.name}, ${isoCodeToCountryName}`;
}

export function useSetReportServiceSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParams = useCallback(
    (filters?: IdentifiedWithCkdRiskFilterModel) => {
      const params = new URLSearchParams();

      if (filters?.groupBy) {
        params.append("groupBy", filters?.groupBy);
      }

      if (filters?.onlyAgeRanges) {
        filters?.onlyAgeRanges.forEach(range => params.append("onlyAgeRanges", range));
      }

      if (filters?.onlyGenders) {
        filters?.onlyGenders.forEach(gender => params.append("onlyGenders", gender));
      }

      if (filters?.onlyAncestries) {
        filters?.onlyAncestries.forEach(ancestry => params.append("onlyAncestries", ancestry));
      }

      setSearchParams(params.toString());
    },
    [setSearchParams],
  );

  return [searchParams, setParams] as const;
}

export function isKeyInIdentifiedWithCkdRiskFilterModel(
  key: string,
): key is keyof IdentifiedWithCkdRiskFilterModel {
  const keys: Array<keyof IdentifiedWithCkdRiskFilterModel> = [
    "groupBy",
    "onlyAgeRanges",
    "onlyGenders",
    "onlyAncestries",
  ];

  return keys.includes(key as keyof IdentifiedWithCkdRiskFilterModel);
}
