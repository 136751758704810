/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddMeasurementsResponseModelApiResponse,
    AddMeasurementsResponseModelApiResponseFromJSON,
    AddMeasurementsResponseModelApiResponseToJSON,
    AddNovaMaxMeasurementHcpModel,
    AddNovaMaxMeasurementHcpModelFromJSON,
    AddNovaMaxMeasurementHcpModelToJSON,
    AddNovaMaxMeasurementPatientModel,
    AddNovaMaxMeasurementPatientModelFromJSON,
    AddNovaMaxMeasurementPatientModelToJSON,
    AddNovaMaxMeasurementsListHcpModel,
    AddNovaMaxMeasurementsListHcpModelFromJSON,
    AddNovaMaxMeasurementsListHcpModelToJSON,
    AddNovaMaxMeasurementsListPatientModel,
    AddNovaMaxMeasurementsListPatientModelFromJSON,
    AddNovaMaxMeasurementsListPatientModelToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    MeasurementListResponseModelApiResponse,
    MeasurementListResponseModelApiResponseFromJSON,
    MeasurementListResponseModelApiResponseToJSON,
    MeasurementResponseModelApiResponse,
    MeasurementResponseModelApiResponseFromJSON,
    MeasurementResponseModelApiResponseToJSON,
} from '../models';

export interface MeasurementsGetRequest {
    limit: number;
    paginationToken?: string;
}

export interface OrganizationsOrganizationIdDevicesIdMeasurementsGetRequest {
    limit: number;
    organizationId: string;
    id: string;
    paginationToken?: string;
}

export interface OrganizationsOrganizationIdHcpsIdMeasurementsBatchPostRequest {
    organizationId: string;
    id: string;
    AddNovaMaxMeasurementsListHcpModel?: AddNovaMaxMeasurementsListHcpModel;
}

export interface OrganizationsOrganizationIdHcpsIdMeasurementsPostRequest {
    organizationId: string;
    id: string;
    AddNovaMaxMeasurementHcpModel?: AddNovaMaxMeasurementHcpModel;
}

export interface OrganizationsOrganizationIdHcpsIdPatientsMeasurementsGetRequest {
    limit: number;
    organizationId: string;
    id: string;
    paginationToken?: string;
}

export interface OrganizationsOrganizationIdMeasurementsGetRequest {
    limit: number;
    organizationId: string;
    paginationToken?: string;
}

export interface OrganizationsOrganizationIdPatientsIdMeasurementsBatchPostRequest {
    organizationId: string;
    id: string;
    AddNovaMaxMeasurementsListPatientModel?: AddNovaMaxMeasurementsListPatientModel;
}

export interface OrganizationsOrganizationIdPatientsIdMeasurementsGetRequest {
    limit: number;
    organizationId: string;
    id: string;
    paginationToken?: string;
}

export interface OrganizationsOrganizationIdPatientsIdMeasurementsPostRequest {
    organizationId: string;
    id: string;
    AddNovaMaxMeasurementPatientModel?: AddNovaMaxMeasurementPatientModel;
}

/**
 * MeasurementApi - interface
 * 
 * @export
 * @interface MeasurementApiInterface
 */
export interface MeasurementApiInterface {
    /**
     * 
     * @param {number} limit 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    measurementsGetRaw(requestParameters: MeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>>;

    /**
     */
    measurementsGet(limit: number, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    organizationsOrganizationIdDevicesIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdDevicesIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdDevicesIdMeasurementsGet(limit: number, organizationId: string, id: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {AddNovaMaxMeasurementsListHcpModel} [AddNovaMaxMeasurementsListHcpModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    organizationsOrganizationIdHcpsIdMeasurementsBatchPostRaw(requestParameters: OrganizationsOrganizationIdHcpsIdMeasurementsBatchPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddMeasurementsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdMeasurementsBatchPost(organizationId: string, id: string, AddNovaMaxMeasurementsListHcpModel?: AddNovaMaxMeasurementsListHcpModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddMeasurementsResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {AddNovaMaxMeasurementHcpModel} [AddNovaMaxMeasurementHcpModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    organizationsOrganizationIdHcpsIdMeasurementsPostRaw(requestParameters: OrganizationsOrganizationIdHcpsIdMeasurementsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdMeasurementsPost(organizationId: string, id: string, AddNovaMaxMeasurementHcpModel?: AddNovaMaxMeasurementHcpModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    organizationsOrganizationIdHcpsIdPatientsMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsIdPatientsMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdHcpsIdPatientsMeasurementsGet(limit: number, organizationId: string, id: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    organizationsOrganizationIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdMeasurementsGet(limit: number, organizationId: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {AddNovaMaxMeasurementsListPatientModel} [AddNovaMaxMeasurementsListPatientModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    organizationsOrganizationIdPatientsIdMeasurementsBatchPostRaw(requestParameters: OrganizationsOrganizationIdPatientsIdMeasurementsBatchPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddMeasurementsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdMeasurementsBatchPost(organizationId: string, id: string, AddNovaMaxMeasurementsListPatientModel?: AddNovaMaxMeasurementsListPatientModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddMeasurementsResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    organizationsOrganizationIdPatientsIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdMeasurementsGet(limit: number, organizationId: string, id: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {AddNovaMaxMeasurementPatientModel} [AddNovaMaxMeasurementPatientModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApiInterface
     */
    organizationsOrganizationIdPatientsIdMeasurementsPostRaw(requestParameters: OrganizationsOrganizationIdPatientsIdMeasurementsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdPatientsIdMeasurementsPost(organizationId: string, id: string, AddNovaMaxMeasurementPatientModel?: AddNovaMaxMeasurementPatientModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementResponseModelApiResponse>;

}

/**
 * 
 */
export class MeasurementApi extends runtime.BaseAPI implements MeasurementApiInterface {

    /**
     */
    async measurementsGetRaw(requestParameters: MeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling measurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/measurements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async measurementsGet(limit: number, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse> {
        const response = await this.measurementsGetRaw({ limit: limit, paginationToken: paginationToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdDevicesIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdDevicesIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdDevicesIdMeasurementsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdDevicesIdMeasurementsGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdDevicesIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/devices/{id}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdDevicesIdMeasurementsGet(limit: number, organizationId: string, id: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdDevicesIdMeasurementsGetRaw({ limit: limit, organizationId: organizationId, id: id, paginationToken: paginationToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdMeasurementsBatchPostRaw(requestParameters: OrganizationsOrganizationIdHcpsIdMeasurementsBatchPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddMeasurementsResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdMeasurementsBatchPost.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdMeasurementsBatchPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}/measurements/batch`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNovaMaxMeasurementsListHcpModelToJSON(requestParameters.AddNovaMaxMeasurementsListHcpModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddMeasurementsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdMeasurementsBatchPost(organizationId: string, id: string, AddNovaMaxMeasurementsListHcpModel?: AddNovaMaxMeasurementsListHcpModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddMeasurementsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdMeasurementsBatchPostRaw({ organizationId: organizationId, id: id, AddNovaMaxMeasurementsListHcpModel: AddNovaMaxMeasurementsListHcpModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdMeasurementsPostRaw(requestParameters: OrganizationsOrganizationIdHcpsIdMeasurementsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdMeasurementsPost.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdMeasurementsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNovaMaxMeasurementHcpModelToJSON(requestParameters.AddNovaMaxMeasurementHcpModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdMeasurementsPost(organizationId: string, id: string, AddNovaMaxMeasurementHcpModel?: AddNovaMaxMeasurementHcpModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdMeasurementsPostRaw({ organizationId: organizationId, id: id, AddNovaMaxMeasurementHcpModel: AddNovaMaxMeasurementHcpModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdPatientsMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdHcpsIdPatientsMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdHcpsIdPatientsMeasurementsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdHcpsIdPatientsMeasurementsGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdHcpsIdPatientsMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/hcps/{id}/patients/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdHcpsIdPatientsMeasurementsGet(limit: number, organizationId: string, id: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdHcpsIdPatientsMeasurementsGetRaw({ limit: limit, organizationId: organizationId, id: id, paginationToken: paginationToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdMeasurementsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdMeasurementsGet(limit: number, organizationId: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdMeasurementsGetRaw({ limit: limit, organizationId: organizationId, paginationToken: paginationToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdMeasurementsBatchPostRaw(requestParameters: OrganizationsOrganizationIdPatientsIdMeasurementsBatchPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddMeasurementsResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdMeasurementsBatchPost.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdMeasurementsBatchPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}/measurements/batch`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNovaMaxMeasurementsListPatientModelToJSON(requestParameters.AddNovaMaxMeasurementsListPatientModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddMeasurementsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdMeasurementsBatchPost(organizationId: string, id: string, AddNovaMaxMeasurementsListPatientModel?: AddNovaMaxMeasurementsListPatientModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddMeasurementsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdMeasurementsBatchPostRaw({ organizationId: organizationId, id: id, AddNovaMaxMeasurementsListPatientModel: AddNovaMaxMeasurementsListPatientModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdMeasurementsGetRaw(requestParameters: OrganizationsOrganizationIdPatientsIdMeasurementsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdPatientsIdMeasurementsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdMeasurementsGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdMeasurementsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdMeasurementsGet(limit: number, organizationId: string, id: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdMeasurementsGetRaw({ limit: limit, organizationId: organizationId, id: id, paginationToken: paginationToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdMeasurementsPostRaw(requestParameters: OrganizationsOrganizationIdPatientsIdMeasurementsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MeasurementResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdPatientsIdMeasurementsPost.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdPatientsIdMeasurementsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/patients/{id}/measurements`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNovaMaxMeasurementPatientModelToJSON(requestParameters.AddNovaMaxMeasurementPatientModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdPatientsIdMeasurementsPost(organizationId: string, id: string, AddNovaMaxMeasurementPatientModel?: AddNovaMaxMeasurementPatientModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MeasurementResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdPatientsIdMeasurementsPostRaw({ organizationId: organizationId, id: id, AddNovaMaxMeasurementPatientModel: AddNovaMaxMeasurementPatientModel }, initOverrides);
        return await response.value();
    }

}
