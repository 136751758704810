import { SingleMeasurement } from "../SingleMeasurement";
import { MeasurementTrendType, UacrStatusType } from "models/TestModels";
import { UACRRangeTable } from "components/MeasurementSideDetails/SideDetails/elements/UACRRangeTable";
import "./UacrResult.scss";

interface UacrResultProps {
  value?: number;
  date?: Date | string;
  trendType?: MeasurementTrendType;
  percent?: number;
  status: UacrStatusType | null;
  uacrType: "UACR" | "SQUACR";
}

export function UacrResult({ value, date, trendType, percent, status, uacrType }: UacrResultProps) {
  return (
    <div className="UacrResult">
      <SingleMeasurement
        measurementUnit={uacrType}
        value={value}
        date={date}
        trendType={trendType}
        percent={percent}
      />

      <UACRRangeTable orientation="vertical" status={status} />
    </div>
  );
}
