import { useActor } from "@xstate/react";
import { FilterSideModalState } from "components/AppTables/common/Filter/model";
import { TableContext } from "libs/ui/Table/model";
import { TableUtils } from "libs/ui/Table/utils";
import { useCallback } from "react";
import { Interpreter } from "xstate";

type TableServiceDataType<T extends Interpreter<TableContext<any, any>, any, any, any, any>> =
  T extends Interpreter<TableContext<infer U, any>, any, any, any, any> ? U : never;
interface GenericPageTableContext<
  TableService extends Interpreter<TableContext<any, any>, any, any, any, any>,
  AvatarState,
  FilterSideModalS extends FilterSideModalState<any>,
> {
  tableService: TableService;
  avatarsState?: AvatarState;
  filterSideModelState?: FilterSideModalS;
}

export function usePagesParentTableConfig<
  TableService extends Interpreter<TableContext<any, any>, any, any, any, any>,
  AvatarState,
  FilterSideModalS extends FilterSideModalState<any>,
>(
  tableContext: GenericPageTableContext<TableService, AvatarState, FilterSideModalS>,
  entity: keyof Omit<TableServiceDataType<TableService>, "limit" | "paginationToken">,
) {
  const [state, send] = useActor(tableContext.tableService);

  const hasData: boolean =
    state.context.data &&
    Array.isArray(state.context.data[entity]) &&
    state.context.data[entity].length > 0;
  const hasFilters = TableUtils.areFiltersApplied(state.context);

  const isTableEmpty = state.matches("loaded") && !hasData && !hasFilters;

  const onFilterToggle = useCallback(
    () => tableContext.filterSideModelState?.openFilterModal(),
    [tableContext],
  );

  const onSearchChange = useCallback(
    (value: string) =>
      send([{ type: "UPDATE_FILTERS", value: { ...state.context.filters, contains: value } }]),
    [send, state.context.filters],
  );

  return { hasFilters, isTableEmpty, onFilterToggle, onSearchChange };
}
