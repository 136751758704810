import { Card } from "libs/ui/Card";
import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { useCallback } from "react";
import { useOverviewAPIContext } from "../OverviewAPIContext";
import { RefreshFailedWidget } from "../RefreshFailedWidget";
import { WidgetLoader } from "../WidgetLoader";
import { EGFRResult } from "./EGFRResult";
import { HeatMapHeader } from "./HeatMapHeader";
import { HeatMapTable } from "./HeatMapTable";
import "./HeatMapWidget.scss";
import { Recommendation } from "./Recommendation";
import { SingleMeasurement } from "./SingleMeasurement";
import { UacrResult } from "./UacrResult";
interface Props {
  organizationId: string;
  patientId: string;
}

export function HeatMapWidget({ organizationId, patientId }: Readonly<Props>) {
  const {
    data: { HeatMap },
    getWidgetData,
    getHeatMapHistory,
  } = useOverviewAPIContext();

  const refreshWidgetData = useCallback(() => {
    getWidgetData(organizationId, patientId, ["HeatMap"]);
  }, [getWidgetData, organizationId, patientId]);

  const eGFRData = isLoaded(HeatMap) ? HeatMap.value?.egfrTest : null;
  const eGFRTest = isLoaded(HeatMap)
    ? eGFRData?.measurements.find(el => el.measurementType === "SerumCreatinine")?.item["Egfr"]
    : null;

  const UACRData = isLoaded(HeatMap)
    ? HeatMap.value?.semiQuantitativeUacrTest ?? HeatMap.value?.uacrTest
    : null;
  const UACRTest = isLoaded(HeatMap)
    ? HeatMap.value?.semiQuantitativeUacrTest
      ? UACRData?.measurements.find(el => el.measurementType === "SemiQuantitativeUACR")?.item[
          "SemiQuantitativeUacr"
        ]
      : UACRData?.measurements.find(el => el.measurementType === "UACR")?.item["Uacr"]
    : null;
  const UACRType = isLoaded(HeatMap)
    ? HeatMap.value?.semiQuantitativeUacrTest
      ? "SQUACR"
      : "UACR"
    : null;

  return (
    <div className="HeatMapWidget">
      {isLoading(HeatMap) || isDefaultModel(HeatMap) ? (
        <WidgetLoader />
      ) : (
        <Card className="HeatMapWidget__card">
          {isLoaded(HeatMap) ? (
            <>
              <HeatMapHeader
                heatMapData={HeatMap}
                getHeatMapHistory={getHeatMapHistory}
                eGFRDate={eGFRData?.measurementTime}
                UACRDate={UACRData?.measurementTime}
                organizationId={organizationId}
                patientId={patientId}
              />

              <div className="HeatMapWidget__body">
                <div className="HeatMapWidget__diagnosis">
                  <Recommendation risk={HeatMap.value?.risk} />

                  <SingleMeasurement
                    measurementUnit="eGFR"
                    value={eGFRTest?.Value}
                    date={eGFRData?.measurementTime}
                    trendType={eGFRTest?.TrendType}
                    percent={eGFRTest?.Percent}
                  />
                </div>

                <UacrResult
                  value={UACRTest?.Value}
                  date={UACRData?.measurementTime}
                  trendType={UACRTest?.TrendType}
                  percent={UACRTest?.Percent}
                  status={UACRTest?.Status}
                  uacrType={UACRType ?? "UACR"}
                />

                <EGFRResult value={eGFRTest?.Value} />

                <HeatMapTable
                  eGFRStatus={eGFRTest?.Status}
                  UACRStatus={UACRTest?.Status}
                  risk={HeatMap.value?.risk}
                />
              </div>
            </>
          ) : (
            <RefreshFailedWidget show={isFailed(HeatMap)} refreshCb={refreshWidgetData} />
          )}
        </Card>
      )}
    </div>
  );
}
