/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddUserRequestModel,
    AddUserRequestModelFromJSON,
    AddUserRequestModelToJSON,
    DeviceDetailsResponseModelApiResponse,
    DeviceDetailsResponseModelApiResponseFromJSON,
    DeviceDetailsResponseModelApiResponseToJSON,
    DeviceListResponseModelApiResponse,
    DeviceListResponseModelApiResponseFromJSON,
    DeviceListResponseModelApiResponseToJSON,
    DevicesFilter,
    DevicesFilterFromJSON,
    DevicesFilterToJSON,
    ErrorApiResponse,
    ErrorApiResponseFromJSON,
    ErrorApiResponseToJSON,
    HcpDetailsResponseModelApiResponse,
    HcpDetailsResponseModelApiResponseFromJSON,
    HcpDetailsResponseModelApiResponseToJSON,
    HcpListResponseModelApiResponse,
    HcpListResponseModelApiResponseFromJSON,
    HcpListResponseModelApiResponseToJSON,
    HcpsFilter,
    HcpsFilterFromJSON,
    HcpsFilterToJSON,
    PatientDetailsResponseModelApiResponse,
    PatientDetailsResponseModelApiResponseFromJSON,
    PatientDetailsResponseModelApiResponseToJSON,
    PatientListResponseModelApiResponse,
    PatientListResponseModelApiResponseFromJSON,
    PatientListResponseModelApiResponseToJSON,
    PatientsFilter,
    PatientsFilterFromJSON,
    PatientsFilterToJSON,
    ResendSignUpRequestModel,
    ResendSignUpRequestModelFromJSON,
    ResendSignUpRequestModelToJSON,
    StringApiResponse,
    StringApiResponseFromJSON,
    StringApiResponseToJSON,
    UpdateUserRequestModel,
    UpdateUserRequestModelFromJSON,
    UpdateUserRequestModelToJSON,
    UpdateUserStatusRequestModel,
    UpdateUserStatusRequestModelFromJSON,
    UpdateUserStatusRequestModelToJSON,
    UpdateUserStatusResponseModelApiResponse,
    UpdateUserStatusResponseModelApiResponseFromJSON,
    UpdateUserStatusResponseModelApiResponseToJSON,
    UserDetailsResponseModelApiResponse,
    UserDetailsResponseModelApiResponseFromJSON,
    UserDetailsResponseModelApiResponseToJSON,
    UserListResponseModelApiResponse,
    UserListResponseModelApiResponseFromJSON,
    UserListResponseModelApiResponseToJSON,
} from '../models';

export interface DevicesGetRequest {
    limit: number;
    paginationToken?: string;
    filters?: DevicesFilter;
}

export interface DevicesIdGetRequest {
    id: string;
}

export interface HcpsGetRequest {
    limit: number;
    paginationToken?: string;
    filters?: HcpsFilter;
}

export interface HcpsIdGetRequest {
    id: string;
}

export interface OrganizationsOrganizationIdAdministratorsGetRequest {
    limit: number;
    organizationId: string;
    paginationToken?: string;
}

export interface OrganizationsOrganizationIdAdministratorsIdDeleteRequest {
    id: string;
    rowVersion: number;
    organizationId: string;
}

export interface OrganizationsOrganizationIdAdministratorsIdGetRequest {
    id: string;
    organizationId: string;
}

export interface OrganizationsOrganizationIdAdministratorsIdPatchRequest {
    id: string;
    organizationId: string;
    UpdateUserStatusRequestModel?: UpdateUserStatusRequestModel;
}

export interface OrganizationsOrganizationIdAdministratorsIdPutRequest {
    id: string;
    organizationId: string;
    UpdateUserRequestModel?: UpdateUserRequestModel;
}

export interface OrganizationsOrganizationIdAdministratorsIdResendSignUpPostRequest {
    organizationId: string;
    id: string;
    ResendSignUpRequestModel: ResendSignUpRequestModel;
}

export interface OrganizationsOrganizationIdAdministratorsPostRequest {
    organizationId: string;
    AddUserRequestModel?: AddUserRequestModel;
}

export interface PatientsGetRequest {
    limit: number;
    paginationToken?: string;
    filters?: PatientsFilter;
}

export interface PatientsIdGetRequest {
    id: string;
}

/**
 * AdminApi - interface
 * 
 * @export
 * @interface AdminApiInterface
 */
export interface AdminApiInterface {
    /**
     * 
     * @param {number} limit 
     * @param {string} [paginationToken] 
     * @param {DevicesFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    devicesGetRaw(requestParameters: DevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceListResponseModelApiResponse>>;

    /**
     */
    devicesGet(limit: number, paginationToken?: string, filters?: DevicesFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    devicesIdGetRaw(requestParameters: DevicesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceDetailsResponseModelApiResponse>>;

    /**
     */
    devicesIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} [paginationToken] 
     * @param {HcpsFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    hcpsGetRaw(requestParameters: HcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpListResponseModelApiResponse>>;

    /**
     */
    hcpsGet(limit: number, paginationToken?: string, filters?: HcpsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    hcpsIdGetRaw(requestParameters: HcpsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpDetailsResponseModelApiResponse>>;

    /**
     */
    hcpsIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} organizationId 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    organizationsOrganizationIdAdministratorsGetRaw(requestParameters: OrganizationsOrganizationIdAdministratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserListResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdAdministratorsGet(limit: number, organizationId: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {number} rowVersion 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    organizationsOrganizationIdAdministratorsIdDeleteRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdAdministratorsIdDelete(id: string, rowVersion: number, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    organizationsOrganizationIdAdministratorsIdGetRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdAdministratorsIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdateUserStatusRequestModel} [UpdateUserStatusRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    organizationsOrganizationIdAdministratorsIdPatchRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateUserStatusResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdAdministratorsIdPatch(id: string, organizationId: string, UpdateUserStatusRequestModel?: UpdateUserStatusRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateUserStatusResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} organizationId 
     * @param {UpdateUserRequestModel} [UpdateUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    organizationsOrganizationIdAdministratorsIdPutRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdAdministratorsIdPut(id: string, organizationId: string, UpdateUserRequestModel?: UpdateUserRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {string} id 
     * @param {ResendSignUpRequestModel} ResendSignUpRequestModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    organizationsOrganizationIdAdministratorsIdResendSignUpPostRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdResendSignUpPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>>;

    /**
     */
    organizationsOrganizationIdAdministratorsIdResendSignUpPost(organizationId: string, id: string, ResendSignUpRequestModel: ResendSignUpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse>;

    /**
     * 
     * @param {string} organizationId 
     * @param {AddUserRequestModel} [AddUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    organizationsOrganizationIdAdministratorsPostRaw(requestParameters: OrganizationsOrganizationIdAdministratorsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>>;

    /**
     */
    organizationsOrganizationIdAdministratorsPost(organizationId: string, AddUserRequestModel?: AddUserRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse>;

    /**
     * 
     * @param {number} limit 
     * @param {string} [paginationToken] 
     * @param {PatientsFilter} [filters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    patientsGetRaw(requestParameters: PatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientListResponseModelApiResponse>>;

    /**
     */
    patientsGet(limit: number, paginationToken?: string, filters?: PatientsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientListResponseModelApiResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    patientsIdGetRaw(requestParameters: PatientsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientDetailsResponseModelApiResponse>>;

    /**
     */
    patientsIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientDetailsResponseModelApiResponse>;

}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI implements AdminApiInterface {

    /**
     */
    async devicesGetRaw(requestParameters: DevicesGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling devicesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async devicesGet(limit: number, paginationToken?: string, filters?: DevicesFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceListResponseModelApiResponse> {
        const response = await this.devicesGetRaw({ limit: limit, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async devicesIdGetRaw(requestParameters: DevicesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeviceDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling devicesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/devices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async devicesIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeviceDetailsResponseModelApiResponse> {
        const response = await this.devicesIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async hcpsGetRaw(requestParameters: HcpsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling hcpsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/hcps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async hcpsGet(limit: number, paginationToken?: string, filters?: HcpsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpListResponseModelApiResponse> {
        const response = await this.hcpsGetRaw({ limit: limit, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async hcpsIdGetRaw(requestParameters: HcpsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<HcpDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hcpsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/hcps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async hcpsIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<HcpDetailsResponseModelApiResponse> {
        const response = await this.hcpsIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsGetRaw(requestParameters: OrganizationsOrganizationIdAdministratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationsOrganizationIdAdministratorsGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsGet(limit: number, organizationId: string, paginationToken?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserListResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdAdministratorsGetRaw({ limit: limit, organizationId: organizationId, paginationToken: paginationToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdDeleteRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdAdministratorsIdDelete.');
        }

        if (requestParameters.rowVersion === null || requestParameters.rowVersion === undefined) {
            throw new runtime.RequiredError('rowVersion','Required parameter requestParameters.rowVersion was null or undefined when calling organizationsOrganizationIdAdministratorsIdDelete.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.rowVersion !== undefined) {
            queryParameters['rowVersion'] = requestParameters.rowVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdDelete(id: string, rowVersion: number, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdAdministratorsIdDeleteRaw({ id: id, rowVersion: rowVersion, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdGetRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdAdministratorsIdGet.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdGet(id: string, organizationId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdAdministratorsIdGetRaw({ id: id, organizationId: organizationId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdPatchRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UpdateUserStatusResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdAdministratorsIdPatch.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestModelToJSON(requestParameters.UpdateUserStatusRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserStatusResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdPatch(id: string, organizationId: string, UpdateUserStatusRequestModel?: UpdateUserStatusRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UpdateUserStatusResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdAdministratorsIdPatchRaw({ id: id, organizationId: organizationId, UpdateUserStatusRequestModel: UpdateUserStatusRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdPutRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdAdministratorsIdPut.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestModelToJSON(requestParameters.UpdateUserRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdPut(id: string, organizationId: string, UpdateUserRequestModel?: UpdateUserRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdAdministratorsIdPutRaw({ id: id, organizationId: organizationId, UpdateUserRequestModel: UpdateUserRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdResendSignUpPostRaw(requestParameters: OrganizationsOrganizationIdAdministratorsIdResendSignUpPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StringApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsIdResendSignUpPost.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationsOrganizationIdAdministratorsIdResendSignUpPost.');
        }

        if (requestParameters.ResendSignUpRequestModel === null || requestParameters.ResendSignUpRequestModel === undefined) {
            throw new runtime.RequiredError('ResendSignUpRequestModel','Required parameter requestParameters.ResendSignUpRequestModel was null or undefined when calling organizationsOrganizationIdAdministratorsIdResendSignUpPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators/{id}/resendSignUp`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendSignUpRequestModelToJSON(requestParameters.ResendSignUpRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsIdResendSignUpPost(organizationId: string, id: string, ResendSignUpRequestModel: ResendSignUpRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StringApiResponse> {
        const response = await this.organizationsOrganizationIdAdministratorsIdResendSignUpPostRaw({ organizationId: organizationId, id: id, ResendSignUpRequestModel: ResendSignUpRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsPostRaw(requestParameters: OrganizationsOrganizationIdAdministratorsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserDetailsResponseModelApiResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling organizationsOrganizationIdAdministratorsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organizations/{organizationId}/administrators`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddUserRequestModelToJSON(requestParameters.AddUserRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async organizationsOrganizationIdAdministratorsPost(organizationId: string, AddUserRequestModel?: AddUserRequestModel, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserDetailsResponseModelApiResponse> {
        const response = await this.organizationsOrganizationIdAdministratorsPostRaw({ organizationId: organizationId, AddUserRequestModel: AddUserRequestModel }, initOverrides);
        return await response.value();
    }

    /**
     */
    async patientsGetRaw(requestParameters: PatientsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientListResponseModelApiResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling patientsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/patients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientListResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async patientsGet(limit: number, paginationToken?: string, filters?: PatientsFilter, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientListResponseModelApiResponse> {
        const response = await this.patientsGetRaw({ limit: limit, paginationToken: paginationToken, filters: filters }, initOverrides);
        return await response.value();
    }

    /**
     */
    async patientsIdGetRaw(requestParameters: PatientsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PatientDetailsResponseModelApiResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patientsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDetailsResponseModelApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async patientsIdGet(id: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PatientDetailsResponseModelApiResponse> {
        const response = await this.patientsIdGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

}
