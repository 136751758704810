/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';
import {
    DocumentResponseModel,
    DocumentResponseModelFromJSON,
    DocumentResponseModelFromJSONTyped,
    DocumentResponseModelToJSON,
} from './DocumentResponseModel';

/**
 * 
 * @export
 * @interface DocumentResponseModelApiResponse
 */
export interface DocumentResponseModelApiResponse {
    /**
     * 
     * @type {DocumentResponseModel}
     * @memberof DocumentResponseModelApiResponse
     */
    data?: DocumentResponseModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof DocumentResponseModelApiResponse
     */
    errors?: Array<ApiError> | null;
}

export function DocumentResponseModelApiResponseFromJSON(json: any): DocumentResponseModelApiResponse {
    return DocumentResponseModelApiResponseFromJSONTyped(json, false);
}

export function DocumentResponseModelApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentResponseModelApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : DocumentResponseModelFromJSON(json['Data']),
        'errors': !exists(json, 'Errors') ? undefined : (json['Errors'] === null ? null : (json['Errors'] as Array<any>).map(ApiErrorFromJSON)),
    };
}

export function DocumentResponseModelApiResponseToJSON(value?: DocumentResponseModelApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': DocumentResponseModelToJSON(value.data),
        'Errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(ApiErrorToJSON)),
    };
}

