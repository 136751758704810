import { Dispatch, SetStateAction, useCallback } from "react";
import { FormFields } from "./FormFields";
import { OrganizationModel, OrganizationModelKey } from "./model";
import { CurrentFormMode } from "models/FormModeModels";

interface OrganizationProps {
  organizationData: OrganizationModel;
  setOrganizationData: Dispatch<SetStateAction<OrganizationModel>>;
  onSubmit: () => void;
  readonly editableFields?: OrganizationModelKey[];
  formMode: CurrentFormMode;
  loading?: boolean;
}

export function Organization({
  organizationData,
  setOrganizationData,
  onSubmit,
  editableFields,
  formMode,
  loading,
}: OrganizationProps) {
  const onChange = useCallback(
    (value: string | undefined, inputKey: OrganizationModelKey) => {
      setOrganizationData(prevValue => {
        return {
          ...prevValue,
          [inputKey]: value,
        };
      });
    },
    [setOrganizationData],
  );

  return (
    <FormFields
      formMode={formMode}
      onChange={onChange}
      onSubmit={onSubmit}
      organizationData={organizationData}
      editableFields={editableFields}
      loading={loading}
    />
  );
}
