import { useFloating, autoPlacement, autoUpdate } from "@floating-ui/react-dom";
import classNames from "classnames";
import { PropsWithChildren, useEffect, useLayoutEffect } from "react";
import { DataAttributeKey } from "../models/common";
import "./Dropdown.scss";

export interface DropdownProps {
  show: boolean;
  targetElement: () => Element | null;
  className?: string;
  floatingProps?: Parameters<typeof useFloating>[0];
  [dataAttribute: DataAttributeKey]: any;
}

export function Dropdown({
  show,
  className,
  targetElement,
  children,
  floatingProps,
  ...props
}: PropsWithChildren<DropdownProps>) {
  const { x, y, reference, floating, strategy, update } = useFloating(
    floatingProps ?? {
      strategy: "absolute",
      placement: "bottom",
      middleware: [autoPlacement({ allowedPlacements: ["top", "bottom"] })],
      whileElementsMounted: autoUpdate,
    },
  );

  useEffect(() => {
    if (!show || !targetElement()) {
      return;
    }

    const onWindowResize = () => {
      requestAnimationFrame(update);
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [reference, show, targetElement, update]);

  useLayoutEffect(() => {
    if (show && targetElement && targetElement()) {
      reference(targetElement());
    }
  }, [reference, show, targetElement]);

  return (
    <div
      role={"listbox"}
      ref={floating}
      style={{
        position: strategy,
        top: y ?? "",
        left: x ?? "",
        display: show ? "" : "none",
      }}
      className={classNames("UI-Components Dropdown", className)}
      {...props}
    >
      {children}
    </div>
  );
}
