import { Auth } from "aws-amplify";
import { UserRoleType } from "models/PersonModels/Roles";

function isAdmin(role?: UserRoleType) {
  return role === "Admin";
}

function isPartner(role?: UserRoleType) {
  return role === "Partner";
}

function isHCP(role?: UserRoleType) {
  return role === "Hcp";
}

function isPatient(role?: UserRoleType) {
  return role === "Patient";
}

export async function getCurrentUserData() {
  const tokenPayload = (await Auth.currentSession()).getIdToken().payload;

  const userRole = tokenPayload ? (tokenPayload["cognito:groups"][0] as UserRoleType) : undefined;
  const organizationId: string = tokenPayload ? tokenPayload["custom:organizationId"] : undefined;
  const currentUserId: string = tokenPayload ? tokenPayload["custom:userId"] : undefined;
  const tenantId: string = tokenPayload ? tokenPayload["custom:tenantId"] : undefined;
  const userRegistryNumber = tokenPayload
    ? (tokenPayload["custom:userRegistryNumber"] as string)
    : undefined;

  const hasOrganizationLevelRights = isAdmin(userRole) || isPartner(userRole);

  return {
    isAdmin: isAdmin(userRole),
    isHcp: isHCP(userRole),
    isPartner: isPartner(userRole),
    isPatient: isPatient(userRole),
    tenantId,
    hasOrganizationLevelRights,
    hasAnyCreationRights: hasOrganizationLevelRights || isHCP(userRole),
    organizationId,
    currentUserId,
    userRegistryNumber,
    userRole,
  };
}
