/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssignedToUserModel,
    AssignedToUserModelFromJSON,
    AssignedToUserModelFromJSONTyped,
    AssignedToUserModelToJSON,
} from './AssignedToUserModel';
import {
    DeviceStatusType,
    DeviceStatusTypeFromJSON,
    DeviceStatusTypeFromJSONTyped,
    DeviceStatusTypeToJSON,
} from './DeviceStatusType';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import {
    ManufacturerType,
    ManufacturerTypeFromJSON,
    ManufacturerTypeFromJSONTyped,
    ManufacturerTypeToJSON,
} from './ManufacturerType';

/**
 * 
 * @export
 * @interface DeviceDetailsResponseModel
 */
export interface DeviceDetailsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof DeviceDetailsResponseModel
     */
    id: string;
    /**
     * 
     * @type {DeviceType}
     * @memberof DeviceDetailsResponseModel
     */
    deviceType: DeviceType;
    /**
     * 
     * @type {ManufacturerType}
     * @memberof DeviceDetailsResponseModel
     */
    manufacturerType: ManufacturerType;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetailsResponseModel
     */
    serialNumber: string;
    /**
     * 
     * @type {DeviceStatusType}
     * @memberof DeviceDetailsResponseModel
     */
    status: DeviceStatusType;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetailsResponseModel
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetailsResponseModel
     */
    organizationName: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDetailsResponseModel
     */
    rowVersion?: number | null;
    /**
     * 
     * @type {AssignedToUserModel}
     * @memberof DeviceDetailsResponseModel
     */
    assignedTo?: AssignedToUserModel;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetailsResponseModel
     */
    uniqueId: string;
}

export function DeviceDetailsResponseModelFromJSON(json: any): DeviceDetailsResponseModel {
    return DeviceDetailsResponseModelFromJSONTyped(json, false);
}

export function DeviceDetailsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceDetailsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deviceType': DeviceTypeFromJSON(json['DeviceType']),
        'manufacturerType': ManufacturerTypeFromJSON(json['ManufacturerType']),
        'serialNumber': json['SerialNumber'],
        'status': DeviceStatusTypeFromJSON(json['Status']),
        'organizationId': json['OrganizationId'],
        'organizationName': json['OrganizationName'],
        'rowVersion': !exists(json, 'RowVersion') ? undefined : json['RowVersion'],
        'assignedTo': !exists(json, 'AssignedTo') ? undefined : AssignedToUserModelFromJSON(json['AssignedTo']),
        'uniqueId': json['UniqueId'],
    };
}

export function DeviceDetailsResponseModelToJSON(value?: DeviceDetailsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'DeviceType': DeviceTypeToJSON(value.deviceType),
        'ManufacturerType': ManufacturerTypeToJSON(value.manufacturerType),
        'SerialNumber': value.serialNumber,
        'Status': DeviceStatusTypeToJSON(value.status),
        'OrganizationId': value.organizationId,
        'OrganizationName': value.organizationName,
        'RowVersion': value.rowVersion,
        'AssignedTo': AssignedToUserModelToJSON(value.assignedTo),
        'UniqueId': value.uniqueId,
    };
}

