import { useAuthenticator } from "@aws-amplify/ui-react";
import { isAdmin, isPartner, isPatient, isHCP } from "../helpers/roles";
import { UserRoleType } from "models/PersonModels";

export function useCurrentUserData() {
  const { user } = useAuthenticator(context => [context.user]);
  const tokenPayload = user.getSignInUserSession()?.getIdToken().payload;

  const userRole = tokenPayload ? (tokenPayload["cognito:groups"][0] as UserRoleType) : "Patient";
  const organizationId = tokenPayload ? (tokenPayload["custom:organizationId"] as string) : "";
  const currentUserId = tokenPayload ? (tokenPayload["custom:userId"] as string) : "";
  const userRegistryNumber = tokenPayload
    ? (tokenPayload["custom:userRegistryNumber"] as string)
    : undefined;

  return {
    isAdmin: isAdmin(userRole),
    isPartner: isPartner(userRole),
    isPatient: isPatient(userRole),
    isHcp: isHCP(userRole),
    userRole,
    organizationId,
    currentUserId,
    userRegistryNumber,
  };
}
