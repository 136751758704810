/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NovaMaxMeasurementData,
    NovaMaxMeasurementDataFromJSON,
    NovaMaxMeasurementDataFromJSONTyped,
    NovaMaxMeasurementDataToJSON,
} from './NovaMaxMeasurementData';

/**
 * 
 * @export
 * @interface AddNovaMaxMeasurementPatientRequestModel
 */
export interface AddNovaMaxMeasurementPatientRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AddNovaMaxMeasurementPatientRequestModel
     */
    deviceSerialNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof AddNovaMaxMeasurementPatientRequestModel
     */
    measurementTime: Date;
    /**
     * 
     * @type {NovaMaxMeasurementData}
     * @memberof AddNovaMaxMeasurementPatientRequestModel
     */
    measurementData: NovaMaxMeasurementData;
}

export function AddNovaMaxMeasurementPatientRequestModelFromJSON(json: any): AddNovaMaxMeasurementPatientRequestModel {
    return AddNovaMaxMeasurementPatientRequestModelFromJSONTyped(json, false);
}

export function AddNovaMaxMeasurementPatientRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNovaMaxMeasurementPatientRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceSerialNumber': json['DeviceSerialNumber'],
        'measurementTime': (new Date(json['MeasurementTime'])),
        'measurementData': NovaMaxMeasurementDataFromJSON(json['MeasurementData']),
    };
}

export function AddNovaMaxMeasurementPatientRequestModelToJSON(value?: AddNovaMaxMeasurementPatientRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DeviceSerialNumber': value.deviceSerialNumber,
        'MeasurementTime': (value.measurementTime.toISOString()),
        'MeasurementData': NovaMaxMeasurementDataToJSON(value.measurementData),
    };
}

