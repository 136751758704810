import { hasFilter } from "components/AppTables/utils";
import { EmptyStateIcon } from "components/EmptyStateIcon";
import { EmptyState } from "libs/ui";
import { TableUtils } from "libs/ui/Table/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useActor } from "@xstate/react";
import { BaseTable } from "../../AppTables/common/Table/BaseTable";
import { BodyRows } from "./BodyRows";
import {
  OrganizationsTableColumns,
  UIOrganizationFilters,
  useOrganizationsTableStateContext,
} from "./context";
import { OrganizationsTableFilter } from "./OrganizationsTableFilter";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";

export function OrganizationsTable() {
  const { t } = useTranslation("translation", { keyPrefix: "OrganizationsTable" });
  const { tableService, filterSideModelState } = useOrganizationsTableStateContext();
  const [state] = useActor(tableService);
  const hasFilters = TableUtils.areFiltersApplied(state.context);
  const noTableData = state.matches("loaded") && !state.context.data.items.length && !hasFilters;
  const { appConfig } = useGlobalConfigContext();

  const appliedColumns: OrganizationsTableColumns[] = useMemo(
    () => ["name", "phone", "address", "countries"],
    [],
  );

  const columns = useMemo(
    () =>
      appliedColumns.map(name => ({
        label: t(`columns.${name}`),
        name,
      })),
    [appliedColumns, t],
  );

  const resolveShowFilterOption = (columnName: string) => {
    return hasFilter(
      columnName,
      NotNullOrUndefined(appConfig?.components.tables.Organization.filterOptions),
    );
  };

  const onOpenFilterModal = (value: any) => filterSideModelState.openFilterModal(value);

  const resolveFilterApplied = (value: string) =>
    !!state.context.filters?.[value as keyof UIOrganizationFilters]?.length;

  if (noTableData) {
    return (
      <EmptyState title={t("emptyTitle")} description={t("emptyDescription")}>
        <EmptyStateIcon variant="HCP" />
      </EmptyState>
    );
  }

  return (
    <>
      <BaseTable
        tableService={tableService}
        className="OrganizationsTable"
        columns={columns}
        onOpenFilterModal={onOpenFilterModal}
        resolveFilterApplied={resolveFilterApplied}
        resolveShowFilterOption={resolveShowFilterOption}
        viewTableSelector={true}
      >
        <BodyRows appliedColumns={appliedColumns} tableService={tableService} />
      </BaseTable>
      <OrganizationsTableFilter
        tableService={tableService}
        filterSideModelState={filterSideModelState}
      />
    </>
  );
}
