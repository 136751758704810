import { ChangeEvent, useCallback, useEffect, useState } from "react";
import "./SearchField.scss";
import { useTranslation } from "react-i18next";
import { useDebounce } from "utils/hooks/useDebounce";
import { Input } from "libs/ui/Input";
import { Icon } from "libs/ui/Icon";

interface SearchFieldProps {
  initValue?: string;
  onInputChange?: (value: string) => void;
  debounceTime?: number;
}

export function SearchField({ onInputChange, initValue, debounceTime = 500 }: SearchFieldProps) {
  const { t } = useTranslation("translation", { keyPrefix: "SearchField" });
  const [value, setValue] = useState(initValue);
  //? TODO, maybe we could use https://xstate.js.org/docs/guides/delays.html
  const [debounce] = useDebounce();

  const setInitValue = useCallback(() => setValue(initValue), [initValue]);

  useEffect(() => {
    setInitValue();
  }, [setInitValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: newVal } = e.target;

    setValue(newVal);

    debounce(() => onInputChange?.(newVal), debounceTime);
  };

  const onClear = () => {
    setValue("");

    debounce(() => onInputChange?.(""), debounceTime);
  };

  return (
    <div className="SearchField" data-testid="search-field">
      <Input
        value={value}
        onChange={onChange}
        label={t("label")}
        inputIcon={{ icon: "Search", iconPosition: "leading" }}
      />
      {!!value && <Icon onClick={onClear} icon="Close" className="SearchField__icon" />}
    </div>
  );
}
