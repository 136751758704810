// import AvatarGroup from "components/PageTemplate/DetailsPage/AvatarGroup"; //TODO NEW_BACKEND
import { CarnaApiEvent } from "config/apiEvent";
import { toastStore } from "config/toast";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { forwardRef, useImperativeHandle, useState } from "react";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { useOnEventStatusSubscribe } from "utils/hooks/useOnEventStatusSubscribe";
import { useTranslation } from "react-i18next";
import { CreateNewSideModal } from "../common/CreateNewSideModal";
import { AddModalForwardedProps, AddModalProps } from "../model";
import { HCPModel } from "components/Forms/HCP/model";
import { HCPFormUtils } from "components/Forms/HCP/HCPFormUtils";
import * as Form from "components/Forms/HCP";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { AddHcpRequestModelForStripping } from "config/binding";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";
import { GlobalConfig } from "utils/createGlobalConfigStore";
import { InitialValuesModel } from "models/PersonModels";
import { DISABLE_FORM_EDITING, EVERY_FORM_FIELD_EDITABLE } from "components/Forms/model";

function getInitialValues(
  appConfig: GlobalConfig,
  initialValuesAsProps?: Partial<InitialValuesModel>,
): HCPModel {
  if (initialValuesAsProps) {
    return {
      ...appConfig.forms.defaultValues.hcp,
      organizationId: initialValuesAsProps.organizationId ?? "",
      organizationName: initialValuesAsProps.organizationName,
    } satisfies HCPModel;
  }

  return appConfig.forms.defaultValues.hcp;
}

export const HCP = forwardRef<AddModalForwardedProps, AddModalProps>(
  ({ onSuccessCb, initialValuesAsProps }, ref) => {
    const { t } = useTranslation("modals", { keyPrefix: "AddModal" });
    const [onEvent, inLoadingState] = useOnEventStatusSubscribe();
    const { appConfig } = useGlobalConfigContext();
    //const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [, /*imageData*/ setImageData] = useState<string | undefined>();
    const [HCPData, setHCPData] = useState<HCPModel>(
      getInitialValues(NotNullOrUndefined(appConfig), initialValuesAsProps),
    );

    const resetAndClose = () => {
      setImageData(undefined);
      setHCPData(getInitialValues(NotNullOrUndefined(appConfig), initialValuesAsProps));
      setShowModal(false);
    };

    const areFieldsReady = HCPFormUtils.getFieldsReady(HCPData, "Add", appConfig);
    const submitDisabled = !areFieldsReady || appConfig === undefined || inLoadingState;

    const onSubmit = (e?: React.FormEvent) => {
      e?.preventDefault();

      if (submitDisabled) {
        return;
      }

      onEvent(
        CarnaApiEvent.Hcp.post(
          { organizationId: HCPData.organizationId ?? "", addHcpRequestModel: { ...HCPData } },
          stripNetworkBodyWith(AddHcpRequestModelForStripping),
        ),
        {
          complete() {
            toastStore.toast.success({ msg: t("successAddHCPToastText"), expire: 5000 });
            resetAndClose();
            if (onSuccessCb) {
              onSuccessCb();
            }
            // if (imageData) {
            //   CarnaApi.DocumentApi.organizationsOrganizationIdUsersIdUploadImagePut(
            //     result.data?.organizationId ?? "",
            //     result.data?.id ?? "",
            //     {
            //       userRoleType: "Hcp",
            //       image: imageData,
            //     },
            //   )
            //     .then(onSuccessCallback)
            //     .catch(() => {
            //       toastStore.toast.error({ msg: t("failedToUploadPicture") });
            //       onSuccessCallback();

            //       navigate(
            //         generatePath(mainRoutePaths.hcpDetails, {
            //           hcpId: result.data?.id ?? "",
            //           organizationId: result.data?.organizationId ?? "",
            //         }),
            //       );
            //     });

            //   return;
            // }
          },
          error(err) {
            switch (err.code) {
              case "BE_ERROR":
                showBeFieldErrors(err.err, t("errorAddHCPToastText"));
                break;

              case "ACTION_FAILED":
                toastStore.toast.error({ msg: t("errorAddHCPToastText") });
                break;
              case "STATUS_QUERY_ERROR":
                resetAndClose();
                toastStore.toast.error({ msg: t("errorFailedToUpdateHCPTable") });
            }
          },
        },
      );
    };

    const toggleModal = () => setShowModal(prevValue => !prevValue);

    useImperativeHandle(
      ref,
      () => ({
        toggleModal,
      }),
      [],
    );

    return (
      <CreateNewSideModal
        title={t("addHCPTitle")}
        submitButton={{
          onClick: onSubmit,
          text: t("buttonAdd"),
          disabled: submitDisabled,
        }}
        show={showModal}
        closeModal={{
          onClick: resetAndClose,
          disabled: inLoadingState,
        }}
        isLoading={inLoadingState}
      >
        <Form.HCP
          formMode="Add"
          entityData={HCPData}
          setEntityData={setHCPData}
          onSubmit={onSubmit}
          editableFields={inLoadingState ? DISABLE_FORM_EDITING : EVERY_FORM_FIELD_EDITABLE}
        >
          {/*
          //TODO NEW_BACKEND
        <AvatarGroup
          loading={false}
          editing={true}
          firstName={undefined}
          avatarForCreate={imageData === undefined}
          imageSrc={imageData}
          onSave={(image?: string) => {
            const [, , imgData] = image?.match(/data:(.*);base64,(.*)/) ?? [];
            return Promise.resolve(setImageData(imgData));
          }}
        /> */}
        </Form.HCP>
      </CreateNewSideModal>
    );
  },
);
