import { Avatar } from "libs/ui";
import "./PatientInfoHeader.scss";

interface PatientInfoHeaderProps {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  registryNumber: string | null | undefined;
  avatar?: string;
}

export function PatientInfoHeader({
  firstName,
  lastName,
  avatar,
  registryNumber,
}: Readonly<PatientInfoHeaderProps>) {
  return (
    <div className="PatientInfoHeader">
      <Avatar
        // TODO NEW_BACKEND
        src={avatar}
        firstName={firstName}
        avatarLoading={false}
        size="large"
        avatarType="Patient"
      />
      <div className="PatientInfoHeader__info">
        <h4 className="PatientInfoHeader__name">{firstName?.concat(` ${lastName}`)}</h4>

        {registryNumber ? (
          <p className="PatientInfoHeader__registryNumber">{registryNumber}</p>
        ) : null}
      </div>
    </div>
  );
}
