import { useInterpret } from "@xstate/react";
import { FEHcpResponseModel } from "models/PersonModels";
import { HCPModel } from "components/Forms/HCP/model";
import { NEW_BACKEND_NULL } from "config/NEW_BACKEND";
import { CarnaApiEvent } from "config/apiEvent";
import { CarnaApiQuery } from "config/apiQuery";
import { toastStore } from "config/toast";
import i18n from "i18next";
import { useCallback, useMemo } from "react";
import { getRowVersion } from "utils/helpers/getRowVersion";
import {
  ConfirmStatusEvent,
  DetailsPageStateContext,
  makeDetailsPageStateMachine,
  SaveEvent,
} from "utils/machines/pages/details/makeDetailsPageStateMachine";
import { DetailsPageServiceList } from "utils/machines/pages/details/model";

import { assign } from "xstate";
import {
  OnEventStatusSubscribeError,
  waitForQueryService,
} from "utils/hooks/useOnEventStatusSubscribe";
import { firstValueFrom } from "rxjs";
import { stripNetworkBodyWith } from "components/Forms/helper";
import { UpdateHcpRequestModelForStripping } from "config/binding";
import { showBeFieldErrors } from "utils/helpers/showBeFieldErrors";

function useApis(hcpId: string, selectedUserOrganizationId: string) {
  const getHcp = useCallback(
    () =>
      CarnaApiQuery.Hcps.getById({
        organizationId: selectedUserOrganizationId,
        hcpEntityId: hcpId,
      }),
    [selectedUserOrganizationId, hcpId],
  );

  const saveHcp = useCallback(
    async (
      _context: DetailsPageStateContext<NonNullable<FEHcpResponseModel>>,
      event: SaveEvent<HCPModel>,
    ) => {
      const putHCP = CarnaApiEvent.Hcp.put(
        {
          organizationId: selectedUserOrganizationId,
          userEntityId: hcpId,
          updateHcpRequestModel: {
            ...event.value,
            rowVersion: event.value.rowVersion,
          },
        },
        stripNetworkBodyWith(UpdateHcpRequestModelForStripping),
      );

      await firstValueFrom(waitForQueryService(putHCP));

      const result = await putHCP;

      return {
        ...event.value,
        ...getRowVersion(result),
      } as FEHcpResponseModel;
    },

    [selectedUserOrganizationId, hcpId],
  );

  const saveHcpStatus = useCallback(
    (
      context: DetailsPageStateContext<NonNullable<FEHcpResponseModel>>,
      event: ConfirmStatusEvent,
    ): Promise<FEHcpResponseModel> => {
      return NEW_BACKEND_NULL;
    },

    [],
  );

  return {
    fetchHCPData: getHcp,
    // fetchAvatar,
    saveHcp,
    saveHcpStatus,
  } as const;
}

const successToast = () =>
  toastStore.pushToast({
    expire: 5000,
    type: "success",
    msg: i18n.t("HCPDetails.successPutToast", { ns: "translation" }),
  });

const errorPutToast = (_context: any, event: any) => {
  const err = event.data as OnEventStatusSubscribeError;

  if (err.type === "OnEventStatusSubscribeError") {
    switch (err.code) {
      case "BE_ERROR":
        showBeFieldErrors(err.err, i18n.t("HCPDetails.errorPutToast", { ns: "translation" }));

        break;
      case "ACTION_FAILED":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("HCPDetails.errorPutToast", { ns: "translation" }),
        });
        break;
      case "STATUS_QUERY_ERROR":
        toastStore.pushToast({
          expire: 5000,
          type: "error",
          msg: i18n.t("HCPDetails.queryServiceError", { ns: "translation" }),
        });
    }
    return;
  }

  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("HCPDetails.errorPutToast", { ns: "translation" }),
  });
};

const errorFetchToast = () => {
  toastStore.pushToast({
    expire: 5000,
    type: "error",
    msg: i18n.t("HCPDetails.errorFetchToast", { ns: "translation" }),
  });
};

export function useMakeHcpPagesDetailState(hcpId: string, organizationId: string) {
  const { fetchHCPData, saveHcp, saveHcpStatus } = useApis(hcpId, organizationId);

  const hcpPageStateMachine = useMemo(
    () =>
      makeDetailsPageStateMachine<
        NonNullable<FEHcpResponseModel>,
        HCPModel,
        DetailsPageServiceList<typeof fetchHCPData, typeof saveHcp, typeof saveHcpStatus>
      >(),
    [],
  );

  const service = useInterpret(hcpPageStateMachine, {
    services: {
      saveData: saveHcp,
      fetchData: fetchHCPData,
      saveStatus: saveHcpStatus,
    },
    actions: {
      dataSaved: successToast,
      savingFailed: errorPutToast,
      failedToLoadData: errorFetchToast,
      loadSavedData: assign({
        data: (_context, event) => {
          return event.data;
        },
      }),
      loadEntityData: assign({
        data: (_context, event) => {
          return event.data;
        },
      }),
      updateSaveStatus: assign({
        data: (context, event) => {
          return {
            ...context.data,
            status: event.data.status ? "Active" : "Deactivated",
          } as FEHcpResponseModel;
        },
      }),
      refreshTable: () => {},
    },
  });

  return service;
}
