import { RouteValues } from "./model";
export * from "./model";

export const mainRoutePaths = {
  home: "/",
  dashboard: "/dashboard",
  devices: "/devices",
  deviceDetails: "/devices/:organizationId/:deviceId",
  hcp: "/hcp",
  hcpDetails: "/hcp/:organizationId/:hcpId",
  tests: "/tests/*",
  patients: "/patients",
  patientDetails: "/patients/:organizationId/:patientId/tests",
  patientGraphs: "/patients/:organizationId/:patientId/graph",
  patientOverview: "/patients/:organizationId/:patientId/overview",
  settings: "/settings",
} as const;

export const settingsRoutePaths = {
  globalPreferences: "/settings/global-preferences",
  login: "/settings/login",
  projectOrganizations: "/settings/project-organizations",
  personalInformation: "/settings/personal-information",
  securityAndPrivacy: "/settings/security-and-privacy",
  support: "/settings/support",
  userDetail: "/settings/user-management/:organizationId/:role/:userId",
  users: "/settings/user-management",
  organizations: "/settings/project-organizations",
  organizationDetails: "/settings/project-organizations/:organizationId/:firstTab",
  ruleEngine: "/settings/rule-engine",
  ruleEngineUserView: "/settings/rule-engine/user-view",
  ruleEngineLaboratory: "/settings/rule-engine/laboratory",
} as const;

export const policyPaths = {
  privacyPolicy: "/policy/privacy-policy",
  cookiePolicy: "/policy/cookie-policy",
  termsAndConditions: "/policy/terms-and-conditions",
} as const;

export const dashboardPaths = {
  dashboardMap: "/dashboard/map",
  dashboardStats: "/dashboard/stats/:organizationId/:patientId",
};

export const NOT_FOUND_ROUTE = "/not-found";

export const ROUTES = {
  ...mainRoutePaths,
  ...settingsRoutePaths,
  ...policyPaths,
  ...dashboardPaths,
} as const;

export const getTranslatedRouteObject = <T extends TFunction>(t: T): RouteValues =>
  ({
    [t("home")]: mainRoutePaths.home,
    [t("administrators")]: settingsRoutePaths.users,
    [t("organizations")]: settingsRoutePaths.organizations,
    [t("devices")]: mainRoutePaths.devices,
    [t("hcp")]: mainRoutePaths.hcp,
    [t("tests")]: mainRoutePaths.tests,
    [t("patients")]: mainRoutePaths.patients,
    [t("settings")]: mainRoutePaths.settings,
    [t("ruleEngine")]: settingsRoutePaths.ruleEngine,
    [t("ruleEngineUserView")]: settingsRoutePaths.ruleEngineUserView,
    [t("ruleEngineLaboratory")]: settingsRoutePaths.ruleEngineLaboratory,
    [t("globalPreferences")]: settingsRoutePaths.globalPreferences,
    [t("personalInformation")]: settingsRoutePaths.personalInformation,
    [t("login")]: settingsRoutePaths.login,
    [t("support")]: settingsRoutePaths.support,
    [t("securityAndPrivacy")]: settingsRoutePaths.securityAndPrivacy,
  }) as const;
