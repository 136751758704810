import { Outlet } from "react-router-dom";
import "./DashboardPage.scss";
import { DashboardPageNavigation } from "./DashboardPageNavigation";

export function DashboardPage() {
  return (
    <div className="DashboardPage">
      <DashboardPageNavigation />

      <div className="DashboardPage__content">
        <Outlet />
      </div>
    </div>
  );
}
