/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserGraphModel,
    UserGraphModelFromJSON,
    UserGraphModelFromJSONTyped,
    UserGraphModelToJSON,
} from './UserGraphModel';

/**
 * 
 * @export
 * @interface OrganizationPatientModel
 */
export interface OrganizationPatientModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationPatientModel
     */
    id: string;
    /**
     * 
     * @type {UserGraphModel}
     * @memberof OrganizationPatientModel
     */
    user: UserGraphModel;
}

export function OrganizationPatientModelFromJSON(json: any): OrganizationPatientModel {
    return OrganizationPatientModelFromJSONTyped(json, false);
}

export function OrganizationPatientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationPatientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'user': UserGraphModelFromJSON(json['User']),
    };
}

export function OrganizationPatientModelToJSON(value: OrganizationPatientModel): any {
    return {
        'Id': value.id,
        'User': UserGraphModelToJSON(value.user),
    };
}

