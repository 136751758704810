import classNames from "classnames";
import { Button } from "libs/ui";
import { Children, PropsWithChildren, useCallback, useState } from "react";
import "./MapFilterSection.scss";
import { useTranslation } from "react-i18next";

interface MapFilterSectionProps {
  title: string;
}

const MAX_CHILDREN_COUNT_BEFORE_FULL_EXPAND = 5;

export function MapFilterSection({ title, children }: PropsWithChildren<MapFilterSectionProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "DashboardPage.MapFilterSection" });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = useCallback(() => {
    if (showFullContent) {
      setShowFullContent(false);
    }
    setIsCollapsed(prevValue => !prevValue);
  }, [showFullContent]);

  const toggleFullContent = useCallback(() => setShowFullContent(prevValue => !prevValue), []);

  const renderShowAllOptionsButton =
    Children.count(children) > MAX_CHILDREN_COUNT_BEFORE_FULL_EXPAND;

  return (
    <div className="MapFilterSection">
      <div className="MapFilterSection__header">
        <p className="MapFilterSection__title">{title}</p>

        <Button
          onClick={toggleContent}
          buttonSize="small"
          buttonType="transparent"
          buttonIcon={{ icon: isCollapsed ? "ChevronDown" : "ChevronUp" }}
        />
      </div>

      <div
        className={classNames("MapFilterSection__content", {
          "MapFilterSection__content--collapsed": isCollapsed,
          "MapFilterSection__content--full": showFullContent,
        })}
      >
        {children}
      </div>

      {!isCollapsed && renderShowAllOptionsButton ? (
        <Button
          className="FullContentToggler"
          onClick={toggleFullContent}
          buttonSize="medium"
          buttonType="link-grey"
        >
          {t(showFullContent ? "showLess" : "showAllButton")}
        </Button>
      ) : null}
    </div>
  );
}
