import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { useCurrentUserData } from "utils/hooks/useCurrentUserData";
import { FormFields } from "./FormFields";
import { HCPFormProps, HCPModel, HCPModelKey } from "./model";

export function HCP({
  entityData,
  setEntityData,
  onSubmit,
  editableFields,
  loading,
  children,
  formMode,
}: PropsWithChildren<HCPFormProps>) {
  const { isAdmin, organizationId } = useCurrentUserData();

  const onChange = useCallback(
    <T extends HCPModelKey>(value: HCPModel[T], inputKey: T) => {
      setEntityData(prevValue => ({
        ...prevValue,
        [inputKey]: value,
      }));
    },
    [setEntityData],
  );

  useEffect(() => {
    if (!isAdmin && !entityData.organizationId) {
      setEntityData(prevValue => ({
        ...prevValue,
        organizationId,
      }));
    }
  }, [isAdmin, organizationId, entityData.organizationId, setEntityData]);

  return (
    <FormFields
      onChange={onChange}
      onSubmit={onSubmit}
      entityData={entityData}
      editableFields={editableFields}
      loading={loading}
      formMode={formMode}
    >
      {children}
    </FormFields>
  );
}
