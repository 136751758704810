import { either, F, ifElse, is, isEmpty, isNil, map, pipe, reject, when } from "ramda";
import React, { PropsWithChildren } from "react";
import { assign, createMachine } from "xstate";

interface TableProp {
  className?: string;
  theadSlot?: React.ReactNode;
  header?: React.ReactNode;
  paginator?: boolean;
  rowsPerPageSelector?: boolean;
  viewTableSelector?: boolean;
  loading?: boolean;
  title?: string;
  noFilterData?: boolean;
  tableContainerAdditionalChildren?: React.ReactNode;
}
export type TableProps = PropsWithChildren<TableProp> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface BackendResponse {
  items: any[];
  [key: string | number]: any;
}

export type TableViewType = "List" | "Grid";

export interface TableContext<T extends BackendResponse, Filters extends Record<string, any>> {
  data: T;
  linesPerPage: 10 | 20 | 50; // limit
  currentPage: number; // page
  totalResults: number; // query count
  totalPages: number;
  //#region local state
  search?: string;
  error: any;
  filters?: Filters;
  countFilters: number;
  tableView: TableViewType;
  //#endregion
}

export type TableEvents<T extends any, Filters extends Record<string, any>> =
  | { type: "UPDATE_TABLE_VIEW"; value: TableViewType }
  | { type: "UPDATE_LINES_PER_PAGE"; value: 10 | 20 | 50 }
  | { type: "UPDATE_CURRENT_PAGE"; value: number }
  | { type: "SEARCH"; value: string }
  | { type: "UPDATE_FILTERS"; value?: Filters }
  | { type: "NEXT_PAGE" }
  | { type: "PREVIOUS_PAGE" }
  | { type: "FETCH" }
  | { type: "RETRY" }
  | { type: "UPDATE_DATA"; processFunction: (currentData: T) => T };

export type TableFetchFuncType<T extends BackendResponse, Filters extends Record<string, any>> = (
  context?: TableContext<T, Filters>,
) => Promise<T | undefined>;

const removeNulls: any = pipe(
  map(when(is(Object), (v: any) => removeNulls(v))),
  reject(ifElse(is(String), F, either(isEmpty, isNil))),
);

export function tableStateMachine<T extends BackendResponse, Filters extends Record<string, any>>(
  fetchFunc: TableFetchFuncType<T, Filters>,
  linesPerPage?: 10 | 20 | 50,
) {
  /** @xstate-layout N4IgpgJg5mDOIC5QBcCGAjANmAdASwDs9kBiAMQFEAVAYQAlFQAHAe1mLxYMZAA9EATAAYBOAQA4AbAEZxAdhEAWAJzS5AGhABPRAFppygMxjlygasPTFAVmmTJigL6PNaLLkwtUESOWr0eVnZkTm4kPj0DORxJeTk5aUMhcUU7Q0lNHQQBUWkhZUUZaQFY8WVrcWtnVwxsHE9vXwBVAAUAEQBBKgoAfQAZAEkAOQoAZR6WigAlCY6AcQpAtg4uHn4EXUVDaMMBS3llOWtkw0zBBTFJCUNlKV2CuScXEDc6hp8IElbO7p6aJqmUwoQyoswWS2CoTWei21jEcjKaisahUGm0iFkxl2cgKihEphkVWerw8Xg+JFGFA6UwC4SCKzCoCy6VyhzkhmskmU9gk0k06301mUMS2iXMZmsOKO1RetVJjU+ZAGfW6UxwFDaAyoEIZ0I20iFIss9wlUusZwQlkUOCi7M53Ku4mkMpJ9TJvhGAA1QS15os6csQqtwgLUqJjtZCrZ8vaMuiNpIhDhFHJYtyhJKzMpki65W6FSQWkCAGoDADyTXGvvBAchwdAocSOFU4gEeIzKTScayugEahwhkKDjD4gz1klufcOAAZnhMMgwAAnQhQHAELhgcjK1XqzXa2u6kMYxM4ax9vtJQo4iriC2SccD1QGkSyQ6GQyTuqz+dLlc4SAcAQUBbiq0w4KMHTFv6zCBlCR4IAa4g2vENiprsbbmOa8ZHMKKTbJINySkIxSfrg34LsuQE4LAqAAG6+EqoFqhqWo6kGjIRAhJ5nsUiRCFe5SVBa4gpDaxFSIo4q7LYpHUWAqCLgAxgAFhSVI0gwB7sXqvaDjgo45DkNyItIfLxlswqSI8xxCNs47iLssmwPJSmqbwsBoAuOCoNOFEABR5EIQgAJQkK6zkKSpbFwQ2eh7NaBmGcZT5mVktjRAaxT3hmNiGA5snTqgc4AK6LpuQJUFMACa0X1pxwiiBIMjyEoqhoj2hwDnIORco85iKJUkiye8K4kBAG74AQtEsAA1rgMDIFQcptKgaC1Rx6wNWIUiyAobZtRag6NVcfWPI8iYCHIw1kqNS6Liwi44EwmCrdOD0ALY4AtS3uCta1aTF9UZiYeJWBmCiHMUFr6DYA4cuyVitlcKjOM864+PA4SuoQxDrXq3ViAaZ5JJdTootDu0xN1xwJCUeS3AI10Knj8G6OkxiXXieVWDchQFIdVw2myHJcjyToFXOFF-uuBBgCzsUbLDN7FGYwgIhZFoKEmZSi0cNzxBOxJ5uRv5UQBIRAfLnGbHCyvmDkQjqzcmuGnhaHlAoJFG1OJuUauNH0RAVuhrbIkqw7TvKMJsg4DiQiJgS1iWBIEs-n7wd6Fyp5h-baspIoFoVKI7ZtrsIgVJGTkuVFAN1aG2wDnsu2HMcDmF-HA72Ge9jdQ5NhPDUPtFZgpVy7XG2IPeyaCYOMg5Pn0Odb1djjnkIkQ0zEArhnCCpNEXPbDY+1qBawqRpY2YiDIDgGgPsruDvmwyI3+xmic0PmEm75HLYViKP-g5UaOCAA */
  return createMachine(
    {
      predictableActionArguments: true,
      context: {
        data: [] as any,
        linesPerPage: linesPerPage ?? 10, // limit
        currentPage: 1,
        totalResults: 1,
        totalPages: 1,

        // internal state
        search: undefined,
        error: undefined,
        filters: undefined,
        countFilters: 0,
        tableView: "List",
      },
      tsTypes: {} as import("./model.typegen").Typegen0,
      schema: {
        context: {} as TableContext<T, Filters>,
        events: {} as TableEvents<T, Filters>,
        services: {} as {
          getTableData: {
            data: T | undefined;
          };
        },
      },
      id: "table",
      initial: "init",
      states: {
        init: {
          on: {
            FETCH: {
              target: "loading",
            },
            UPDATE_FILTERS: {
              actions: "updateFilters",
              target: "loading",
            },
          },
        },
        loaded: {
          on: {
            UPDATE_TABLE_VIEW: {
              actions: "updateTableView",
            },
            FETCH: {
              actions: "fetch",
              target: "loading",
            },
            UPDATE_LINES_PER_PAGE: {
              actions: "updateLinesPerPage",
              target: "loading",
            },
            UPDATE_CURRENT_PAGE: {
              actions: "updateCurrentPage",
            },
            UPDATE_DATA: {
              actions: "updateData",
            },
            SEARCH: {
              actions: "updateSearch",
              target: "search",
            },
            UPDATE_FILTERS: {
              actions: "updateFilters",
              target: "loading",
            },
            NEXT_PAGE: [
              {
                actions: "nextPage",
                cond: ({ currentPage, data }) => {
                  return currentPage < data.pages;
                },
                target: "loading",
              },
            ],
            PREVIOUS_PAGE: {
              actions: "previousPage",
              cond: ({ currentPage }) => currentPage > 1,
              target: "loading",
            },
          },
        },
        filtering: {
          initial: undefined,
        },
        search: {
          after: {
            "1000": {
              target: "loading",
            },
          },
          on: {
            SEARCH: {
              actions: "updateSearch",
              target: "search",
              internal: false,
            },
          },
        },
        failure: {
          on: {
            RETRY: {
              target: "loading",
            },
          },
        },
        loading: {
          invoke: [
            {
              src: "getTableData",
              id: "getTableData",
              onDone: [
                {
                  actions: "processIncomingData",
                  target: "loaded",
                },
              ],
              onError: [
                {
                  actions: "failed",
                  target: "failure",
                },
              ],
            },
          ],
        },
      },
    },
    {
      services: {
        getTableData: fetchFunc,
      },
      actions: {
        failed: (_context, event) => {
          console.error(`Failed to fetch data from ${fetchFunc.name}`, event);
        },
        fetch: assign({
          data: (_context, _event) => ({ items: [] as any }) as T,
          currentPage: _context => 1,
        }),

        updateTableView: assign({
          tableView: (_context, event) => event.value,
        }),
        updateData: assign({
          data: (_context, _event) => {
            return _event.processFunction(_context.data);
          },
        }),
        updateLinesPerPage: assign({
          linesPerPage: (_context, event) => event.value,
          data: (_context, _event) => ({ items: [] as any }) as T,
          currentPage: (_context, _event) => 1,
        }),
        updateCurrentPage: assign({
          currentPage: (_context, event) => event.value,
        }),
        updateSearch: assign({
          search: (_context, event) => event.value,
        }),
        updateFilters: assign({
          filters: (_context, event) => {
            if (event?.value === undefined) {
              return undefined;
            }

            const result = removeNulls(event.value);
            return isEmpty(result) ? undefined : result;
          },
          data: (_context, _event) => ({ items: [] as any }) as T,
          currentPage: _context => 1,
          countFilters: (_context, event) => {
            if (event.value === undefined) {
              return 0;
            }
            const result = removeNulls(event.value);
            if (isEmpty(result)) return 0;
            const resultCount = Object.keys(result).length;
            return Object.hasOwn(result, "contains") ? resultCount - 1 : resultCount;
          },
        }),
        nextPage: assign({
          currentPage: context => context.currentPage + 1,
        }),
        previousPage: assign({
          currentPage: context => context.currentPage - 1,
        }),
        processIncomingData: assign({
          data: (context, event) => {
            if (event.data && !event.data?.items) {
              return { ...event.data, items: [] };
            } else if (event.data && event.data.items) {
              return event.data;
            }

            return context.data;
          },
          totalResults: (context, event) => {
            return event.data?.queryCount ?? context.totalResults;
          },
          totalPages: (context, event) => {
            return event.data?.pages ?? context.totalPages;
          },
        }),
      },
    },
  );
}
