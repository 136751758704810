import className from "classnames";
import { useAppMenuContext } from "context/AppMenuContext";
import "./Hamburger.scss";

export const Hamburger = () => {
  const { menuOpen, setMenuOpen } = useAppMenuContext();

  return (
    <button
      className={className("Hamburger", {
        "Hamburger--active": menuOpen,
      })}
      type="button"
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <span className="Hamburger__bar Hamburger__bar--top" />
      <span className="Hamburger__bar Hamburger__bar--middle" />
      <span className="Hamburger__bar Hamburger__bar--bottom" />
    </button>
  );
};
