import classNames from "classnames";
import { FieldLoader } from "../FieldLoader";
import React, { forwardRef, RefObject, useState } from "react";
import { FieldValidation } from "../FieldValidation";
import { Icon, isIconType } from "../Icon";
import { FormFieldBasic } from "../models";
import { ElementIcon } from "../models/common";
import { renderValidElement } from "../utils";
import i18n from "i18next";
import "./Input.scss";

type HTMLInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface InputProps extends HTMLInputProps, FormFieldBasic {
  inputClassName?: string;
  inputIcon?: ElementIcon;
  leadingElement?: React.ReactNode;
  inputRef?: RefObject<HTMLInputElement>;
  loading?: boolean;
  optional?: boolean;
}

export const Input = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      label,
      validation,
      className,
      inputClassName,
      inputIcon,
      variant = "regular",
      value,
      onFocus,
      onBlur,
      readOnly,
      suffix,
      leadingElement,
      inputRef,
      loading,
      optional,
      ...props
    },
    ref,
  ) => {
    const [active, setActive] = useState(false);
    const { successText, errorText } = validation ?? {};
    const { icon, iconPosition } = inputIcon ?? {};

    const onInputFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onFocus) {
        onFocus(event);
      }

      setActive(true);
    };

    const onInputBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onBlur) {
        onBlur(event);
      }

      setActive(false);
    };

    const isLabelActive = !!value || !!inputRef?.current?.value || !!props.defaultValue || active;

    const iconPositionClass = iconPosition ? `Input__element--${iconPosition}` : undefined;

    const hasValidLeadingElement = React.isValidElement(leadingElement);

    const iconClickable = !props.disabled;

    const iconClassName = classNames(`Icon--input`, {
      "Icon--disabled": props.disabled,
      "Icon--error": errorText,
      "Icon--clickable": iconClickable,
      "Icon--trailing": iconPosition === "trailing",
      "Icon--leading": iconPosition === "leading",
    });

    if (loading) {
      return <FieldLoader />;
    }

    return (
      <div ref={ref} className={classNames(`UI-Components Input`, className)} data-type={variant}>
        {inputIcon &&
          (isIconType(icon) ? (
            <Icon icon={icon} className={iconClassName} />
          ) : (
            renderValidElement(icon, {
              className: classNames(iconClassName, icon?.props.className),
            })
          ))}

        {renderValidElement(<div className="Input__leading-element">{leadingElement}</div>)}

        {readOnly ? (
          <p
            role="paragraph"
            className={classNames(
              "Input__element Input__element--disabled",
              iconPositionClass,
              inputClassName,
              {
                "Input__element--with-suffix": suffix,
                "Input__element--leading": hasValidLeadingElement,
              },
            )}
          >
            {value}
          </p>
        ) : (
          <input
            ref={inputRef}
            {...props}
            className={classNames(
              "Input__element",
              {
                "Input__element--error": errorText,
                "Input__element--success": successText,
                "Input__element--with-suffix": suffix,
                "Input__element--disabled": props.disabled,
                "Input__element--leading": hasValidLeadingElement,
              },
              iconPositionClass,
              inputClassName,
            )}
            value={value}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            readOnly={readOnly}
          />
        )}

        {label && (
          <label
            className={classNames("Input__label", {
              "Input__label--leading": iconPosition === "leading" || hasValidLeadingElement,
              "Input__label--active": isLabelActive,
              "Input__label--error": !!errorText,
              "Input__label--success": !!successText,
            })}
          >
            {optional
              ? i18n.t("ui-components.Input.optional-label", { ns: "translation", label })
              : label}
          </label>
        )}
        {suffix && <span className="Input__suffix">{suffix}</span>}
        <FieldValidation {...validation} />
      </div>
    );
  },
);
