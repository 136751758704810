/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeasurementsOptionsModel,
    MeasurementsOptionsModelFromJSON,
    MeasurementsOptionsModelFromJSONTyped,
    MeasurementsOptionsModelToJSON,
} from './MeasurementsOptionsModel';

/**
 * 
 * @export
 * @interface LaboratoryModel
 */
export interface LaboratoryModel {
    /**
     * 
     * @type {MeasurementsOptionsModel}
     * @memberof LaboratoryModel
     */
    measurements: MeasurementsOptionsModel;
}

export function LaboratoryModelFromJSON(json: any): LaboratoryModel {
    return LaboratoryModelFromJSONTyped(json, false);
}

export function LaboratoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaboratoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurements': MeasurementsOptionsModelFromJSON(json['Measurements']),
    };
}

export function LaboratoryModelToJSON(value?: LaboratoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Measurements': MeasurementsOptionsModelToJSON(value.measurements),
    };
}

