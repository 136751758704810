/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RangeModel
 */
export interface RangeModel {
    /**
     * 
     * @type {number}
     * @memberof RangeModel
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof RangeModel
     */
    to: number;
}

export function RangeModelFromJSON(json: any): RangeModel {
    return RangeModelFromJSONTyped(json, false);
}

export function RangeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RangeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': json['From'],
        'to': json['To'],
    };
}

export function RangeModelToJSON(value?: RangeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'From': value.from,
        'To': value.to,
    };
}

